import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import cookie from "react-cookies";

class CustomerMenuComponent extends Component {
  state = {
    isLoggedin: false,
  };

  componentWillMount() {
    const token = cookie.load("APP_AT");
    if (token !== undefined) {
      this.setState({ isLoggedin: true });
    }
  }

  render() {
    return (
      <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
        <ul className="mb-1 top-content">
          <li className="logo d-none d-xl-block d-lg-block"></li>

          {process.env.REACT_APP_IS_OUTOFSTOCK !== "Y" && (
            <li>
              <Link
                onClick={() => (window.location.href = "/lottery-search")}
                to="/lottery-search"
                className="nav-content-bttn open-font"
              >
                <i className="feather-credit-card btn-round-md bg-customer-menu-ico me-3"></i>
                {/* <img src={`/assets/images/1+1.png`} alt="avater" width="40" /> */}
                <span>ซื้อลอตเตอรี่</span>
              </Link>
            </li>
          )}

          {/* <li><Link onClick={()=> window.location.href = "/lottery-search" } to="/lottery-search" className="nav-content-bttn open-font"><i className="feather-credit-card btn-round-md bg-red-gradiant me-3"></i><span>ซื้อสลาก</span></Link></li> */}
          <li>
            <Link to="/news" className="nav-content-bttn open-font">
              {/* <li></li> */}
              {/* <img src={`/assets/images/1+4.png`} alt="avater" width="34" />
               */}
              <i className="feather-home btn-round-md bg-customer-menu-ico me-3"></i>
              <span>เกี่ยวกับเรา</span>
            </Link>
          </li>
          {this.state.isLoggedin && (
            <li>
              <Link to="/cart" className="nav-content-bttn open-font">
                {/* <img src={`/assets/images/1+2.png`} alt="avater" width="40" /> */}
                <i className="feather-shopping-cart btn-round-md bg-customer-menu-ico me-3"></i>
                <span>ตะกร้า</span>
              </Link>
            </li>
          )}
          {this.state.isLoggedin && (
            <li>
              <Link to="/payment" className="nav-content-bttn open-font">
                {/* <img src={`/assets/images/1+5.png`} alt="avater" width="40" /> */}
                <i className="feather-list btn-round-md bg-customer-menu-ico me-3"></i>
                <span>คำสั่งซื้อรอชำระ</span>
              </Link>
            </li>
          )}
          {this.state.isLoggedin && (
            <li>
              <Link to="/order-history" className="nav-content-bttn open-font">
                {/* <img src={`/assets/images/1+3.png`} alt="avater" width="40" /> */}
                <i className="feather-bar-chart btn-round-md bg-customer-menu-ico me-3"></i>
                <span>ประวัติการสั่งซื้อ</span>
              </Link>
            </li>
          )}
          {this.state.isLoggedin && (
            <li>
              <Link to="/vault" className="nav-content-bttn open-font">
                {/* <img src={`/assets/images/1+3.png`} alt="avater" width="40" /> */}
                <i className="feather-server btn-round-md bg-customer-menu-ico me-3"></i>
                <span>ตู้เซฟ</span>
              </Link>
            </li>
          )}
          {/*<li><Link to="/contactinformation" className="nav-content-bttn open-font"><i className="feather-globe btn-round-md bg-gold-gradiant me-3"></i><span>บริการของเรา</span></Link></li>*/}
          {/* <li><Link to="/userpage" className="nav-content-bttn open-font"><i className="feather-users btn-round-md bg-primary-gradiant me-3"></i><span>ตัวแทนจำหน่าย </span></Link></li> */}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {};
};

const CustomerMenu = connect(mapStateToProps)(CustomerMenuComponent);
export default CustomerMenu;
