import React, { Component, Fragment } from "react";
import { connect } from 'react-redux'
import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import PageTitle from "../components/PageTitle";

class CustomerListComponent extends Component {


  // componentDidMount() {
  //   this.initialPage()
  // }

  // initialPage = () => {
  //   const { dispatch } = this.props
  //   dispatch(getCartData())
  // }

  // handleRemoveFromCart = (basketId) => {
  //   const { dispatch } = this.props
  //   console.log("handleRemoveFromCart")
  //   dispatch(removeFromCart(basketId))
  // }

  // handleCartSubmit = () => {
  //   const { dispatch } = this.props
  //   console.log("handleCartSubmit")
  //   dispatch(cartSubmit())
  // }

  render() {
    // const { cart_list, cart_sumtopay } = this.props
    // let sumAmount = 0
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        <div className="main-content">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0" >
              {/* CONTENT HERE */}

              <PageTitle title="จัดการสมาชิก" />

              <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                <h4 className="mont-font fw-600 font-md mb-2 mb-0 mt-0">ค้นหา</h4>

                <div className="row">
                  <div className="col-sm-3 col-xs-12">
                    <div className="form-group lottery-digit">
                      <label className="mont-font fw-600 font-xsss mb-2">สถานะ</label>
                      <select className="form-control" name="status" onChange={this.handleInputChange} >
                        <option value="A">ทั้งหมด</option>
                        <option value="P">รอแจ้งชำระ</option>
                        <option value="S">ชำระแล้ว</option>
                        <option value="C">ยกเลิก</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3 col-xs-12">
                    <label className="mont-font fw-600 font-xsss mb-2">&nbsp;</label>
                    <button onClick={this.handleSubmit} className="update-cart bg-blue-gradiant d-block text-white fw-600 text-uppercase 
                    font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0">ค้นหา</button>
                  </div>
                </div>

              </div>
              <div className="row payment-list mb-hide">
                <div className="col-lg-12 mb-3">
                  <div className="table-content table-responsive">
                    <table className="table text-center">
                      <thead className="bg-greyblue rounded-3">
                        <tr>
                          <th className="border-0 p-4">ลำดับ</th>
                          <th className="border-0 p-4 text-left">Username</th>
                          {/* <th className="border-0 p-4">วันที่ทำรายการ</th>
                          <th className="border-0 p-4">จำนวนสลาก</th>
                          <th className="border-0 p-4">จำนวนเงิน</th> */}
                          <th className="border-0 p-4">สถานะ</th>
                          {/* <th className="border-0 p-4">&nbsp;</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {/* {
                          payment_list != null &&
                          payment_list.map((data, index) => {
                            return [
                              <tr key={index} className="data-row">
                                <td className="product-thumbnail ps-0">{index + 1}</td>
                                <td className="product-headline text-left wide-column">
                                  <h3>{data.paymentNo}</h3>
                                </td>
                                <td className="product-quantity">
                                  {data.paymentDate}
                                </td>
                                <td className="product-quantity">
                                  {data.totalOrder} <br />
                                  <div className={`nav-content-bttn open-font me-3 d-inline-block float-right lotto-view-btn lotto-view-btn-${index} hide`}
                                    onClick={() => this.handleViewLottoTable(index)}
                                  >
                                    <i className="feather-zoom-in btn-round-xs"></i><span>ดูสลาก</span></div>
                                </td>
                                <td className="product-quantity">
                                  {data.totalAmount}
                                </td>
                                <td className="product-quantity">
                                  {
                                    data.status === "C" &&
                                    <div className="text-red">{data.statusText}<br /></div>
                                  }
                                  {
                                    data.status !== "C" &&
                                    <div>{data.statusText}<br /></div>
                                  }
                                  
                                </td>
                              </tr>
                            ]
                          })} */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              paging

              {/* CONTENT ABOVE */}
            </div>
          </div>
        </div>
        {/* POPUP HERE */}

        {/* POPUP ABOVE */}
        <Appfooter />
      </Fragment>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {
    // cart_list: state.cart.cart_list || [],
    // cart_sumtopay: state.cart.cart_sumtopay || 0,
  }
}

const CustomerList = connect(mapStateToProps)(CustomerListComponent)
export default CustomerList;