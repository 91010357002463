import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { showDisplayPopupError } from '../actions/display';
// import { hideDisplayPopupQR } from '../actions/display';

class PopupBuyNowComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      telno: "",
      custFirstname: "",
      custLastname: "",
    }
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  close = () => {
    const { onClose } = this.props
    //Reset data
    this.setState({
      telno: "",
      custFirstname: "",
      custLastname: "",
    });
    onClose()
  }


  handleSubmit = () => {
    const { data, onSubmit } = this.props
    // console.log(this.state)
    //TODO Validate value before submit to backend
    const validate = this.validateForm()
    if (!validate.status) {
      this.showErrorPopup("Error!!", validate.message)
    }
    else{
      onSubmit(data, data.packNum, this.state)
    }
  }
  
  validateForm = () => {
    const validateRequired = this.validateFormRequiredField()
    if (!validateRequired.status) {
      return validateRequired
    }
    const validateFormFieldFormat = this.validateFormFieldFormat()
    if(!validateFormFieldFormat.status){
      return validateFormFieldFormat
    }
    return {
      "status": true,
    }
  }


  validateFormRequiredField = () => {
    let errorArray = [];
    if(this.state.telno === ""){
      errorArray.push("หมายเลขโทรศัพท์");
    }
    if(this.state.custFirstname === ""){
      errorArray.push("ชื่อ");
    }
    if(this.state.custLastname === ""){
      errorArray.push("นามสกุล");
    }
    if(errorArray.length > 0){
      return { 
        "status": false,
        "message": `<b>กรุณากรอกข้อมูล</b><br/>- ${errorArray.join("<br/>- ")}`
      }
    }
    return { 
      "status": true,
    }
  }

  validateWithRegex = (data, regEx) => {
    return regEx.test(data);
  }

  validateFormFieldFormat = () => {
    let errorArray = [];
    var phoneno = this.state.telno;
    var firstname = this.state.custFirstname;
    var lastname = this.state.custLastname;
    var patternNameRegEx = /^[A-Za-zก-๙]+$/ 
    var patternNumberOnlyRegEx = /^[0-9]+$/
    
    if(!this.validateWithRegex(phoneno, patternNumberOnlyRegEx)){
      errorArray.push("หมายเลขโทรศัพท์ ต้องเป็นตัวเลขเท่านั้น");
    }
    if(!this.validateWithRegex(firstname, patternNameRegEx)){
      errorArray.push("ชื่อ ต้องเป็นภาษาไทยหรือภาษาอังกฤษเท่านั้น");
    }
    if(!this.validateWithRegex(lastname, patternNameRegEx)){
      errorArray.push("นามสกุล ต้องเป็นภาษาไทยหรือภาษาอังกฤษเท่านั้น");
    }
    if(errorArray.length > 0){
      return { 
        "status": false,
        "message": `<b>กรุณากรอกข้อมูลให้ถูกต้อง</b><br/>- ${errorArray.join("<br/>- ")}`
      }
    }
    return { 
      "status": true,
    }
  }

  showErrorPopup = (title, message) => {
    const { dispatch } = this.props
    dispatch(showDisplayPopupError({
      "title": title,
      "message": message
    }))
  }

  // close = () => {
  //   const { dispatch } = this.props
  //   dispatch(hideDisplayPopupQR())
  // }

  // print = () => {
  //   window.print()
  // }

  render() {
    const { 
      is_show, 
    } = this.props
    return (
      <Modal centered backdrop="static" show={is_show} >
        <article className={`message is-primary`}>
          <div className="message-header">
            <p>กรอกข้อมูลติดต่อเบื้องต้น</p>
          </div>
          <div className="message-body">

            <div className="row pt-3"> 
              <div>
              <div className="form-group icon-input mb-3 error">
                  <i className="font-sm ti-mobile text-red pe-0"></i>
                  <input type="text" name="telno" value={this.state.telno} onChange={this.handleInputChange}
                    className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="หมายเลขโทรศัพท์" />
                </div>
                <div className="form-group icon-input mb-3">
                  <i className="font-sm ti-user text-red  pe-0"></i>
                  <input type="text" name="custFirstname" value={this.state.custFirstname} onChange={this.handleInputChange}
                    className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="ชื่อ" />
                </div>
                <div className="form-group icon-input mb-3">
                  <i className="font-sm ti-user text-red  pe-0"></i>
                  <input type="text" name="custLastname" value={this.state.custLastname} onChange={this.handleInputChange}
                    className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="นามสกุล" />
                </div>
              </div>
              

                <div className="col-sm-8 col-xs-7 pt-3">
                  <button className="button is-success w-100" onClick={() => this.handleSubmit()}>ตกลง</button>
                </div>
                <div className="col-sm-4 col-xs-5 pt-3">
                  <button className="button is-danger w-100" onClick={() => this.close()}>ยกเลิก</button>
                </div>
            </div>
              
          </div>

        </article>
      </Modal>
    );
  }
}


const mapStateToProps = function (state, props) {
  return {
  }
}

const PopupBuyNow = connect(mapStateToProps)(PopupBuyNowComponent)
export default PopupBuyNow;