import React, { useState, useEffect, Fragment } from "react";
import { redirect, useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Popupchat from "../components/Popupchat";
import {
  showDisplayPopupError,
  showDisplayPopupMessage,
} from "../actions/display";
import { getFileByFileIdAndSetStage } from "../actions/file";
import {
  getDealerDetailData,
  registerDealerToCustomer,
  updateDealerData,
} from "../actions/dealer";
import PageTitle from "../components/PageTitle";
import { getBankAccountList } from "../actions/bank";
import { getQRImageAndShow } from "../actions/file";

const defaultFormData = {
  dealerid: "",
  code: "",
  name: "",
  idcard: "",
  telephoneno: "",
  address: "",
  province: "",
  lineid: "",
  bankid: "",
  bankacct: "",
  bankacctname: "",
  fileName: "No file uploaded",
  file: null,
};

const formLang = {
  dealerid: "รหัสร้านค้า",
  code: "รหัสร้านค้า",
  name: "ชื่อ นามสกุล",
  idcard: "รหัสบัตร",
  telephoneno: "เบอร์โทรศัพท์",
  address: "ที่อยู่",
  province: "จังหวัด",
  lineid: "ไลน์",
  bankid: "id บัญชีธนาคาร",
  bankacct: "ธนาคาร",
  bankacctname: "ชื่อบัญชีธนาคาร",
  fileName: "ชื่อไฟล์",
  file: "ไฟล์",
};

const DealerProfileFC = (props) => {
  const { dealer_id } = useParams();

  const { dealer_detail, dealer_doc_file, bank_list, user_detail } = props;

  const [formData, setFormData] = useState(defaultFormData);
  const [pubLink, setPubLink] = useState("");
  const dispatch = useDispatch();

  const validateFileType = (fileType) => {
    console.log(fileType);
    const allowedExtensions = ["image/png", "image/jpeg"];
    return allowedExtensions.includes(fileType);
  };

  const validateFormRequiredField = () => {
    let errorArray = [];
    if (errorArray.length > 0) {
      return {
        status: false,
        message: `<b>กรุณากรอกข้อมูล</b><br/>- ${errorArray.join("<br/>- ")}`,
      };
    }
    return {
      status: true,
    };
  };

  const validateForm = () => {
    const validated = validateFormRequiredField();
    if (!validated.status) {
      return validated;
    }
    return {
      status: true,
    };
  };

  const showErrorPopup = (title, message) => {
    dispatch(
      showDisplayPopupError({
        title: title,
        message: message,
      })
    );
  };

  const showMessagePopup = (title, message) => {
    dispatch(
      showDisplayPopupMessage({
        title: title,
        message: message,
      })
    );
  };

  const handleInputChange = (event) => {
    console.log("handleInputChange");
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({ ...formData, ...{ [name]: value } });
  };

  const handleFileChange = (e) => {
    console.log("handleFileChange");
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileType = file.type;
      if (validateFileType(fileType)) {
        const fileName = file.name;
        setFormData({ ...formData, ...{ file_name: fileName, file: file } });
      } else {
        showErrorPopup("Error!!", "File type allow: .png, .jpg");
        e.target.value = "";
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validated = validateForm();
    if (!validated.status) {
      showErrorPopup("Error!!", validated.message);
    } else {
      dispatch(updateDealerData(formData));
    }
  };

  const initialPage = () => {
    if (
      (user_detail.is_admin && dealer_id) ||
      (user_detail.is_dealer && user_detail.dealer_id)
    ) {
      const id = dealer_id || user_detail.dealer_id;

      setFormData({ ...formData, ...{ dealerid: id } });
      console.log("id", id);
      dispatch(getDealerDetailData(id, "id"));
    } else {
      if (user_detail.is_dealer) {
        redirect("/defaultsettings");
      } else if (user_detail.is_admin) {
        redirect("/dealerpage");
      } else {
        redirect("/");
      }
    }
  };

  const fetchDealerDetailData = (data) => {
    console.log("fetchDealerDetailData");
    setPubLink(
      `${window.location.protocol}//${window.location.host}?dealer=${data.detail_code}`
    );
    setFormData({
      dealerid: data.id,
      code: data.detail_code,
      name: data.detail_name,
      idcard: data.detail_idcard,
      telephoneno: data.detail_telno,
      address: data.detail_address,
      province: data.detail_province,
      lineid: data.detail_lineid,
      bankid: data.bank_bankid === null ? "" : data.bank_bankid,
      bankacct: data.bank_bankacct === null ? "" : data.bank_bankacct,
      bankacctname:
        data.bank_bankacctname === null ? "" : data.bank_bankacctname,
    });
    if (data.detail_fileid) {
      dispatch(getFileByFileIdAndSetStage(data.detail_fileid));
    }
  };

  useEffect(() => {
    if (user_detail) {
      initialPage();
      dispatch(getBankAccountList());
    }
  }, [user_detail]);

  useEffect(() => {
    if (dealer_detail) {
      console.log(dealer_detail);
      fetchDealerDetailData(dealer_detail);
    }
  }, [dealer_detail]);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      {/* <Rightchat /> */}

      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row lottery-search">
              <div className="col-xl-12">
                <PageTitle title="ข้อมูลร้านค้า" />

                <div className="text-right px-5">
                  {pubLink && (
                    <span className="mr-5">
                      <code>{pubLink}</code>
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => {
                          navigator.clipboard.writeText(pubLink);
                          showMessagePopup("copied", "คัดลอกลิงก์สำเร็จ");
                        }}
                      >
                        <span className="fa-regular fa-copy w-5 h-5 text-black font-xs"></span>
                      </button>
                    </span>
                  )}

                  <button
                    className="border-0 bg-transparent mr-5"
                    onClick={() => {
                      dispatch(getQRImageAndShow(dealer_detail, "dealer"));
                    }}
                  >
                    <span className="fa fa-qrcode w-5 h-5 text-facebook font-xs"></span>
                  </button>
                  {!dealer_id && !user_detail.is_customer && (
                    <button
                      className=""
                      onClick={() => {
                        dispatch(registerDealerToCustomer(dealer_detail.id));
                      }}
                    >
                      สร้างตู้เซฟ
                    </button>
                  )}
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          รหัสร้านค้า
                        </label>
                        <input
                          type="text"
                          value={formData.code}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mb-3">
                    <div className="form-group row-icon-input col-6">
                      <i className="font-sm ti-user text-grey-500 pe-0"></i>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="ชื่อร้านค้า"
                      />
                    </div>
                    <div className="form-group row-icon-input col-6">
                      <i className="font-sm ti-id-badge text-grey-500 pe-0"></i>
                      <input
                        type="text"
                        name="idcard"
                        value={formData.idcard}
                        onChange={handleInputChange}
                        maxLength="13"
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="รหัสบัตรประชาชน"
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <div className="form-group row-icon-input mb-3 col-6">
                      <i className="font-sm ti-mobile text-grey-500 pe-0"></i>
                      <input
                        type="text"
                        name="telephoneno"
                        value={formData.telephoneno}
                        onChange={handleInputChange}
                        maxLength="10"
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="เบอร์โทร"
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <div className="form-group row-icon-input col-6">
                      <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="ที่อยู่"
                      />
                    </div>
                    <div className="form-group row-icon-input col-6">
                      <input
                        type="text"
                        name="province"
                        value={formData.province}
                        onChange={handleInputChange}
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="จังหวัด"
                      />
                    </div>
                  </div>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-user text-grey-500 pe-0"></i>
                    <input
                      type="text"
                      name="lineid"
                      value={formData.lineid}
                      onChange={handleInputChange}
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                      placeholder="Line id (ถ้ามี)"
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          ข้อมูลใบเอกสารผู้ค้าสลาก (ถ้ามี)
                        </label>
                        {Object.keys(dealer_doc_file).length === 0 && (
                          <div>
                            <img src={dealer_doc_file.file_url} alt="" />
                            <br />
                            <br />
                          </div>
                        )}
                        <input
                          type="file"
                          onChange={handleFileChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <h4 className="fw-700 display1-size display2-md-size mb-4">
                    ข้อมูลบัญชีธนาคาร
                  </h4>

                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          ธนาคาร
                        </label>
                        <select
                          value={formData.bankid}
                          onChange={handleInputChange}
                          name="bankid"
                          className="style2-select form-control text-grey-900 font-xsss fw-600"
                        >
                          <option key="0" value="0">
                            -- กรุณาเลือก --
                          </option>
                          {bank_list.map((data, i) => {
                            return (
                              <option key={i} no={i + 1} value={data.bankId}>
                                {data.bankName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          ชื่อบัญชี
                        </label>
                        <input
                          type="text"
                          name="bankacctname"
                          value={formData.bankacctname}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          เลขที่บัญชี
                        </label>
                        <input
                          type="text"
                          name="bankacct"
                          value={formData.bankacct}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      {/* <a href="/accountinformation" 
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">บันทึก</a> */}
                      <input
                        type="submit"
                        className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 "
                        value="บันทึก"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      {/* <Appfooter />  */}
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    user_detail: state.user.user_detail || {},
    dealer_detail: state.dealer.dealer_detail || {},
    bank_list: state.bank.bank_list || {},
    dealer_doc_file: state.file.current_file || {},
  };
};

const DealerProfile = connect(mapStateToProps)(DealerProfileFC);
export default DealerProfile;
