import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import cookie from "react-cookies";
import LeftMenuPane from "./LeftMenuPane";

import packageJson from "../../package.json";
import { getDealerDetailData } from "../actions/dealer";
import { getInstallmentInfo } from "../actions/number";
import { logout } from "../actions/authen";
import { ServerInfo, ServerInfo_Code } from "../constants/config";
import Session from "../actions/session";
import { useSubscription } from "@apollo/client";
import { GET_BASKET_COUNT_SUBSCRIPTION } from "../graphql/query";
import HeaderBastket from "./HeaderBasket";

const HeaderComponent = (props) => {
  const { dealer_ref_detail, user_detail } = props;

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isNoti, setIsNoti] = useState(false);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [dealerinp, setDealerinp] = useState("");

  const toggleOpen = () => setIsOpen(!isOpen);
  const toggleActive = () => setIsActive(!isActive);
  const toggleisNoti = () => setIsNoti(!isNoti);

  const handleSubmit = () => {
    const dealerCode = dealerinp;
    const dealerId = dealerCode.replace("d", "") - 0;
    cookie.save("dealer_ref", dealerinp, {
      path: "/",
      expires: new Date(Date.now() + 10 * 365 * 24 * 60 * 60 * 1000),
      secure: true,
    });
    dispatch({
      type: "DEALER_REF",
      value: dealerCode,
    });
    // console.log(dealerCode);
    dispatch(getDealerDetailData(dealerCode, "code", true));
  };

  useEffect(() => {
    const token = cookie.load("APP_AT");
    if (token !== undefined) {
      const formData = new FormData();
      formData.append("token", Session.getUserToken());
      fetch(`${ServerInfo.URL_CHECK_TOKEN}`, {
        method: "POST",
        headers: {
          Authorization: ServerInfo.URL_LOGIN_AUTH,
        },
        body: formData,
      })
        .then((resp) => {
          if (resp.status === 400) {
            dispatch(logout());
          }
        })
        .catch((e) => {
          // dispatch(logout());
        });
      setIsLoggedin(true);
    }

    dispatch(getInstallmentInfo());
  }, []);

  return (
    <div className="nav-header bg-white shadow-xs border-0">
      {!dealer_ref_detail?.id && (
        <div
          className="dealer-form"
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            background: "#F2F2F2",
            zIndex: 999,
            top: 0,
            left: 0,
          }}
        >
          <div className="container enter-dealer-code-wrp">
            <div className="frm-wrp">
              <img
                src={`/assets/images/logonew.jpeg`}
                alt="avater"
                className=""
                style={{ width: "300px" }}
              />
              <br />
              {/* <h2>ใส่รหัสตัวแทนเพื่อดำเนินการต่อ</h2> */}
              <form>
                <h1>กรุณาใส่รหัสร้านค้าตัวแทน</h1>
                <div className="ui divider"></div>
                <div className="ui form">
                  <div className="field">
                    {/* <label>Username</label> */}
                    <br />
                    <input
                      type="text"
                      name="dealercode"
                      placeholder="d000xx"
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                      onChange={(e) => {
                        setDealerinp(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                      // window.location.href = "/";
                    }}
                    className="fluid ui button blue"
                  >
                    ค้นหา
                  </button>
                </div>
              </form>
              <div className="form-msg">
                กรณีไม่รู้รหัสตัวแทน หรือ รหัสตัวแทนที่ระบุไม่ถูกต้อง หรือ
                ได้ถูกยกเลิกไปแล้ว
                <br />
                กรุณาติดต่อตัวแทนเพื่อรับรหัส หรือติดต่อแอดมินที่{" "}
                <Link to="https://lin.ee/If6kbq7">https://lin.ee/If6kbq7</Link>
                เพื่อสุ่มรับรหัสตัวแทนใหม่
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="nav-top">
        <Link onClick={() => (window.location.href = "/")} to="/">
          <img
            src={`/assets/images/web-logo.jpg`}
            alt="avater"
            className="shadow-sm rounded-circle web-icon"
          />
          <span className="d-inline-block fredoka-font ls-3 fw-700 text-current font-xxl logo-text mb-0">
            มหาชน
          </span>
          <p style={{ display: "none" }}>Build date: {packageJson.buildDate}</p>
        </Link>

        <Link to="/" className="mob-menu ms-auto me-2 chat-active-btn">
          {" "}
        </Link>
        <span onClick={toggleActive} className="me-2 menu-search-icon mob-menu">
          {" "}
        </span>

        {!isLoggedin && (
          <Link
            to="/register-cust"
            className="mob-menu ms-auto me-2 chat-active-btn"
          >
            ลงทะเบียน
          </Link>
        )}
        {!isLoggedin && (
          <Link to="/login" className="mob-menu ms-auto me-2 chat-active-btn">
            เข้าสู่ระบบ
          </Link>
        )}
        {/* {
                        this.state.isLoggedin &&
                        <Link to="/defaultsettings" className="mob-menu ms-auto me-2 chat-active-btn">Login</Link>
                        <Link to="/defaultsettings" 
                            className="p-0 ms-3 menu-icon">
                            <img src="/assets/images/user.png" alt="user" className="w40 mt--1" />        
                        </Link>
                    } */}

        {/* <Link to="/register-cust" className="mob-menu ms-auto me-2 chat-active-btn">Register</Link>                   */}
        <button
          onClick={toggleOpen}
          className="bg-cyan text-white fw-600 rounded-2 d-block d-lg-none"
          style={{ padding: "5px 10px", border: "none" }}
        >
          <i className="feather-menu"></i> เมนู
        </button>
      </div>
      {/* <form action="#" className="float-left header-search ms-3">
          <div className="form-group mb-0 icon-input">
            <i className="feather-search font-sm text-grey-400"></i>
            <input
              type="text"
              placeholder="กรอกคำค้นหา"
              className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg"
            />
          </div>
        </form> */}
      {/* <Link onClick={() => (window.location.href = "/")} to="/">
          <NavLink
            activeClassName="active"
            to="/"
            className="p-2 text-center ms-3 menu-icon center-menu-icon"
          >
            <i className="feather-home font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
          </NavLink>
        </Link> */}
      {/*<NavLink activeClassName="active" to="/defaultstorie" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-zap font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
        <NavLink activeClassName="active" to="/defaultvideo" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-video font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> 
                  <NavLink activeClassName="active" to="/defaultgroup" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-user font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> */}
      <span className={`ms-auto`}></span>
      {isLoggedin && user_detail.is_customer && <HeaderBastket />}
      {/* {
          this.state.isLoggedin && (
            <span
              className={`p-2 pointer text-center ms-auto menu-icon ${notiClass}`}
              id="dropdownMenu3"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={this.toggleisNoti}
            >
              <span className="dot-count bg-warning"></span>
              <i className="feather-bell font-xl text-current"></i>
            </span>
          )
          // <div className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${notiClass}`} aria-labelledby="dropdownMenu3">
          //     <h4 className="fw-700 font-xss mb-4">Notification</h4>
          //     <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
          //         <img src="/assets/images/user.png" alt="user" className="w40 position-absolute left-0" />
          //         <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Hendrix Stamp <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 3 min</span></h5>
          //         <h6 className="text-grey-500 fw-500 font-xssss lh-4">There are many variations of pass..</h6>
          //     </div>
          //     <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
          //         <img src="/assets/images/user.png" alt="user" className="w40 position-absolute left-0" />
          //         <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Goria Coast <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 2 min</span></h5>
          //         <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
          //     </div>

          //     <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
          //         <img src="/assets/images/user.png" alt="user" className="w40 position-absolute left-0" />
          //         <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Surfiya Zakir <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 1 min</span></h5>
          //         <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
          //     </div>
          //     <div className="card bg-transparent-card w-100 border-0 ps-5">
          //         <img src="/assets/images/user.png" alt="user" className="w40 position-absolute left-0" />
          //         <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Victor Exrixon <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 30 sec</span></h5>
          //         <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
          //     </div>
          // </div>
        } */}
      {
        //this.state.isLoggedin &&
        //<Link to="/defaultmessage" className="p-2 text-center ms-3 menu-icon chat-active-btn">
        //  <i className="feather-message-square font-xl text-current"></i>
        //</Link>
      }
      {/* <Darkbutton /> */}
      {!isLoggedin && (
        <Link
          to="/register-cust"
          className="ms-auto header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl"
        >
          ลงทะเบียน
        </Link>
      )}
      {!isLoggedin && (
        <Link
          to="/login"
          className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl"
        >
          เข้าสู่ระบบ
        </Link>
      )}
      {isLoggedin && (
        <Link
          to="/defaultsettings"
          className="p-1 ms-3 menu-icon  btn-round-lg  bg-greylight"
        >
          <img src="/assets/images/user.png" alt="user" className="w35 mt--1" />
        </Link>
      )}
      <LeftMenuPane navClass={isOpen ? " nav-active" : ""} />
      {/* <div className={`app-header-search ${isActive ? " show" : ""}`}>
        <form className="search-form">
          <div className="form-group searchbox mb-0 border-0 p-1">
            <input
              type="text"
              className="form-control border-0"
              placeholder="Search..."
            />
            <i className="input-icon">
              <ion-icon
                name="search-outline"
                role="img"
                className="md hydrated"
                aria-label="search outline"
              ></ion-icon>
            </i>
            <span className="ms-1 mt-1 d-inline-block close searchbox-close">
              <i className="ti-close font-xs" onClick={toggleActive}></i>
            </span>
          </div>
        </form>
      </div> */}
      <CookieConsent
        // enableDeclineButton
        overlay
        location="bottom"
        buttonText="ยอมรับ"
        cookieName="mahachonCookieConsent"
        style={{ background: "#2B373B", marginBottom: "50px" }}
        expires={1}
      >
        <div>เว็บไซต์นี้ใช้คุกกี้</div> mahachonlottery
        ให้ความสำคัญต่อข้อมูลส่วนบุคคลของท่าน เพื่อการพัฒนาและปรับปรุงเว็บไซต์
        หากท่านใช้บริการเว็บไซต์นี้โดยไม่มีการปรับตั้งค่าใดๆ
        แสดงว่าท่านยินยอมที่จะรับ
        <a
          href="/cookie-policy"
          style={{
            textDecorationLine: "underline",
            color: "rgb(255, 212, 45)",
          }}
        >
          นโยบายคุกกี้
        </a>
        บนเว็บไซต์ ,
        <a
          href="/privacy"
          style={{
            textDecorationLine: "underline",
            color: "rgb(255, 212, 45)",
          }}
        >
          นโยบายความเป็นส่วนตัวของเรา
        </a>{" "}
        และ{" "}
        <a
          href="/terms"
          style={{
            textDecorationLine: "underline",
            color: "rgb(255, 212, 45)",
          }}
        >
          ข้อกำหนดและเงื่อนไขการใช้งาน
        </a>
      </CookieConsent>
    </div>
  );
};

const mapStateToProps = function (state, props) {
  return {
    dealer_ref_detail: state.dealer.dealer_ref_detail || null,
    user_detail: state.user.user_detail || null,
  };
};

const Header = connect(mapStateToProps)(HeaderComponent);
export default Header;
