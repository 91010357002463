import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { adminChangePassword } from "../actions/userlist";

class PopupAdminChangePassComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // userId: null,
      // userName: null,
      setPasssword: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  close = () => {
    const { onClose } = this.props;
    console.log("close");
    this.setState({ popup: { is_show: false, data: {} }, setPasssword: "" });
    onClose();
  };
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleClickSavePassword = () => {
    // console.log(this.props.data);
    const { dispatch, onClose } = this.props;
    const userId = this.props.data.userId;
    const userName = this.props.data.userName;
    const newPassword = this.state.setPasssword;
    // console.log(newPassword, userName, userId);
    dispatch(adminChangePassword({ userId: userId, new: newPassword }));
    onClose();
    this.setState({ setPasssword: "" });
  };

  render() {
    const { is_show, data } = this.props;
    // this.setState({ userName: data.userName, });
    // console.log(is_show);
    // console.log(this.state.setPasssword);
    let userName = data != null ? data.userName : "";
    // let userId = data != null ? data.userId : "";
    return (
      <Modal centered backdrop="static" show={is_show}>
        <article className={`message is-primary`}>
          <div className="message-header">
            {/* {image_name} */}
            เปลี่ยนรหัสผ่าน
            <button
              className="delete"
              aria-label="delete"
              onClick={() => this.close()}
            ></button>
          </div>
          <div className="message-body">
            {/* <img src={image_url} alt="" /> */}
            Username : {userName}
            <br />
            <div>
              <input
                type="password"
                name="setPasssword"
                className="form-control"
                value={this.state.setPasssword}
                onChange={this.handleInputChange}
              />
            </div>
            <br />
            <div className="row">
              <div className="col-6">
                <button
                  className="bg-cyan d-block text-white rounded-3 border-size-md p-2 text-center w-100 border-0"
                  onClick={() => this.handleClickSavePassword()}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </article>
      </Modal>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {
    // is_show: props.is_show || false,
    // user_name: props.data || {},
    // image_name: state.display.popup_image.image_name || "",
  };
};

const PopupAdminChangePass = connect(mapStateToProps)(
  PopupAdminChangePassComponent
);
export default PopupAdminChangePass;
