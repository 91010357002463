import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import PageTitle from "../components/PageTitle";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { getStockListData, setStockDetailSelected } from "../actions/stock";
import { Link } from "react-router-dom";

class StockListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agentname: "",
      filename: "",
      startdate: "",
      enddate: "",
      startdate_input: "",
      enddate_input: "",
    };
  }

  componentDidMount() {
    this.initialPage();
  }

  initialPage = () => {
    const { dispatch, stock_list } = this.props;
    dispatch(setStockDetailSelected(null));
    dispatch(getStockListData(stock_list.upload_id, stock_list.upload_id));
  };

  handleInputChange = (event) => {
    console.log("handleInputChange");
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleInputDateChange = (date, inputValueName, inputName) => {
    console.log("handleInputDateChange", date);
    const dateFormatted = moment(date).format("yyyy-MM-DD");
    this.setState({
      [inputValueName]: dateFormatted,
      [inputName]: date,
    });
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    console.log(this.state);
    dispatch(getStockListData(this.state));
    //TODO Validate value before submit to backend
    // const validate = this.validateFormRequiredField()
    // if(!validate.status){
    //   this.showErrorPopup("Error!!", validate.message)
    // }
    // else{
    //   const dataSubmit = {
    //     paymentNo: data.paymentNo,
    //     file_name: this.state.file_name,
    //     file: this.state.file,
    //     refno: this.state.refno,
    //     refdate: this.state.refdate,
    //     amount: this.state.amount,
    //   }
    //   onSubmit(dataSubmit)
    // }
  };

  handleClickViewDetail = (data) => {
    // console.log(data);
    const { dispatch } = this.props;
    dispatch(setStockDetailSelected(data, true));
    //window.locate.replace(`/admin/stock-detail`)
    //navigate(`/admin/stock-detail`)
  };

  render() {
    const { stock_list } = this.props;
    return (
      <Fragment>
        <Header />
        <Leftnav />
        {/* <Rightchat /> */}

        <div className="main-content">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row lottery-search">
                <div className="col-xl-12">
                  <PageTitle title="จัดการ Stock" />

                  <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                    <h4 className="mont-font fw-600 font-md mb-2 mb-0 mt-0">
                      ค้นหา
                    </h4>

                    <div className="row">
                      <div className="col-sm-3 col-xs-12">
                        <div className="form-group lottery-digit">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            ชื่อตัวแทน
                          </label>
                          <input
                            type="text"
                            name="agentname"
                            value={this.state.agentname}
                            onChange={this.handleInputChange}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-sm-3 col-xs-12">
                        <div className="form-group lottery-digit">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            ชื่อไฟล์
                          </label>
                          <input
                            type="text"
                            name="filename"
                            value={this.state.filename}
                            onChange={this.handleInputChange}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-sm-3 col-xs-12">
                        <div className="form-group lottery-digit">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            วันที่นำเข้าเริ่มต้น
                          </label>
                          <DatePicker
                            selected={this.state.startdate_input}
                            closeOnScroll={true}
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            className="form-control"
                            showPopperArrow={false}
                            placeholderText="dd/MM/yyyy"
                            // dateFormat="MMMM d, yyyy h:mm aa"
                            onChange={(date) =>
                              this.handleInputDateChange(
                                date,
                                "startdate",
                                "startdate_input"
                              )
                            }
                          />
                          {/* <input type="text" name="startdate" value={this.state.startdate} onChange={this.handleInputChange} className="form-control" /> */}
                        </div>
                      </div>

                      <div className="col-sm-3 col-xs-12">
                        <div className="form-group lottery-digit">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            วันที่นำเข้าสิ้นสุด
                          </label>
                          <DatePicker
                            selected={this.state.enddate_input}
                            closeOnScroll={true}
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            className="form-control"
                            showPopperArrow={false}
                            placeholderText="dd/MM/yyyy"
                            // dateFormat="MMMM d, yyyy h:mm aa"
                            onChange={(date) =>
                              this.handleInputDateChange(
                                date,
                                "enddate",
                                "enddate_input"
                              )
                            }
                          />
                          {/* <input type="text" name="enddate" value={this.state.enddate} onChange={this.handleInputChange} className="form-control" /> */}
                        </div>
                      </div>

                      <div className="col-sm-3 col-xs-12">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          &nbsp;
                        </label>
                        <button
                          onClick={this.handleSubmit}
                          className="update-cart bg-cyan d-block text-white fw-600 text-uppercase 
                    font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0 "
                        >
                          ค้นหา
                        </button>
                      </div>

                      <div className="col-sm-3 col-xs-12">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          &nbsp;
                        </label>
                        <Link
                          to="/admin/stock-in"
                          className="update-cart bg-cyan d-block text-white fw-600 text-uppercase 
                    font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"
                        >
                          <span>Upload</span>
                        </Link>
                      </div>

                      <div className="col-sm-3 col-xs-12">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          &nbsp;
                        </label>
                        <Link
                          to="/admin/stock-new-in"
                          className="update-cart bg-cyan d-block text-white fw-600 text-uppercase 
                    font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"
                        >
                          <span>Upload New</span>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="row payment-list mb-show">
                    <div className="col-xl-12 cart-wrapper mb-0">
                      {stock_list != null &&
                        stock_list.map((data, index) => {
                          return (
                            <div key={index} className="row">
                              <div className="col-lg-12 mb-3 ">
                                <div className="card p-3 pb-0 rounded-3 shadow-xs border-0 bg-grey font-xss">
                                  <div className="">
                                    ชื่อตัวแทน:{" "}
                                    <strong>{data.agent_name}</strong>
                                  </div>
                                  <div className="">
                                    ชื่อไฟล์: <strong>{data.file_name}</strong>
                                  </div>
                                  <div className="">
                                    งวดวันที่:{" "}
                                    <strong>{data.installDate}</strong>
                                  </div>
                                  <div className="">
                                    จำนวนสลาก: <strong>{data.totaldoc}</strong>
                                    &nbsp;(นำเข้า={data.verified_success} /
                                    ไม่ถูกต้อง={data.verified_error})
                                  </div>
                                  <div className="">
                                    วันที่นำเข้า:{" "}
                                    <strong>{data.created_date}</strong>
                                  </div>
                                  <div className="">
                                    จำนวนสลาก: <strong>{data.totaldoc}</strong>
                                    <Link
                                      className="text-white"
                                      onClick={() =>
                                        this.handleClickViewDetail(data)
                                      }
                                      to={`/admin/stock-detail`}
                                    >
                                      <i className="feather-zoom-in btn-round-xs w15"></i>
                                      ดูรายละเอียด
                                    </Link>
                                  </div>
                                </div>
                                <div className="card p-3 rounded-3 shadow-xs border-0 bg-grey font-xss">
                                  <div className=""></div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className="row payment-list mb-hide">
                    <div className="col-lg-12 mb-3">
                      <div className="table-content table-responsive">
                        <table className="table text-center">
                          <thead className="bg-greyblue rounded-3">
                            <tr>
                              <th className="border-0 p-4">ลำดับ</th>
                              <th className="border-0 p-4 text-left">
                                ชื่อตัวแทน
                              </th>
                              <th className="border-0 p-4">ชื่อไฟล์</th>
                              <th className="border-0 p-4">งวดวันที่</th>
                              <th className="border-0 p-4">จำนวนสลาก</th>
                              <th className="border-0 p-4">
                                จำนวนสลากนำเข้าระบบ
                              </th>
                              <th className="border-0 p-4">
                                จำนวนสลากไม่ถูกต้อง
                              </th>
                              <th className="border-0 p-4">วันที่นำเข้า</th>
                              <th className="border-0 p-4">Action</th>
                              {/* <th className="border-0 p-4">&nbsp;</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {stock_list != null &&
                              stock_list.map((data, index) => {
                                // "agent_name":"ตัวแทน1","upload_id":66,"file_name":"AG001 600x600 2.zip","totaldoc":100,"verified_success":null,"verified_error":null
                                //,"created_date":"10/10/2021"},{"agent_name":"แฟ้ม lotto","upload_id":67,"file_name":"300x300 BW.zip","totaldoc":100,"verified_success":null,"verified_error":null,"created_date":"10/10/2021"
                                return [
                                  <tr key={index} className="data-row">
                                    <td className="product-thumbnail ps-0">
                                      {index + 1}
                                    </td>
                                    <td className="product-headline text-left wide-column">
                                      <h3>{data.agent_name}</h3>
                                    </td>
                                    <td className="product-quantity">
                                      {data.file_name}
                                    </td>
                                    <td className="product-quantity">
                                      {data.installDate}
                                    </td>
                                    <td className="product-quantity">
                                      {data.totaldoc}
                                    </td>
                                    <td className="product-quantity">
                                      {data.verified_success}
                                    </td>
                                    <td className="product-quantity">
                                      {data.verified_error}
                                    </td>
                                    <td className="product-quantity">
                                      {data.created_date}
                                    </td>
                                    {/* <td className="product-quantity">
                                      <div className={`nav-content-bttn open-font me-3 d-inline-block float-right lotto-view-btn lotto-view-btn-${index} hide`}
                                        onClick={() => this.handleClickViewDetail(data)}
                                      >
                                        <i className="feather-zoom-in btn-round-xs"></i><span>ดูรายละเอียด</span></div>
                                    </td> */}
                                    <td className="product-quantity">
                                      <div
                                        className={`nav-content-bttn rounded-3 text-white bg-cyan open-font me-3 d-inline-block w125 float-right lotto-view-btn lotto-view-btn-${index} hide`}
                                      >
                                        <Link
                                          className="text-white"
                                          onClick={() =>
                                            this.handleClickViewDetail(data)
                                          }
                                          to={`/admin/stock-detail`}
                                        >
                                          <i className="feather-zoom-in btn-round-xs w15"></i>
                                          ดูรายละเอียด
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>,
                                ];
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* CONTENT ABOVE */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        {/* <Appfooter />  */}
      </Fragment>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {
    stock_list: state.stock.stock_list || [],
  };
};

const StockList = connect(mapStateToProps)(StockListComponent);
export default StockList;
