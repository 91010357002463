import React, {
  useEffect,
  useState,
  useCallback,
  Fragment,
  useRef,
} from "react";
import { connect, useDispatch } from "react-redux";
import { ServerInfo, ServerInfo_Code } from "../constants/config";
import session from "../actions/session";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import SectionTitle from "../components/SectionTitle";
import Popupchat from "../components/Popupchat";
import { useDropzone } from "react-dropzone";
import { Buffer } from "buffer";
import "jimp";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import Select from "react-select";

import "fabric";
import {
  getAgentList,
  getWaitReleaseData,
  stockRelease,
} from "../actions/stock";

const Jimp = global.Jimp;
const fabric = global.fabric;

global.Buffer = Buffer;

const StockUploadNewComponent = (props) => {
  const { stock_wait_release, stock_agent_list } = props;

  const dispatch = useDispatch();

  const cachedJpegDecoder = Jimp.decoders["image/jpeg"];
  Jimp.decoders["image/jpeg"] = (data) => {
    const userOpts = { maxMemoryUsageInMB: 1024 };
    return cachedJpegDecoder(data, userOpts);
  };

  const [agentId, setAgentId] = useState(1);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [isCostFormShow, setIsCostFormShow] = useState(false);
  const [cost, setCost] = useState(90);
  const [selectedFile, setSelectedFile] = useState(null);
  const [waitNum, setWaitNum] = useState(null);

  const [queueFiles, setQueueFiles] = useState(null);

  const [agentList, setAgentList] = useState([]);

  const canvasRef = useRef();

  const getWaitForRelease = () => {
    dispatch(getWaitReleaseData(agentId));
  };

  const getAgentListData = () => {
    dispatch(getAgentList());
  };

  // const release = () => {
  //   URL_STOCK_RELEASE;
  // };

  useEffect(() => {
    console.log(stock_wait_release.length);
    if (stock_wait_release) {
      console.log("set");
      setWaitNum(stock_wait_release.length);
    }
  }, [stock_wait_release]);

  const base64ToBlob = (base64String, contentType = "") => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    return new Blob([byteArray], { type: contentType });
  };

  const addLotto = async (file) =>
    new Promise((resolve, reject) => {
      const token = session.getUserToken();
      const { base64, no, indt_id, set, book, cost } = file;

      const formData = new FormData();
      formData.append("no", no);
      formData.append("indt_id", indt_id);
      formData.append("set", set);
      formData.append("book", book);
      formData.append("cost", cost);
      formData.append(
        "file",
        base64ToBlob(
          base64.replace("data:image/jpeg;base64,", ""),
          "image/jpeg"
        ),
        file.filename
      );
      fetch(`${ServerInfo.URL_STOCK_ADDVERIFIED}?agentid=${agentId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then((resp) => resp.json())
        .then((data) => {
          resolve(file);
        });
    });

  const scanLottoImage = async (base64) => {
    const blob = await fetch(base64).then((res) => res.blob());
    const formData = new FormData();
    formData.append("lotteryFile", blob, {
      filename: "lotto.jpg",
      contentType: "image/jpeg",
    });

    const data = await fetch(`${ServerInfo.URL_SCAN_LOTTO}`, {
      method: "POST",
      body: formData,
    }).then((resp) => resp.json());

    return data;
  };

  const readImageFromBase64 = async (
    base64,
    filename,
    area = [520, 100, 200, 200]
  ) => {
    const base64Trim = Buffer.from(
      base64.replace("data:image/jpeg;base64,", ""),
      "base64"
    );

    const info = await Jimp.read(base64Trim).then(async (image) => {
      const { width, height } = image.bitmap;
      // console.log(image.bitmap.width, image.bitmap.height);
      image.crop(area[0], area[1], area[2], area[3]);
      // console.log(await image.getBase64Async(Jimp.AUTO));
      return {
        crop: await image.getBase64Async(Jimp.AUTO),
        originWidth: width,
        originHeight: height,
      };
    });

    return { base64, filename, ...info };
  };

  const readImageFile = async (f) =>
    new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        "load",
        async () => {
          const { result } = reader;
          resolve(await readImageFromBase64(result, f.name));

          // console.log(await scanLottoImage(file), f.filename);
        },
        false
      );

      if (f) {
        reader.readAsDataURL(f);
      }
    });

  // task executor
  const addTaskUpload = (() => {
    let pending = Promise.resolve();

    const run = async (file) => {
      try {
        await pending;
      } finally {
        return addLotto(file);
      }
    };
    // update pending promise so that next task could await for it
    return (file) => (pending = run(file));
  })();

  // task executor
  const addTaskRead = (() => {
    let pending = Promise.resolve();

    const run = async (file) => {
      try {
        await pending;
      } finally {
        const { base64, crop, filename, originWidth, originHeight } =
          await readImageFile(file);
        const scanned = await scanLottoImage(crop);
        if (scanned?.data) {
          const splitted = scanned.data.split("-");
          // console.log(files.length, splitted);
          if (splitted.length > 4) {
            return {
              base64,
              no: splitted[3],
              indt_id: splitted[1] - 0,
              set: splitted[2] - 0,
              book: splitted[4] - 0,
              area: [520, 100, 200, 200],
              filename,
              originWidth,
              originHeight,
              cost: cost,
            };
          }
        }

        return {
          base64,
          no: "",
          indt_id: "",
          set: "",
          book: "",
          area: [520, 100, 200, 200],
          filename,
          originWidth,
          originHeight,
          cost: cost,
        };
      }
    };

    // update pending promise so that next task could await for it
    return (file) => (pending = run(file));
  })();

  const allProgress = (proms, progress_cb) => {
    let d = 0;
    progress_cb(0);
    for (const p of proms) {
      p.then(() => {
        d++;
        progress_cb((d * 100) / proms.length);
      });
    }
    return Promise.all(proms);
  };

  const costFormClose = () => {
    setIsCostFormShow(false);
    if (queueFiles) {
      setLoading(true);
      allProgress(
        queueFiles.map(async (file) => addTaskRead(file)),
        (p) => {
          // console.log(`% Done = ${p.toFixed(2)}`);
          setProgress(p.toFixed(2));
        }
      ).then((data) => {
        setLoading(false);
        setProgress(0);
        setFiles([...files, ...data].sort((a, b) => a.number - b.number));
        setQueueFiles(null);
      });
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setQueueFiles(acceptedFiles);
      setIsCostFormShow(true);
      // if (acceptedFiles) {
      //   setLoading(true);
      //   allProgress(
      //     acceptedFiles.map(async (file) => addTaskRead(file)),
      //     (p) => {
      //       // console.log(`% Done = ${p.toFixed(2)}`);
      //       setProgress(p.toFixed(2));
      //     }
      //   ).then((data) => {
      //     setLoading(false);
      //     setProgress(0);
      //     setFiles([...files, ...data].sort((a, b) => a.number - b.number));
      //   });
      // }
    },
    [files, addTaskRead]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const reloadFile = async (fileIndex) => {
    const fileInfo = files[fileIndex];
    const imageInfo = await readImageFromBase64(
      fileInfo.base64,
      fileInfo.filename,
      fileInfo.area
    );
    const { base64, crop, filename, originWidth, originHeight } = imageInfo;
    const scanned = await scanLottoImage(crop);
    if (scanned?.data) {
      const splitted = scanned.data.split("-");
      // console.log(files.length, splitted);
      if (splitted.length > 4) {
        const newFiles = files.map((file, index) => {
          if (index === fileIndex) {
            return {
              ...file,
              no: splitted[3],
              indt_id: splitted[1] - 0,
              set: splitted[2] - 0,
              book: splitted[4] - 0,
            };
          }
          return file;
        });

        setFiles([...newFiles]);
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    const results = await allProgress(
      files.map(async (file) => addTaskUpload(file)),
      (p) => {
        // console.log(`% Done = ${p.toFixed(2)}`);
        setProgress(p.toFixed(2));
      }
    );
    setLoading(false);
    setFiles([]);
    setProgress(0);

    alert(`success: ${results.length}`);
  };

  const selectFile = (fileIndex) => {
    setSelectedFile(fileIndex);
    setIsShow(true);
  };

  const close = () => {
    setSelectedFile(null);
    setIsShow(false);
  };

  useEffect(() => {
    getWaitForRelease();
    getAgentListData();
  }, []);

  useEffect(() => {
    setAgentList(
      stock_agent_list.map((agent) => ({
        label: `${agent.agentCode} - ${agent.agentName}`,
        value: agent.agentId,
      }))
    );
  }, [stock_agent_list]);

  useEffect(() => {
    if (agentId) {
      getWaitForRelease();
    }
  }, [agentId]);

  useEffect(() => {
    if (canvasRef.current) {
      if (isShow === false) {
        canvasRef.current.replaceChildren();
        const node = document.createElement("canvas");
        canvasRef.current.appendChild(node);
      } else {
        const { originWidth, originHeight, area } = files[selectedFile];
        const ratio = originWidth / 800;

        console.log(canvasRef.current);
        const canvas = new fabric.Canvas(
          canvasRef.current.getElementsByTagName("canvas")[0],
          {
            width: 800,
            height: originHeight / ratio,
          }
        );

        const rect = new fabric.Rect({
          top: area[1] / ratio,
          left: area[0] / ratio,
          width: area[2] / ratio,
          height: area[3] / ratio,
          fill: "",
          stroke: "black",
          strokeWidth: 2,
        });

        canvas.add(rect);
        canvas.on("object:modified", () => {
          const newFiles = files.map((file, index) => {
            if (index === selectedFile) {
              const newArea = [
                rect.left * ratio,
                rect.top * ratio,
                rect.width * rect.scaleX * ratio,
                rect.height * rect.scaleY * ratio,
              ];
              file.area = newArea;
            }
            return file;
          });

          setFiles([...newFiles]);
        });
      }
    }
  }, [canvasRef, isShow, files[selectedFile]]);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      {/* <Rightchat /> */}

      <Modal centered backdrop="static" show={isShow}>
        <button className="delete" aria-label="delete" onClick={close}></button>
        <ModalBody>
          {files[selectedFile] && (
            <div
              className="lot-qr-scanner position-relative"
              style={{ width: "800px" }}
            >
              <img
                src={files[selectedFile].base64}
                width="100%"
                height="auto"
              />
              <div
                ref={canvasRef}
                style={{ position: "absolute", left: 0, top: 0 }}
              >
                <canvas></canvas>
              </div>
              <div>
                <button onClick={() => reloadFile(selectedFile)}>Reload</button>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal centered backdrop="static" show={isCostFormShow}>
        <ModalBody>
          <div
            className="position-relative p-5"
            style={{ width: "100%", background: "white" }}
          >
            <div>ต้นทุน/ใบ</div>
            <div className="form-group icon-input mb-0">
              <i className="ti-tag font-xs text-grey-400"></i>
              <input
                type="number"
                className="style1-input bg-transparent ps-5 font-xsss mb-5 fw-500  w-100"
                placeholder="ต้นทุน"
                defaultValue={cost}
                onChange={(e) => {
                  setCost(Number(e.target.value));
                }}
              />
            </div>
            <div>
              <button
                className="button is-success w-100"
                onClick={costFormClose}
              >
                OK
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row lottery-search">
              <div className="col-xl-12">
                <SectionTitle title="Stock in" />
                {/* {waitNum > 0 && ( */}
                <div className="row">
                  <div className="col-xl-6">
                    <Select
                      options={agentList}
                      value={agentList.find((a) => a.value === agentId)}
                      onChange={(e) => {
                        setAgentId(e.value);
                      }}
                    />
                  </div>
                  <div className="col-xl-6">
                    <div style={{ marginBottom: "15px" }}>
                      <Button
                        onClick={() => {
                          confirmAlert({
                            title: "ยืนยันการปล่อยล๊อตเตอรี่",
                            message: `จำนวน ${waitNum} ใบ`,
                            buttons: [
                              {
                                label: "ปล่อยเลย",
                                onClick: () => {
                                  dispatch(stockRelease(agentId));
                                },
                              },
                              {
                                label: "ยกเลิก",
                                onClick: () => {},
                              },
                            ],
                          });
                        }}
                      >
                        Release all {waitNum} doc
                      </Button>
                    </div>
                  </div>
                </div>

                {/* )} */}
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {progress}%
                  </div>
                </div>
                <form>
                  {/* <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          รหัสตัวแทนขาย
                        </label>
                        <input
                          type="text"
                          name="agentId"
                          value=""
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          รายละเอียดลิ้นชัก
                        </label>
                        <input
                          type="text"
                          name="drawerName"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <div
                          {...getRootProps()}
                          style={{ border: "1px dashed", minHeight: "100px" }}
                        >
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <p>Drop the files here ...</p>
                          ) : (
                            <p>
                              Drag 'n' drop some files here, or click to select
                              files
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      {loading && <span>Loading... Dont touch!!</span>}
                      <table class="table text-center">
                        <tbody>
                          <tr>
                            <th>#</th>
                            <th>name</th>
                            <th>image</th>
                            <th>number</th>
                            <th>set</th>
                            <th>inst</th>
                            <th>book</th>
                            <th>cost</th>
                            <th>&nbsp;</th>
                          </tr>
                          {files
                            .sort((a, b) => a.no - b.no)
                            .map((file, index) => (
                              <tr
                                key={`file-${index}`}
                                className={`${
                                  file.no === "" ? "bg-danger text-white" : ""
                                } `}
                              >
                                <td>{index + 1}</td>
                                <td>{file.filename}</td>
                                <td>
                                  <img
                                    src={file.base64}
                                    alt=""
                                    width="100"
                                    onClick={() => {
                                      selectFile(index);
                                    }}
                                  />
                                </td>
                                <td>{file.no}</td>
                                <td>{file.set}</td>
                                <td>{file.indt_id}</td>
                                <td>{file.book}</td>
                                <td>
                                  <input
                                    type="number"
                                    defaultValue={file.cost}
                                    onChange={(e) => {
                                      setFiles(
                                        files.map((f) => {
                                          if (
                                            f.no === file.no &&
                                            f.set === file.set &&
                                            f.indt_id === file.indt_id &&
                                            f.book === file.book
                                          ) {
                                            f.cost = Number(e.target.value);
                                          }
                                          return f;
                                        })
                                      );
                                    }}
                                  />
                                </td>
                                <td>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setFiles(
                                        files.filter((f, i) => i !== index)
                                      );
                                    }}
                                  >
                                    X
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      {/* <a href="/accountinformation" 
                    className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">บันทึก</a> */}
                      <input
                        type="button"
                        className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 "
                        disabled={loading}
                        value={loading ? "Loading..." : "บันทึก"}
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      {/* <Appfooter />  */}
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    stock_wait_release: state.stock.stock_wait_release || null,
    stock_agent_list: state.stock.stock_agent_list || null,
  };
};

const StockUploadNew = connect(mapStateToProps)(StockUploadNewComponent);
export default StockUploadNew;
