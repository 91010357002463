import { ServerInfo, ServerInfo_Code } from "../constants/config";
import { apiActionWithToken, apiAction } from "./actions";
import actionUtils from "./actionUtils";
import store from "../store";
import { getVaultList } from "./vault";

const CLASSNAME = `actions:number`;

//GET NUMBER SEARCH
export const getNumberSearchList = (data) => {
  // console.log(`[${CLASSNAME}] getNumberSearchList`)
  console.log(data);
  let params = {};
  if (data.lotto_type !== "none") {
    params.potype = data.lotto_type;
  }
  if (data.lotto_set !== "0") {
    params.set = data.lotto_set;
  }
  const digit_array = [
    data.digit_1,
    data.digit_2,
    data.digit_3,
    data.digit_4,
    data.digit_5,
    data.digit_6,
  ];
  let numb = "";
  digit_array.map((x) => {
    if (x === "") {
      numb = numb + "s";
    } else {
      numb = numb + x;
    }
    return "";
  });
  if (numb !== "") {
    params.numb = numb;
  }
  params.dealerId = data.dealer_id;
  params.perPage = 200;

  return async (dispatch) => {
    dispatch(setNumberSearchCriteria(data));
    // dispatch(resetNumberSearchList());
    dispatch(
      apiActionWithToken(
        {
          url: `${ServerInfo.URL_NUMBER}`,
          method: `GET`,
          data: params,
          onSuccess: onGetNumberSearchListResponse,
          onFailure: actionUtils.onCommonFailure,
          label: "APICALLER_CALL_API",
          onSuccessFailureParamOverride: {
            SERVICE_CODE: ServerInfo_Code.URL_NUMBER,
          },
        },
        true
      )
    );
  };
};

export const onGetNumberSearchListResponse = (statusCode, statusText, data) => {
  // console.log(`[${CLASSNAME}] onGetNumberSearchListResponse statusCode: ${statusCode}`)
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "NUMBER_SET_SEARCH_RESULT",
      value: data.result.content,
    });
  };
};

//RESET NUMBER SEARCH
export const resetNumberSearchList = () => {
  // console.log(`[${CLASSNAME}] resetNumberSearchList`)
  return async (dispatch) => {
    dispatch({
      type: "NUMBER_SET_SEARCH_RESULT",
      value: null,
    });
  };
};

//SET NUMBER SEARCH CROTERIA
export const setNumberSearchCriteria = (data) => {
  // console.log(`[${CLASSNAME}] setNumberSearchCriteria`)
  return async (dispatch) => {
    dispatch({
      type: "NUMBER_SET_SEARCH_CRITERIA",
      value: data,
    });
  };
};

//GET AGENT NUMBER SEARCH
export const getNumberGroupSearch = (data) => {
  let params = {};
  if (data !== undefined) {
    if (data.numb !== "" && data.numb !== undefined) {
      params.numb = data.numb;
    }
    if (data.set !== "" && data.set !== undefined) {
      params.set = data.set;
    }
    if (data.installDate !== "" && data.installDate !== undefined) {
      params.installDate = actionUtils.formatDateToSend_dash2slash(
        data.installDate
      );
    }
  }

  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] onGetAgent`)F
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_ADMIN_NUMBERGROUP_SEARCH}`,
        method: `GET`,
        data: params,
        onSuccess: onGetNumberGroupSearchResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_ADMIN_NUMBERGROUP_SEARCH,
        },
      })
    );
  };
};

export const onGetNumberGroupSearchResponse = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onGetNumberGroupSearchResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "AGENT_NUMBER_LIST_DATA",
      value: data.result.content,
    });
  };
};

export const sendNumberToCustomer = (custId, numIds, installmentId) => {
  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] onGetAgent`)F
    const params = {
      customerId: custId,
      numberIds: numIds,
    };
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_VAULT_SEND_NUMBER}`,
        method: `POST`,
        data: params,
        onSuccess: (statusCode, statusText, data) =>
          onSendNumberToCustomerResponse(
            statusCode,
            statusText,
            data,
            installmentId
          ),
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_VAULT_SEND_NUMBER,
        },
      })
    );
  };
};

export const onSendNumberToCustomerResponse = (
  statusCode,
  statusText,
  data,
  installmentId
) => {
  console.log(
    `[${CLASSNAME}] onSendNumberToCustomerResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    const { user } = store.getState();
    console.log("store.getState()", store.getState());
    dispatch(getVaultList(user.user_detail.customer_id, installmentId));
  };
};

export const getInstallmentInfo = () => {
  return async (dispatch) => {
    dispatch(
      apiAction({
        url: `${ServerInfo.URL_NUMBER_INSTINFO}`,
        method: `GET`,
        onSuccess: onGetInstallmentInfoResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_VAULT_SEND_NUMBER,
        },
      })
    );
  };
};

export const onGetInstallmentInfoResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onSendNumberToCustomerResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    const { current, previous, all } = data;

    console.log(current, previous, all);
    dispatch({
      type: "INSTALLMENT_SET_CURRENT",
      value: current,
    });
    dispatch({
      type: "INSTALLMENT_SET_PREVIOUS",
      value: previous,
    });
    dispatch({
      type: "INSTALLMENT_SET_ALL",
      value: all,
    });
  };
};
