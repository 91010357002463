import {
  LabelConst,
  ActionConst,
  ServerInfo,
  ServerInfo_Code,
  HttpConst
} from "../constants";
import { apiAction } from "./actions";
import actionUtils from "./actionUtils";

export const getBankAccountList = () => {
  return async (dispatch) => {
    dispatch(
      apiAction(
        {
          url: ServerInfo.URL_BANK,
          method: HttpConst.MethodGet,
          data: {},
          onSuccess: onGetBankAccountListResponse,
          onFailure: actionUtils.onCommonFailure,
          label: LabelConst.ApiCaller_CALL_API,
          onSuccessFailureParamOverride: {
            SERVICE_CODE: ServerInfo_Code.URL_BANK,
          },
        },
        true
      )
    );
  };
};

export const onGetBankAccountListResponse = (statusCode, statusText, data) => {
  return async (dispatch) => {
    dispatch({
      type: ActionConst.BankSetBankList,
      value: data.result,
    });
  };
};
