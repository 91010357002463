import { ServerInfo, ServerInfo_Code } from "../constants/config";
import { apiActionWithToken } from "./actions";
import actionUtils from "./actionUtils";

const CLASSNAME = `actions:user`;

//GET CUSTOMER DATA
export const getUserDetail = () => {
  // console.log(`[${CLASSNAME}] getBankAccountList`)
  return async (dispatch) => {
    dispatch(
      apiActionWithToken(
        {
          url: `${ServerInfo.URL_USER_DETAIL}`,
          method: `GET`,
          data: {},
          onSuccess: onGetUserDetailResponse,
          onFailure: actionUtils.onCommonFailure,
          label: "APICALLER_CALL_API",
          onSuccessFailureParamOverride: {
            SERVICE_CODE: ServerInfo_Code.URL_USER_DETAIL,
          },
        },
        true
      )
    );
  };
};

export const onGetUserDetailResponse = (statusCode, statusText, data) => {
  // console.log(`[${CLASSNAME}] onGetBankAccountListResponse statusCode: ${statusCode}`)
  return async (dispatch) => {
    console.log("data", data.result);
    if (data.result.is_dealer && data.result.is_customer) {
      dispatch({
        type: "DEALER_REF",
        value: data.result.dealer_id,
      });      
    }
    dispatch({
      type: "USER_DETAIL_DATA",
      value: data.result,
    });
  };
};
