import { ServerInfo, ServerInfo_Code } from "../constants/config";
import Message from "../constants/message";
import { apiActionWithToken } from "./actions";
import {
  closePopupData,
  showDisplayPopupError,
  showDisplayPopupMessage,
} from "./display";
import formData from "form-data";
import actionUtils from "./actionUtils";

const CLASSNAME = `actions:payment`;

const formatDateToSend = (dateIn) => {
  //FROM yyyy-mm-dd to mm/dd/yyyy
  const dateList = dateIn.split("-");
  return `${dateList[2]}/${dateList[1]}/${dateList[0]}`;
};

//GET PAYMENT DATA
export const getPaymentListData = (data) => {
  // console.log(`[${CLASSNAME}] getPaymentListData`)
  // console.log(data)
  let params = {};
  if (data !== undefined) {
    if (data.from_date !== "" && data.from_date !== undefined) {
      params.from_date = formatDateToSend(data.from_date);
    }
    if (data.to_date !== "" && data.to_date !== undefined) {
      params.to_date = formatDateToSend(data.to_date);
    }
    if (
      data.status !== "" &&
      data.status !== undefined &&
      data.status !== "A"
    ) {
      params.status = data.status;
    }
  }
  // console.log("params", JSON.stringify(params))
  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] getPaymentListData`)
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_PAYMENT_SEARCH}`,
        method: `GET`,
        data: params,
        onSuccess: onGetPaymentListResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_PAYMENT_SEARCH,
        },
      })
    );
  };
};

export const onGetPaymentListResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetPaymentListResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "PAYMENT_SET_PAYMENT_LIST_DATA",
      value: data.result.content,
    });
    //{"result":{"content":[{"basketId":10,"packId":1,"packNum":5,"packAmount":600,"total":1,"amount":600,"orderdate":"01/08/2021 06:16","numberNo":"000001","basketStatus":"W","basketStatusText":"GEP001_W","agentName":"ตัวแทน1"}],"pageable":{"sort":{"sorted":false,"unsorted":true,"empty":true},"pageSize":20,"pageNumber":0,"offset":0,"unpaged":false,"paged":true},"totalElements":1,"totalPages":1,"last":true,"first":true,"sort":{"sorted":false,"unsorted":true,"empty":true},"number":0,"numberOfElements":1,"size":20,"empty":false},"statusDescription":"200 OK","sumtopay":600,"status":"SUCCESS","statusCode":200}
    // dispatch({
    //   type: "NUMBER_SET_SEARCH_RESULT",
    //   value: data.result.content
    // })
  };
};

//GET PAYMENT DATA
export const getDealerPaymentListData = (data) => {
  console.log(`[${CLASSNAME}] getDealerPaymentListData`);
  console.log(data);
  let params = {};
  if (data) {
    const {
      paymentno,
      personname,
      startdate,
      enddate,
      dealerid,
      installmentId,
    } = data;
    params = {
      ...(paymentno && { paymentno }),
      ...(personname && { personname }),
      ...(startdate && { startdate }),
      ...(enddate && { enddate }),
      ...(installmentId && { installmentId }),
      dealerid,
    };
  }
  // console.log("params", JSON.stringify(params))
  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] getPaymentListData`)
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_DEALER_PAYMENT_SEARCH}`,
        method: `GET`,
        data: params,
        onSuccess: onGetDealerPaymentListResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_PAYMENT_SEARCH,
        },
      })
    );
  };
};

export const onGetDealerPaymentListResponse = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onGetPaymentListResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log("payment", data);
    dispatch({
      type: "PAYMENT_SET_DEALER_PAYMENT_LIST_DATA",
      value: data.result,
    });
    //{"result":{"content":[{"basketId":10,"packId":1,"packNum":5,"packAmount":600,"total":1,"amount":600,"orderdate":"01/08/2021 06:16","numberNo":"000001","basketStatus":"W","basketStatusText":"GEP001_W","agentName":"ตัวแทน1"}],"pageable":{"sort":{"sorted":false,"unsorted":true,"empty":true},"pageSize":20,"pageNumber":0,"offset":0,"unpaged":false,"paged":true},"totalElements":1,"totalPages":1,"last":true,"first":true,"sort":{"sorted":false,"unsorted":true,"empty":true},"number":0,"numberOfElements":1,"size":20,"empty":false},"statusDescription":"200 OK","sumtopay":600,"status":"SUCCESS","statusCode":200}
    // dispatch({
    //   type: "NUMBER_SET_SEARCH_RESULT",
    //   value: data.result.content
    // })
  };
};

export const uploadPaymentSlip = (data) => {
  console.log(`[${CLASSNAME}] uploadPaymentSlip`);
  // console.log(data)
  // console.log(data.file)
  var dataForm = new formData();
  dataForm.append("file", data.file, data.file.name);
  dataForm.append("paymentNo", data.paymentNo);
  dataForm.append("refno", data.refno);
  dataForm.append("refdate", data.refdate);
  dataForm.append("amount", data.amount);
  // console.log(dataForm)
  // console.log(dataForm.getHeaders())

  // const formData = new FormData();
  // formData.append("jsonData", data.jsonData);
  // formData.append("image", data.image);
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] uploadPaymentSlip`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_PAYMENT_UPLOAD_SLIP}`,
        method: `POST`,
        data: dataForm,
        onSuccess: onUploadPaymentSlipResponse,
        onFailure: onUploadPaymentSlipFailure,
        label: "APICALLER_CALL_API",
        // headersOverride: {
        //   ...dataForm.getHeaders(),
        // }
      })
    );
  };
};

export const onUploadPaymentSlipResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onUploadPaymentSlipResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    //{"result":{"paymentNo":"TSPL-202108P00032","paymentDate":"01/08/2021 05:00","slipFileId":6},"statusDescription":"200 OK","status":"SUCCESS","statusCode":200}
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: "สำเร็จ",
        message: `แจ้งชำระเงินเรียบร้อย`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          dispatch(closePopupData());
          window.location.href = "/payment";
        },
      })
    );
  };
};

export const onUploadPaymentSlipFailure = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onUploadPaymentSlipFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    //   {
    //     "status": "BAD_REQUEST",
    //     "message": "Required request part 'file' is not present",
    //     "errors": [
    //         "file part is missing"
    //     ]
    // }
    //   {
    //     "statusDescription": "500 INTERNAL_SERVER_ERROR",
    //     "errorCode": "",
    //     "status": "Internal Server Error",
    //     "statusCode": 500
    // }
    // {"status":"METHOD_NOT_ALLOWED","message":"Request method 'POST' not supported","errors":["POST method is not supported for this request. Supported methods are GET "]}
    // {"status":"BAD_REQUEST","message":"Failed to convert value of type 'java.lang.String' to required type 'int'; nested exception is java.lang.NumberFormatException: For input string: \"upslip\"","errors":["id should be of type int"]}

    if (data.status === "Conflict") {
      data.statusDescription =
        "รายการนี้ชำระเงินสำเร็จแล้ว กรุณาโหลดหน้าเพจใหม่";
    }

    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.status + ": " + data.statusDescription,
      })
    );
  };
};

//GET ADMIN PAYMENT LIST DATA
export const getAdminPaymentListData = (data, page = 1) => {
  console.log(`[${CLASSNAME}] getAdminPaymentListData`);
  // console.log(data)
  let params = {
    // paymentno: "",
    // personname: "",
    // startdate: "",
    // enddate: "",
  };
  if (data !== undefined) {
    if (data.paymentno !== "" && data.paymentno !== undefined) {
      params.paymentno = data.paymentno;
    }
    if (data.personname !== "" && data.personname !== undefined) {
      params.personname = data.personname;
    }
    if (data.startdate !== "" && data.startdate !== undefined) {
      params.startdate = data.startdate; //formatDateToSend(data.startdate)
    }
    if (data.enddate !== "" && data.enddate !== undefined) {
      params.enddate = data.enddate; // formatDateToSend(data.enddate)
    }
    params.page = page;
    // if(data.status !== "" && data.status !== undefined && data.status !== "A"){
    //   params.status = data.status
    // }
  }
  // console.log("params", JSON.stringify(params))
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] getAdminPaymentListData`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_ADMIN_PAYMENT_LIST}`,
        method: `GET`,
        data: params,
        onSuccess: onGetAdminPaymentListResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_ADMIN_PAYMENT_LIST,
        },
      })
    );
  };
};

export const onGetAdminPaymentListResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetAdminPaymentListResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "PAYMENT_SET_ADMIN_PAYMENT_LIST_DATA",
      value: data.result,
    });
    //{"result":{"content":[{"basketId":10,"packId":1,"packNum":5,"packAmount":600,"total":1,"amount":600,"orderdate":"01/08/2021 06:16","numberNo":"000001","basketStatus":"W","basketStatusText":"GEP001_W","agentName":"ตัวแทน1"}],"pageable":{"sort":{"sorted":false,"unsorted":true,"empty":true},"pageSize":20,"pageNumber":0,"offset":0,"unpaged":false,"paged":true},"totalElements":1,"totalPages":1,"last":true,"first":true,"sort":{"sorted":false,"unsorted":true,"empty":true},"number":0,"numberOfElements":1,"size":20,"empty":false},"statusDescription":"200 OK","sumtopay":600,"status":"SUCCESS","statusCode":200}
    // dispatch({
    //   type: "NUMBER_SET_SEARCH_RESULT",
    //   value: data.result.content
    // })
  };
};

// export const onGetAdminPaymentListFailure = (statusCode, statusText, data) => {
//   console.log(`[${CLASSNAME}] onGetAdminPaymentListFailure statusCode: ${statusCode}`)
//   return async (dispatch) => {
//     console.log(data)
//     //send date 20 - 20
//     // TODO handle this error
//     //{"statusDescription":"PreparedStatementCallback; SQL [ SELECT COUNT(*) FROM ( select tpd.payment_id as id,tpd.payment_no as pay_no,tpd.payment_date as pay_date, null as install_date, u.person_name as custname, tpd.total_number as total, tpd.total_amount as amount,  tpd.payment_status as pay_status, 'B' as pay_method, ts.file_id as fileslip  from transflow.trn_payment_data tpd inner join members.trn_customers tc on tpd.customer_id = tc.cust_id  inner join public.user u on tc.user_id = u.user_id left join trn_slips ts on tpd.payment_id = ts.payment_id where tpd.payment_id is not null and tpd.payment_date between '20/10/2021' and '20/10/2021' ) AS derivedTable ]; ERROR: date/time field value out of range: 20/10/2021\n  Hint: Perhaps you need a different datestyle setting.\n  Position: 565; nested exception is org.postgresql.util.PSQLException: ERROR: date/time field value out of range: 20/10/2021\n  Hint: Perhaps you need a different datestyle setting.\n  Position: 565","errorCode":"","status":"Internal Server Error","statusCode":500}
//     dispatch(showDisplayPopupError({
//       "title": Message.MessageCommon.systemError,
//       "message": data.error + ": " + data.error_description
//     }))
//   }
// }

//SET ADMIN PAYMENT DETAIL
export const setAdminPaymentDetailSelected = (data) => {
  console.log(`[${CLASSNAME}] setAdminPaymentDetailSelected`);
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "PAYMENT_SET_ADMIN_PAYMENT_DATA",
      value: data,
    });
  };
};

//GET ADMIN PAYMENT DETAIL DATA
export const getAdminPaymentDetailData = (id) => {
  console.log(`[${CLASSNAME}] getAdminPaymentDetailData`);
  // console.log(data)
  let params = {
    id: id,
  };
  console.log("params", JSON.stringify(params));
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] getAdminPaymentDetailData`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_ADMIN_PAYMENT_DETAIL}`,
        method: `GET`,
        data: params,
        onSuccess: onGetAdminPaymentDetailResponse,
        onFailure: onGetAdminPaymentDetailFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onGetAdminPaymentDetailResponse = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onGetAdminPaymentDetailResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch({
      type: "PAYMENT_SET_ADMIN_PAYMENT_DETAIL_DATA",
      value: data.result,
    });
    //{"result":{"content":[{"basketId":10,"packId":1,"packNum":5,"packAmount":600,"total":1,"amount":600,"orderdate":"01/08/2021 06:16","numberNo":"000001","basketStatus":"W","basketStatusText":"GEP001_W","agentName":"ตัวแทน1"}],"pageable":{"sort":{"sorted":false,"unsorted":true,"empty":true},"pageSize":20,"pageNumber":0,"offset":0,"unpaged":false,"paged":true},"totalElements":1,"totalPages":1,"last":true,"first":true,"sort":{"sorted":false,"unsorted":true,"empty":true},"number":0,"numberOfElements":1,"size":20,"empty":false},"statusDescription":"200 OK","sumtopay":600,"status":"SUCCESS","statusCode":200}
    // dispatch({
    //   type: "NUMBER_SET_SEARCH_RESULT",
    //   value: data.result.content
    // })
  };
};

export const onGetAdminPaymentDetailFailure = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onGetAdminPaymentDetailFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.error + ": " + data.error_description,
      })
    );
  };
};

//UPDATE PAYMENT STATUS
export const updatePaymentStatus = (payment_id, status) => {
  console.log(`[${CLASSNAME}] updatePaymentStatus`);
  // console.log(data)
  let params = `?paymentId=${payment_id}&paymentStatus=${status}`;
  console.log("params", JSON.stringify(params));
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] updatePaymentStatus`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_ADMIN_PAYMENT_UPSTATUS}${params}`,
        method: `PUT`,
        onSuccess: onUpdatePaymentStatusResponse,
        onFailure: onUpdatePaymentStatusFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onUpdatePaymentStatusResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onUpdatePaymentStatusResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    //SHOW SUCCESS MSG AND CLOSE POPUP
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: Message.MessageCommon.success,
        message: `บันทึกสำเร็จ`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          dispatch(closePopupData());
          window.location = "/admin/payment-list";
        },
      })
    );
    // dispatch({
    //   type: "PAYMENT_SET_ADMIN_PAYMENT_DETAIL_DATA",
    //   value: data.result
    // })
    //{"result":{"content":[{"basketId":10,"packId":1,"packNum":5,"packAmount":600,"total":1,"amount":600,"orderdate":"01/08/2021 06:16","numberNo":"000001","basketStatus":"W","basketStatusText":"GEP001_W","agentName":"ตัวแทน1"}],"pageable":{"sort":{"sorted":false,"unsorted":true,"empty":true},"pageSize":20,"pageNumber":0,"offset":0,"unpaged":false,"paged":true},"totalElements":1,"totalPages":1,"last":true,"first":true,"sort":{"sorted":false,"unsorted":true,"empty":true},"number":0,"numberOfElements":1,"size":20,"empty":false},"statusDescription":"200 OK","sumtopay":600,"status":"SUCCESS","statusCode":200}
    // dispatch({
    //   type: "NUMBER_SET_SEARCH_RESULT",
    //   value: data.result.content
    // })
  };
};

export const onUpdatePaymentStatusFailure = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onUpdatePaymentStatusFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    //TODO HANDLE 401
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.status + ": " + data.statusDescription,
      })
    );
    //{
    //     "error": "invalid_token",
    //     "error_description": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiIwODYxODYyNjIyIiwiZGVmYXVsdENvbXBhbnlJZCI6bnVsbCwidGF4SWRzIjoiIiwiZW5hYmxlZCI6dHJ1ZSwiY2xpZW50X2lkIjoiYWRtaW5hcHAiLCJpc0FjY291bnROb25FeHBpcmVkIjp0cnVlLCJjb21wYW55cyI6W10sInNjb3BlIjpbIlJFQUQiLCJDUkVBVEUiLCJVUERBVEUiLCJERUxFVEUiXSwiZXhwIjoyNTE1NDk2ODc5LCJqdGkiOiI5MDY0NTIxNS1iOTRkLTRmNjctYmQxMS1lZWEzNTdlMTM0ZTYiLCJlbWFpbCI6IjA4NjE4NjI2MjIiLCJhY2NvdW50Tm9uTG9ja2VkIjp0cnVlLCJkZXBhcnRtZW50TmFtZSI6bnVsbCwiY3JlZGVudGlhbHNOb25FeHBpcmVkIjp0cnVlLCJpc0FjY291bnROb25Mb2NrZWQiOnRydWUsInVzZXJJZCI6MjYwLCJzdGF4IjoiIiwiYXV0aG9yaXRpZXMiOltdLCJwZXJzb25OYW1lIjoiQ3VzdDEgUHJvbSIsInVzZXJCcmFuY2hzIjpbXSwidXNlclJvbGVzIjpbXSwiYXVkIjpbIm1zL2FkbWluIiwibXMvdXNlciIsIm13L2FkbWluYXBwIl0sImNvbXBhbnlJZCI6bnVsbCwicGhvbmVOdW1iZXIiOiIwODYxODYyNjIyIiwicm9sZUlkcyI6W10sImlzQ3JlZGVudGlhbHNOb25FeHBpcmVkIjp0cnVlLCJpc0VuYWJsZWQiOnRydWUsImFjY291bnROb25FeHBpcmVkIjp0cnVlLCJicmFuY2hJZHMiOiIiLCJzdGF0dXMiOjEsImFjdGl2YXRlZCI6IlkiLCJ1c2VybmFtZSI6IjA4NjE4NjI2MjIifQ.V1J2Mn045uz1YOgnJ4-0dc0OewfZYtXXhPyf8OAOKbw"
    // }
  };
};

//URL_PAYMENT_CHECK
export const checkPaymentStatus = (paymentQRData) => {
  // console.log(`[${CLASSNAME}] checkPaymentStatus`)
  return async (dispatch) => {
    dispatch(
      apiActionWithToken(
        {
          url: `${ServerInfo.URL_PAYMENT_CHECK}/${paymentQRData.paymentNo}`,
          method: `GET`,
          onSuccess: onCheckPaymentStatusResponse,
          onFailure: actionUtils.onCommonFailure,
          label: "APICALLER_CALL_API",
          onBackground: true,
          onSuccessFailureParamOverride: {
            SERVICE_CODE: ServerInfo_Code.URL_PAYMENT_CHECK,
            PAYMENT_QR_DATA: paymentQRData,
          },
        },
        true
      )
    );
  };
};

export const onCheckPaymentStatusResponse = (
  statusCode,
  statusText,
  data,
  onSuccessParam
) => {
  // console.log(`[${CLASSNAME}] onCheckPaymentStatusResponse statusCode: ${statusCode}`)
  return async (dispatch) => {
    // console.log(data)
    // console.log(onSuccessParam)
    if (data.result === "S") {
      if (!onSuccessParam.PAYMENT_QR_DATA.isForcePay) {
        window.location.href = "/vault";
      } else {
        // SHOW MESSAGE AND GOTO REGISTER with prefill
        var param = "?";
        param += "&telno=" + onSuccessParam.PAYMENT_QR_DATA.telno;
        param +=
          "&custFirstname=" + onSuccessParam.PAYMENT_QR_DATA.custFirstname;
        param += "&custLastname=" + onSuccessParam.PAYMENT_QR_DATA.custLastname;
        dispatch(
          showDisplayPopupMessage({
            is_show: true,
            title: "สำเร็จ",
            message:
              "ท่านทำรายการสั่งซื้อสำเร็จ กรุณาลงทะเบียนเพื่อดูประวัติการสั่งซื้อ",
            is_show_submit_button: true,
            is_show_cancel_button: false,
            submit_button_text: "Ok",
            cancel_button_text: null,
            status: "success",
            submit_function: async () => {
              dispatch(closePopupData());
              window.location.href = "/register-cust" + param;
            },
          })
        );
      }
    }
  };
};
