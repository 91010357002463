import React, { Component, Fragment, useEffect, useState } from "react";
import { redirect, useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Popupchat from "../components/Popupchat";
import { showDisplayPopupError } from "../actions/display";
import { getFileByFileIdAndSetStage } from "../actions/file";
import { getAgentDetailData, updateAgentData } from "../actions/agent";
import PageTitle from "../components/PageTitle";
import { getBankAccountList } from "../actions/bank";

const defaultFormData = {
  agentid: "",
  code: "",
  name: "",
  idcard: "",
  telephoneno: "",
  address: "",
  province: "",
  lineid: "",
  bankid: "",
  bankacct: "",
  bankacctname: "",
  fileName: "No file uploaded",
  file: null,
};

const formLang = {
  dealerid: "รหัสตัวแทนขาย",
  code: "รหัสตัวแทนขาย",
  name: "ชื่อ นามสกุล",
  idcard: "รหัสบัตร",
  telephoneno: "เบอร์โทรศัพท์",
  address: "ที่อยู่",
  province: "จังหวัด",
  lineid: "ไลน์",
  bankid: "id บัญชีธนาคาร",
  bankacct: "ธนาคาร",
  bankacctname: "ชื่อบัญชีธนาคาร",
  fileName: "ชื่อไฟล์",
  file: "ไฟล์",
};

const AgentProfileFC = (props) => {
  const { agent_id, ...ddd } = useParams();

  const { agent_detail, bank_list, agent_doc_file, user_detail } = props;

  const [formData, setFormData] = useState(defaultFormData);
  const dispatch = useDispatch();

  const validateFileType = (fileType) => {
    console.log(fileType);
    const allowedExtensions = ["image/png", "image/jpeg"];
    return allowedExtensions.includes(fileType);
  };

  const validateFormRequiredField = () => {
    let errorArray = [];
    if (errorArray.length > 0) {
      return {
        status: false,
        message: `<b>กรุณากรอกข้อมูล</b><br/>- ${errorArray.join("<br/>- ")}`,
      };
    }
    return {
      status: true,
    };
  };

  const validateForm = () => {
    const validated = validateFormRequiredField();
    if (!validated.status) {
      return validated;
    }
    return {
      status: true,
    };
  };

  const showErrorPopup = (title, message) => {
    dispatch(
      showDisplayPopupError({
        title: title,
        message: message,
      })
    );
  };

  const handleInputChange = (event) => {
    console.log("handleInputChange");
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({ ...formData, ...{ [name]: value } });
  };

  const handleFileChange = (e) => {
    console.log("handleFileChange");
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileType = file.type;
      if (validateFileType(fileType)) {
        const fileName = file.name;
        setFormData({ ...formData, ...{ fileName: fileName, file: file } });
      } else {
        showErrorPopup("Error!!", "File type allow: .png, .jpg");
        e.target.value = "";
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validated = validateForm();
    if (!validated.status) {
      showErrorPopup("Error!!", validated.message);
    } else {
      dispatch(updateAgentData(formData));
    }
  };

  const initialPage = () => {
    console.log("initialPage", agent_id);
    if (
      (user_detail.is_admin && agent_id) ||
      (user_detail.is_dealer && user_detail.agent_id)
    ) {
      const id = agent_id || user_detail.agent_id;
      setFormData({ ...formData, ...{ agentid: id } });
      dispatch(getAgentDetailData(id));
    } else {
      if (user_detail.is_agent) {
        redirect("/defaultsettings");
      } else if (user_detail.is_admin) {
        redirect("/agentpage");
      } else {
        redirect("/")      
      }
    }
  };

  const fetchAgentDetailData = (data) => {
    console.log("fetchAgentDetailData");
    setFormData({
      code: data.detail_code,
      name: data.detail_name,
      idcard: data.detail_idcard,
      telephoneno: data.detail_telno,
      address: data.detail_address,
      province: data.detail_province,
      lineid: data.detail_lineid,
      bankid: data.bank_bankid === null ? "" : data.bank_bankid,
      bankacct: data.bank_bankacct === null ? "" : data.bank_bankacct,
      bankacctname:
        data.bank_bankacctname === null ? "" : data.bank_bankacctname,

      // detail_fileid: detail.fileid,
      // detail_registereddate: detail.registereddate,
      // bank_bankid: bank.bankid,
      // bank_bankname: bank.bankname,
      // bank_bankacct: bank.bankacct,
      // bank_bankacctname: bank.bankacctname,
      // fileName: "No file uploaded",
      // file: null,
    });
    if (data.detail_fileid) {
      dispatch(getFileByFileIdAndSetStage(data.detail_fileid));
    }
  };
  // fetchAgentPacknumData = (agent_packnum) => {
  //   console.log("fetchAgentPacknumData", agent_packnum)
  //   this.setState({
  //     datas_init: agent_packnum.datas
  //   })
  //   agent_packnum.datas.map((data, index) => {
  //     const stateName_cost = `cost_num_${data.num}`
  //     const stateName_status = `status_num_${data.num}`
  //     this.setState({
  //       [stateName_cost]: `${data.cost}`,
  //       [stateName_status]: `${data.active}`
  //     })
  //   })
  // //   {
  // //     "num" : 1,"cost":  100, "active":"Y"
  // // }
  // }

  useEffect(() => {
    if (user_detail) {
      initialPage();
      dispatch(getBankAccountList());
    }
  }, [user_detail]);

  useEffect(() => {
    if (agent_detail) {
      fetchAgentDetailData(agent_detail);
    }
  }, [agent_detail]);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      {/* <Rightchat /> */}

      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row lottery-search">
              <div className="col-xl-12">
                <PageTitle title="ข้อมูลตัวแทนขาย" />

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          รหัสตัวแทนขาย
                        </label>
                        <input
                          type="text"
                          value={formData.code}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mb-3">
                    <div className="form-group row-icon-input col-6">
                      <i className="font-sm ti-user text-grey-500 pe-0"></i>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="ชื่อ - นามสกุล"
                      />
                    </div>
                    <div className="form-group row-icon-input col-6">
                      <i className="font-sm ti-id-badge text-grey-500 pe-0"></i>
                      <input
                        type="text"
                        name="idcard"
                        value={formData.idcard}
                        onChange={handleInputChange}
                        maxLength="13"
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="รหัสบัตรประชาชน"
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <div className="form-group row-icon-input mb-3 col-6">
                      <i className="font-sm ti-mobile text-grey-500 pe-0"></i>
                      <input
                        type="text"
                        name="telephoneno"
                        value={formData.telephoneno}
                        onChange={handleInputChange}
                        maxLength="10"
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="เบอร์โทร"
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <div className="form-group row-icon-input col-6">
                      <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="ที่อยู่"
                      />
                    </div>
                    <div className="form-group row-icon-input col-6">
                      <input
                        type="text"
                        name="province"
                        value={formData.province}
                        onChange={handleInputChange}
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="จังหวัด"
                      />
                    </div>
                  </div>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-user text-grey-500 pe-0"></i>
                    <input
                      type="text"
                      name="lineid"
                      value={formData.lineid}
                      onChange={handleInputChange}
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                      placeholder="Line id (ถ้ามี)"
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          ข้อมูลใบเอกสารผู้ค้าสลาก (ถ้ามี)
                        </label>
                        {Object.keys(agent_doc_file).length === 0 && (
                          <div>
                            <img src={agent_doc_file.file_url} alt="" />
                            <br />
                            <br />
                          </div>
                        )}
                        <input
                          type="file"
                          onChange={handleFileChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <h4 className="fw-700 display1-size display2-md-size mb-4">
                    ข้อมูลบัญชีธนาคาร
                  </h4>

                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          ธนาคาร
                        </label>
                        <select
                          value={formData.bankid}
                          onChange={handleInputChange}
                          name="bankid"
                          className="style2-select form-control text-grey-900 font-xsss fw-600"
                        >
                          <option key="0" value="0">
                            -- กรุณาเลือก --
                          </option>
                          {bank_list.map((data, i) => {
                            return (
                              <option key={i} no={i + 1} value={data.bankId}>
                                {data.bankName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          ชื่อบัญชี
                        </label>
                        <input
                          type="text"
                          name="bankacctname"
                          value={formData.bankacctname}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          เลขที่บัญชี
                        </label>
                        <input
                          type="text"
                          name="bankacct"
                          value={formData.bankacct}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      {/* <a href="/accountinformation" 
                      className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">บันทึก</a> */}
                      <input
                        type="submit"
                        className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 "
                        value="บันทึก"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      {/* <Appfooter />  */}
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    agent_detail: state.agent.agent_detail || {},
    bank_list: state.bank.bank_list || {},
    agent_doc_file: state.file.current_file || {},
  };
};

const AgentProfile = connect(mapStateToProps)(AgentProfileFC);
export default AgentProfile;
