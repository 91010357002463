import { ServerInfo, ServerInfo_Code } from "../constants/config";
import Message from "../constants/message";
import { apiActionWithToken } from "./actions";
import {
  showDisplayPopupMessage,
  showDisplayPopupError,
  closePopupData,
} from "./display";
import formData from "form-data";
import actionUtils from "./actionUtils";
import { redirect } from "react-router-dom";

const CLASSNAME = `actions:agent`;

//SAVE AGENT
export const saveAgent = (data) => {
  console.log(`[${CLASSNAME}] saveAgent`);
  let dataForm = new formData();
  dataForm.append("firstname", data.firstName);
  dataForm.append("lastname", data.lastName);
  dataForm.append("idcard", data.cardId);
  dataForm.append("telephoneno", data.phoneNumber);
  dataForm.append("address", data.address);
  dataForm.append("province", data.province);
  dataForm.append("lineid", data.lineId);
  if (data.file !== null) {
    dataForm.append("file", data.file, data.file.name);
  } else {
    dataForm.append("file", "");
  }
  dataForm.append("fileName", data.fileName);
  dataForm.append("fileType", data.fileType);
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] saveAgent`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_AGENT_SAVE}`,
        method: `POST`,
        data: dataForm,
        onSuccess: onSaveAgentResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onSaveAgentResponse = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onSaveAgentResponse statusCode: ${statusCode}`);
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: Message.MessageCommon.success,
        message: `บันทึกสำเร็จ`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          window.location = "/userpage";
        },
      })
    );
  };
};

// export const onSaveAgentFailure = (statusCode, statusText, data) => {
//   console.log(`[${CLASSNAME}] onSaveAgentFailure statusCode: ${statusCode}`)
//   return async (dispatch) => {
//     console.log(data)
//     //EX. 401 {"status":"UNAUTHORIZED","message":"Access is denied","errors":["Access is denied"]}
//     //EX. 401 {"error":"invalid_token","error_description":""}
//     //EX. 400 {"error":"invalid_request","error_description":"Missing grant type"}
//     //401 {"error":"invalid_token","error_description":"A"}
//     if (statusCode === 401) {
//       if (data.status !== undefined) {
//         dispatch(showDisplayPopupError({
//           "title": data.status,
//           "message": data.message
//         }))
//       } else {
//         dispatch(showDisplayPopupError({
//           "title": Message.MessageCommon.errorFound,
//           "message": data.error + ": " + Message.MessageAuthen.tokenFail
//         }))
//       }
//     } else {
//       dispatch(showDisplayPopupError({
//         "title": Message.MessageCommon.systemError,
//         "message": data.status + ": " + (data.statusDescription ? data.statusDescription : data.message)
//       }))
//     }
//   }
// }

//GET AGENT LIST
export const onGetAgent = (data) => {
  // console.log(`[${CLASSNAME}] onGetAgent`)
  // console.log(data)
  let params = {
    agentcode: "",
    agentname: "",
    isactive: "",
  };
  if (data !== undefined) {
    if (data.code !== "" && data.code !== undefined) {
      params.agentcode = data.code;
    }
    if (data.name !== "" && data.name !== undefined) {
      params.agentname = data.name;
    }
    if (data.status !== "" && data.status !== undefined) {
      params.isactive = data.status;
    }
  }

  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] onGetAgent`)
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_AGENT_SEARCH}`,
        method: `GET`,
        data: params,
        onSuccess: onGetAgentListResponse,
        onFailure: onGetAgentListFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onGetAgentListResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetAgentListResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    // console.log(data);
    dispatch({
      type: "AGENT_LIST_DATA",
      value: data.result,
    });
    // {"result":[{"agent_name":"ตัวแทน1","upload_id":66,"file_name":"AG001 600x600 2.zip","totaldoc":100,"verified_success":null,"verified_error":null,"created_date":"10/10/2021"},{"agent_name":"แฟ้ม lotto","upload_id":67,"file_name":"300x300 BW.zip","totaldoc":100,"verified_success":null,"verified_error":null,"created_date":"10/10/2021"}],"statusDescription":"200 OK","status":"SUCCESS","statusCode":200}
    //{"result":{"content":[{"basketId":10,"packId":1,"packNum":5,"packAmount":600,"total":1,"amount":600,"orderdate":"01/08/2021 06:16","numberNo":"000001","basketStatus":"W","basketStatusText":"GEP001_W","agentName":"ตัวแทน1"}],"pageable":{"sort":{"sorted":false,"unsorted":true,"empty":true},"pageSize":20,"pageNumber":0,"offset":0,"unpaged":false,"paged":true},"totalElements":1,"totalPages":1,"last":true,"first":true,"sort":{"sorted":false,"unsorted":true,"empty":true},"number":0,"numberOfElements":1,"size":20,"empty":false},"statusDescription":"200 OK","sumtopay":600,"status":"SUCCESS","statusCode":200}
    // dispatch({
    //   type: "NUMBER_SET_SEARCH_RESULT",
    //   value: data.result.content
    // })
  };
};

export const onGetAgentListFailure = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onGetAgentListFailure statusCode: ${statusCode}`);
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.error + ": " + data.error_description,
      })
    );
  };
};

//GET AGENT PACKNUM DATA
export const getAgentPacknumData = (agent_id) => {
  console.log(`[${CLASSNAME}] getAgentPacknumData`);
  let params = {
    agentid: "",
  };
  if (agent_id !== "" && agent_id !== undefined) {
    params.agentid = agent_id;
  }
  // trans/api/agent/register/packnum?agentid=xxx
  console.log("params", JSON.stringify(params));
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] getAgentPacknumData`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_AGENT_PACKNUM}`,
        method: `GET`,
        data: params,
        onSuccess: onGetAgentPacknumDataResponse,
        onFailure: onGetAgentPacknumDataFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

const comparePacknumDatas = (a, b) => {
  if (a.num < b.num) {
    return -1;
  }
  if (a.num > b.num) {
    return 1;
  }
  return 0;
};

export const onGetAgentPacknumDataResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetAgentPacknumDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    // console.log(data)
    let agentPacknum = data.result;
    if (agentPacknum.datas != null) {
      agentPacknum.datas.sort(comparePacknumDatas);
    }
    dispatch({
      type: "AGENT_SET_AGENT_PACKNUM_DATA",
      value: agentPacknum,
    });
  };
};

export const onGetAgentPacknumDataFailure = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetAgentPacknumDataFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.error + ": " + data.error_description,
      })
    );
  };
};

//SET AGENT PACKNUM DATA
export const setAgentPacknumData = (data, is_admin = true) => {
  console.log(`[${CLASSNAME}] setAgentPacknumData`);
  return async (dispatch) => {
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_AGENT_PACKNUM}`,
        method: `POST`,
        data: data,
        onSuccess: (statusCode, statusText, data) =>
          onSetAgentPacknumDataResponse(statusCode, statusText, data, is_admin),
        onFailure: onSetAgentPacknumDataFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onSetAgentPacknumDataResponse = (
  statusCode,
  statusText,
  data,
  is_admin = true
) => {
  console.log(
    `[${CLASSNAME}] onSetAgentPacknumDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: Message.MessageCommon.success,
        message: `บันทึกสำเร็จ`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          dispatch(closePopupData());
          if (is_admin) {
            redirect("/agentpage");
          } else {
            window.location.reload();
          }
        },
      })
    );
  };
};

export const onSetAgentPacknumDataFailure = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onSetAgentPacknumDataFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    //Ex
    // {
    //   "statusDescription": "limit Cost",
    //   "errorCode": "-719",
    //   "status": "Bad Request",
    //   "statusCode": 400
    // }
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.errorFound,
        message: data.status + ": " + data.statusDescription,
      })
    );
  };
};

//GET AGENT PRICING DATA
export const getAgentPricingData = (agent_id) => {
  console.log(`[${CLASSNAME}] getAgentPricingData`);
  let params = {
    agentid: "",
  };
  if (agent_id !== "" && agent_id !== undefined) {
    params.agentid = agent_id;
  }
  // trans/api/agent/register/packnum?agentid=xxx
  console.log("params", JSON.stringify(params));
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] getAgentPricingData`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_AGENT_PRICINGS}`,
        method: `GET`,
        data: params,
        onSuccess: onGetAgentPricingDataResponse,
        onFailure: onGetAgentPricingDataFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onGetAgentPricingDataResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetAgentPricingDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    let agentPricing = data.result;
    dispatch({
      type: "AGENT_SET_AGENT_PRICING_DATA",
      value: agentPricing,
    });
  };
};

export const onGetAgentPricingDataFailure = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetAgentPricingDataFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.error + ": " + data.error_description,
      })
    );
  };
};

//GET AGENT PRICING DATA
export const getAllAgentPricingData = () => {
  console.log(`[${CLASSNAME}] getAllAgentPricingData`);
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] getAllAgentPricingData`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_ALL_AGENT_PRICES}`,
        method: `GET`,
        onSuccess: onGetAllAgentPricingDataResponse,
        onFailure: onGetAllAgentPricingDataFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onGetAllAgentPricingDataResponse = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onGetAllAgentPricingDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    let agentPricing = data.result;
    dispatch({
      type: "AGENT_SET_ALL_AGENT_PRICING_DATA",
      value: agentPricing.pricingData.sort((a, b) => {
        if (Number(a.range) < Number(b.range)) {
          return -1;
        }
        if (Number(a.range) > Number(b.range)) {
          return 1;
        }

        return 0;
      }),
    });
  };
};

export const onGetAllAgentPricingDataFailure = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onGetAllAgentPricingDataFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.error + ": " + data.error_description,
      })
    );
  };
};

//SET AGENT PACKNUM DATA
export const saveAgentPricingData = (data, is_admin = true) => {
  console.log(`[${CLASSNAME}] saveAgentPricingData`);
  return async (dispatch) => {
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_AGENT_PRICINGS}`,
        method: `POST`,
        data: data,
        onSuccess: (statusCode, statusText, data) =>
          onSaveAgentPricingDataResponse(
            statusCode,
            statusText,
            data,
            is_admin
          ),
        onFailure: onSaveAgentPricingDataFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onSaveAgentPricingDataResponse = (
  statusCode,
  statusText,
  data,
  is_admin = true
) => {
  console.log(
    `[${CLASSNAME}] onSaveAgentPricingDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: Message.MessageCommon.success,
        message: `บันทึกสำเร็จ`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          dispatch(closePopupData());
          if (is_admin) {
            redirect("/agentpage");
          } else {
            window.location.reload();
          }
        },
      })
    );
  };
};

export const onSaveAgentPricingDataFailure = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onSaveAgentPricingDataFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.errorFound,
        message: data.status + ": " + data.statusDescription,
      })
    );
  };
};

//GET AGENT DETAIL
export const getAgentDetailData = (agent_id) => {
  console.log(`[${CLASSNAME}] getAgentDetailData`);
  let params = {
    id: "",
  };
  if (agent_id !== "" && agent_id !== undefined) {
    params.id = agent_id;
  }
  // /trans/api/adm/agent/detail?id=37
  console.log("params", JSON.stringify(params));
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] getAgentDetailData`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_AGENT_DETAIL}`,
        method: `GET`,
        data: params,
        onSuccess: onGetAgentDetailDataResponse,
        onFailure: onGetAgentDetailDataFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onGetAgentDetailDataResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetAgentDetailDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch({
      type: "AGENT_SET_AGENT_DETAIL_DATA",
      value: convertResponseAgentDetailToObject(data.result),
    });
  };
};

const convertResponseAgentDetailToObject = (data) => {
  const detail = data.detail;
  const bank = data.bank;
  return {
    id: data.id,
    detail_code: detail.code,
    detail_name: detail.name,
    detail_idcard: detail.idcard,
    detail_telno: detail.telno,
    detail_address: detail.address,
    detail_province: detail.province,
    detail_lineid: detail.lineid,
    detail_fileid: detail.fileid,
    detail_registereddate: detail.registereddate,
    bank_bankid: bank.bankid,
    bank_bankname: bank.bankname,
    bank_bankacct: bank.bankacct,
    bank_bankacctname: bank.bankacctname,
  };
};

export const onGetAgentDetailDataFailure = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetAgentDetailDataFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    actionUtils.handleError(dispatch, statusCode, data, function () {
      window.location.href = "/agentpage";
    });
  };
};

//Update Agent Data
export const updateAgentData = (data) => {
  console.log(`[${CLASSNAME}] agentUpdateData`);
  var dataForm = new formData();
  if (data.file !== null) {
    dataForm.append("file", data.file, data.file.name);
  }
  dataForm.append("code", data.code);
  dataForm.append("name", data.name);
  dataForm.append("idcard", data.idcard);
  dataForm.append("telephoneno", data.telephoneno);
  dataForm.append("address", data.address);
  dataForm.append("province", data.province);
  dataForm.append("lineid", data.lineid);
  dataForm.append("agentid", data.agentid);
  dataForm.append("bankid", data.bankid);
  dataForm.append("bankacct", data.bankacct);
  dataForm.append("bankacctname", data.bankacctname);
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] agentUpdateData`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_AGENT_UPDATE}`,
        method: `POST`,
        data: dataForm,
        onSuccess: onUpdateAgentDataResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onUpdateAgentDataResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onUpdateAgentDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: Message.MessageCommon.success,
        message: `บันทึกสำเร็จ`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          window.location = "/agentpage";
        },
      })
    );
  };
};

//GET AGENT NUMBER SEARCH
export const getAgentNumberSearch = (data, page = 1) => {
  // console.log(`[${CLASSNAME}] getAgentNumberSearch`)
  // console.log(data)
  let params = {
    // numb: "",
    // numberStatus: "",
    // agentId: "",
    // agentCode: "",
    // agentName: "",
    // installDate: "",
  };
  if (data !== undefined) {
    if (data.numb !== "" && data.numb !== undefined) {
      params.numb = data.numb;
    }
    if (data.numberStatus !== "" && data.numberStatus !== undefined) {
      params.numberStatus = data.numberStatus;
    }
    if (data.agentId !== "" && data.agentId !== undefined) {
      params.agentId = data.agentId;
    }
    if (data.agentCode !== "" && data.agentCode !== undefined) {
      params.agentcode = data.agentCode;
    }
    if (data.agentName !== "" && data.agentName !== undefined) {
      params.agentName = data.agentName;
    }
    if (data.paymentNo !== "" && data.paymentNo !== undefined) {
      params.paymentNo = data.paymentNo;
    }
    if (data.installDate !== "" && data.installDate !== undefined) {
      params.installDate = actionUtils.formatDateToSend_dash2slash(
        data.installDate
      );
    }
    params.page = page;
  }

  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] onGetAgent`)
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_AGENT_NUMBER_SEARCH}`,
        method: `GET`,
        data: params,
        onSuccess: onGetAgentNumberSearchResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_AGENT_NUMBER_SEARCH,
        },
      })
    );
  };
};

export const onGetAgentNumberSearchResponse = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onGetAgentNumberSearchResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "AGENT_NUMBER_LIST_DATA",
      value: data.result.content,
    });
    // {"result":[{"agent_name":"ตัวแทน1","upload_id":66,"file_name":"AG001 600x600 2.zip","totaldoc":100,"verified_success":null,"verified_error":null,"created_date":"10/10/2021"},{"agent_name":"แฟ้ม lotto","upload_id":67,"file_name":"300x300 BW.zip","totaldoc":100,"verified_success":null,"verified_error":null,"created_date":"10/10/2021"}],"statusDescription":"200 OK","status":"SUCCESS","statusCode":200}
    //{"result":{"content":[{"basketId":10,"packId":1,"packNum":5,"packAmount":600,"total":1,"amount":600,"orderdate":"01/08/2021 06:16","numberNo":"000001","basketStatus":"W","basketStatusText":"GEP001_W","agentName":"ตัวแทน1"}],"pageable":{"sort":{"sorted":false,"unsorted":true,"empty":true},"pageSize":20,"pageNumber":0,"offset":0,"unpaged":false,"paged":true},"totalElements":1,"totalPages":1,"last":true,"first":true,"sort":{"sorted":false,"unsorted":true,"empty":true},"number":0,"numberOfElements":1,"size":20,"empty":false},"statusDescription":"200 OK","sumtopay":600,"status":"SUCCESS","statusCode":200}
    // dispatch({
    //   type: "NUMBER_SET_SEARCH_RESULT",
    //   value: data.result.content
    // })
  };
};

//SET AGENT SELECTED
export const setAgentSelected = (data) => {
  console.log(`[${CLASSNAME}] setAgentSelected`);
  return async (dispatch) => {
    dispatch({
      type: "AGENT_SET_AGENT_SELECTED",
      value: data,
    });
  };
};

// AGENT UPSTATUS
export const upstatusAgentNumber = (docId, currentStateCriteria) => {
  console.log(`[${CLASSNAME}] upstatusAgentNumber`);
  return async (dispatch) => {
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_AGENT_NUMBER_UPSTATUS}/${docId}`,
        method: `GET`,
        data: {},
        onSuccess: onUpstatusAgentNumberResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_AGENT_NUMBER_UPSTATUS,
          CRITERIA: currentStateCriteria,
        },
      })
    );
  };
};

export const onUpstatusAgentNumberResponse = (
  statusCode,
  statusText,
  data,
  onSuccessParam = null
) => {
  console.log(
    `[${CLASSNAME}] onUpstatusAgentNumberResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    let searchParam = "?";
    if (onSuccessParam !== null) {
      let criteria = onSuccessParam.CRITERIA;
      if (criteria.agentCode !== "") {
        searchParam = searchParam + "&agentCode=" + criteria.agentCode;
      }
      if (criteria.agentName !== "") {
        searchParam = searchParam + "&agentName=" + criteria.agentName;
      }
      if (criteria.numb !== "") {
        searchParam = searchParam + "&numb=" + criteria.numb;
      }
      if (criteria.installDate !== "") {
        searchParam = searchParam + "&installDate=" + criteria.installDate;
      }
      if (criteria.numberStatus !== "") {
        searchParam = searchParam + "&numberStatus=" + criteria.numberStatus;
      }
    }
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: Message.MessageCommon.success,
        message: `บันทึกสำเร็จ`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          window.location = `/agent/number-search${searchParam}`;
        },
      })
    );
  };
};
