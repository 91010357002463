const defaultState = {
  user_detail: {
    is_customer: false,
    is_dealer: false,
    is_agent: false,
    is_admin: false,
    customer_id: null,
    dealer_id: null,
    agent_id: null,
    user_id: null,
  },
};

const user = (state = defaultState, action = "") => {
  switch (action.type) {
    case "USER_DETAIL_DATA":
      // console.log('AGENT_LIST_DATA')
      return {
        ...state,
        user_detail: action.value,
      };

    default:
      return state;
  }
};

export default user;
