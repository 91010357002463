
const defaultState = {
  cart_list: [],
  cart_sumtopay: 0,
}

const cart = (state = defaultState, action = "") => {
  switch(action.type) {
    case 'CART_SET_CART_DATA':
      // console.log('CART_SET_CART_DATA')
      return {
        ...state,
        cart_list: action.value,
        cart_sumtopay: action.sumtopay
      }
    case 'CART_ADD_TO_CART':
      // console.log('CART_ADD_TO_CART')
      // console.log(action.value)
      // console.log(state.cart_list)
      let temp = state.cart_list
      if(temp.filter(x => x.packId === action.value.packId).length === 0){
        temp.push(action.value)
      }
      // // console.log(state.cart_list.push(action.value))
      // // console.log(state.cart_list)
      // let temp = state.cart_list
      // const newCartList = temp.push(action.value)
      return {
        ...state,
        cart_list: temp
      }
    case 'CART_REMOVE_FROM_CART':
      return {
        ...state,
        cart_list: state.cart_list.filter(x => x.packId !== action.value)
      }
    default:
      return state
  }
}

export default cart