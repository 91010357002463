import React, { Component , Fragment } from "react";
import { connect } from 'react-redux'
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import PageTitle from '../components/PageTitle';
import "react-datepicker/dist/react-datepicker.css";
import { getAdminPaymentDetailData } from "../actions/payment";

class PaymentDetailComponent extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      // agentname: "",
      // filename: "",
      // startdate: "",
      // enddate: "",
      // startdate_input: "",
      // enddate_input: "",
    }
  }

  componentDidMount() {
    this.initialPage()
  }

  componentWillMount() {
    const { payment_data } = this.props
    if(payment_data === null){
      window.location.href = "/admin/payment-list"
    }
  }

  initialPage = () => {
    const { dispatch, payment_data } = this.props
    dispatch(getAdminPaymentDetailData(payment_data.id))
  }

  handleBack = () => {
    window.location.href = "/admin/payment-list"+this.props.location.search
  }
  // handleInputChange = event => {
  //   console.log("handleInputChange")
  //   const target = event.target;
  //   const value = target.type === 'checkbox' ? target.checked : target.value;
  //   const name = target.name;
  //   this.setState({
  //     [name]: value
  //   });
  // }

  // handleInputDateChange = (date, inputValueName, inputName) => {
  //   console.log("handleInputDateChange", date)
  //   const dateFormatted = moment(date).format('yyyy-MM-DD');
  //   this.setState({
  //     [inputValueName]: dateFormatted,
  //     [inputName]: date
  //   });
  // }

  // handleSubmit = () => {
  //   const { dispatch } = this.props
  //   console.log(this.state)
  //   dispatch(getStockListData(this.state))
  //   //TODO Validate value before submit to backend
  //   // const validate = this.validateFormRequiredField()
  //   // if(!validate.status){
  //   //   this.showErrorPopup("Error!!", validate.message)
  //   // }
  //   // else{
  //   //   const dataSubmit = {
  //   //     paymentNo: data.paymentNo,
  //   //     file_name: this.state.file_name,
  //   //     file: this.state.file,
  //   //     refno: this.state.refno,
  //   //     refdate: this.state.refdate,
  //   //     amount: this.state.amount,
  //   //   }
  //   //   onSubmit(dataSubmit)
  //   // }
  // }

  // handleClickViewDetail = (data) => {
  //   const { dispatch } = this.props
  //   dispatch(setStockDetailSelected(data, true))
  //   this.props.history.push('/admin/stock-detail')
  // }

  render() {
    const { payment_data, payment_detail } = this.props
    return (
      <Fragment> 
        <Header />
        <Leftnav />
        {/* <Rightchat /> */}

        <div className="main-content">
        <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">

          <div className="row lottery-search">
            <div className="col-xl-12">
                
              <PageTitle title={`รายละเอียด รายการคำสั่ง ${payment_data.pay_no}`} />

              <div className="row payment-list mb-show">
                <div className="col-xl-12 cart-wrapper mb-0">
                  {
                    payment_detail != null &&
                    payment_detail.map((data, index) => {
                      return (
                        <div key={index} className="row">
                          <div className="col-lg-12 mb-3 " >
                            <div className="card p-3 pb-0 rounded-3 shadow-xs border-0 bg-grey font-xss">
                              <div className="">
                                ชื่อตัวแทน: <strong>{data.agent_name}</strong>
                              </div>
                              <div className="">
                                ชื่อไฟล์: <strong>{data.file_name}</strong>
                              </div>
                              <div className="">
                                จำนวนสลาก: <strong>{data.totaldoc}</strong> 
                                &nbsp;(นำเข้า={data.verified_success} / ไม่ถูกต้อง={data.verified_error})
                              </div>
                              <div className="">
                                วันที่นำเข้า: <strong>{data.created_date}</strong>
                              </div>
                              <div className="">
                                จำนวนสลาก: <strong>{data.totaldoc}</strong>
                                <div className={`nav-content-bttn open-font me-3 d-inline-block float-right lotto-view-btn lotto-view-btn-${index} hide`} 
                                  onClick={() => this.handleClickViewDetail(data)}
                                >
                                  <i className="feather-zoom-in btn-round-xs"></i><span>ดูรายละเอียด</span></div>
                              </div>
                            </div>
                            <div className="card p-3 rounded-3 shadow-xs border-0 bg-grey font-xss">
                              <div className="">
                              </div>
                            </div>

                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>

              <div className="row payment-list mb-hide">
                <div className="col-lg-12 mb-3">
                  <div className="table-content table-responsive">
                    <table className="table text-center">
                      <thead className="bg-greyblue rounded-3">
                        <tr>
                          <th className="border-0 p-4">No.</th>
                          <th className="border-0 p-4 text-left">ชื่อตัวแทน</th>
                          <th className="border-0 p-4">เลขสลาก</th>
                          <th className="border-0 p-4">งวดวันที่</th>
                          <th className="border-0 p-4">งวดที่</th>
                          <th className="border-0 p-4">ชุดที่</th>
                          <th className="border-0 p-4">จำนวนทั้งหมด</th>
                          <th className="border-0 p-4">จำนวนเงิน</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          payment_detail != null &&
                          payment_detail.map((data, index) => {
                            const rowClass = index % 2 === 0 ? '' : 'bg-lightblue'
                            return [
                              <tr key={index} className={`data-row ${rowClass}`}>
                                <td className="product-thumbnail ps-0">{index + 1}</td>
                                <td className="product-headline text-left wide-column">
                                  <h3>{data.agent}</h3>
                                </td>
                                <td className="product-quantity">
                                  {data.numberno}
                                </td>
                                <td className="product-quantity">
                                  {payment_data.install_date}
                                </td>
                                <td className="product-quantity">
                                  {data.number_install_id}
                                </td>
                                <td className="product-quantity">
                                  {data.number_set_id}
                                </td>
                                <td className="product-quantity">
                                  {data.total}
                                </td>
                                <td className="product-quantity">
                                  {data.amount}
                                </td>
                              </tr>
                            ]
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-sm-3 col-xs-12">
                <button onClick={this.handleBack} className="update-cart bg-blue-gradiant d-block text-white fw-600 text-uppercase 
                font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0">กลับ</button>
              </div>

              {/* CONTENT ABOVE */}

            </div>          
          </div>
            
        </div>
        </div>
        </div>

        {/* <Popupchat /> */}
        {/* <Appfooter />  */}
      </Fragment>
    );
  }
}


const mapStateToProps = function(state, props){
  return {
    payment_data: state.payment.admin_payment || null,
    payment_detail: state.payment.admin_payment_detail || [],
  }
}

const PaymentDetail = connect(mapStateToProps)(PaymentDetailComponent)
export default PaymentDetail;
