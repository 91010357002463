import React, { Component, Fragment, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import PageTitle from "../components/PageTitle";
import moment from "moment-timezone";
import "moment/locale/th";
import { toBuddhistYear } from "../libs/buddhist-year";
import "react-datepicker/dist/react-datepicker.css";
import { getDealerWinnerData } from "../actions/dealer";

const prize = [
  {
    key: "first",
    value: "รางวัลที่ 1",
  },
  {
    key: "second",
    value: "รางวัลที่ 2",
  },
  {
    key: "third",
    value: "รางวัลที่ 3",
  },
  {
    key: "fourth",
    value: "รางวัลที่ 4",
  },
  {
    key: "fifth",
    value: "รางวัลที่ 5",
  },
  {
    key: "near1",
    value: "ใกล้เคียงรางวัลที่ 1",
  },
  {
    key: "last2",
    value: "เลขท้าย 2 ตัว",
  },
  {
    key: "last3b",
    value: "เลขท้าย 3 ตัว",
  },
  {
    key: "last3f",
    value: "เลขหน้า 3 ตัว",
  },
];

const DealerWinnerComponent = (props) => {
  const dispatch = useDispatch();

  moment.locale("th");

  const { dealer_winner, user_detail, installment_list } = props;

  const [installmentId, setInstallmentId] = useState(null);

  const initialPage = () => {
    // dispatch(setAdminPaymentDetailSelected(null))
    dispatch(getDealerWinnerData(user_detail.dealer_id, installmentId));
  };

  const handleInstallmentChange = (instid) => {
    setInstallmentId(instid);
  };

  useEffect(() => {
    if (installmentId) {
      dispatch(getDealerWinnerData(user_detail.dealer_id, installmentId));
    }
  }, [installmentId]);

  useEffect(() => {
    if (user_detail.dealer_id && installment_list) {
      const current = installment_list.find((inst) => inst.active === "1");
      if (current) {
        setInstallmentId(current.installmentId);
        dispatch(
          getDealerWinnerData(user_detail.dealer_id, current.installmentId)
        );
      }
    }
  }, [user_detail, installment_list]);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      {/* <Rightchat /> */}

      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row lottery-search">
              <div className="col-xl-12">
                <PageTitle title="ลูกค้าถูกรางวัล" />

                <div>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "15px",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        textAlign: "right",
                        padding: "10px 15px",
                      }}
                    >
                      <label for="installment_id">ประจำงวด</label>&nbsp;
                      <select
                        className="form-control w-auto"
                        style={{
                          minWidth: "150px",
                          height: "40px",
                          lineHeight: "25px",
                        }}
                        name="installment_id"
                        onChange={(e) => {
                          handleInstallmentChange(e.target.value);
                        }}
                      >
                        {installment_list?.map((inst) => (
                          <option
                            key={`inst-list-${inst.installmentId}`}
                            value={inst.installmentId}
                            selected={inst.active === "1"}
                          >
                            {toBuddhistYear(
                              moment(inst.installmentDate),
                              "D MMMM YYYY"
                            )}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row payment-list mb-show">
                  <div className="col-xl-12 cart-wrapper mb-0">
                    {dealer_winner &&
                      dealer_winner.map((data, index) => {
                        return (
                          <div key={index} className="row">
                            <div className="col-lg-12 mb-3 ">
                              <div className="card p-3 rounded-3 shadow-xs border-0 bg-grey font-xss">
                                <div className="">
                                  หมายเลข: <strong>{data.number_no}</strong>
                                </div>
                                <div className="">
                                  รางวัล:{" "}
                                  <strong>
                                    {
                                      prize.find(
                                        (p) => p.key === data.result_type
                                      )?.value
                                    }
                                  </strong>
                                </div>
                                <div className="">
                                  จำนวน(ใบ): {data.quantity}
                                </div>
                                <div className="">
                                  จำนวนเงิน(บาท):{" "}
                                  <strong>
                                    {Intl.NumberFormat().format(data.sum)}
                                  </strong>
                                </div>
                                <div className="">
                                  ชื่อลูกค้า: {data.first_name} {data.last_name}
                                </div>
                                <div className="">
                                  เบอร์โทร: {data.cust_telno}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="row payment-list mb-hide">
                  <div className="col-lg-12 mb-3">
                    <div className="table-content table-responsive">
                      <table className="table text-center">
                        <thead className="bg-grey rounded-3">
                          <tr>
                            <th className="border-0 p-4">หมายเลข</th>
                            <th className="border-0 p-4 text-left">รางวัล</th>
                            <th className="border-0 p-4">จำนวน(ใบ)</th>
                            <th className="border-0 p-4">จำนวนเงิน(บาท)</th>
                            <th className="border-0 p-4">ชื่อลูกค้า</th>
                            <th className="border-0 p-4">เบอร์โทร</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dealer_winner != null &&
                            dealer_winner.map((data, index) => {
                              const rowClass =
                                index % 2 === 0 ? "" : "bg-lightblue";
                              return [
                                <Fragment key={`fragment-${index}`}>
                                  <tr
                                    key={index}
                                    className={`data-row ${rowClass}`}
                                  >
                                    {/* <td className="product-thumbnail ps-0">{index + 1}</td> */}
                                    <td className="product-headline text-left wide-column">
                                      <strong>{data.number_no}</strong>
                                    </td>
                                    <td className="product-quantity">
                                      <strong>
                                        {
                                          prize.find(
                                            (p) => p.key === data.result_type
                                          )?.value
                                        }
                                      </strong>
                                    </td>
                                    <td className="product-quantity">
                                      {data.quantity}
                                    </td>
                                    <td className="product-quantity">
                                      <strong>
                                        {Intl.NumberFormat().format(data.sum)}
                                      </strong>
                                    </td>
                                    <td className="product-quantity">
                                      {data.first_name} {data.last_name}
                                    </td>
                                    <td className="product-quantity">
                                      {data.cust_telno}
                                    </td>
                                  </tr>
                                </Fragment>,
                              ];
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                {/* CONTENT ABOVE */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Popupchat /> */}
      {/* <Appfooter />  */}
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    dealer_winner: state.dealer.dealer_winner || [],
    user_detail: state.user.user_detail || null,
    installment_list: state.number.installment_list || null,
  };
};

const DealerWinner = connect(mapStateToProps)(DealerWinnerComponent);
export default DealerWinner;
