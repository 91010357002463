import jwtDecode from 'jwt-decode';
import cookie from 'react-cookies';
import Utils from '../Utils';

const ACCESS_TOKEN = 'APP_AT';
const REFRESH_TOKEN = 'APP_RT';
const ACCESS_TOKEN_ANONYMOUS = 'APP_AT_ANO';
const REFRESH_TOKEN_ANONYMOUS = 'APP_RT_ANO';

//TYPE
type TokenType = {
  accessToken: string;
  refreshToken: string;
  tokenType?: string;
};

// COOKIE Logon
export const saveUserTokenToMemory = (token: TokenType, is_anomymous) => {
  const expires = new Date(jwtDecode(token.refreshToken).exp * 1000);
  if(is_anomymous){
    cookie.save(ACCESS_TOKEN_ANONYMOUS, Utils.encryptWithKey(token.accessToken), {
      path: '/', expires, // secure: true, // for https site
    });
    cookie.save(REFRESH_TOKEN_ANONYMOUS, Utils.encryptWithKey(token.refreshToken), {
      path: '/', expires, // secure: true, // for https site
    });
  }else{
    removeAnomymousToken()
    cookie.save(ACCESS_TOKEN, Utils.encryptWithKey(token.accessToken), {
      path: '/', expires, // secure: true, // for https site
    });
    cookie.save(REFRESH_TOKEN, Utils.encryptWithKey(token.refreshToken), {
      path: '/', expires, // secure: true, // for https site
    });
  }
}

export const removeAnomymousToken = () => {
  cookie.remove(ACCESS_TOKEN_ANONYMOUS, { path: '/' });
  cookie.remove(REFRESH_TOKEN_ANONYMOUS, { path: '/' });
};

export const removeAppWebSession = () => {
  cookie.remove(ACCESS_TOKEN, { path: '/' });
  cookie.remove(REFRESH_TOKEN, { path: '/' });
  removeAnomymousToken()
};

export const getUserToken = () => {
  const token = cookie.load(ACCESS_TOKEN)
  return Utils.decryptWithKey(token)
};

export const getAnonymousToken = () => {
  const token = cookie.load(ACCESS_TOKEN_ANONYMOUS)
  return Utils.decryptWithKey(token)
};

export const session = {
  saveUserTokenToMemory,
  removeAppWebSession,
  getUserToken,
  getAnonymousToken,
}

export default session;