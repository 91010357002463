
const defaultState = {
  current_file: null,
  lotto_files: null
}

const file = (state = defaultState, action = "") => {
  switch(action.type) {
    case 'FILE_SET_CURRENT_FILE':
      return {
        ...state,
        current_file: action.value
      }
    case 'FILE_SET_LOTTO_FILES':
      return {
        ...state,
        lotto_files: {
          ...state.lotto_files,
          ...action.value
        }
      }
    default:
      return state
  }
}

export default file