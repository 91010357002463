import React,{ Component } from 'react';
import { connect } from 'react-redux'
import { Button, Spinner, Modal } from 'react-bootstrap'

class LoadingComponent extends Component{

  render() {
    const { is_show } = this.props
    return (
      <Modal show={is_show} size="sm" centered>
        <Button variant="info" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />{' '}
          Loading...
        </Button>
      </Modal>
    );
  }
}


const mapStateToProps = function(state, props){
  return {
    is_show: state.display.loading.is_show || false
  }
}

const Loading = connect(mapStateToProps)(LoadingComponent)
export default Loading;