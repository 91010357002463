import { API_START, API_END, API_ERROR, ACCESS_DENIED } from '../actions/actionTypes';

export const apiStart = label => ({
  type: API_START,
  payload: label
});

export const apiEnd = label => ({
  type: API_END,
  payload: label
});

export const apiError = (requestData, error) => ({
  type: API_ERROR,
  requestData,
  error
});

export const accessDenied = url => ({
  type: ACCESS_DENIED,
  payload: {
    url
  }
});
