import React, { Component, Fragment, useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { ServerInfo, ServerInfo_Code } from "../constants/config";
import session from "../actions/session";
import Lottery from "../components/Lottery";

import moment from "moment";
import "moment/locale/th";
import { toBuddhistYear } from "../libs/buddhist-year";

const ValutItemComponent = (props) => {
  const { vault, user_detail, setVaultSeletedData, setIsOpen } = props;

  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [history, setHistory] = useState(null);

  const loadHistory = () => {
    const token = session.getUserToken();
    fetch(
      `${ServerInfo.URL_VAULT_HISTORY}/${user_detail.customer_id}/${
        vault.installmentId
      }/${vault.numberIds.join(",")}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        setHistory(data.result);
      });
  };

  const handleOpen = () => {
    if (!history) {
      loadHistory();
    }
    setIsHistoryOpen(true);
  };

  const handleClose = () => {
    setIsHistoryOpen(false);
  };

  useEffect(() => {
    console.log(isHistoryOpen);
  }, [isHistoryOpen]);

  return (
    <div
      key={`vault-key-${vault.fileId}`}
      className="col-md-6 col-xss-12 pe-2 ps-2 mb-3 position-relative"
    >
      {vault.win && (
        <div
          className="position-absolute pe-2 ps-2"
          style={{
            zIndex: 15,
            width: "200",
            bottom: 0,
            right: 0,
            textAlign: "right",
          }}
        >
          <img src="/assets/images/win.png" width="80" />
          <p style={{ background: "white", padding: "10px" }}>
            ถูกรางวัลรวม:
            <span
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                color: "red",
              }}
            >
              {vault.price.split(",").reduce((a, b) => a - 0 + (b - 0), 0)}{" "}
            </span>
            บาท
          </p>
        </div>
      )}

      {isHistoryOpen && (
        <div
          className="position-absolute pe-2 ps-2"
          style={{
            left: 0,
            bottom: 0,
            width: "100%",
            height: "50%",
            zIndex: 17,
            background: "rgba(255,255,255,0.7)",
            padding: "",
            overflow: "hidden",
          }}
        >
          {history && (
            <ul>
              {history.map((item, index) => (
                <li key={`vault-history-${index}`}>
                  {item.type === "entry" ? "เข้าระบบตู้เซฟ" : "โอน"} |{" "}
                  {item.numberNo} |{" "}
                  {toBuddhistYear(moment(item.date), "D/MM/YY H:m")}{" "}
                  {item.type === "transfer" && (
                    <>
                      | โดย :{" "}
                      {item.owner.email === item.dealer.code
                        ? item.dealer.name
                        : item.owner.fullname}
                    </>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
      <div
        className="position-absolute"
        style={{
          right: "10px",
          top: "10px",
          zIndex: 3,
          cursor: "pointer",
        }}
        onClick={() => {
          if (isHistoryOpen) {
            handleClose();
          } else {
            handleOpen();
          }
        }}
      >
        <i
          className="feather-list btn-round-xs bg-white me-3"
          style={{
            boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
          }}
        />
      </div>
      <a
        onClick={() => {
          if (user_detail.is_dealer) {
            setVaultSeletedData(vault);
            setIsOpen(true);
          }
        }}
      >
        <Lottery key={`lot-key-${vault.numberNo}`} data={vault} />
      </a>
      {vault.isChecked && vault.win === "" && (
        <div
          style={{
            width: "100%",
            height: "100%",
            background: "#000",
            top: 0,
            left: 0,
            opacity: 0.7,
            zIndex: 2,
          }}
          className="position-absolute"
        ></div>
      )}
    </div>
  );
};

const mapStateToProps = function (state, props) {
  return {
    user_detail: state.user.user_detail || null,
  };
};

const VaultItem = connect(mapStateToProps)(ValutItemComponent);
export default VaultItem;
