import React, { Component, Fragment, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import cookie from 'react-cookies'
import { Stream } from "form-data";

class CheckNumberComponent extends Component {
  
}

const CheckNumber = () => {
    const videoRef = useRef(null);
    const photoRef = useRef(null);
    
    const [hasPhoto, setHasPhoto] = useState(false);
    
    const getVideo = () => {
        navigator.mediaDevices.getUserMedia({video:{width: 1280, height: 800 }
        })
        .then(stream => {
            let video = videoRef.current;
            video.srcObject = stream;
            video.play();
        })
        .catch(err => {
            console.error(err);
        })
    }
    
    useEffect(() => {
        getVideo();
    },[videoRef])

    return (
        <>
          <div className="Checknum">
              <div className="camera">
                  <video ref={videoRef}></video>
                  <button>SNAP!</button>
              </div>
              <div className={'result' + (hasPhoto)}>
                  <canvas ref={photoRef}></canvas>
                  <button>CLOSE!</button>
              </div>
          </div>
        </>
    );
};

export default CheckNumber;