import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Popupchat from "../components/Popupchat";
import { showDisplayPopupError } from "../actions/display";
import { saveAgent } from "../actions/agent";
import { saveDealer } from "../actions/dealer";
import PageTitle from "../components/PageTitle";

const defaultFormData = {
  userType: "agent",
  fullName: "",
  cardId: "",
  password: "",
  phoneNumber: "",
  address: "",
  province: "",
  lineId: "",
  fileName: "No file uploaded",
  file: null,
  fileType: "",
};

const formLang = {
  userType: "ประเภทสมาชิก",
  fullName: "ชื่อ นามสกุล",
  cardId: "รหัสบัตร",
  password: "รหัสผ่าน",
  phoneNumber: "เบอร์โทรศัพท์",
  address: "ที่อยู่",
  province: "จังหวัด",
  lineId: "ไลน์",
  fileName: "ชื่อไฟล์",
  file: "ไฟล์",
  fileType: "ประเภทไฟล์",
};

const Userpage = () => {
  const [formData, setFormData] = useState(defaultFormData);
  const dispatch = useDispatch();
  const { userType } = useParams();

  useEffect(() => {
    if (userType && ["agent", "dealer"].includes(userType)) {
      setFormData({ ...formData, ...{ userType } });
    }
  }, [userType]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormData({ ...formData, ...{ [name]: value } });
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileName = file.name;
      setFormData({
        ...formData,
        ...{
          fileName: fileName,
          file: file,
          fileType: file.type,
        },
      });
    }
  };

  const validateFileType = () => {
    if (
      formData.fileType === "image/png" ||
      formData.fileType === "image/jpeg" ||
      formData.fileType === "image/jpg" ||
      formData.fileType === "application/pdf"
    ) {
      return {
        status: true,
      };
    } else {
      return {
        status: false,
        message: `<b>ชนิดไฟล์ไม่ถูกต้อง</b>`,
      };
    }
  };

  const validateTypeForm = () => {
    const validated = validateFileType();
    if (!validated.status) {
      return validated;
    }
    return {
      status: true,
    };
  };

  const validateForm = () => {
    const validated = validateFormRequiredField();
    if (!validated.status) {
      return validated;
    }
    return {
      status: true,
    };
  };

  const validateFormRequiredField = () => {
    let errorArray = [];
    const validateNotEmpty = [
      "fullName",
      "cardId",
      "phoneNumber",
      "password",
      "address",
      "province",
    ];
    validateNotEmpty.forEach((formName) => {
      if (!formData[formName].length) {
        errorArray.push(formLang[formName]);
      }
    });
    if (errorArray.length > 0) {
      return {
        status: false,
        message: `<b>กรุณากรอกข้อมูล</b><br/>- ${errorArray.join("<br/>- ")}`,
      };
    }
    return {
      status: true,
    };
  };

  const showErrorPopup = (title, message) => {
    // const { dispatch } = this.props;
    dispatch(
      showDisplayPopupError({
        title: title,
        message: message,
      })
    );
  };

  const handleSubmit = (e) => {
    // const { dispatch } = this.props;
    e.preventDefault();
    const validate = validateForm();
    if (!validate.status) {
      showErrorPopup("Error!!", validate.message);
    } else {
      const validateType = validateTypeForm();
      if (formData.file !== null && !validateType.status) {
        showErrorPopup("Error!!", validateType.message);
      } else {
        const dataSubmit = {
          fullName: formData.fullName.trim(),
          firstName: formData.fullName
            .trim()
            .replace(/ +(?= )/g, "")
            .split(" ")[0],
          lastName:
            formData.fullName
              .trim()
              .replace(/ +(?= )/g, "")
              .split(" ")[1] || "",
          cardId: formData.cardId,
          phoneNumber: formData.phoneNumber,
          password: formData.password,
          address: formData.address,
          province: formData.province,
          lineId: formData.lineId,
          fileName: formData.fileName,
          file: formData.file,
          fileType: formData.fileType,
        };
        dispatch(
          userType === "dealer" ? saveDealer(dataSubmit) : saveAgent(dataSubmit)
        );
      }
    }
  };

  return (
    <Fragment>
      <Header />
      <Leftnav />
      {/* <Rightchat /> */}
      {/* <Leftnav />
              <Rightchat />


              <div className="main-content ">
                  <div className="middle-sidebar-bottom">
                      <div className="middle-sidebar-left pe-0">
                          <div className="row">
                              <div className="col-xl-12 mb-3">
                                  <ProfilecardThree />
                              </div>
                              <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                                  <Profiledetail />
                                  <Profilephoto />
                                  <Events />
                              </div>
                              <div className="col-xl-8 col-xxl-9 col-lg-8">
                                  <Createpost />
                                  <Postview id="32" postvideo="" postimage="post.png" avater="user.png" user="Surfiya Zakir" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                  <Postview id="31" postvideo="" postimage="post.png" avater="user.png" user="David Goria" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                  <Postview id="33" postvideo="" postimage="post.png" avater="user.png" user="Anthony Daugloi" time="2 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                  <Load />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <Popupchat />
              <Appfooter /> */}

      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="card shadow-none border-0 ms-auto me-auto">
              <div className="card-body rounded-0 text-left">
                <PageTitle
                  title={`ลงทะเบียน${
                    formData.userType === "dealer" ? "ดีลเลอร์" : "ตัวแทนขาย"
                  }`}
                />
                {/* <h2 className="fw-700 display1-size display2-md-size mb-4"><br />ลงทะเบียนตัวแทนขาย</h2> */}
                <form>
                  <div className="form-group row mb-3">
                    <div className="form-group row-icon-input col-6">
                      <i className="font-sm ti-user text-grey-500 pe-0"></i>
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleInputChange}
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="ชื่อ - นามสกุล"
                      />
                    </div>
                    <div className="form-group row-icon-input col-6">
                      <i className="font-sm ti-id-badge text-grey-500 pe-0"></i>
                      <input
                        type="text"
                        name="cardId"
                        value={formData.cardId}
                        onChange={handleInputChange}
                        maxLength="13"
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="รหัสบัตรประชาชน"
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <div className="form-group row-icon-input mb-3 col-6">
                      <i className="font-sm ti-mobile text-grey-500 pe-0"></i>
                      <input
                        type="text"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        maxLength="10"
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="เบอร์โทร"
                      />
                    </div>
                    <div className="form-group row-icon-input col-6">
                      <i className="font-sm ti-key text-grey-500 pe-0"></i>
                      <input
                        type="text"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="รหัสผ่าน"
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <div className="form-group row-icon-input col-6">
                      <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="ที่อยู่"
                      />
                    </div>
                    <div className="form-group row-icon-input col-6">
                      <input
                        type="text"
                        name="province"
                        value={formData.province}
                        onChange={handleInputChange}
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="จังหวัด"
                      />
                    </div>
                  </div>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-user text-grey-500 pe-0"></i>
                    <input
                      type="text"
                      name="lineId"
                      value={formData.lineId}
                      onChange={handleInputChange}
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                      placeholder="Line id (ถ้ามี)"
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          ข้อมูลใบเอกสารผู้ค้าสลาก (ถ้ามี)
                        </label>
                        <input
                          type="file"
                          onChange={handleFileChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </form>

                <div className="row">
                  <div className="col-sm-5"></div>
                  <div className="col-sm-2">
                    <div className="text-center">
                      <input
                        type="submit"
                        onClick={handleSubmit}
                        className="form-control text-center style2-input text-white fw-600 bg-primary border-0 p-0"
                        value="Submit"
                      />
                    </div>
                  </div>
                  <div className="col-sm-5"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      {/* <Appfooter /> */}
    </Fragment>
  );
};

export default Userpage;
