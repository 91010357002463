import React, { Component, Fragment, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import PageTitle from "../components/PageTitle";
import moment from "moment-timezone";
import "moment/locale/th";
import { toBuddhistYear } from "../libs/buddhist-year";
import "react-datepicker/dist/react-datepicker.css";
import { getDealerProfitList, getWinnerList } from "../actions/report";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const ReportComponent = (props) => {
  const dispatch = useDispatch();

  moment.locale("th");

  const { winner_list, dealer_profit_list, user_detail, installment_list } =
    props;

  const [installmentId, setInstallmentId] = useState(null);

  const handleInstallmentChange = (instid) => {
    setInstallmentId(instid);
  };

  useEffect(() => {
    if (installmentId) {
      dispatch(getWinnerList(installmentId));
      dispatch(getDealerProfitList(installmentId));
    }
  }, [installmentId]);

  useEffect(() => {
    if (installment_list) {
      const current = installment_list.find((inst) => inst.active === "1");
      if (current) {
        setInstallmentId(current.installmentId);
        dispatch(getWinnerList(current.installmentId));
        dispatch(getDealerProfitList(current.installmentId));
      }
    }
  }, [installment_list]);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      {/* <Rightchat /> */}

      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row lottery-search">
              <div className="col-xl-12">
                <PageTitle title="รายงาน" />

                <div>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "15px",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        textAlign: "right",
                        padding: "10px 15px",
                      }}
                    >
                      <label for="installment_id">ประจำงวด</label>&nbsp;
                      <select
                        className="form-control w-auto"
                        style={{
                          minWidth: "150px",
                          height: "40px",
                          lineHeight: "25px",
                        }}
                        name="installment_id"
                        onChange={(e) => {
                          handleInstallmentChange(e.target.value);
                        }}
                      >
                        {installment_list?.map((inst) => (
                          <option
                            key={`inst-list-${inst.installmentId}`}
                            value={inst.installmentId}
                            selected={inst.active === "1"}
                          >
                            {toBuddhistYear(
                              moment(inst.installmentDate),
                              "D MMMM YYYY"
                            )}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <Tabs>
                  <TabList>
                    <Tab>ถูกรางวัล</Tab>
                    <Tab>กำไรดีลเลอร์</Tab>
                  </TabList>

                  <TabPanel>
                    {winner_list?.length > 0 &&
                      (() => {
                        const columns = Object.keys(winner_list[0]);
                        return (
                          <div className="row winner-list">
                            <div className="col-lg-12 mb-3">
                              <div className="table-content table-responsive">
                                <table className="table text-center">
                                  <thead className="bg-grey rounded-3">
                                    <tr>
                                      {columns.map((name, headIndex) => (
                                        <th
                                          key={`winners-head-${headIndex}`}
                                          className="border-0 p-4"
                                        >
                                          {name}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {winner_list.map((winner, index) => (
                                      <tr key={`winners-row-${index}`}>
                                        {columns.map((name, colIndex) => (
                                          <td
                                            key={`winners-col-${index}-${colIndex}`}
                                          >
                                            {winner[name]}
                                          </td>
                                        ))}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        );
                      })()}
                  </TabPanel>
                  <TabPanel>
                    {dealer_profit_list?.length > 0 &&
                      (() => {
                        const columns = Object.keys(dealer_profit_list[0]);
                        return (
                          <div className="row winner-list">
                            <div className="col-lg-12 mb-3">
                              <div className="table-content table-responsive">
                                <table className="table text-center">
                                  <thead className="bg-grey rounded-3">
                                    <tr>
                                      {columns.map((name, headIndex) => (
                                        <th
                                          key={`profit-head-${headIndex}`}
                                          className="border-0 p-4"
                                        >
                                          {name}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dealer_profit_list.map((profit, index) => (
                                      <tr key={`profit-row-${index}`}>
                                        {columns.map((name, colIndex) => (
                                          <td
                                            key={`profit-col-${index}-${colIndex}`}
                                          >
                                            {profit[name]}
                                          </td>
                                        ))}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        );
                      })()}
                  </TabPanel>
                </Tabs>

                {/* CONTENT ABOVE */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Popupchat /> */}
      {/* <Appfooter />  */}
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    user_detail: state.user.user_detail || null,
    installment_list: state.number.installment_list || null,
    winner_list: state.report.winner_list || null,
    dealer_profit_list: state.report.dealer_profit_list || null,
  };
};

const Report = connect(mapStateToProps)(ReportComponent);
export default Report;
