import { ServerInfo, ServerInfo_Code } from "../constants/config";
import { apiActionWithToken, apiAction } from "./actions";
import actionUtils from "./actionUtils";
import store from "../store";
import { getVaultList } from "./vault";
import { getInstallmentInfo } from "./number";

const CLASSNAME = `actions:installment`;

export const startNewInstallment = (date) => {
  const params = {
    date,
  };
  return async (dispatch) => {
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_ADMIN_INST_NEWINST}`,
        method: `POST`,
        data: params,
        onSuccess: onStartNewInstallmentResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_ADMIN_INST_NEWINST,
        },
      })
    );
  };
};

export const onStartNewInstallmentResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onStartNewInstallmentResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    const { result } = data;

    dispatch(getInstallmentInfo());
  };
};

export const closeInstallment = () => {
  return async (dispatch) => {
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_ADMIN_INST_CLOSEINST}`,
        method: `POST`,
        data: {},
        onSuccess: onCloseInstallmentResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_ADMIN_INST_CLOSEINST,
        },
      })
    );
  };
};

export const onCloseInstallmentResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onCloseInstallmentResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    const { result } = data;

    dispatch({
      type: "INSTALLMENT_SET_CURRENT",
      value: result,
    });
  };
};
