import { createStore, applyMiddleware } from "redux";
import reducer from "./reducers";
import thunk from "redux-thunk";
import apiMiddleware from "./middleware/apiMiddleware";
import dealerMiddleware from "./middleware/dealerMiddleware";

let middleware = [thunk, apiMiddleware, dealerMiddleware];
const store = createStore(reducer, applyMiddleware(...middleware));

export default store;
