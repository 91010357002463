import React,{ Component } from 'react';
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { hideDisplayPopupImage } from '../actions/display';

class PopupImageComponent extends Component {

  close = () => {
    const { dispatch } = this.props
    dispatch(hideDisplayPopupImage())
  }

  render() {
    const { 
      is_show, image_url, image_name
    } = this.props
    return (
      <Modal centered backdrop="static" show={is_show} >
        <article className={`message is-primary`}>
          <div className="message-header">
            {image_name}
            <button className="delete" aria-label="delete" onClick={this.close}></button>
          </div>
          <div className="message-body">
            <img src={image_url} alt="" />
          </div>
        </article>
      </Modal>
    );
  }
}


const mapStateToProps = function(state, props){
  return {
    is_show: state.display.popup_image.is_show || false,
    image_url: state.display.popup_image.image_url || "",
    image_name: state.display.popup_image.image_name || "",
  }
}

const PopupImage = connect(mapStateToProps)(PopupImageComponent)
export default PopupImage;