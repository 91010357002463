import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { useSubscription } from "@apollo/client";
import { GET_BASKET_COUNT_SUBSCRIPTION } from "../graphql/query";

const AppfooterComponent = (props) => {
  const { user_detail } = props;

  const { data: basketData } = useSubscription(GET_BASKET_COUNT_SUBSCRIPTION, {
    variables: { cust_id: user_detail.customer_id },
  });

  if (!user_detail.is_customer) {
    return <></>;
  }

  return (
    <div className="app-footer border-0 shadow-lg bg-primary-gradiant">
      <Link to="/" className="nav-content-bttn nav-center">
        <i className="feather-home"></i>
      </Link>
      {/* <Link onClick={()=> window.location.href = "/lottery-search" } to="/lottery-search" className="nav-content-bttn nav-center"><i className="feather-credit-card"></i></Link> */}
      {/* <Link to="/defaultvideo" className="nav-content-bttn"><i className="feather-package"></i></Link>
                <Link to="/defaultlive" className="nav-content-bttn" data-tab="chats"><i className="feather-layout"></i></Link>            */}
      <Link to="/cart" className="nav-content-bttn">
        <i className="feather-shopping-cart">
          {basketData?.transflow_trn_cust_basket_aggregate?.aggregate.sum
            .order_total && (
            <span className="num-count bg-danger">
              {
                basketData.transflow_trn_cust_basket_aggregate.aggregate.sum
                  .order_total
              }
            </span>
          )}
        </i>
      </Link>
    </div>
  );
};

const mapStateToProps = function (state, props) {
  return {
    user_detail: state.user.user_detail || null,
  };
};

const Appfooter = connect(mapStateToProps)(AppfooterComponent);
export default Appfooter;
