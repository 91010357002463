import { ServerInfo, ServerInfo_Code } from "../constants/config";
import Message from "../constants/message";
import { apiActionWithToken } from "./actions";
import {
  closePopupData,
  showDisplayPopupError,
  showDisplayPopupMessage,
} from "./display";
import { getNumberSearchList } from "./number";

import store from "../store";
import actionUtils from "./actionUtils";
// import session from "./session"

const CLASSNAME = `actions:cart`;

export const addToCart = (data, dealerId) => {
  console.log(`[${CLASSNAME}] addToCart`);
  // const dataPost = {
  //   "packId": data.packId,
  //   "total": total,
  //   "numberNo": data.numberId
  // }
  const dataPost = {
    ...data,
    dealerId,
    numberNo: data.numberNo,
    numberIds: data.numberIds,
  };
  return async (dispatch) => {
    return dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_BASKET_ADD}`,
        method: `POST`,
        data: dataPost,
        onSuccess: onAddToCartResponse,
        onFailure: onAddToCartFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onAddToCartResponse = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onAddToCartResponse statusCode: ${statusCode}`);
  return async (dispatch) => {
    console.log(data);
    // {"result":{"basketId":10,"packId":1,"total":1,"amount":600,"orderdate":"01/08/2021 06:16"},"statusDescription":"200 OK","status":"SUCCESS","statusCode":200}
    const state = store.getState();
    const search_criteria = state.number.search_criteria || null;
    if (search_criteria) {
      dispatch(getNumberSearchList(search_criteria));
    }
    // dispatch(
    //   showDisplayPopupMessage({
    //     is_show: true,
    //     title: "สำเร็จ",
    //     message: `ใส่สินค้าลงตะกร้าเรียบร้อย`,
    //     is_show_submit_button: true,
    //     is_show_cancel_button: true,
    //     submit_button_text: "ดูตะกร้าสินค้า",
    //     cancel_button_text: "เลือกซื้อต่อ",
    //     status: "success",
    //     submit_function: async () => {
    //       dispatch(closePopupData());
    //       //REDIRECT TO /devices
    //       console.log("submit");
    //       window.location.href = "/cart";
    //     },
    //     cancel_function: async () => {
    //       dispatch(closePopupData());
    //       // console.log(store.getState())
    //       const state = store.getState();
    //       const search_criteria = state.number.search_criteria || null;
    //       let param = "";
    //       if (search_criteria != null) {
    //         param = "?prefill=y";
    //         param += "&d1=" + search_criteria.digit_1;
    //         param += "&d2=" + search_criteria.digit_2;
    //         param += "&d3=" + search_criteria.digit_3;
    //         param += "&d4=" + search_criteria.digit_4;
    //         param += "&d5=" + search_criteria.digit_5;
    //         param += "&d6=" + search_criteria.digit_6;
    //         param += "&type=" + search_criteria.lotto_type;
    //         param += "&set=" + search_criteria.lotto_set;
    //       }
    //       console.log("reload");
    //       window.location.href = "/lottery-search" + param;
    //     },
    //   })
    // );
  };
};

export const onAddToCartFailure = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onAddToCartFailure statusCode: ${statusCode}`);
  return async (dispatch) => {
    console.log(data);
    // 401 => {"error":"invalid_token","error_description":"null"}
    //{"statusDescription":"SER001","errorCode":"SER001","status":"Bad Request","statusCode":400}
    if (statusCode === 401) {
      //Show error not login and redirect to login page
      // dispatch(showDisplayPopupMessage({
      //   is_show: true,
      //   title: Message.MessageCommon.errorFound,
      //   message: data.error + ": " + Message.MessageAuthen.tokenFail,
      //   is_show_submit_button: true,
      //   is_show_cancel_button: false,
      //   submit_button_text: "Ok",
      //   cancel_button_text: null,
      //   status: "error",
      //   submit_function: async () => {
      //     dispatch(closePopupData())
      //     window.location = '/login';
      //   }
      // }))
      window.location = "/login";
    } else {
      dispatch(
        showDisplayPopupError({
          title: Message.MessageCommon.systemError,
          message:
            data.status +
            ": " +
            (data.statusDescription ? data.statusDescription : data.message),
        })
      );
    }
  };
};

//GET CART DATA
export const getCartData = () => {
  // console.log(`[${CLASSNAME}] getCartData`)
  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] getCartData2`)
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_BASKET_SEARCH}`,
        method: `GET`,
        data: {},
        onSuccess: onGetCartDataResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onGetCartDataResponse = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onGetCartDataResponse statusCode: ${statusCode}`);
  const cart_list = data.sumtopay === 0 ? [] : data.result.content;
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "CART_SET_CART_DATA",
      value: cart_list,
      sumtopay: data.sumtopay,
    });
    //{"result":{"content":[{"basketId":10,"packId":1,"packNum":5,"packAmount":600,"total":1,"amount":600,"orderdate":"01/08/2021 06:16","numberNo":"000001","basketStatus":"W","basketStatusText":"GEP001_W","agentName":"ตัวแทน1"}],"pageable":{"sort":{"sorted":false,"unsorted":true,"empty":true},"pageSize":20,"pageNumber":0,"offset":0,"unpaged":false,"paged":true},"totalElements":1,"totalPages":1,"last":true,"first":true,"sort":{"sorted":false,"unsorted":true,"empty":true},"number":0,"numberOfElements":1,"size":20,"empty":false},"statusDescription":"200 OK","sumtopay":600,"status":"SUCCESS","statusCode":200}
    // dispatch({
    //   type: "NUMBER_SET_SEARCH_RESULT",
    //   value: data.result.content
    // })
  };
};

// export const onGetCartDataFailure = (statusCode, statusText, data) => {
//   console.log(`[${CLASSNAME}] onGetCartDataFailure statusCode: ${statusCode}`)
//   return async (dispatch) => {
//     console.log(data)
//     dispatch(showDisplayPopupError({
//       "title": Message.MessageCommon.systemError,
//       "message": data.error + ": " + data.error_description
//     }))
//   }
// }

export const removeFromCart = (basketId) => {
  console.log(`[${CLASSNAME}] removeFromCart`);
  return async (dispatch) => {
    // dispatch({
    //   type: "CART_REMOVE_FROM_CART",
    //   value: data
    // })
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_BASKET_CANCEL}/${basketId}`,
        method: `GET`,
        data: {},
        onSuccess: onCancelCartResponse,
        onFailure: onCancelCartFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onCancelCartResponse = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onCancelCartResponse statusCode: ${statusCode}`);
  return async (dispatch) => {
    console.log(data);
    //{"result":true,"statusDescription":"200 OK","status":"SUCCESS","statusCode":200}
    dispatch(getCartData());
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: "สำเร็จ",
        message: `ลบสินค้าออกจากตะกร้าเรียบร้อย`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          dispatch(closePopupData());
        },
      })
    );
  };
};

export const onCancelCartFailure = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onCancelCartFailure statusCode: ${statusCode}`);
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.error + ": " + data.error_description,
      })
    );
  };
};

export const cartSubmit = () => {
  console.log(`[${CLASSNAME}] cartSubmit`);
  return async (dispatch) => {
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_PAYMENT_ALL}`,
        method: `POST`,
        data: {},
        onSuccess: onCartSubmitResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onCartSubmitResponse = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onCartSubmitResponse statusCode: ${statusCode}`);
  return async (dispatch) => {
    console.log(data);
    //{"result":{"paymentNo":"TSPL-202108P00030","totalAmount":1300,"totalOrder":2,"paymentDate":"01/08/2021 07:50","status":"P","statusText":"GEP001_P","items":[{"numberNo":"000002","numberDate":null,"totalNumber":2,"totalAmount":700,"agentName":"ตัวแทน1"},{"numberNo":"000001","numberDate":null,"totalNumber":1,"totalAmount":600,"agentName":"ตัวแทน1"}]},"statusDescription":"200 OK","status":"SUCCESS","statusCode":200}
    // TODO Show Success
    /*dispatch(showDisplayPopupMessage({
      is_show: true,
      title: "สำเร็จ",
      message: `ทำการสั่งซื้อเรียบร้อย กรุณาชำระเงินและแจ้งชำระเงินภายใน 15 นาที`,
      is_show_submit_button: true,
      is_show_cancel_button: false,
      submit_button_text: "ชำระเงิน",
      cancel_button_text: null, 
      status: "success",
      submit_function: async () => {
        dispatch(closePopupData())
        window.location.href = "/payment"
      }
    }))*/

    const paymentQR = {
      paymentNo: data.result.paymentNo,
      totalAmount: data.result.totalAmount,
      qrFileId: data.result.qrFileId,
      qrExpire: data.result.qrExpire,
      isForcePay: false,
    };
    dispatch({
      type: "PAYMENT_SET_PAYMENT_QR_DATA",
      value: paymentQR,
    });

    /* CHANGE TO QR PAY
    var form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', 'https://www.thaiepay.com/epaylink/payment.aspx');

    var refno = document.createElement('input');
    refno.setAttribute('type', 'hidden');
    refno.setAttribute('name', 'refno');
    refno.setAttribute('value', data.result.paymentNo);
    //refno.setAttribute('value', '999999999999');
    form.appendChild(refno);

    var merchantid = document.createElement('input');
    merchantid.setAttribute('type', 'hidden');
    merchantid.setAttribute('name', 'merchantid');
    merchantid.setAttribute('value', '36633921');
    form.appendChild(merchantid);

    var customeremail = document.createElement('input');
    customeremail.setAttribute('type', 'hidden');
    customeremail.setAttribute('name', 'customeremail');
    customeremail.setAttribute('value', data.result.userEmail);
    form.appendChild(customeremail);

    var cc = document.createElement('input');
    cc.setAttribute('type', 'hidden');
    cc.setAttribute('name', 'cc');
    cc.setAttribute('value', '00');
    form.appendChild(cc);

    var productdetail = document.createElement('input');
    productdetail.setAttribute('type', 'hidden');
    productdetail.setAttribute('name', 'productdetail');
    productdetail.setAttribute('value', 'ซื้อสลากจำนวน ' + data.result.totalOrder + ' ใบ');
    form.appendChild(productdetail);

    var total = document.createElement('input');
    total.setAttribute('type', 'hidden');
    total.setAttribute('name', 'total');
    total.setAttribute('value', data.result.totalAmount);
    form.appendChild(total);

    var fsubmit = document.createElement('input');
    fsubmit.setAttribute('type', 'hidden');
    fsubmit.setAttribute('name', 'Submit');
    fsubmit.setAttribute('value', 'Confirm Order');
    form.appendChild(fsubmit);

    document.body.appendChild(form);
    form.submit();
    */
  };
};

// export const onCartSubmitFailure = (statusCode, statusText, data) => {
//   console.log(`[${CLASSNAME}] onCartSubmitFailure statusCode: ${statusCode}`)
//   return async (dispatch) => {
//     console.log(data)
//     //{"statusDescription":"PAM0011","errorCode":"SER001","status":"Bad Request","statusCode":400}
//     dispatch(showDisplayPopupError({
//       "title": Message.MessageCommon.systemError,
//       "message": data.status + ": " + data.statusDescription
//     }))
//   }
// }

export const buyNow = (data, dealerId) => {
  console.log(`[${CLASSNAME}] buyNow`);
  // const dataPost = {
  //   "packId": data.packId,
  //   "totalOrder": total,
  //   "numberId": data.numberId
  // }
  const dataPost = { ...data, dealerId, numberNo: data.numberId };
  console.log("dataPost", dataPost);
  return async (dispatch) => {
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_PAYMENT_NOW}`,
        method: `POST`,
        data: dataPost,
        onSuccess: onBuyNowResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onBuyNowResponse = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onBuyNowResponse statusCode: ${statusCode}`);
  return async (dispatch) => {
    // console.log(data)
    // console.log(data.result.paymentNo)
    // console.log(data.result.totalAmount)

    const paymentQR = {
      paymentNo: data.result.paymentNo,
      totalAmount: data.result.totalAmount,
      qrFileId: data.result.qrFileId,
      qrExpire: data.result.qrExpire,
      isForcePay: false,
    };
    dispatch({
      type: "PAYMENT_SET_PAYMENT_QR_DATA",
      value: paymentQR,
    });

    /* CHANGE TO QR PAY
    var form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', 'https://www.thaiepay.com/epaylink/payment.aspx');

    var refno = document.createElement('input');
    refno.setAttribute('type', 'hidden');
    refno.setAttribute('name', 'refno');
    refno.setAttribute('value', data.result.paymentNo);
    //refno.setAttribute('value', '999999999,999');
    //refno.setAttribute('value', '999,999,999,999');
    form.appendChild(refno);

    var merchantid = document.createElement('input');
    merchantid.setAttribute('type', 'hidden');
    merchantid.setAttribute('name', 'merchantid');
    merchantid.setAttribute('value', '36633921');
    form.appendChild(merchantid);

    var customeremail = document.createElement('input');
    customeremail.setAttribute('type', 'hidden');
    customeremail.setAttribute('name', 'customeremail');
    customeremail.setAttribute('value', data.result.userEmail);
    form.appendChild(customeremail);

    var cc = document.createElement('input');
    cc.setAttribute('type', 'hidden');
    cc.setAttribute('name', 'cc');
    cc.setAttribute('value', '00');
    form.appendChild(cc);

    var productdetail = document.createElement('input');
    productdetail.setAttribute('type', 'hidden');
    productdetail.setAttribute('name', 'productdetail');
    productdetail.setAttribute('value', 'ซื้อสลากจำนวน ' + data.result.totalOrder + ' ใบ');
    form.appendChild(productdetail);

    var total = document.createElement('input');
    total.setAttribute('type', 'hidden');
    total.setAttribute('name', 'total');
    total.setAttribute('value', data.result.totalAmount);
    form.appendChild(total);

    var fsubmit = document.createElement('input');
    fsubmit.setAttribute('type', 'hidden');
    fsubmit.setAttribute('name', 'Submit');
    fsubmit.setAttribute('value', 'Confirm Order');
    form.appendChild(fsubmit);

    document.body.appendChild(form);
    form.submit();
    */
  };
};

export const onBuyNowFailure = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onBuyNowFailure statusCode: ${statusCode}`);
  return async (dispatch) => {
    console.log(data);
    // 401 => {"error":"invalid_token","error_description":"null"}
    //{"statusDescription":"SER001","errorCode":"SER001","status":"Bad Request","statusCode":400}
    if (statusCode === 401) {
      //Show error not login and redirect to login page
      // dispatch(showDisplayPopupMessage({
      //   is_show: true,
      //   title: Message.MessageCommon.errorFound,
      //   message: data.error + ": " + Message.MessageAuthen.tokenFail,
      //   is_show_submit_button: true,
      //   is_show_cancel_button: false,
      //   submit_button_text: "Ok",
      //   cancel_button_text: null,
      //   status: "error",
      //   submit_function: async () => {
      //     dispatch(closePopupData())
      //     window.location = '/login';
      //   }
      // }))
      window.location = "/login";
    } else {
      dispatch(
        showDisplayPopupError({
          title: Message.MessageCommon.systemError,
          message:
            data.status +
            ": " +
            (data.statusDescription ? data.statusDescription : data.message),
        })
      );
    }
  };
};

export const buyNowForcePay = (data, total, custData) => {
  console.log(`[${CLASSNAME}] buyNowForcePay`);
  // console.log(data, custData)
  const dataPost = {
    packId: data.packId,
    totalOrder: total,
    numberId: data.numberId,
    telno: custData.telno,
    custFirstname: custData.custFirstname,
    custLastname: custData.custLastname,
  };
  // console.log(dataPost)
  return async (dispatch) => {
    dispatch(
      apiActionWithToken(
        {
          url: `${ServerInfo.URL_PAYMENT_NOW_FORCEPAY}`,
          method: `POST`,
          data: dataPost,
          onSuccess: onBuyNowForcePayResponse,
          onFailure: actionUtils.onCommonFailure,
          label: "APICALLER_CALL_API",
          onSuccessFailureParamOverride: {
            SERVICE_CODE: ServerInfo_Code.URL_PAYMENT_NOW_FORCEPAY,
          },
        },
        true
      )
    );
  };
};

export const onBuyNowForcePayResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onBuyNowForcePayResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    // console.log(data)
    // console.log(data.result.paymentNo)
    // console.log(data.result.totalAmount)

    const paymentQR = {
      paymentNo: data.result.paymentNo,
      totalAmount: data.result.totalAmount,
      qrFileId: data.result.qrFileId,
      qrExpire: data.result.qrExpire,
      userEmail: data.result.userEmail,
      isForcePay: true,
      telno: data.result.telno,
      custFirstname: data.result.custFirstname,
      custLastname: data.result.custLastname,
    };
    dispatch({
      type: "PAYMENT_SET_PAYMENT_QR_DATA",
      value: paymentQR,
    });
  };
};
