import { ServerInfo, ServerInfo_Code } from "../constants/config";
import Message from "../constants/message";
import { apiActionWithToken, apiAction } from "./actions";
import {
  showDisplayPopupMessage,
  showDisplayPopupError,
  closePopupData,
} from "./display";
import formData from "form-data";
import actionUtils from "./actionUtils";
import { redirect } from "react-router-dom";
import { getUserDetail } from "./user";
import cookie from "react-cookies";
import qs from "qs";
import { onGetUserList } from "./userlist";

const CLASSNAME = `actions:dealer`;

//SAVE AGENT
export const saveDealer = (data) => {
  console.log(`[${CLASSNAME}] saveDealer`);
  let dataForm = new formData();
  dataForm.append("firstname", data.firstName);
  dataForm.append("lastname", data.lastName);
  dataForm.append("idcard", data.cardId);
  dataForm.append("telephoneno", data.phoneNumber);
  dataForm.append("password", data.password);
  dataForm.append("address", data.address);
  dataForm.append("province", data.province);
  dataForm.append("lineid", data.lineId);
  if (data.file !== null) {
    dataForm.append("file", data.file, data.file.name);
  } else {
    dataForm.append("file", "");
  }
  dataForm.append("fileName", data.fileName);
  dataForm.append("fileType", data.fileType);
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] saveDealer`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_DEALER_SAVE}`,
        method: `POST`,
        data: dataForm,
        onSuccess: onSaveDealerResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onSaveDealerResponse = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onSaveDealerResponse statusCode: ${statusCode}`);
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: Message.MessageCommon.success,
        message: `บันทึกสำเร็จ`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          window.location = "/userpage/dealer";
        },
      })
    );
  };
};

export const onGetDealer = (data) => {
  // console.log(`[${CLASSNAME}] onGetAgent`)
  // console.log(data)
  let params = {
    dealercode: "",
    dealername: "",
    isactive: "",
  };
  if (data !== undefined) {
    if (data.code !== "" && data.code !== undefined) {
      params.dealercode = data.code;
    }
    if (data.name !== "" && data.name !== undefined) {
      params.dealername = data.name;
    }
    if (data.status !== "" && data.status !== undefined) {
      params.isactive = data.status;
    }
  }

  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] onGetAgent`)
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_DEALER_SEARCH}`,
        method: `GET`,
        data: params,
        onSuccess: onGetDealerListResponse,
        onFailure: onGetDealerListFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onGetDealerListResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetDealerListResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "DEALER_LIST_DATA",
      value: data.result,
    });
  };
};

export const onGetDealerListFailure = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetDealerListFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.error + ": " + data.error_description,
      })
    );
  };
};

//SET AGENT SELECTED
export const setDealerSelected = (data) => {
  console.log(`[${CLASSNAME}] setDealerSelected`);
  return async (dispatch) => {
    dispatch({
      type: "DEALER_SET_DEALER_SELECTED",
      value: data,
    });
  };
};

//GET DEALER DETAIL
export const getDealerDetailData = (
  dealer_code_or_id,
  idType = "code",
  ref = false
) => {
  console.log(`[${CLASSNAME}] getDealerDetailData`);
  let url = ServerInfo.URL_DEALER_DETAIL;
  if (dealer_code_or_id !== "" && dealer_code_or_id !== undefined) {
    if (idType === "code") {
      url = ServerInfo.URL_DEALER_DETAIL_BY_CODE;
    }
  }
  // /trans/api/adm/agent/detail?id=37
  // console.log("params", JSON.stringify(params));
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] getDealerDetailData`);
    dispatch(
      apiAction({
        url: `${url}/${dealer_code_or_id}`,
        method: `GET`,
        data: {},
        onSuccess: (statusCode, statusText, data) =>
          onGetDealerDetailDataResponse(statusCode, statusText, data, ref),
        onFailure: onGetDealerDetailDataFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

const convertResponseDealerDetailToObject = (data) => {
  if (data) {
    const detail = data.detail;
    const bank = data.bank;
    return {
      id: data.id,
      detail_code: detail.code,
      detail_name: detail.name,
      detail_idcard: detail.idcard,
      detail_telno: detail.telno,
      detail_address: detail.address,
      detail_province: detail.province,
      detail_lineid: detail.lineid,
      detail_fileid: detail.fileid,
      detail_registereddate: detail.registereddate,
      bank_bankid: bank.bankid,
      bank_bankname: bank.bankname,
      bank_bankacct: bank.bankacct,
      bank_bankacctname: bank.bankacctname,
    };
  }

  return {};
};

export const onGetDealerDetailDataResponse = (
  statusCode,
  statusText,
  data,
  ref = false
) => {
  console.log(
    `[${CLASSNAME}] onGetAgentDetailDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    dispatch({
      type: ref
        ? "DEALER_SET_DEALER_REF_DETAIL_DATA"
        : "DEALER_SET_DEALER_DETAIL_DATA",
      value: convertResponseDealerDetailToObject(data.result),
    });
    if (ref) {
      cookie.save("dealer_ref", data.result.detail.code, {
        path: "/",
        expires: new Date(Date.now() + 10 * 365 * 24 * 60 * 60 * 1000),
        secure: true,
      });
      dispatch({
        type: "DEALER_REF",
        value: data.result.detail.code,
      });

      cookie.remove("dealer_ref_loaded");

      const { search } = window.location;
      const queryString = search.replace("?", "");

      const obj = qs.parse(queryString);

      if (obj.dealer) {
        window.location.href = "/";
      }
    }
  };
};

export const onGetDealerDetailDataFailure = (
  statusCode,
  statusText,
  data,
  ref = false
) => {
  console.log(
    `[${CLASSNAME}] onGetDealerDetailDataFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    actionUtils.handleError(dispatch, statusCode, data, function () {
      window.location.href = ref ? "/login" : "/dealerpage";
    });
  };
};

//Update Dealer Data
export const updateDealerData = (data) => {
  console.log(`[${CLASSNAME}] dealerUpdateData`);
  var dataForm = new formData();
  if (data.file) {
    dataForm.append("file", data.file, data.file.name);
  }
  dataForm.append("code", data.code);
  dataForm.append("name", data.name);
  dataForm.append("idcard", data.idcard);
  dataForm.append("telephoneno", data.telephoneno);
  dataForm.append("address", data.address);
  dataForm.append("province", data.province);
  dataForm.append("lineid", data.lineid);
  dataForm.append("dealerid", data.dealerid);
  dataForm.append("bankid", data.bankid);
  dataForm.append("bankacct", data.bankacct);
  dataForm.append("bankacctname", data.bankacctname);
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] dealerUpdateData`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_DEALER_UPDATE}`,
        method: `POST`,
        data: dataForm,
        onSuccess: onUpdateDealerDataResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onUpdateDealerDataResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onUpdateDealerDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: Message.MessageCommon.success,
        message: `บันทึกสำเร็จ`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          window.location = "/dealerpage";
        },
      })
    );
  };
};

//GET DEALER PACKNUM DATA
export const getDealerPacknumData = (dealer_id) => {
  console.log(`[${CLASSNAME}] getDealerPacknumData`);
  let params = {
    dealerid: "",
  };
  if (dealer_id !== "" && dealer_id !== undefined) {
    params.dealerid = dealer_id;
  }
  // trans/api/dealer/register/packnum?dealerid=xxx
  console.log("params", JSON.stringify(params));
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] getDealerPacknumData`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_DEALER_PACKNUM}`,
        method: `GET`,
        data: params,
        onSuccess: onGetDealerPacknumDataResponse,
        onFailure: onGetDealerPacknumDataFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

const comparePacknumDatas = (a, b) => {
  if (a.num < b.num) {
    return -1;
  }
  if (a.num > b.num) {
    return 1;
  }
  return 0;
};

export const onGetDealerPacknumDataResponse = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onGetDealerPacknumDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    // console.log(data)
    let dealerPacknum = data.result;
    if (dealerPacknum.datas != null) {
      dealerPacknum.datas.sort(comparePacknumDatas);
    }
    dispatch({
      type: "DEALER_SET_DEALER_PACKNUM_DATA",
      value: dealerPacknum,
    });
  };
};

export const onGetDealerPacknumDataFailure = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetDealerPacknumDataFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.error + ": " + data.error_description,
      })
    );
  };
};

//SET DEALER PACKNUM DATA
export const setDealerPacknumData = (data, is_admin = true) => {
  console.log(`[${CLASSNAME}] setDealerPacknumData`);
  return async (dispatch) => {
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_DEALER_PACKNUM}`,
        method: `POST`,
        data: data,
        onSuccess: (statusCode, statusText, data) =>
          onSetDealerPacknumDataResponse(
            statusCode,
            statusText,
            data,
            is_admin
          ),
        onFailure: onSetDealerPacknumDataFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onSetDealerPacknumDataResponse = (
  statusCode,
  statusText,
  data,
  is_admin
) => {
  console.log(
    `[${CLASSNAME}] onSetDealerPacknumDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: Message.MessageCommon.success,
        message: `บันทึกสำเร็จ`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          dispatch(closePopupData());
          if (is_admin) {
            redirect("/dealerpage");
          } else {
            window.location.reload();
          }
        },
      })
    );
  };
};

export const onSetDealerPacknumDataFailure = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onSetDealerPacknumDataFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    //Ex
    // {
    //   "statusDescription": "limit Cost",
    //   "errorCode": "-719",
    //   "status": "Bad Request",
    //   "statusCode": 400
    // }
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.errorFound,
        message: data.status + ": " + data.statusDescription,
      })
    );
  };
};

//GET DEALER PRICING DATA
export const getDealerPricingData = (dealer_id) => {
  console.log(`[${CLASSNAME}] getDealerPricingData`);
  let params = {
    dealerid: "",
  };
  if (dealer_id !== "" && dealer_id !== undefined) {
    params.dealerid = dealer_id;
  }
  // trans/api/dealer/register/packnum?dealerid=xxx
  console.log("params", JSON.stringify(params));
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] getDealerPricingData`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_DEALER_PRICINGS}`,
        method: `GET`,
        data: params,
        onSuccess: onGetDealerPricingsDataResponse,
        onFailure: onGetDealerPricingsDataFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onGetDealerPricingsDataResponse = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onGetDealerPricingsDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    dispatch({
      type: "DEALER_SET_DEALER_PRICINGS_DATA",
      value: data.result,
    });
  };
};

export const onGetDealerPricingsDataFailure = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onGetDealerPricingsDataFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.error + ": " + data.error_description,
      })
    );
  };
};

//SET DEALER PACKNUM DATA
export const saveDealerPricingData = (data, is_admin = true) => {
  console.log(`[${CLASSNAME}] saveDealerPricingData`);
  return async (dispatch) => {
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_DEALER_PRICINGS}`,
        method: `POST`,
        data: data,
        onSuccess: (statusCode, statusText, data) =>
          onSaveDealerPricingDataResponse(
            statusCode,
            statusText,
            data,
            is_admin
          ),
        onFailure: onSaveDealerPricingDataFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onSaveDealerPricingDataResponse = (
  statusCode,
  statusText,
  data,
  is_admin
) => {
  console.log(
    `[${CLASSNAME}] onSaveDealerPricingDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: Message.MessageCommon.success,
        message: `บันทึกสำเร็จ`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          dispatch(closePopupData());
          if (is_admin) {
            redirect("/dealerpage");
          } else {
            window.location.reload();
          }
        },
      })
    );
  };
};

export const onSaveDealerPricingDataFailure = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onSaveDealerPricingDataFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.errorFound,
        message: data.status + ": " + data.statusDescription,
      })
    );
  };
};

//GET DEALER PACKNUM DATA
export const getDealerMinimumProfitData = () => {
  console.log(`[${CLASSNAME}] getDealerMinimumProfitData`);

  return async (dispatch) => {
    console.log(`[${CLASSNAME}] getDealerMinimumProfitData`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_DEALER_MINIMUM_PROFIT}`,
        method: `GET`,
        onSuccess: onGetDealerMinimumProfitDataResponse,
        onFailure: onGetDealerMinimumProfitDataFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onGetDealerMinimumProfitDataResponse = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onGetDealerMinimumProfitDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    let minimumProfitData = data.result - 0;

    dispatch({
      type: "DEALER_MINIMUM_PROFIT",
      value: minimumProfitData,
    });
  };
};

export const onGetDealerMinimumProfitDataFailure = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onGetDealerMinimumProfitDataFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.error + ": " + data.error_description,
      })
    );
  };
};

//GET DEALER PACKNUM DATA
export const registerDealerToCustomer = (dealer_id) => {
  console.log(`[${CLASSNAME}] registerDealerToCustomer`);

  var dataForm = new formData();
  dataForm.append("dealerid", dealer_id);

  return async (dispatch) => {
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_DEALER_REGIS_CUSTOMER}`,
        method: `POST`,
        data: dataForm,
        onSuccess: onRegisterDealerToCustomerResponse,
        onFailure: onRegisterDealerToCustomerFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onRegisterDealerToCustomerResponse = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onRegisterDealerToCustomerResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    let minimumProfitData = data.result - 0;

    dispatch(getUserDetail());
  };
};

export const onRegisterDealerToCustomerFailure = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onRegisterDealerToCustomerFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.error + ": " + data.error_description,
      })
    );
  };
};

export const adminCreateDealerFromCustomer = (data, currentSearch = null) => {
  console.log(`[${CLASSNAME}] adminCreateDealerFromCustomer`);
  const dataForm = new formData();
  dataForm.append("userid", data.userId);
  dataForm.append("idcard", data.idcard);
  dataForm.append("province", data.province);
  return async (dispatch) => {
    console.log(dataForm);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_ADMIN_DEALER_FROM_CUSTOMER}`,
        method: `POST`,
        data: dataForm,
        onSuccess: (statusCode, statusText, data) =>
          onAdminCreateDealerFromCustomerResponse(
            statusCode,
            statusText,
            data,
            currentSearch
          ),
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};
export const onAdminCreateDealerFromCustomerResponse = (
  statusCode,
  statusText,
  data,
  currentSearch
) => {
  console.log(
    `[${CLASSNAME}] onAdminCreateDealerFromCustomerResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    if (data.result) {
      dispatch(
        showDisplayPopupMessage({
          is_show: true,
          title: Message.MessageCommon.success,
          message: `สร้าง ดีลเลอร์ ${data.result.dealerCode} สำเร็จ`,
          is_show_submit_button: true,
          is_show_cancel_button: false,
          submit_button_text: "Ok",
          cancel_button_text: null,
          status: "success",
          submit_function: null,
        })
      );
      dispatch(
        onGetUserList({ ...currentSearch, username: data.result.dealerCode })
      );
    } else {
      dispatch(
        showDisplayPopupError({
          title: Message.MessageCommon.errorFound,
          message: data.result,
        })
      );
    }
  };
};

//GET PAYMENT DATA
export const getDealerWinnerData = (dealerId, installmentId) => {
  console.log(`[${CLASSNAME}] getDealerWinnerData`);
  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] getPaymentListData`)
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_DEALER_WINNER}/${dealerId}/${installmentId}`,
        method: `GET`,
        onSuccess: onGetDealerWinnerResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_DEALER_WINNER,
        },
      })
    );
  };
};

export const onGetDealerWinnerResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetDealerWinnerResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    dispatch({
      type: "DEALER_WINNER_DATA",
      value: data.result,
    });
  };
};
