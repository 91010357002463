import React,{Component} from 'react';

class Popupchat extends Component {
    render() {
        return (
            <div></div>     
        );
    }
}

export default Popupchat;