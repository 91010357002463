import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { hideDisplayPopupQR } from '../actions/display';

class PopupQRComponent extends Component {

  close = () => {
    const { dispatch } = this.props
    dispatch(hideDisplayPopupQR())
  }

  print = () => {
    window.print()
  }

  render() {
    const {
      is_show, image_url, qr_title, qr_label
    } = this.props
    return (
      <Modal centered backdrop="static" show={is_show} >
        <article className={`message is-primary`}>
          <div className="message-header">
            {qr_title}
            <button className="delete" aria-label="delete" onClick={this.close}></button>
          </div>
          <div className="message-body">
            <div className={`text-center`} >
              <img src={image_url} alt="" /><br />
              <label className={`text-black fw-600`}>{qr_label}</label><br />
            </div>
            <div className={`text-center no-print`}>
              <button className="mt-5 btn pt-2 pb-2 ps-5 pe-5 lh-24 ms-1 ls-3 d-inline-block
              rounded-xl bg-skype font-xss fw-700 ls-lg text-white" aria-label="print" onClick={this.print}>Print</button><br />
            </div>
          </div>
        </article>
      </Modal>
    );
  }
}


const mapStateToProps = function (state, props) {
  return {
    is_show: state.display.popup_qr.is_show || false,
    image_url: state.display.popup_qr.image_url || "",
    qr_title: state.display.popup_qr.qr_title || "",
    qr_label: state.display.popup_qr.qr_label || "",
  }
}

const PopupQR = connect(mapStateToProps)(PopupQRComponent)
export default PopupQR;