import React, { Component, Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import PageTitle from "../components/PageTitle";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import "moment/locale/th";
import { toBuddhistYear } from "../libs/buddhist-year";
import Lottery from "../components/Lottery";
import "react-datepicker/dist/react-datepicker.css";
import {
  getAdminPaymentListData,
  setAdminPaymentDetailSelected,
} from "../actions/payment";
import { getImageFileAndShowSlipCheck } from "../actions/file";
import { TbX } from "react-icons/tb";

const PaymentListComponent = (props) => {
  moment.locale("th");
  moment.tz.setDefault("Asia/Bangkok");

  const { payment_list } = props;

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    paymentno: "",
    personname: "",
    startdate: "",
    enddate: "",
    startdate_input: "",
    enddate_input: "",
  });

  const [paymentList, setPaymentList] = useState([]);

  const [scrollTop, setScrollTop] = useState(0);

  const [page, setPage] = useState(1);
  const [reach, setReach] = useState(false);

  const [modalData, setModalData] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const initialPage = () => {
    setPaymentList([]);
    dispatch(setAdminPaymentDetailSelected(null));
    dispatch(getAdminPaymentListData(formData));
  };

  const setQueryData = () => {
    const queryParams = new URLSearchParams(window.location.search);
    // paymentno: "21", personname: "Th", startdate: "2021-12-06", enddate: "2021-12-07
    if (
      queryParams.get("paymentno") != null ||
      queryParams.get("personname") != null ||
      queryParams.get("startdate") != null ||
      queryParams.get("enddate") != null
    ) {
      let paymentnoIn = "";
      let personnameIn = "";
      let startdateIn = "";
      let enddateIn = "";
      if (queryParams.get("paymentno") != null) {
        paymentnoIn = queryParams.get("paymentno");
      }
      if (queryParams.get("personname") != null) {
        personnameIn = queryParams.get("personname");
      }
      if (queryParams.get("startdate") != null) {
        startdateIn = queryParams.get("startdate");
      }
      if (queryParams.get("enddate") != null) {
        enddateIn = queryParams.get("enddate");
      }

      setFormData({
        paymentno: paymentnoIn,
        personname: personnameIn,
        startdate: startdateIn,
        enddate: enddateIn,
        startdate_input:
          startdateIn === "" ? "" : moment(startdateIn, "yyyy-MM-DD").toDate(),
        enddate_input:
          enddateIn === "" ? "" : moment(enddateIn, "yyyy-MM-DD").toDate(),
      });
    }

    initialPage();
  };

  const handleInputChange = (event) => {
    console.log("handleInputChange");
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputDateChange = (date, inputValueName, inputName) => {
    console.log("handleInputDateChange", date);
    if (date !== null) {
      const dateFormatted = moment(date).format("yyyy-MM-DD");
      setFormData({
        ...formData,
        [inputValueName]: dateFormatted,
        [inputName]: date,
      });
    } else {
      setFormData({
        ...formData,
        [inputValueName]: "",
        [inputName]: "",
      });
    }
  };

  const handleClear = () => {
    setFormData({
      paymentno: "",
      personname: "",
      startdate: "",
      enddate: "",
      startdate_input: "",
      enddate_input: "",
    });
  };

  const handleSubmit = () => {
    setPaymentList([]);
    dispatch(getAdminPaymentListData(formData));
    setPage(1);
  };

  const handleClickViewDetail = (data) => {
    // console.log(data);
    // const pList = paymentList.map((p) => {
    //   if (data.id === p.id) {
    //     p.view = !!!p.view;
    //   }
    //   return p;
    // });
    // setPaymentList(pList);
    setModalData(data);
    setIsShow(true);
  };

  const renderButtonViewDeterBtn = (data, index) => {
    if (data.items.length > 0)
      return (
        <div
          className={`nav-content-bttn open-font me-3 d-inline-block float-right lotto-view-btn lotto-view-btn-${index} hide`}
          onClick={() => handleClickViewDetail(data)}
        >
          <i className="feather-zoom-in btn-round-xs"></i>
          <span>ดูรายละเอียด</span>
        </div>
      );
  };

  const handleClickCheckSlip = (data) => {
    dispatch(setAdminPaymentDetailSelected(data));
    dispatch(getImageFileAndShowSlipCheck(data.fileslip));
  };

  const loadMore = () => {
    dispatch(getAdminPaymentListData(formData, page + 1));
    setPage(page + 1);
  };

  useEffect(() => {
    if (
      scrollTop + window.innerHeight === document.body.clientHeight &&
      paymentList.length
    ) {
      console.log("touch");
      if (!reach) {
        console.log("calling");
        loadMore();
      }
    }
  }, [scrollTop]);

  useEffect(() => {
    if (payment_list) {
      if (!payment_list.length && paymentList.length) {
        setReach(true);
      }
      if (payment_list.length) {
        setPaymentList([
          ...paymentList,
          ...payment_list.map((p) => ({ ...p, view: false })),
        ]);
      }
    }
  }, [payment_list]);

  useEffect(() => {
    setQueryData();

    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      {/* <Rightchat /> */}

      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row lottery-search">
              <div className="col-xl-12">
                <PageTitle title="รายการคำสั่งซื้อของลูกค้า" />

                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                  <h4 className="mont-font fw-600 font-md mb-2 mb-0 mt-0">
                    ค้นหา
                  </h4>

                  <div className="row">
                    <div className="col-sm-3 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          เลขที่คำสั่งซื้อ
                        </label>
                        <input
                          type="text"
                          name="paymentno"
                          value={formData.paymentno}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-sm-3 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          ชื่อลูกค้า
                        </label>
                        <input
                          type="text"
                          name="personname"
                          value={formData.personname}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-sm-3 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          วันที่คำสั่งซื้อ (ตั้งแต่)
                        </label>
                        <DatePicker
                          selected={formData.startdate_input}
                          closeOnScroll={true}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                          className="form-control"
                          showPopperArrow={false}
                          placeholderText="dd/MM/yyyy"
                          // dateFormat="MMMM d, yyyy h:mm aa"
                          onChange={(date) =>
                            handleInputDateChange(
                              date,
                              "startdate",
                              "startdate_input"
                            )
                          }
                        />
                        {/* <input type="text" name="startdate" value={this.state.startdate} onChange={this.handleInputChange} className="form-control" /> */}
                      </div>
                    </div>

                    <div className="col-sm-3 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          วันที่คำสั่งซื้อ (ถึง)
                        </label>
                        <DatePicker
                          selected={formData.enddate_input}
                          closeOnScroll={true}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                          className="form-control"
                          showPopperArrow={false}
                          placeholderText="dd/MM/yyyy"
                          // dateFormat="MMMM d, yyyy h:mm aa"
                          onChange={(date) =>
                            handleInputDateChange(
                              date,
                              "enddate",
                              "enddate_input"
                            )
                          }
                        />
                        {/* <input type="text" name="enddate" value={this.state.enddate} onChange={this.handleInputChange} className="form-control" /> */}
                      </div>
                    </div>

                    <div className="col-sm-3 col-xs-12">
                      <label className="mont-font fw-600 font-xsss mb-2">
                        &nbsp;
                      </label>
                      <button
                        onClick={handleSubmit}
                        className="update-cart bg-cyan d-block text-white fw-600 text-uppercase 
                    font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"
                      >
                        ค้นหา
                      </button>
                    </div>

                    <div className="col-sm-3 col-xs-12">
                      <label className="mont-font fw-600 font-xsss mb-2">
                        &nbsp;
                      </label>
                      <button
                        onClick={handleClear}
                        className="update-cart bg-white d-block text-cyan fw-600 text-uppercase 
                    font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-success border"
                      >
                        ล้างข้อมูล
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row payment-list mb-show">
                  <div className="col-xl-12 cart-wrapper mb-0">
                    {paymentList?.map((data, index) => {
                      let pay_status = "";
                      pay_status =
                        data.pay_status === "P" ? "รอชำระ" : pay_status;
                      pay_status =
                        data.pay_status === "V" ? "รอตรวจสอบ" : pay_status;
                      pay_status =
                        data.pay_status === "S"
                          ? "Payment success"
                          : pay_status;
                      pay_status =
                        data.pay_status === "C" ? "Cancel" : pay_status;
                      return (
                        <div key={index} className="row">
                          <div className="col-lg-12 mb-3 ">
                            <div className="card p-3 rounded-3 shadow-xs border-0 bg-grey font-xss">
                              <div className="">
                                เลขที่คำสั่งซื้อ: <strong>{data.pay_no}</strong>
                              </div>
                              <div className="">
                                วันที่คำสั่งซื้อ:{" "}
                                <strong>
                                  {toBuddhistYear(
                                    moment(data.payment_date),
                                    "DD/MM/YYYY HH:mmน."
                                  )}
                                </strong>
                              </div>
                              <div className="">
                                งวดประจำวันที่:{" "}
                                <strong>
                                  {toBuddhistYear(
                                    moment(data.install_date),
                                    "DD/MM/YYYY"
                                  )}
                                </strong>
                              </div>
                              <div className="">
                                ชื่อลูกค้า: <strong>{data.custname}</strong>
                              </div>
                              <div className="">
                                จำนวนทั้งหมด: <strong>{data.total}</strong>
                              </div>
                              {/* <div className="">
                                  จำนวนสลาก: <strong>{data.totaldoc}</strong>
                                  &nbsp;(นำเข้า={data.verified_success} /
                                  ไม่ถูกต้อง={data.verified_error})
                                </div> */}

                              <div className="">
                                จำนวนเงิน: <strong>{data.amount}</strong>
                              </div>

                              <div className="">
                                กำไร(ตามส่วน):{" "}
                                <strong>
                                  {data.request_dealer_add_amount}
                                </strong>
                              </div>

                              <div className="">
                                วิธีชำระเงิน:{" "}
                                <strong>
                                  {data.pay_method === "B"
                                    ? "โอนผ่านธนาคาร"
                                    : data.pay_method}
                                </strong>
                              </div>
                              <div className="">
                                สถานะ: <strong>{pay_status}</strong>
                                {/* {(data.items.length > 0)} */}
                                {renderButtonViewDeterBtn(data, index)}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="row payment-list mb-hide">
                  <div className="col-lg-12 mb-3">
                    <div className="table-content table-responsive">
                      <table className="table text-center">
                        <thead className="bg-grey rounded-3">
                          <tr>
                            <th className="border-0 p-4">เลขที่คำสั่งซื้อ</th>
                            <th className="border-0 p-4 text-left">
                              วันที่คำสั่งซื้อ
                            </th>
                            <th className="border-0 p-4">งวดประจำวันที่</th>
                            <th className="border-0 p-4">ชื่อลูกค้า</th>
                            <th className="border-0 p-4">จำนวนทั้งหมด</th>
                            <th className="border-0 p-4">จำนวนเงิน</th>
                            <th className="border-0 p-4">สถานะ</th>
                            <th className="border-0 p-4">วิธีชำระเงิน</th>
                            <th className="border-0 p-4">Slip</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paymentList?.map((data, index) => {
                            const rowClass =
                              index % 2 === 0 ? "" : "bg-lightblue";
                            let pay_status = "";
                            pay_status =
                              data.pay_status === "P" ? "รอชำระ" : pay_status;
                            pay_status =
                              data.pay_status === "V"
                                ? "รอตรวจสอบ"
                                : pay_status;
                            pay_status =
                              data.pay_status === "S"
                                ? "Payment success"
                                : pay_status;
                            pay_status =
                              data.pay_status === "C" ? "Cancel" : pay_status;
                            return [
                              <Fragment key={`desktop-${index}`}>
                                <tr className={`data-row ${rowClass}`}>
                                  {/* <td className="product-thumbnail ps-0">{index + 1}</td> */}
                                  <td className="product-headline text-left wide-column">
                                    <h3
                                      className="lotto-view-btn"
                                      onClick={() =>
                                        data.items.length > 0 &&
                                        handleClickViewDetail(data)
                                      }
                                      style={{
                                        cursor:
                                          data.items.length == 0 && "unset",
                                      }}
                                    >
                                      <strong>{data.pay_no}</strong>
                                    </h3>
                                  </td>
                                  <td className="product-quantity">
                                    {toBuddhistYear(
                                      moment(data.payment_date),
                                      "DD/MM/YYYY HH:mmน."
                                    )}
                                  </td>
                                  <td className="product-quantity">
                                    {toBuddhistYear(
                                      moment(data.install_date),
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td className="product-quantity">
                                    {data.custname}
                                  </td>
                                  <td className="product-quantity">
                                    {data.total}
                                  </td>
                                  <td className="product-quantity">
                                    {data.amount}
                                  </td>
                                  <td className="product-quantity">
                                    {pay_status}
                                  </td>
                                  <td className="product-quantity">
                                    {data.pay_method === "B"
                                      ? "โอนผ่านธนาคาร"
                                      : data.pay_method}
                                  </td>
                                  <td className="product-quantity">
                                    {data.fileslip !== null && (
                                      <div
                                        className={`nav-content-bttn open-font me-3 d-inline-block float-right lotto-view-btn lotto-view-btn-${index} hide`}
                                        onClick={() =>
                                          handleClickCheckSlip(data)
                                        }
                                      >
                                        <i className="feather-zoom-in btn-round-xs"></i>
                                        <span>ตรวจสอบ Slip</span>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              </Fragment>,
                            ];
                          })}
                        </tbody>
                      </table>
                      <div style={{ textAlign: "center" }}>
                        <button onClick={loadMore}>Load more</button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* CONTENT ABOVE */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal centered backdrop={true} show={isShow}>
        <div style={{ position: "fixed", right: 0, zIndex: 1 }}>
          <Button
            variant="ghost"
            style={{ color: "white", fontSize: "30px" }}
            onClick={() => {
              setIsShow(false);
            }}
          >
            <TbX />
          </Button>
        </div>
        <article className={`message is-primary`}>
          <div className="message-header">
            <p style={{ flex: 1 }}>{modalData?.pay_no}</p>
          </div>
          <div className="message-body">
            {modalData && (
              <div className={``} style={{ paddingTop: "15px" }}>
                {modalData.items.map((item, indexItem) => {
                  const lottoData = {
                    numberDate: item.numberDate,
                    numberId: item.numberNo,
                    agentName: item.agentName,
                    packAmount: item.packAmount,
                    packNum: item.packNum,
                    packId: item.packId,
                    fileIds: item.fileIds,
                    totalNumber: item.totalNumber,
                    totalAmount: item.totalAmount,
                    dealerName: item.dealerName,
                    dealerCode: item.dealerCode,
                  };
                  return (
                    <div
                      className="lottery-search-result d-inline-block float-left"
                      key={`desktop-items-${indexItem}`}
                    >
                      <Lottery data={lottoData} page="adminPayment" />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </article>
      </Modal>

      {/* <Popupchat /> */}
      {/* <Appfooter />  */}
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    payment_list: state.payment.admin_payment_list || [],
  };
};

const PaymentList = connect(mapStateToProps)(PaymentListComponent);
export default PaymentList;
