import { ServerInfo, ServerInfo_Code } from "../constants/config";
import Message from "../constants/message";
import { apiActionWithTokenResponseType } from "./actions";
import actionUtils from "./actionUtils";
import {
  showDisplayPopupError,
  showDisplayPopupImage,
  showDisplayPopupQR,
  showDisplayPopupSlipCheck,
  showDisplayPopupStockCheck,
} from "./display";
import store from "../store";

const CLASSNAME = `actions:file`;

//GET IMAGE FILE AND SHOW
export const getImageFileAndShow = (file_id, file_name) => {
  console.log(`[${CLASSNAME}] getImageFile`);
  // console.log(file_name)
  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] getImageFile`)
    dispatch(
      apiActionWithTokenResponseType({
        url: `${ServerInfo.URL_GET_FILE}/${file_id}`,
        method: `GET`,
        data: {},
        onSuccess: onGetImageFileResponse,
        onFailure: onGetImageFileFailure,
        label: "APICALLER_CALL_API",
        responseTypeOverride: "json",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_GET_FILE,
          SERVICE_ATTR: file_id,
        },
      })
    );
    dispatch({
      type: "DISPLAY_SET_POPUP_IMAGE_NAME",
      value: file_name,
    });
  };
};

export const onGetImageFileResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetImageFileResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    dispatch(
      showDisplayPopupImage({
        image_url: data.imageUrl,
      })
    );
  };
};

export const onGetImageFileFailure = (
  statusCode,
  statusText,
  data,
  onFailParam
) => {
  console.log(`[${CLASSNAME}] onGetImageFileFailure statusCode: ${statusCode}`);
  return async (dispatch) => {
    // console.log(data)

    // {"SERVICE_CODE":ServerInfo_Code.URL_GET_FILE, "SERVICE_ATTR": file_id}
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.error + ": " + data.error_description,
      })
    );
  };
};

//GET ZIP FILE AND DOWNLOAD
export const getZipFileAndDownload = (file_id) => {
  console.log(`[${CLASSNAME}] getZipFileAndDownload`);
  console.log(file_id);
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] getZipFileAndDownload`);
    dispatch(
      apiActionWithTokenResponseType({
        url: `${ServerInfo.URL_GET_FILE}/${file_id}`,
        method: `GET`,
        data: {},
        onSuccess: onGetZipFileResponse,
        onFailure: onGetZipFileFailure,
        label: "APICALLER_CALL_API",
        responseTypeOverride: "json",
      })
    );
  };
};

export const onGetZipFileResponse = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onGetZipFileResponse statusCode: ${statusCode}`);
  return async (dispatch) => {
    console.log(data.imageUrl);
    dispatch({
      type: "STOCK_SET_STOCK_ZIP_URL",
      value: data.imageUrl,
    });
  };
};

export const onGetZipFileFailure = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onGetZipFileFailure statusCode: ${statusCode}`);
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.error + ": " + data.error_description,
      })
    );
  };
};

//GET IMAGE FILE AND SHOW SLIP CHECK
export const getImageFileAndShowSlipCheck = (file_id) => {
  console.log(`[${CLASSNAME}] getImageFileAndShowSlipCheck`);
  // console.log(file_name)
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] getImageFileAndShowSlipCheck`);
    dispatch(
      apiActionWithTokenResponseType({
        url: `${ServerInfo.URL_GET_FILE}/${file_id}`,
        method: `GET`,
        data: {},
        onSuccess: onGetImageFileAndShowSlipCheckResponse,
        onFailure: onGetImageFileAndShowSlipCheckFailure,
        label: "APICALLER_CALL_API",
        responseTypeOverride: "json",
      })
    );
    // dispatch({
    //   type: "DISPLAY_SET_POPUP_IMAGE_NAME",
    //   value: file_name
    // })
  };
};

export const onGetImageFileAndShowSlipCheckResponse = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onGetImageFileAndShowSlipCheckResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    dispatch(
      showDisplayPopupSlipCheck({
        image_url: data.imageUrl,
      })
    );
  };
};

export const onGetImageFileAndShowSlipCheckFailure = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onGetImageFileAndShowSlipCheckFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.error + ": " + data.error_description,
      })
    );
  };
};

export const getImageFileAndShowCheck = (lotto_data) => {
  console.log(`[${CLASSNAME}] getImageFileAndShowCheck`);
  // console.log(file_name)
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] getImageFileAndShowCheck`);
    dispatch(
      apiActionWithTokenResponseType({
        url: `${ServerInfo.URL_GET_FILE}/${lotto_data.file_id}`,
        method: `GET`,
        data: {},
        onSuccess: onGetImageCheckFileResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        responseTypeOverride: "json",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_GET_FILE,
          SERVICE_ATTR: lotto_data.file_id,
          LOTTO_DATA: lotto_data,
        },
      })
    );
  };
};

export const onGetImageCheckFileResponse = (
  statusCode,
  statusText,
  data,
  onSuccessParam
) => {
  console.log(
    `[${CLASSNAME}] onGetImageCheckFileResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    dispatch(
      showDisplayPopupStockCheck({
        image_url: data.imageUrl,
        lotto_data: onSuccessParam.LOTTO_DATA,
      })
    );
  };
};

// export const onGetImageCheckFileFailure = (statusCode, statusText, data) => {
//   console.log(`[${CLASSNAME}] onGetImageCheckFileFailure statusCode: ${statusCode}`)
//   return async (dispatch) => {
//     console.log(data)
//     dispatch(showDisplayPopupError({
//       "title": Message.MessageCommon.systemError,
//       "message": data.error + ": " + data.error_description
//     }))
//   }
// }

//GET AGENT QR FILE AND SHOW
export const getQRImageAndShow = (data, type = "agent") => {
  console.log(`[${CLASSNAME}] getQRImageAndShow ${type}`);
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] getQRImageAndShow ${type}`);
    console.log(data);
    dispatch(
      apiActionWithTokenResponseType({
        url: `${
          type === "agent" ? ServerInfo.URL_AGENT_QR : ServerInfo.URL_DEALER_QR
        }/${data.id}`,
        method: `GET`,
        data: {},
        onSuccess: (statusCode, statusText, data) =>
          onGetQRImageAndShowResponse(statusCode, statusText, data, type),
        onFailure: actionUtils.onCommonFailure, // onGetImageFileFailure,
        label: "APICALLER_CALL_API",
        responseTypeOverride: "blob",
      })
    );
  };
};

export const onGetQRImageAndShowResponse = (
  statusCode,
  statusText,
  data,
  dataType
) => {
  console.log(
    `[${CLASSNAME}] onGetQRImageAndShowResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    const state = store.getState();

    const data_selected =
      dataType === "agent"
        ? state.agent.agent_selected
        : state.dealer.dealer_selected || null;
    const data_code = data_selected === null ? "Agent QR" : data_selected.code;
    const data_name =
      data_selected === null ? "Agent Name" : data_selected.name;
    dispatch(
      showDisplayPopupQR({
        qr_title: data_name,
        qr_label: data_code,
        image_url: URL.createObjectURL(data),
      })
    );
  };
};

// GET FILE BY FILEID AND SET STAGE
export const getFileByFileIdAndSetStage = (file_id) => {
  console.log(`[${CLASSNAME}] getFileByFileIdAndSetStage`);
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] getFileByFileIdAndSetStage`);
    dispatch(
      apiActionWithTokenResponseType({
        url: `${ServerInfo.URL_GET_FILE}/${file_id}`,
        method: `GET`,
        data: {},
        onSuccess: onGetFileByFileIdAndSetStageResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        responseTypeOverride: "json",
      })
    );
  };
};

export const onGetFileByFileIdAndSetStageResponse = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onGetFileByFileIdAndSetStageResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch({
      type: "FILE_SET_CURRENT_FILE",
      value: {
        file_url: data.imageUrl,
      },
    });
  };
};

// export const onGetFileByFileIdAndSetStageFailure = (statusCode, statusText, data) => {
//   console.log(`[${CLASSNAME}] onGetFileByFileIdAndSetStageFailure statusCode: ${statusCode}`)
//   return async (dispatch) => {
//     console.log(data)
//     dispatch(showDisplayPopupError({
//       "title": Message.MessageCommon.systemError,
//       "message": data.error + ": " + data.error_description
//     }))
//   }
// }

// GET FILE BY FILEID AND SET TO <IMG>
export const getFileByFileIdAndSetToImgTag = (file_id, img_tag_id) => {
  console.log(`[${CLASSNAME}] getFileByFileIdAndSetToImgTag ${file_id}`);
  if (!file_id) {
    return () => {};
  }
  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] getFileByFileIdAndSetToImgTag`)
    dispatch(
      apiActionWithTokenResponseType({
        url: `${ServerInfo.URL_GET_FILE}/${file_id}`,
        method: `GET`,
        data: {},
        onSuccess: onGetFileByFileIdAndSetToImgTagResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        responseTypeOverride: "json",
        onSuccessFailureParamOverride: {
          file_id: file_id,
          img_tag_id: img_tag_id,
          SERVICE_CODE: ServerInfo_Code.URL_GET_FILE,
          SERVICE_ATTR: file_id,
        },
      })
    );
  };
};

export const onGetFileByFileIdAndSetToImgTagResponse = (
  statusCode,
  statusText,
  data,
  paramOverride
) => {
  console.log(
    `[${CLASSNAME}] onGetFileByFileIdAndSetToImgTagResponse statusCode: ${statusCode}`
  );
  // console.log(`[${CLASSNAME}] onGetFileByFileIdAndSetToImgTagResponse paramOverride: ${paramOverride}`)
  return async (dispatch) => {
    // console.log(data)
    // let file_url = URL.createObjectURL(data)
    // let img_tag_id = paramOverride.img_tag_id
    // console.log(file_url)
    // console.log(img_tag_id)
    dispatch({
      type: "FILE_SET_LOTTO_FILES",
      value: {
        file_url: data.imageUrl,
        img_tag_id: paramOverride.img_tag_id,
      },
    });
  };
};

// export const onGetFileByFileIdAndSetToImgTagFailure = (statusCode, statusText, data, paramOverride) => {
//   console.log(`[${CLASSNAME}] onGetFileByFileIdAndSetToImgTagFailure statusCode: ${statusCode}`)
//   // console.log(`[${CLASSNAME}] onGetFileByFileIdAndSetToImgTagFailure paramOverride: ${paramOverride}`)
//   return async (dispatch) => {
//     console.log(data)

//     dispatch(showDisplayPopupError({
//       "title": Message.MessageCommon.systemError,
//       "message": data.error + ": " + data.error_description
//     }))
//   }
// }
