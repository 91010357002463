import React, { Component , Fragment } from "react";
import { connect } from 'react-redux'
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Popupchat from '../components/Popupchat';
import { showDisplayPopupError } from '../actions/display'
import SectionTitle from "../components/SectionTitle";
import { stockUpload } from "../actions/stock";

class StockUploadComponent extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      agentId: "",
      fileCount: 0,
      // file_name: "No file uploaded",
      // file: null,
      files: null
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange = event => {
    console.log("handleInputChange")
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }


  handleFileChange = (e) => {
    // console.log("handleFileChange" + e.target.files.length)
    if (e.target.files.length > 0) {
      // const file = e.target.files[0]
      // const fileName = file.name
      this.setState({
        // file_name: fileName,
        // file: file,
        fileCount: e.target.files.length,
        files: e.target.files
      })
    }
    else{
      this.setState({
        fileCount: 0,
        files: null
      })
    }
  }

  handleSubmit = event => {
    const { dispatch } = this.props
    event.preventDefault();
    console.log(this.state)
    //TODO Validate value before submit to backend
    const validate = this.validateForm()
    if(!validate.status){
      this.showErrorPopup("Error!!", validate.message)
    }
    else{
      const dataSubmit = {
        agentId: this.state.agentId,
        // file_name: this.state.file_name,
        // file: this.state.file,
        files: this.state.files
      }
      dispatch(stockUpload(dataSubmit))
      // onSubmit(dataSubmit)
    }
  }

  validateForm = () => {
    const validateFormRequiredField = this.validateFormRequiredField()
    if(!validateFormRequiredField.status){
      return validateFormRequiredField
    }
    return { 
      "status": true,
    }
  }


  validateFormRequiredField = () => {
    let errorArray = [];
    // if(this.state.file_name === "No file uploaded"){
    //   errorArray.push("เลือก Zip file");
    // }
    if(this.state.agentId === ""){
      errorArray.push("รหัสตัวแทนขาย");
    }
    if(this.state.fileCount === 0){
      errorArray.push("เลือก Zip file");
    }
    if(errorArray.length > 0){
      return { 
        "status": false,
        "message": `<b>กรุณากรอกข้อมูล</b><br/>- ${errorArray.join("<br/>- ")}`
      }
    }
    return { 
      "status": true,
    }
  }

  showErrorPopup = (title, message) => {
    const { dispatch } = this.props
    dispatch(showDisplayPopupError({
      "title": title,
      "message": message
    }))
  }


  render() {
    return (
      <Fragment> 
        <Header />
        <Leftnav />
        {/* <Rightchat /> */}

        <div className="main-content">
        <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">

          <div className="row lottery-search">
            <div className="col-xl-12">
                
              <SectionTitle title="Stock in"/>

              <form onSubmit={this.handleSubmit}>

                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">รหัสตัวแทนขาย</label>
                        <input type="text" name="agentId" value={this.state.agentId} onChange={this.handleInputChange} className="form-control" />
                    </div>        
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">รายละเอียดลิ้นชัก</label>
                        <input type="text" name="drawerName" className="form-control" />
                    </div>        
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">เลือก Zip file</label>
                        <input type="file" onChange={this.handleFileChange} multiple className="form-control" />
                    </div>        
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    {/* <a href="/accountinformation" 
                      className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">บันทึก</a> */}
                    <input type="submit" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 " value="บันทึก" />
                  </div>
                </div>
                
              </form>

            </div>          
          </div>
            
        </div>
        </div>
        </div>

        <Popupchat />
        {/* <Appfooter />  */}
      </Fragment>
    );
  }
}


const mapStateToProps = function(state, props){
  return {
    search_result: state.number.search_result || null,
  }
}

const StockUpload = connect(mapStateToProps)(StockUploadComponent)
export default StockUpload;
