import { setAdminPaymentDetailSelected } from "./payment";

export const setDisplayPopupData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "DISPLAY_SET_POPUP",
      value: data,
    });
  };
};

export const closePopupData = () => {
  return async (dispatch) => {
    dispatch(
      setDisplayPopupData({
        is_show: false,
        title: "",
        message: "",
        submit_button_text: "",
        cancel_button_text: "",
        submit_function: null,
        cancel_function: null,
        close_function: null,
        is_show_submit_button: false,
        is_show_cancel_button: false,
        status: "",
      })
    );
  };
};

export const showDisplayPopupError = (data) => {
  const title = data.title === "" ? "Error" : data.title;
  const popupData = {
    is_show: true,
    title: `${title}`,
    message: `${data.message}`, // `<PrettyPrintJson />Data: ${JSON.stringify(data)}</pre>`,
    is_show_submit_button: true,
    is_show_cancel_button: false,
    submit_button_text: "OK",
    cancel_button_text: "",
    status: "error",
  };
  return async (dispatch) => {
    dispatch({
      type: "DISPLAY_SET_POPUP",
      value: popupData,
    });
  };
};

export const showDisplayPopupMessage = (data) => {
  console.log("showDisplayPopupMessage");
  const title = data.title === "" ? "Success" : data.title;
  const popupData = {
    is_show: true,
    title: `${title}`,
    message: `${data.message}`, // `<PrettyPrintJson />Data: ${JSON.stringify(data)}</pre>`,
    is_show_submit_button: true,
    is_show_cancel_button: false,
    submit_button_text: "OK",
    cancel_button_text: "",
    status: "success",
    submit_function: data.submit_function ? data.submit_function : null,
  };
  return async (dispatch) => {
    dispatch({
      type: "DISPLAY_SET_POPUP",
      value: popupData,
    });
  };
};

export const showHideLoading = (is_show) => {
  // console.log("showHideLoading is_show: ", is_show)
  return async (dispatch) => {
    dispatch({
      type: "DISPLAY_SET_LOADING_IS_SHOW",
      value: is_show,
    });
  };
};

export const showDisplayPopupImage = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "DISPLAY_SET_POPUP_IMAGE_URL",
      value: data.image_url,
    });
    dispatch({
      type: "DISPLAY_SET_POPUP_IMAGE_ISSHOW",
      value: true,
    });
  };
};

export const hideDisplayPopupImage = () => {
  const popupData = {
    is_show: false,
    image_url: "",
    image_name: "",
  };
  return async (dispatch) => {
    dispatch({
      type: "DISPLAY_SET_POPUP_IMAGE",
      value: popupData,
    });
  };
};

export const showDisplayPopupSlipCheck = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "DISPLAY_SET_POPUP_SLIPCHECK_URL",
      value: data.image_url,
    });
    dispatch({
      type: "DISPLAY_SET_POPUP_SLIPCHECK_ISSHOW",
      value: true,
    });
  };
};

export const showDisplayPopupStockCheck = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "DISPLAY_SET_POPUP_STOCKCHECK_URL",
      value: data.image_url,
    });
    dispatch({
      type: "DISPLAY_SET_POPUP_STOCKCHECK_ISSHOW",
      value: true,
    });
    dispatch({
      type: "DISPLAY_SET_POPUP_STOCKCHECK_LOTTO_DATA",
      value: data.lotto_data,
    });
  };
};

export const hideDisplayPopupSlipCheck = () => {
  const popupData = {
    is_show: false,
    image_url: "",
    image_name: "",
  };
  return async (dispatch) => {
    dispatch({
      type: "DISPLAY_SET_POPUP_SLIPCHECK",
      value: popupData,
    });
    dispatch(setAdminPaymentDetailSelected(null));
  };
};

export const hideDisplayPopupVerifyCheck = () => {
  const popupData = {
    is_show: false,
    image_url: "",
    image_name: "",
    data: "",
    data_list: [],
  };
  return async (dispatch) => {
    dispatch({
      type: "DISPLAY_SET_POPUP_STOCKCHECK",
      value: popupData,
    });
  };
};

export const showDisplayPopupQR = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "DISPLAY_SET_POPUP_QR",
      value: {
        is_show: true,
        image_url: data.image_url,
        qr_title: data.qr_title,
        qr_label: data.qr_label,
      },
    });
  };
};

export const hideDisplayPopupQR = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "DISPLAY_SET_POPUP_QR",
      value: {
        is_show: false,
        image_url: "",
        qr_title: "",
        qr_label: "",
      },
    });
  };
};
