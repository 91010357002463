import React from "react";
import {
  TbTrendingUp,
  TbTrendingDown,
  TbTriangleInvertedFilled,
  TbTriangleFilled,
  TbArrowBigUpFilled,
  TbArrowBigDownFilled,
} from "react-icons/tb";

const DealerCostTab = (props) => {
  const { all_agent_pricings } = props;
  return (
    <div className="table-content table-responsive">
      <h3>ตารางต้นทุน</h3>
      <p>
        <TbArrowBigDownFilled color="red" /> = ต่ำสุด &nbsp; | &nbsp;
        <TbArrowBigUpFilled color="green" /> = สูงสุด
      </p>

      <div className="cost-table">
        <div className="cost-row">
          <div style={{ flex: 1 }}>เลขท้าย</div>
          <div style={{ flex: 2 }}>ชุด</div>
          <div style={{ flex: 2 }}>ราคา</div>
        </div>
        {(() => {
          let num = "";
          return all_agent_pricings.map((pricing) => {
            const dup = num === pricing.range;
            num = pricing.range;
            return (
              <div className={`cost-row ${dup ? "" : "first"}`}>
                <div style={{ flex: 1 }}>{dup ? "" : pricing.range}</div>
                <div style={{ flex: 2 }}>{pricing.packNum} ใบ</div>
                <div style={{ flex: 2 }}>
                  <TbArrowBigDownFilled color="red" /> |{" "}
                  <TbArrowBigUpFilled color="green" />
                  <br />
                  {pricing.minPrice} | {pricing.maxPrice}
                </div>
              </div>
            );
          });
        })()}
      </div>
    </div>
  );
};

export default DealerCostTab;
