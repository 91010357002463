import React,{Component} from 'react';

const eventsList = [
    // {
    //     date: '9',
    //     month:'ก.ค.',
    //     status:'bg-warning',
    //     name: 'เช็กดวงสมพงษ์ นำพาโชคลาภสิ่งดีๆเข้ามาให้ดวงคุณพุ่งขึ้นฟ้า',
    //     location: 'วันที่ 9 กรกฎาคม 2021 15:02',
    // },
    // {
    //     date: '8',
    //     month:'ก.ค.',
    //     status:'bg-primary',
    //     name: 'รวมข่าวหวย แม่น้ำหนึ่ง/อ่างน้ำมนต์ฤาษีเณร /ท้าวเวสสุวรรณ',
    //     location: 'วันที่ 8 กรกฎาคม 2021 18:06',
    // },
    // {
    //     date: '7',
    //     month:'ก.ค.',
    //     status:'bg-success',
    //     name: 'อัปเดตวันขอเงินพระจันทร์ครึ่งปีหลัง 2564',
    //     location: 'วันที่ 7 กรกฎาคม 2021 18:38',
    // },
]

class Events extends Component {
    render() {
        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-flex align-items-center p-4">
                    <h4 className="fw-700 mb-0 font-xssss text-grey-900">
                        <a href="/#" className="p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">ข่าวหวยประจำวัน</a>
                    </h4>
                    <a href="/defaultevent" className="fw-600 ms-auto font-xssss text-primary">See all</a>
                </div>
                {eventsList.map((value , index) => (
                <div  key={index} className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden">
                    <div className={`bg-success me-2 p-3 rounded-xxl ${value.status}`}><h4 className="fw-700 font-lg ls-3 lh-1 text-white mb-0"><span className="ls-1 d-block font-xsss text-white fw-600">{value.month}</span>{value.date}</h4></div>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-2">{value.name} <span className="d-block font-xsssss fw-500 mt-1 lh-4 text-grey-500">{value.location}</span> </h4>
                </div>

                ))}

                
            </div>
        );
    }
}

export default Events;