const DisplayConfig = {
  "page_home": {
    "type": "page",
    "enable": true,
    "authorize": "anonymous",
  },
  "page_register": {
    "type": "page",
    "enable": true,
    "authorize": "anonymous",
  },
  "page_login": {
    "type": "page",
    "enable": true,
    "authorize": "anonymous",
  },
  "page_forgot_password": {
    "type": "page",
    "enable": true,
    "authorize": "anonymous",
  },
  "page_lottery_search": {
    "type": "page",
    "enable": true,
    "authorize": "anonymous",
  },
  "page_cart": {
    "type": "page",
    "enable": true,
    "authorize": "customer",
  },
  "page_payment": {
    "type": "page",
    "enable": true,
    "authorize": "customer",
  },
  "page_order_history": {
    "type": "page",
    "enable": true,
    "authorize": "customer",
  },
  "block_news": {
    "type": "block",
    "enable": true,
    "authorize": "anonymous",
    "pages": ["home"],
  },
  // "menu_news": {
  //   "type": "menu",
  //   "authorize": "anonymous",
  //   "pages": ["home"],
  // },
}

module.exports = {
  DisplayConfig,
}