import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import cookie from "react-cookies";

class AdminMenuComponent extends Component {
  state = {
    isLoggedin: false,
  };

  componentWillMount() {
    const token = cookie.load("APP_AT");
    if (token !== undefined) {
      this.setState({ isLoggedin: true });
    }
  }

  render() {
    const { is_agent, is_dealer, is_admin } = this.props.user_detail;
    return (
      <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
        <div className="nav-caption fw-600 font-xssss text-grey-500">
          <span></span> จัดการ
        </div>
        <ul className="mb-1 top-content">
          <li className="logo d-none d-xl-block d-lg-block"></li>
          {/* <li><Link to="/admin/customer-list" className="nav-content-bttn open-font"><i className="feather-tv btn-round-md bg-blue-gradiant me-3"></i><span>สมาชิก</span></Link></li>
          <li><Link to="/admin/customer-list" className="nav-content-bttn open-font"><i className="feather-tv btn-round-md bg-blue-gradiant me-3"></i><span>ตัวแทนจำหน่าย</span></Link></li> */}

          {is_admin && (
            <li>
              <Link to="/admin" className="nav-content-bttn open-font">
                <i className="feather-tv btn-round-md bg-blue-gradiant me-3"></i>
                <span>Dashboard</span>
              </Link>
            </li>
          )}
          {is_admin && (
            <li>
              <Link
                to="/admin/stock-list"
                className="nav-content-bttn open-font"
              >
                <i className="feather-tv btn-round-md bg-blue-gradiant me-3"></i>
                <span>Stock</span>
              </Link>
            </li>
          )}
          {is_agent && (
            <li>
              <Link
                to="/agent/number-search"
                className="nav-content-bttn open-font"
              >
                <i className="feather-credit-card btn-round-md bg-red-gradiant me-3"></i>
                <span>ค้นหาสลาก</span>
              </Link>
            </li>
          )}
          {is_agent && (
            <li>
              <Link
                to="/agent/numbergroup-search"
                className="nav-content-bttn open-font"
              >
                <i className="feather-credit-card btn-round-md bg-red-gradiant me-3"></i>
                <span>ค้นหาสลากชุด</span>
              </Link>
            </li>
          )}
          {is_agent && (
            <li>
              <Link
                to="/agent/price_setup"
                className="nav-content-bttn open-font"
              >
                <i className="feather-tag btn-round-md bg-gold-gradiant me-3"></i>
                <span>ตั้งราคาทุน</span>
              </Link>
            </li>
          )}
          {is_dealer && (
            <li>
              <Link
                to="/dealer/price_setup"
                className="nav-content-bttn open-font"
              >
                <i className="feather-tag btn-round-md bg-gold-gradiant me-3"></i>
                <span>ตั้งราคา</span>
              </Link>
            </li>
          )}
          {is_admin && (
            <li>
              <Link to="/agentpage" className="nav-content-bttn open-font">
                <i className="feather-users btn-round-md bg-primary-gradiant me-3"></i>
                <span>ตัวแทนขาย</span>
              </Link>
            </li>
          )}
          {is_admin && (
            <li>
              <Link to="/dealerpage" className="nav-content-bttn open-font">
                <i className="feather-users btn-round-md bg-primary-gradiant me-3"></i>
                <span>ดีลเลอร์</span>
              </Link>
            </li>
          )}
          {is_admin && (
            <li>
              <Link to="/userlist" className="nav-content-bttn open-font">
                <i className="feather-users btn-round-md bg-primary-gradiant me-3"></i>
                <span>User</span>
              </Link>
            </li>
          )}
          {is_admin && (
            <li>
              <Link to="/admin/result" className="nav-content-bttn open-font">
                <i className="feather-cloud-lightning btn-round-md bg-primary-gradiant me-3"></i>
                <span>ผลการออกรางวัล</span>
              </Link>
            </li>
          )}
          {is_admin && (
            <li>
              <Link to="/admin/report" className="nav-content-bttn open-font">
                <i className="feather-airplay btn-round-md bg-primary-gradiant me-3"></i>
                <span>รายงาน</span>
              </Link>
            </li>
          )}
          {is_admin && (
            <li>
              <Link
                to="/admin/payment-list"
                className="nav-content-bttn open-font"
              >
                <i className="feather-list btn-round-md bg-gold-gradiant me-3"></i>
                <span>รายการคำสั่งซื้อ</span>
              </Link>
            </li>
          )}

          {is_dealer && (
            <li>
              <Link
                to="/dealer/paymenthistory"
                className="nav-content-bttn open-font"
              >
                <i className="feather-list btn-round-md bg-gold-gradiant me-3"></i>
                <span>คำสั่งซื้อของลูกค้า</span>
              </Link>
            </li>
          )}

          {is_dealer && (
            <li>
              <Link to="/dealer/winner" className="nav-content-bttn open-font">
                <i className="feather-dollar-sign btn-round-md bg-gold-gradiant me-3"></i>
                <span>ลูกค้าถูกรางวัล</span>
              </Link>
            </li>
          )}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {
    user_detail: state.user.user_detail || {},
  };
};

const AdminMenu = connect(mapStateToProps)(AdminMenuComponent);
export default AdminMenu;
