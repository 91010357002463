/* eslint-disable @typescript-eslint/no-explicit-any */
import jwtDecode from 'jwt-decode';
import cookie from 'react-cookies';
import { refreshWrapper } from './services/authService';
import Utils from './Utils';
// import LoginService from './../services/authenService';
// import globalVar from './Singleton';

// const SESSION_NAME_USER_TOKEN = 'ESTAMP_USER_TOKEN';
// const SESSION_ABBR = 'SESSION_ABBR';
const ACCESS_TOKEN = 'APP_AT';
const REFRESH_TOKEN = 'APP_RT';

type TokenType = {
  accessToken: string;
  refreshToken: string;
  tokenType?: string;
};

type RefreshTokenInfoType = {
  exp: number;
};

export const setEStampWebItem = (key: string, item: TokenType, isJwt = false) => {
  // sessionStorage.setItem(key, JSON.stringify(item));
  // localStorage.setItem(key, JSON.stringify(item));

  let expires = new Date();
  let maxAge: number | undefined = 1000;
  if (isJwt) {
    expires = new Date(jwtDecode<RefreshTokenInfoType>(item.refreshToken).exp * 1000);
    maxAge = undefined;
  }
  const value = JSON.stringify(item);
  cookie.save(key, Utils.encryptWithKey(value), {
    path: '/',
    expires,
    maxAge, // domain: window.location.origin,
    secure: true, // httpOnly: true
  });
};

export const getAppWebItem = (
  key: string // JSON.parse(sessionStorage.getItem(key))
) =>
  // JSON.parse(localStorage.getItem(key))
  // console.log(JSON.parse(cookie.load(key)))
  Utils.decryptWithKey(cookie.load(key));

export const getUserToken = () => {
  if (getAppWebItem(ACCESS_TOKEN)) {
    return { accessToken: getAppWebItem(ACCESS_TOKEN), refreshToken: getAppWebItem(REFRESH_TOKEN) };
  }
  return null;
};

export const removeAppWebSession = () => {
  // sessionStorage.removeItem(SESSION_NAME_USER_TOKEN);
  // sessionStorage.clear();

  // localStorage.removeItem(SESSION_NAME_USER_TOKEN);
  // localStorage.clear();

  cookie.remove(ACCESS_TOKEN, { path: '/' });
  cookie.remove(REFRESH_TOKEN, { path: '/' });
};

export const preServeRefresh = (access_token: string, refresh_token: string) => {
  if (getUserToken()) {
    // console.log('Preserve on ' + new Date((jwtDecode(access_token).exp * 1000)));
    const prserveTime =
      new Date().getTime() +
      (new Date(jwtDecode<RefreshTokenInfoType>(access_token).exp * 1000).getTime() - new Date().getTime()) / 2; // jwtDecode(access_token).exp * 1000 - new Date().getTime() - 10000;

    const timeout = jwtDecode<RefreshTokenInfoType>(access_token).exp * 1000 - prserveTime;

    if (timeout > 0) {
      setTimeout(() => {
        if (getUserToken()) {
          refreshWrapper(refresh_token, true);
        }
      }, timeout);
    } else {
      removeAppWebSession();
      // redirectToPortal();
      // globalVar.isChangePage = true;
      // Object.freeze(globalVar);
      // window.location = '/';
      const apiUrl = Utils.dynamicOriginUrl(process.env.REACT_APP_URL);
      window.location = `${apiUrl}/portal/auth/login`;
    }
  }
};

export const saveTokenToMemory = (token: TokenType) => {
  // console.log("token", token)
  // console.log(jwtDecode(token.refreshToken))
  // console.log("token enc", Utils.encryptWithKey(token.accessToken))
  const expires = new Date(jwtDecode(token.refreshToken).exp * 1000);
  cookie.save(ACCESS_TOKEN, Utils.encryptWithKey(token.accessToken), {
    path: '/',
    expires,
    // secure: true, // for https site
  });
  cookie.save(REFRESH_TOKEN, Utils.encryptWithKey(token.refreshToken), {
    path: '/',
    expires,
    // secure: true, // for https site
  });
}

export const saveTokenToMemory2 = (token: TokenType) => {
  const expires = new Date(jwtDecode<RefreshTokenInfoType>(token.refreshToken).exp * 1000);

  cookie.save(ACCESS_TOKEN, Utils.encryptWithKey(token.accessToken), {
    path: '/',
    expires,
    secure: true,
  });
  cookie.save(REFRESH_TOKEN, Utils.encryptWithKey(token.refreshToken), {
    path: '/',
    expires,
    secure: true,
  });

  // preServeRefresh(token.accessToken, token.refreshToken);
};

// export const saveABBRToMemory = (abbr) => {
//   setEStampWebItem(SESSION_ABBR, abbr);
// };

// export const getSystemAbbr = () => getEStampWebItem(SESSION_ABBR);

// export const removeSystemAbbr = () => {
//   sessionStorage.removeItem(SESSION_ABBR);
// };
