import React, { useState, Fragment, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import PageTitle from "../components/PageTitle";
import { useSubscription } from "@apollo/client";
import {
  GET_DOC_COUNT_SUBSCRIPTION,
  GET_REVENUE_SUBSCRIPTION,
  GET_DOC_SOLD_COUNT_SUBSCRIPTION,
  GET_ACTIVE_CUST_COUNT,
  GET_DOC_BASKET_COUNT_SUBSCRIPTION,
  GET_DOC_PAYMENT_COUNT_SUBSCRIPTION,
} from "../graphql/query";
import DatePicker from "react-datepicker";
import moment from "moment";
import { closeInstallment, startNewInstallment } from "../actions/installment";
import { confirmAlert } from "react-confirm-alert";

const ThaiBaht = new Intl.NumberFormat("th-TH");

const DashboardComponent = (props) => {
  moment.locale("th");
  moment.tz.setDefault("Asia/Bangkok");

  const dispatch = useDispatch();

  const { previous_installment, current_installment } = props;

  const [enableNew, setEnableNew] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const { data } = useSubscription(GET_REVENUE_SUBSCRIPTION, {
    variables: {
      date: previous_installment?.installmentDate
        ? moment(previous_installment.installmentDate)
            .add(1, "day")
            .format("YYYY-MM-DD")
        : "",
    },
  });

  const { data: docCountData } = useSubscription(GET_DOC_COUNT_SUBSCRIPTION);
  const { data: docSoldCountData } = useSubscription(
    GET_DOC_SOLD_COUNT_SUBSCRIPTION
  );
  const { data: activeCustCountData } = useSubscription(GET_ACTIVE_CUST_COUNT);
  const { data: docBasketCountData } = useSubscription(
    GET_DOC_BASKET_COUNT_SUBSCRIPTION
  );
  const { data: docPaymentCountData } = useSubscription(
    GET_DOC_PAYMENT_COUNT_SUBSCRIPTION
  );

  const closeInst = () => {
    dispatch(closeInstallment());
  };

  const startNewInst = (date) => {
    dispatch(startNewInstallment(moment(date).format("YYYY-MM-DD")));
  };

  useEffect(() => {
    if (previous_installment) {
      console.log(previous_installment.installmentDate);
    }
  }, [previous_installment]);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row feed-body">
              <div className="col-xl-12 col-xxl-12 col-lg-12">
                {/* <div className="card-body p-0">
                  <a
                    href="/"
                    className="font-xss fw-600 text-black-500 card-body p-0 d-flex align-items-center"
                  >
                    <i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>
                    ผลสลากกินแบ่งรัฐบาล 16 พฤศจิกายน 2564
                  </a>
                </div> */}
                <div className="row ps-2 pe-1">
                  {/* {lastdocList.map((value, index) => (
                    <div key={index} className="col-md-3 col-sm-6 pe-2 ps-2">
                      <div className="card w150 d-block border-0 shadow-xss rounded-4 overflow-hidden mb-4">
                        <div className="card-body d-block w-100 p-4 text-center">
                          <h4 className="fw-700 font-xss mt-3 mb-0">
                            {value.name}
                          </h4>
                          <div className="clearfix"></div>
                          <h2>
                            <a
                              href="#follow"
                              className="text-center p-2 lh-20 w100 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xss fw-700 ls-lg text-white"
                            >
                              {value.value}
                            </a>
                          </h2>
                          <div className="clearfix"></div>
                          <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                            {value.detail}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))} */}
                </div>

                <div className="row ps-2 pe-1">
                  {data?.transflow_trn_payment_data_aggregate && (
                    <div className="col-lg-3 pe-2">
                      <div
                        className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                        style={{ background: `#e5f6ff` }}
                      >
                        <div className="card-body d-flex p-0">
                          <i className="btn-round-lg d-inline-block me-3 bg-primary-gradiant feather-dollar-sign font-md text-white"></i>
                          <h4 className="text-primary font-xl fw-700">
                            {ThaiBaht.format(
                              data.transflow_trn_payment_data_aggregate
                                .aggregate.sum.total_amount
                            )}
                            <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                              รายได้งวดนี้
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}

                  {activeCustCountData?.transflow_trn_vault_aggregate && (
                    <div className="col-lg-3 ps-2">
                      <div
                        className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                        style={{ background: `#fff0e9` }}
                      >
                        <div className="card-body d-flex p-0">
                          <i className="btn-round-lg d-inline-block me-3 bg-warning feather-user-check font-md text-white"></i>
                          <h4 className="text-warning font-xl fw-700">
                            {ThaiBaht.format(
                              activeCustCountData.transflow_trn_vault_aggregate
                                .aggregate.count
                            )}
                            <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                              จำนวนลูกค้างวดนี้
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="row ps-2 pe-1">
                  {docCountData?.transflow_trn_number_doc_aggregate && (
                    <div className="col-lg-3 pe-2 ps-2">
                      <div
                        className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                        style={{ background: `#f6f3ff` }}
                      >
                        <div className="card-body d-flex p-0">
                          <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-tag font-md text-white"></i>
                          <h4 className="text-secondary font-xl fw-700">
                            {ThaiBaht.format(
                              docCountData.transflow_trn_number_doc_aggregate
                                .aggregate.count
                            )}
                            <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                              จำนวนใบวางขาย
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}
                  {docSoldCountData?.transflow_trn_number_doc_aggregate && (
                    <div className="col-lg-3 pe-2 ps-2">
                      <div
                        className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                        style={{ background: `#e2f6e9` }}
                      >
                        <div className="card-body d-flex p-0">
                          <i className="btn-round-lg d-inline-block me-3 bg-success feather-shopping-bag font-md text-white"></i>
                          <h4 className="text-success font-xl fw-700">
                            {ThaiBaht.format(
                              docSoldCountData
                                .transflow_trn_number_doc_aggregate.aggregate
                                .count
                            )}
                            <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                              จำนวนใบที่ขายไป
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}
                  {docBasketCountData?.transflow_trn_number_doc_aggregate && (
                    <div className="col-lg-3 pe-2 ps-2">
                      <div
                        className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                        style={{ background: `#FCF3CF` }}
                      >
                        <div className="card-body d-flex p-0">
                          <i
                            className="btn-round-lg d-inline-block me-3 feather-shopping-bag font-md text-white"
                            style={{ background: `#F1C40F` }}
                          ></i>
                          <h4
                            className="font-xl fw-700"
                            style={{
                              color: "#F1C40F",
                            }}
                          >
                            {ThaiBaht.format(
                              docBasketCountData
                                .transflow_trn_number_doc_aggregate.aggregate
                                .count
                            )}
                            <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                              ในตะกร้า
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}
                  {docPaymentCountData?.transflow_trn_number_doc_aggregate && (
                    <div className="col-lg-3 pe-2 ps-2">
                      <div
                        className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                        style={{ background: `#F5B7B1` }}
                      >
                        <div className="card-body d-flex p-0">
                          <i
                            className="btn-round-lg d-inline-block me-3 feather-shopping-bag font-md text-white"
                            style={{ background: `#CB4335` }}
                          ></i>
                          <h4
                            className=" font-xl fw-700"
                            style={{
                              color: "#CB4335",
                            }}
                          >
                            {ThaiBaht.format(
                              docPaymentCountData
                                .transflow_trn_number_doc_aggregate.aggregate
                                .count
                            )}
                            <span className="fw-500 mt-0 d-block text-white font-xssss">
                              อยู่ขั้นตอนการจ่าย
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="row ps-2 pe-1">
                  <div className="col-lg-4 pe-2 ps-2">
                    <div className="nebula period">
                      Nebula
                      <img src="/assets/images/nebula.jpg" />
                      {current_installment.closing && enableNew && (
                        <div>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            minDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            locale="th-TH"
                            placeholderText="Weeks start on Monday"
                          />
                          <button
                            onClick={() => {
                              confirmAlert({
                                title: "การยืนยัน",
                                message: `คุณแน่ใจที่จะเปิดงวดใหม่`,
                                buttons: [
                                  {
                                    label: "แน่ใจ",
                                    onClick: () => {
                                      setEnableNew(false);
                                      startNewInst(startDate);
                                    },
                                  },
                                  {
                                    label: "ยกเลิก",
                                    onClick: () => {},
                                  },
                                ],
                              });
                            }}
                            className="update-cart bg-primary-gradiant d-block text-white fw-600 text-uppercase 
                    font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"
                          >
                            Start Cycle
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 pe-2 ps-2">
                    <div className="red-giant period">
                      Red Giant
                      <img src="/assets/images/red-giant.jpg" />
                      {!current_installment.closing && (
                        <>
                          <p>
                            {moment(current_installment.installmentDate).format(
                              "DD-MM-YYYY"
                            )}
                          </p>
                          <button
                            onClick={() => {
                              confirmAlert({
                                title: "การยืนยัน",
                                message: `คุณแน่ใจที่จะปิดงวด`,
                                buttons: [
                                  {
                                    label: "แน่ใจ",
                                    onClick: () => {
                                      closeInst();
                                    },
                                  },
                                  {
                                    label: "ยกเลิก",
                                    onClick: () => {},
                                  },
                                ],
                              });
                            }}
                            className="update-cart bg-red-gradiant d-block text-white fw-600 text-uppercase 
                    font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"
                          >
                            Close Cycle
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 pe-2 ps-2">
                    <div className="black-hole period">
                      Black Hole
                      <img src="/assets/images/blackhole.jpg" />
                      {current_installment.closing && !enableNew && (
                        <button
                          onClick={() => {
                            setEnableNew(true);
                          }}
                          className="update-cart bg-gold-gradiant d-block text-white fw-600 text-uppercase 
                    font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"
                        >
                          New Cycle
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4 text-center">
                  <a
                    href="/defaultmember"
                    className="p-2 lh-20 w300 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl"
                  >
                    ตรวจผลสลากทั้งหมด
                  </a>
                </div> */}
              </div>
              {/* <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    previous_installment: state.number.previous_installment || null,
    current_installment: state.number.current_installment || null,
  };
};

const Dashboard = connect(mapStateToProps)(DashboardComponent);
export default Dashboard;
