import React, { Component, Fragment } from "react";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';

class OutOfStock extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <Leftnav />

        <div className="main-content bg-white right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">

              <div className="row pt-5">
                
                
                <div className="col-4 pe-2 ps-2 mb-hide">
                  <img src="/assets/images/s2ulogo.png" alt="avater" width="auto" className="shadow-sm rounded-circle " />
                </div>

                <div className="col-lg-8 col-sm-12 pe-4 ps-4">
                  <div className="card w-100 border-0 shadow-none p-5 rounded-xxl mb-3" style={{ background: `#f6f3ff` }}>
                    <div className="card-body d-flex p-0">
                      {/* <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-command text-white"></i> */}
                      <h4 className="text-primary font-xxl fw-700">สลากได้จำหน่ายหมดแล้ว เตรียมพบกับสลากในงวด<br/>วันที่ 16 มิถุนายน 2565 </h4>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

        <Popupchat />
        <Appfooter />
      </Fragment>
    );
  }
}

export default OutOfStock;