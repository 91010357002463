import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Popupchat from "../components/Popupchat";
import { showDisplayPopupError } from "../actions/display";
import { saveAgent } from "../actions/agent";
import { saveDealer } from "../actions/dealer";
import PageTitle from "../components/PageTitle";

const PrivacyPage = () => {
  return (
    <Fragment>
      <Header />
      <Leftnav />

      <div className="main-content privacy-page">
        <div className="middle-sidebar-bottom">
          <div
            className="middle-sidebar-left pe-0"
            style={{ padding: "0 30px" }}
          >
            <br />
            <div className="text-center">
              <strong style={{ fontSize: "18px" }}>
                {/* นโยบายการใช้คุกกี้(Cookie Privacy) */}
                นโยบายความเป็นส่วนตัว (PRIVACY POLICY)
              </strong>
            </div>
            <br />
            <p>
              เว็บไซต์นี้ให้บริการโดย&nbsp;บริษัท สลากทูยู จำกัด
              บริษัทขอเรียนว่า บริษัทเคารพความเป็นส่วนตัวของผู้ใช้งาน
              และตระหนักดีว่าข้อมูลส่วนบุคคลของผู้ใช้งานแต่ละท่านมีความสำคัญอย่างยิ่ง
              บริษัทจึงต้องการที่จะชี้แจงให้ทราบเกี่ยวกับการเก็บรวบรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
              {/* เว็บไซต์นี้ใช้คุกกี้และเครื่องมืออื่นเพื่อช่วยแยกแยะรูปแบบการใช้งานเว็บไซต์ของท่านจากผู้ใช้งานอื่นๆซึ่งจะช่วยให้ท่านได้รับประสบการณ์ที่ดีจากการใช้งานเว็บไซต์
              และช่วยให้เราสามารถพัฒนาคุณภาพของเว็บไซต์ให้ดียิ่งขึ้น
              กรณีที่ท่านใช้งานเว็บไซต์นี้ต่อไป
              ถือว่าท่านได้ยินยอมให้เราติดตั้งคุกกี้ไว้ในเครื่องคอมพิวเตอร์ของท่าน */}
            </p>
            <p style={{ marginTop: "15px" }}>
              ดังนั้น บริษัทจึงได้มีนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้
              เพื่อชี้แจงรายละเอียดและ วิธีการจัดเก็บรวบรวม ใช้ และ/หรือเปิดเผย
              การคุ้มครองข้อมูล การเข้าถึงข้อมูล การโอนย้าย
              และการวิเคราะห์ประมวลผลข้อมูลส่วนบุคคลของท่าน ดังต่อไปนี้
            </p>

            <p style={{ marginTop: "15px" }}>
              ข้อมูลส่วนบุคคลในทีนี้หมายถึงข้อมูลต่างๆ
              ในการทำการลงทะเบียนผ่านเว็บไซต์ mahachonlottery
              การลงทะเบียนเข้าร่วมกิจกรรมต่างๆ ของบริษัท คุกกี้
              ข้อมูลการทำรายการ และประสบการณ์การใช้งาน
            </p>

            <p style={{ marginTop: "15px" }}>
              นโยบายการคุ้มครองข้อมูลส่วนบุคคล (Privacy Policy)
              นี้อยู่ภายใต้พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
              โดยบริษัทมีอำนาจหน้าที่ตัดสินใจเกี่ยวกับการเก็บรวบรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคล ซึ่งตามกฎหมายเรียกว่า
              ‘ผู้ควบคุมข้อมูลส่วนบุคคล’
              โดยมีพนักงานที่บริษัทมอบหมายโดยเฉพาะให้มีหน้าที่ดำเนินการเกี่ยวกับการเก็บรวบรวม
              ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลตามคำสั่งหรือในนามของบริษัท
              ซึ่งตามกฎหมายเรียกว่า ‘ผู้ประมวลผลข้อมูลส่วนบุคคล’ ส่วนท่านถือเป็น
              ‘เจ้าของข้อมูลส่วนบุคคล’ ตามกฎหมายนี้
            </p>

            <p style={{ marginTop: "15px" }}>
              <strong>
                ข้อ 1. ข้อมูลส่วนบุคคลอะไรบ้างที่บริษัทเก็บรวบรวม ใช้
                และ/หรือเปิดเผย
              </strong>
              <br />
              เราจะเก็บรวบรวมข้อมูลส่วนบุคคลซึ่งเป็นข้อมูลที่ทำให้สามารถระบุตัวตนของท่านได้
              ไม่ว่าทางตรงหรือทางอ้อม ได้แก่
              ข้อมูลที่ท่านให้ไว้โดยตรงจากการลงทะเบียนlสมัครสมาชิกเว็บไซต์
              การลงทะเบียนเข้าร่วมกิจกรรมต่างๆ ของบริษัท คุกกี้
              ข้อมูลการทำรายการ และประสบการณ์การใช้งานผ่านหน้าเว็บไซต์
              ผู้ที่ได้รับมอบหมาย หรือช่องทางอื่นใด เช่น ข้อมูลส่วนตัว เช่น ชื่อ
              นามสกุล อายุ วันเดือนปีเกิด สถานภาพสมรส เลขประจำตัวประชาชน
              เลขหนังสือเดินทาง ข้อมูลการติดต่อ เช่น ที่อยู่อาศัย สถานที่ทำงาน
              หมายเลขโทรศัพท์ อีเมล ไอดีไลน์ ข้อมูลอุปกรณ์หรือเครื่องมือ เช่น IP
              Address MAC Address Cookie ID ข้อมูลอื่นๆ เช่น การใช้งานเว็บไซต์
              เสียง ภาพนิ่ง ภาพเคลื่อนไหว
              และข้อมูลอื่นใดที่ถือว่าเป็นข้อมูลส่วนบุคคลภายใต้กฎหมายคุ้มครองข้อมูลส่วนบุคคล
            </p>
            <p style={{ marginTop: "15px" }}>
              <strong>
                ข้อ 2. วัตถุประสงค์ในการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูล
              </strong>{" "}
              <br />
              เราจะนำข้อมูลของท่านมาใช้เพื่อการพัฒนาและปรับปรุงเว็บไซต์
              https://mahachonlottery.com แพลตฟอร์มออนไลน์
              และช่องทางโซเชียลมีเดียอื่นๆ ในเครือ
              ตลอดจนการวิเคราะห์และประมวลผลข้อมูลส่วนบุคคล
              เพื่อให้ตอบโจทย์การใช้งานของผู้ใช้งาน
              ด้วยวิธีการทางอิเล็กทรอนิกส์แก่ท่านให้มีประสิทธิภาพมากยิ่งขึ้น
              หากภายหลังมีการเปลี่ยนแปลงวัตถุประสงค์ บริษัทจะแจ้งให้ท่านทราบ
              เพื่อขอความยินยอม และจัดให้มีบันทึกการแก้ไขเพิ่มเติมไว้เป็นหลักฐาน
              ทั้งนี้ บริษัทจะไม่กระทำการเก็บรวบรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน เพื่อประโยชน์อย่างอื่น
              ที่นอกเหนือจากวัตถุประสงค์ที่ได้แจ้งไว้กับท่านไว้ก่อนหรือขณะเก็บรวบรวม
            </p>
            <p style={{ marginTop: "15px" }}>
              <strong>ข้อ 3. การคุ้มครองข้อมูลส่วนบุคคล</strong> <br />
              บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้เป็นอย่างดีตามมาตรการเชิงเทคนิค
              (Technical Measure) และมาตรการเชิงบริหารจัดการ (Organizational
              Measure)
              เพื่อรักษาความมั่นคงปลอดภัยในการประมวลผลข้อมูลส่วนบุคคลที่เหมาะสม
              และเพื่อป้องกันการละเมิดข้อมูลส่วนบุคคล การสูญหาย การเข้าถึง ทำลาย
              ใช้ แปลง แก้ไข การนำข้อมูลไปใช้ หรือเปิดเผยข้อมูลนอกวัตถุประสงค์
              หรือโดยไม่มีอำนาจหรือโดยไม่ชอบ <br />
              <br />
              ก่อนที่บริษัทจะทำการเก็บรวบรวมข้อมูล ใช้ หรือเปิดเผยข้อมูลของท่าน
              บริษัทจะทำการขอความยินยอมจากท่านก่อน
              โดยการขอความยินยอมบริษัทจะทำโดยชัดแจ้ง
              เป็นหนังสือหรือทำโดยระบบอิเล็กทรอนิกส์
              <br />
              <br /> ทั้งนี้
              ท่านมีความเป็นอิสระในการให้ความยินยอมในการที่บริษัทจะเก็บรวบรวม
              ใช้ หรือเปิดเผย ข้อมูลส่วนบุคคลของท่าน
              บริษัทจะไม่กำหนดเงื่อนไขในการให้ความยินยอมเพื่อให้เข้าถึงการรับบริการ
              หรือเข้าทำสัญญากับบริษัท
              หากข้อมูลส่วนบุคคลนั้นไม่มีความจำเป็นหรือเกี่ยวข้องสำหรับการเข้าทำสัญญาหรือการรับบริการนั้น
              <br />
              <br />
              นอกจากนี้ ผู้บริหาร พนักงาน ลูกจ้าง ผู้รับจ้าง ตัวแทน ที่ปรึกษา
              และผู้รับข้อมูลจากบริษัทมีหน้าที่ต้องรักษาข้อมูลส่วนบุคคลตามมาตรการรักษาความลับที่บริษัทกำหนดขึ้น
              <br />
              <br />
              ในกรณีที่ท่านเจ้าของข้อมูลส่วนบุคคลท่านใดเป็นผู้ยังไม่บรรลุนิติภาวะ
              ถือเป็นผู้เยาว์ตามประมวลกฎหมายแพ่งและพาณิชย์
              การให้ความยินยอมของท่านต้องได้รับความยินยอมจากผู้ใช้อำนาจปกครองที่มีอำนาจกระทำแทนท่านด้วย
              หากผู้จะให้ความยินยอมนั้นเป็นผู้เยาว์ที่อายุไม่เกิน 10 ปี
              ต้องขอความยินยอมจากผู้ใช้อำนาจปกครองนั้นโดยตรง
              <br />
              <br />
              ในกรณีเจ้าของข้อมูลส่วนบุคคลท่านใดเป็นบุคคลไร้ความสามารถหรือบุคคลเสมือนไร้ความสามารถ
              ต้องขอความยินยอมจากผู้อนุบาลหรือผู้พิทักษ์ที่มีอำนาจกระทำการแทนบุคคลไร้ความสามารถหรือบุคคลเสมือนไร้ความสามารถนั้นแล้วแต่กรณี
              <br />
              <br />
              บริษัทจะดำเนินการให้ข้อมูลของท่านนั้นถูกต้อง เป็นปัจจุบัน สมบูรณ์
              และไม่ก่อให้เกิดความเข้าใจผิด
            </p>

            <p style={{ marginTop: "15px" }}>
              <strong>
                ข้อ 4. การเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
              </strong>
              <br />
              <strong>การเก็บรวบรวมส่วนบุคคล</strong>{" "}
              บริษัทจะเก็บรวบรวมข้อมูลของท่านโดยขอความยินยอมจากท่านก่อน
              เว้นแต่กรณีที่กฎหมายให้อำนาจไว้ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
              พ.ศ. 2562 มาตรา 24 หรือ มาตรา 26 <br />
              <br />
              บริษัทจะเก็บรวบรวมข้อมูลของท่านไว้เป็นระยะเวลา 10 ปี
              นับแต่วันที่เลิกสัญญา
              <br />
              <br />
              บริษัทจะเก็บรวบรวมข้อมูลของท่านเฉพาะเท่าที่จำเป็น
              ภายใต้วัตถุประสงค์ที่บริษัทได้แจ้งไว้ใน ข้อ 2.
              <br />
              <br /> ทั้งนี้
              หากมีความจำเป็นที่ท่านจะต้องให้ข้อมูลส่วนบุคคลแก่บริษัทเพื่อปฏิบัติตามกฎหมายหรือสัญญา
              หรือเพื่อต้องเข้าทำสัญญากับบริษัท บริษัทจะแจ้งให้ท่านทราบก่อน
              และจะแจ้งผลกระทบหากท่านไม่ยินยอมให้ข้อมูลแก่บริษัท
              <br />
              <br />
              บริษัทจะไม่เก็บรวบรวมข้อมูลของท่านจากแหล่งที่มาอื่นที่ไม่ใช่ของท่านโดยตรง
              เว้นแต่กรณีที่บริษัทได้แจ้งท่านถึงการเก็บรวบรวมข้อมูลของท่านจากแหล่งที่มาอื่นภายใน
              30 วัน และได้รับความยินยอมจากท่านแล้ว
              หรือเป็นการเก็บรวบรวมที่ได้รับการยกเว้นไม่ต้องขอความยินยอม
              ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 มาตรา 24
              หรือมาตรา 26
              <br />
              <br />
              บริษัทจะไม่เก็บรวบรวมข้อมูลส่วนบุคคลที่เกี่ยวกับเชื้อชาติ
              เผ่าพันธ์ุ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนาหรือปรัชญา
              พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ
              ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ
              หรือข้อมูลอื่นใดซึ่งกระทบต่อท่านในทำนองเดียวกันตามประกาศของคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
              โดยไม่ได้รับความยินยอมโดยชัดแจ้งจากท่าน
              เว้นแต่เป็นกรณีตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
              มาตรา 26 บัญญัติให้กระทำได้ <br />
              <br />
              <strong>การใช้ หรือเปิดเผยข้อมูลส่วนบุคคล</strong> บริษัทจะไม่ ใช้
              หรือเปิดเผยข้อมูลของท่านก่อนที่จะได้รับความยินยอมจากท่าน
              เว้นแต่เป็นข้อมูลของท่านที่เก็บรวบรวมได้โดยไม่ต้องขอความยินยอม
              ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 มาตรา 24
              หรือมาตรา 26
              <br />
              <br />
              บริษัทจะดูแลให้พนักงานที่ได้รับมอบหมายในเรื่องนี้โดยเฉพาะ มิให้
              ใช้ หรือเปิดเผย แสดง
              หรือทำให้ปรากฏในลักษณะอื่นใดซึ่งข้อมูลส่วนบุคคลของท่าน
              นอกเหนือไปจากวัตถุประสงค์
              ภายใต้หลักเกณฑ์ที่กฎหมายอนุญาตให้เปิดเผยได้ตามขอบเขตที่ท่านได้ให้ความยินยอม
              หรือขอบเขตที่เกี่ยวข้องในนโยบายฉบับนี้
              <br />
              <br />
              ในกรณีที่บริษัทจะเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคคลหรือนิติบุคคลภายนอก
              บุคคลหรือนิติบุคคลภายนอกนั้นจะต้องแจ้งวัตถุประสงค์ในการ ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลของท่านให้บริษัททราบก่อน
              และต้องไม่ใช้หรือเปิดเผยข้อมูลส่วนบุคคล
              นอกเหนือจากวัตถุประสงค์ที่ได้ให้ไว้แก่บริษัท
              <br />
              <br />
              บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของท่านแก่บริษัทผู้ร่วมวิเคราะห์ข้อมูล
              เพื่อการวิเคราะห์และพัฒนาปรับปรุงผลิตภัณฑ์และ/หรือบริการ
              การทำวิจัยหรือจัดทำข้อมูลทางสถิติ การบริหารกิจการ
              และการส่งเสริมการตลาด เช่น การประชาสัมพันธ์กิจกรรม ผลิตภัณฑ์
              และ/หรือบริการ ให้แก่ท่าน <br />
              <br />
              ในกรณีที่บริษัท ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลที่ได้รับยกเว้นไม่ต้องขอความยินยอม
              บริษัทจะบันทึกการใช้หรือเปิดเผยข้อมูลนั้นเป็นหนังสือหรือระบบอิเล็กทรอนิกส์
              <br />
              <br />
              ในกรณีที่บริษัทส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ
              ประเทศปลายทางหรือองค์กรระหว่างประเทศที่รับข้อมูลส่วนบุคคลต้องมีมาตราฐานคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอ
              ทั้งนี้
              ต้องเป็นไปตามหลักเกณฑ์การคุ้มครองข้อมูลส่วนบุคคลตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลกำหนด
              เว้นแต่เป็นกรณีตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
              มาตรา 28 (1) - (6)
            </p>

            <p style={{ marginTop: "15px" }}>
              <strong>ข้อ 5. สิทธิของท่านเกี่ยวกับข้อมูลส่วนบุคคล</strong>
              <br />
              สิทธิของท่านในข้อนี้เป็นสิทธิตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
              พ.ศ. 2562 และกฎหมายอื่นที่เกี่ยวข้องที่ท่านควรทราบ
              โดยท่านสามารถขอใช้สิทธิต่างๆ ได้ภายใต้ข้อกำหนดของกฎหมาย
              และนโยบายที่บริษัทกำหนดไว้ก่อนหรือในขณะหรือที่จะมีการแก้ไขเพิ่มเติมในอนาคต
              ตลอดจนหลักเกณฑ์ตามที่บริษัทกำหนดขึ้น <br />
              <br />
              <p>
                5.1 สิทธิในการขอถอนความยินยอม:
                ท่านมีสิทธิที่จะถอนความยินยอมเมื่อใดก็ได้ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของท่านอยู่กับบริษัท
                เว้นแต่มีข้อจำกัดสิทธินั้นโดยกฎหมายหรือมีสัญญาที่ให้ประโยชน์แก่ท่านอยู่
                (ไม่ว่าจะเป็นความยินยอมที่ท่านให้ไว้ก่อนวันที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลใช้บังคับหรือหลังจากนั้น)
                <br />
                <br />
                5.2 สิทธิในการขอเข้าถึง ขอรับข้อมูลหรือสำเนาข้อมูล:
                ท่านมีสิทธิขอเข้าถึง หรือรับ
                ข้อมูลส่วนบุคคลของท่านเองที่อยู่ในความรับผิดชอบของบริษัท
                และขอให้บริษัททำสำเนาข้อมูลดังกล่าวให้แก่ท่าน
                รวมถึงขอให้เปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลที่ท่านไม่ได้ให้ความยินยอมว่าบริษัทได้ข้อมูลส่วนบุคคลของท่านมาได้อย่างไร
                <br />
                <br />
                5.3 สิทธิในการคัดค้าน: ท่านมีสิทธิคัดค้านการเก็บรวบรวม ใช้
                หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเมื่อใดก็ได้
                ในกรณีที่กฎหมายให้อำนาจบริษัทในการเก็บรวบรวมข้อมูลของท่านได้โดยไม่ต้องขอความยินยอมจากท่านก่อน
                <br />
                <br />
                5.4 สิทธิขอให้แก้ไขข้อมูล:
                ท่านมีสิทธิขอแก้ไขข้อมูลส่วนบุคคลของท่านให้ถูกต้อง เป็นปัจจุบัน
                และไม่ก่อให้เกิดความเข้าใจผิด
                <br />
                <br />
                5.5 สิทธิขอให้ลบหรือทำลายข้อมูล:
                ท่านมีสิทธิขอลบหรือทำลายข้อมูลส่วนบุคคลของท่าน
                หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของได้
                ดังต่อไปนี้
                <br />
                <ol>
                  <li>
                    ข้อมูลของท่านไม่มีความจำเป็นที่จะเก็บรักษาไว้ตามวัตถุประสงค์ในการเก็บรวบรวม
                    ใช้ หรือเปิดเผยข้อมูลของท่าน{" "}
                  </li>
                  <li>
                    เมื่อท่านได้ใช้สิทธิขอถอนความยินยอมและบริษัทไม่มีอำนาจตามกฎหมายที่จะเก็บรวบรวม
                    ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านได้{" "}
                  </li>
                  <li>
                    เมื่อท่านใช้สิทธิคัดค้านตาม ข้อ 5.3
                    และบริษัทไม่อาจปฏิเสธคำขอได้ตามกฎหมาย
                  </li>
                  <li>
                    เมื่อข้อมูลของท่านได้ถูกเก็บ ใช้
                    หรือเปิดเผยโดยไม่ชอบด้วยกฎหมาย
                  </li>
                </ol>
                <br />
                5.6 สิทธิขอให้ระงับการใช้ข้อมูล:
                ท่านมีสิทธิขอให้บริษัทระงับการใช้ข้อมูลของท่าน ในกรณีดังต่อไปนี้
                <br />
                <ol>
                  <li>เมื่อบริษัทอยู่ในระหว่างการตรวจสอบตามที่ท่านร้องขอ</li>
                  <li>
                    เมื่อเป็นข้อมูลส่วนบุคคลที่ต้องลบหรือทำลาย
                    แต่ท่านขอให้ระงับการใช้แทน
                  </li>
                  <li>
                    เมื่อข้อมูลของท่านหมดความจำเป็นในการเก็บรักษาตามวัตถุประสงค์ในการเก็บรวบรวมข้อมูลของท่าน
                    แต่ท่านมีความจำเป็นต้องขอให้เก็บรักษาไว้เพื่อใช้ในการตั้งสิทธิเรียกร้องตามกฎหมายการปฏิบัติตามหรือการใช้สิทธิเรียกร้องตามกฎหมาย
                    หรือการยกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมาย
                  </li>
                  <li>
                    เมื่อบริษัทอยู่ระหว่างพิสูจน์คำขอคัดค้านของท่านตามข้อ 5.3
                    เพื่อดูว่าบริษัทมีอำนาจตามกฎหมายให้ปฏิเสธคำคัดค้านของท่านได้หรือไม่
                  </li>
                </ol>
                <br />
                5.7
                ท่านมีสิทธิร้องขอให้บริษัทดำเนินการให้ข้อมูลของท่านนั้นถูกต้อง
                เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
                หากบริษัทไม่ดำเนินการตามคำร้องขอ
                บริษัทจะบันทึกคำร้องขอของท่านพร้อมด้วยเหตุผลไว้เป็นหนังสือหรือระบบอิเล็กทรอนิกส์
                <br />
                5.8 สิทธิร้องเรียน:
                ท่านมีสิทธิร้องเรียนต่อผู้มีอำนาจตามกฎหมายที่เกี่ยวข้อง
                หากท่านเชื่อว่าการเก็บรวบรวม ใช้
                และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเป็นการกระทำในลักษณะที่ฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายที่เกี่ยวข้อง
                <br />
                5.9
                การใช้สิทธิของท่านดังกล่าวข้างต้นอาจถูกจำกัดภายใต้กฎหมายที่เกี่ยวข้อง
                และมีบางกรณีที่มีเหตุจำเป็นที่บริษัทอาจปฏิเสธหรือไม่สามารถดำเนินการตามคำขอใช้สิทธิข้างต้นของท่านได้
                เช่น ต้องปฏิบัติตามกฎหมายหรือคำสั่งศาล
                การใช้สิทธิละเมิดสิทธิหรือเสรีภาพของบุคคลอื่น เป็นต้น
              </p>
            </p>

            <p style={{ marginTop: "15px" }}>
              <strong>
                ข้อ 6. การเชื่อมโยงข้อมูลส่วนบุคคลกับบุคคลอื่นหรือหน่วยงานอื่น
              </strong>{" "}
              <p>
                6.1 บริษัทอาจเชื่อมโยงข้อมูลส่วนบุคคลกับบุคคลหรือหน่วยงานอื่น
                โดยบริษัทจะแจ้งให้ท่านทราบก่อนที่จะทำการเชื่อมโยงข้อมูลส่วนบุคคล
                พร้อมทั้งขอความยินยอม โดยมีรายละเอียดต่อไปนี้เป็นอย่างน้อย{" "}
                <ul>
                  <li>บุคคลหรือหน่วยงานที่จะทำการเชื่อมโยงข้อมูลส่วนบุคคล </li>
                  <li>วัตถุประสงค์ในการเชื่อมโยงข้อมูลส่วนบุคคล </li>
                  <li>วิธีการในการเชื่อมโยงข้อมูลส่วนบุคคล </li>
                  <li>ข้อมูลส่วนบุคคลที่จะทำการเชื่อมโยง</li>
                </ul>
                <br />
                6.2 หากมีการเปลี่ยนแปลงการเชื่อมโยงข้อมูล
                บริษัทจะแจ้งให้ท่านทราบถึงการเปลี่ยนแปลงดังกล่าว
                และขอความยินยอมก่อนการดำเนินการ
              </p>
            </p>

            <p style={{ marginTop: "15px" }}>
              <strong>
                ข้อ 7. การเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล
              </strong>{" "}
              <p>
                บริษัทอาจปรับปรุงนโยบายส่วนบุคคลนี้เป็นครั้งคราว
                เพื่อให้สอดคล้องกับการเปลี่ยนแปลงของการให้บริการและ/หรือผลิตภัณฑ์ของบริษัท
                การดำเนินงานของบริษัท และข้อเสนอแนะหรือความคิดเห็นจากท่าน
                โดยบริษัทจะประกาศแจ้งการเปลี่ยนแปลงให้ทราบอย่างชัดเจนก่อนจะเริ่มดำเนินการเปลี่ยนแปลง
                หรืออาจส่งประกาศให้ท่านทราบโดยตรง
              </p>
              <br />
            </p>
            <br />
            <br />
            <h4>
              <strong>คุกกี้คืออะไร?</strong>
            </h4>

            <p>
              คุกกี้ คือ
              ข้อความขนาดเล็กที่ประกอบด้วยส่วนของข้อมูลที่มาจากการดาวน์โหลดที่อาจถูกเก็บบันทึกไว้ในเว็บเบราว์เซอร์ที่ท่านใช้งานหรืออุปกรณ์อื่น
              ๆ ที่ต่อเชื่อมอินเตอร์เน็ต (อาทิ เครื่องคอมพิวเตอร์ สมาร์ทโฟน
              หรือแท็บเล็ตของท่าน)
              โดยที่เครื่องเซิร์ฟเวอร์สามารถเรียกดูได้ในภายหลัง
              คล้ายกับหน่วยความจำของหน้าเว็บ
            </p>

            <p>
              การทำงานของคุกกี้
              ช่วยให้เรารวบรวมและจัดเก็บข้อมูลการเยี่ยมชมเว็บไซต์ของท่านดังต่อไปนี้โดยอัตโนมัติ
            </p>

            <ul>
              <li>
                อินเตอร์เน็ตโดเมนและ IP Address จากจุดที่ท่านเข้าสู่เว็บไซต์
              </li>
              <li>
                ประเภทของเบราว์เซอร์ซอฟต์แวร์
                ตลอดจนโครงสร้างและระบบการปฏิบัติงานที่ใช้ในการเข้าสู่เว็บไซต์
              </li>
              <li>วันที่และเวลาที่ท่านเข้าสู่เว็บไซต์</li>
              <li>
                ที่อยู่ของเว็บไซต์อื่นที่เชื่อมโยงท่านเข้าสู่เว็บไซต์ของเรา
              </li>
              <li>
                หน้าเว็บที่ท่านเข้า เยี่ยมชม และนำท่านออกจากเว็บไซต์ของเรา
                รวมถึงเนื้อหาบนหน้าเว็บที่ท่านเยี่ยมชมและระยะเวลาที่ท่านใช้ในการเยี่ยมชม
              </li>
            </ul>
            <br />
            <h4>
              <strong>การใช้งานคุกกี้</strong>
            </h4>

            <p>
              ประเภทของคุกกี้ที่เราใช้และในการใช้คุกกี้ดังกล่าว ประกอบด้วย
              แต่ไม่จำกัดเพียง รายการต่อไปนี้
            </p>

            <ol>
              <li>
                <strong>
                  คุกกี้ประเภทที่มีความจำเป็นอย่างยิ่ง (Strictly Necessary
                  Cookies)
                </strong>
                : คุกกี้ประเภทนี้มีความสำคัญต่อการทำงานของเว็บไซต์
                ซึ่งรวมถึงคุกกี้ที่ทำให้ท่านสามารถเข้าถึงข้อมูลและใช้งานในเว็บไซต์ของเราได้อย่างปลอดภัย
              </li>
              <li>
                <strong>
                  คุกกี้เพื่อการวิเคราะห์/วัดผลการทำงานของเว็บไซต์(Analytical/Performance
                  Cookies):
                </strong>
                ?คุกกี้ประเภทนี้จะช่วยให้เราสามารถจดจำและนับจำนวนผู้เข้าเยี่ยมชมเว็บไซต์ตลอดจนช่วยให้เราทราบถึงพฤติกรรมในการเยี่ยมชมเว็บไซต์
                เพื่อปรับปรุงการทำงานของเว็บไซต์ให้มีคุณภาพดีขึ้นและมีความเหมาะสมมากขึ้น
                อีกทั้งเพื่อรวบรวมข้อมูลทางสถิติเกี่ยวกับวิธีการเข้าและพฤติกรรมการเยี่ยมชมเว็บไซต์
                ซึ่งจะช่วยปรับปรุงการทำงานของเว็บไซต์โดยให้ผู้ใช้งานสามารถค้นหาสิ่งที่ต้องการได้อย่างง่ายดาย
                และช่วยให้เราเข้าใจถึงความสนใจของผู้ใช้
                และวัดความมีประสิทธิผลของโฆษณาของเรา
              </li>
              <li>
                <strong>
                  คุกกี้เพื่อการทำงานของเว็บไซต์ (Functionality Cookies):
                </strong>
                ?คุกกี้ประเภทนี้ใช้ในการจดจำตัวท่านเมื่อท่านกลับมาใช้งานเว็บไซต์อีกครั้ง
                ซึ่งจะช่วยให้เราสามารถปรับแต่งเนื้อหาสำหรับท่าน
                ปรับให้เว็บไซต์ของเราตอบสนองความต้องการใช้งานของท่าน
                รวมถึงจดจำการตั้งค่าของท่าน อาทิ ภาษา หรือภูมิภาค
                หรือขนาดของตัวอักษรที่ท่านเลือกใช้ในการใช้งานในเว็บไซต์
              </li>
              <li>
                <strong>
                  คุกกี้เพื่อปรับเนื้อหาเข้ากับกลุ่มเป้าหมาย (Targeting
                  Cookies):
                </strong>
                &nbsp;คุกกี้ประเภทนี้จะบันทึกการเข้าชมเว็บไซต์ของท่าน
                หน้าเว็บที่ท่านได้เยี่ยมชม และลิงค์ที่ท่านเยี่ยมชม
                เราจะใช้ข้อมูลนี้เพื่อปรับให้เว็บไซต์และเนื้อหาใด ๆ
                ที่ปรากฏอยู่บนหน้าเว็บตรงกับความสนใจของคุณมากขึ้น นอกจากนี้
                เรายังอาจแชร์ข้อมูลนี้กับบุคคลที่สามเพื่อวัตถุประสงค์ดังกล่าว
              </li>
            </ol>

            <p>
              โปรดทราบคุกกี้เหล่านี้มักจะเป็นคุกกี้เพื่อการวิเคราะห์/วัดผลการทำงาน
              หรือคุกกี้เพื่อปรับเนื้อหาเข้ากับกลุ่มเป้าหมาย
              ท่านควรต้องศึกษานโยบายการใช้คุกกี้และนโยบายส่วนบุคคลในเว็บไซต์ของบุคคลที่สาม
              เพื่อให้เข้าใจถึงวิธีการที่บุคคลที่สามอาจนำข้อมูลของท่านไปใช้
            </p>
            <br />
            <h4>
              <strong>การตั้งค่าคุกกี้</strong>
            </h4>

            <p>
              ท่านสามารถบล็อกการทำงานของคุกกี้ได้โดยการกำหนดค่าในเบราวเซอร์ของท่าน
              ซึ่งท่านอาจปฏิเสธการติดตั้งค่าคุกกี้ทั้งหมดหรือบางประเภทก็ได้
              แต่พึงตระหนักว่าหากท่านตั้งค่าเบราวเซอร์ของท่านด้วยการบล็อกคุกกี้ทั้งหมด
              (รวมถึงคุกกี้ที่จำเป็นต่อการใช้งาน)
              ท่านอาจจะไม่สามารเข้าสู่เว็บไซต์ทั้งหมดหรือบางส่วนของเราได้
            </p>

            <p>
              เมื่อใดก็ตามที่ท่านต้องการยกเลิกความยินยอมการใช้งานคุกกี้
              ท่านจะต้องตั้งค่าเบราวเซอร์ของท่านเพื่อให้ลบคุกกี้ออกจากแต่ละเบราวเซอร์ที่ท่านใช้งาน
            </p>

            <br />
            <p>
              <strong>
                การจัดการคุกกี้ในบราว์เซอร์โดยการตั้งค่าตาม ดังนี้
              </strong>
            </p>

            <p>
              <strong>- Cookies settings in Chrome</strong>&nbsp;
              <a href="https://support.google.com/chrome/answer/95647?hl=th">
                (https://support.google.com/chrome/answer/95647?hl=th)
              </a>
              <br />
              <strong>- Cookies settings in Firefox</strong>
              <br />
              <a href="https://support.mozilla.org/th-TH/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies">
                (https://support.mozilla.org/th-TH/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies)
              </a>
              <br />
              <strong>- Cookies settings in Safari&nbsp;</strong>
              <a href="https://support.apple.com/th-th/guide/safari/sfri11471/mac">
                (https://support.apple.com/th-th/guide/safari/sfri11471/mac)
              </a>
              <br />
              &nbsp;and iOS&nbsp;
              <a href="https://support.apple.com/th-th/HT201265">
                (https://support.apple.com/th-th/HT201265)
              </a>
              <br />
              <strong>- Cookies settings in Internet Explorer&nbsp;</strong>
              <a href="https://support.microsoft.com/th-th/windows/%E0%B8%A5%E0%B8%9A%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B8%88%E0%B8%B1%E0%B8%94%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%84%E0%B8%B8%E0%B8%81%E0%B8%81%E0%B8%B5%E0%B9%89-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10">
                (https://support.microsoft.com/th-th/windows/ลบและจัดการคุกกี้-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10)
              </a>
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PrivacyPage;
