import React, { Component, Fragment } from "react";
import { connect } from 'react-redux'
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Events from '../components/Events';
import { showHideLoading, showDisplayPopupError } from '../actions/display'
// import { RegisterRequestDto } from '../dto/RegisterDto';
import { forgetpassCustomer } from "../actions/customer";



class ForgotPasswordComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '', //0123456788
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = event => {
    const { dispatch } = this.props
    event.preventDefault();
    // //TODO Validate value before submit to backend
    const validateRequired = this.validateFormRequiredField()
    if(!validateRequired.status){
       this.showErrorPopup("Error!!", validateRequired.message)
    }
     else{
       const reqData: RegisterResponseDto = {
        telephoneno: this.state.username,
       }
       dispatch(forgetpassCustomer(reqData))
     }
  }

  validateFormRequiredField = () => {
    let errorArray = [];
    if (this.state.username === "") {
      errorArray.push("หมายเลขโทรศัพท์");
    }
    if (errorArray.length > 0) {
      return {
        "status": false,
        "message": `<b>กรุณากรอกข้อมูล</b><br/>- ${errorArray.join("<br/>- ")}`
      }
    }
    return {
      "status": true,
    }
  }

  showHideLoading = (isShow) => {
    const { dispatch } = this.props
    dispatch(showHideLoading(isShow))
  }

  showErrorPopup = (title, message) => {
    const { dispatch } = this.props
    dispatch(showDisplayPopupError({
      "title": title,
      "message": message
    }))
  }

  render() {
    return (
      <Fragment>
        <Header />
        <Leftnav />
        <div className="main-content">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              {/* CONTENT HERE */}

              <div className="row feed-body">
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                  <div className="card shadow-none border-0 ms-auto me-auto login-card">
                    <div className="card-body rounded-0 text-left">

                      <h2 className="fw-700 display1-size display2-md-size mb-3">ลืมรหัสผ่าน</h2>
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group icon-input mb-3">
                          <i className="font-sm ti-mobile text-red pe-0"></i>
                          <input type="text" name="username" value={this.state.username} onChange={this.handleInputChange}
                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="หมายเลขโทรศัพท์" />
                        </div>

                        <div className="col-sm-12 p-0 text-left">
                          <div className="form-group mb-1">
                            {/* <a href="/login" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 ">Login</a> */}
                            <input type="submit" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 " value="ยืนยัน" />
                          </div>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                  <Events />
                </div>
              </div>

              {/* CONTENT ABOVE */}
            </div>
          </div>
        </div>
        {/* POPUP HERE */}
        {/* POPUP ABOVE */}
        <Appfooter />
      </Fragment>
    );
  }
}


const mapStateToProps = function (state, props) {
  return {
  }
}

const ForgotPassword = connect(mapStateToProps)(ForgotPasswordComponent)
export default ForgotPassword;