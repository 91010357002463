import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import cookie from "react-cookies";
import CustomerMenu from "./CustomerMenu";
import ProfileMenu from "./ProfileMenu";
import AdminMenu from "./AdminMenu";
import { getUserDetail } from "../actions/user";

const LeftMenuPaneComponent = (props) => {
  const { user_detail, navClass } = props;

  const dispatch = useDispatch();

  const [isLoggedin, setIsLoggedin] = useState(false);

  useEffect(() => {
    const token = cookie.load("APP_AT");
    if (token !== undefined) {
      setIsLoggedin(true);
    }    
  }, []);

  useEffect(() => {
    if (isLoggedin) {
      dispatch(getUserDetail());
    }
  }, [isLoggedin])

  useEffect(() => {
    console.log(user_detail);
  }, [user_detail]);

  return (
    <nav className={`navigation scroll-bar ${navClass}`}>
      <div className="container ps-0 pe-0">
        <div className="nav-content">
          {
            // !this.state.isAdminPage &&
            user_detail.is_customer && <CustomerMenu />
          }
          {
            // this.state.isAdminPage &&
            isLoggedin &&
              (user_detail.is_admin ||
                user_detail.is_agent ||
                user_detail.is_dealer) && <AdminMenu />
          }
          {isLoggedin && <ProfileMenu />}
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = function (state, props) {
  return {
    user_detail: state.user.user_detail || {},
  };
};

const LeftMenuPane = connect(mapStateToProps)(LeftMenuPaneComponent);
export default LeftMenuPane;
