const defaultState = {
    vault: {
      vault_list: false,
    },
  };
  
  const user = (state = defaultState, action = "") => {
    switch (action.type) {
      case "VAULT_LIST_DATA":
        // console.log('AGENT_LIST_DATA')
        return {
          ...state,
          vault_list: action.value,
        };
  
      default:
        return state;
    }
  };
  
  export default user;
  