const defaultState = {
  agent_list: [],
  agent_packnum: {},
  agent_detail: {
    id: "",
    detail: "",
    bank: "",
  },
  agent_number_list: [],
  agent_selected: {},
  agent_pricings: [],
  all_agent_pricings: [],
};

const agent = (state = defaultState, action = "") => {
  switch (action.type) {
    case "AGENT_LIST_DATA":
      // console.log('AGENT_LIST_DATA')
      return {
        ...state,
        agent_list: action.value,
      };
    case "AGENT_SET_AGENT_PACKNUM_DATA":
      // console.log('AGENT_SET_AGENT_PACKNUM_DATA')
      return {
        ...state,
        agent_packnum: action.value,
      };
    case "AGENT_SET_AGENT_DETAIL_DATA":
      // console.log('AGENT_SET_AGENT_DETAIL_DATA')
      return {
        ...state,
        agent_detail: action.value,
      };
    case "AGENT_NUMBER_LIST_DATA":
      // console.log('AGENT_NUMBER_LIST_DATA')
      return {
        ...state,
        agent_number_list: action.value,
      };
    case "AGENT_SET_AGENT_SELECTED":
      // console.log('AGENT_SET_AGENT_SELECTED')
      return {
        ...state,
        agent_selected: action.value,
      };
    case "AGENT_SET_AGENT_PRICING_DATA":
      // console.log('AGENT_SET_AGENT_PRICING_DATA')
      return {
        ...state,
        agent_pricings: action.value,
      };
    case "AGENT_SET_ALL_AGENT_PRICING_DATA":
      // console.log('AGENT_SET_AGENT_PRICING_DATA')
      return {
        ...state,
        all_agent_pricings: action.value,
      };

    default:
      return state;
  }
};

export default agent;
