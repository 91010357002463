import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class ProfileMenuComponent extends Component {
  render() {
    return (
      <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1">
        <div className="nav-caption fw-600 font-xssss text-grey-500">
          <span> {/*Account*/} บัญชี</span>
        </div>
        <ul className="mb-1">
          <li className="logo d-none d-xl-block d-lg-block"></li>
          <li>
            <Link
              to="/defaultsettings"
              className="nav-content-bttn open-font h-auto pt-2 pb-2"
            >
              <i className="font-sm feather-settings me-3 text-grey-500"></i>
              <span>
                {/* Settings */}
                การตั้งค่า
              </span>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {};
};

const ProfileMenu = connect(mapStateToProps)(ProfileMenuComponent);
export default ProfileMenu;
