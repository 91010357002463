import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Popupchat from "../components/Popupchat";
import { showDisplayPopupError } from "../actions/display";
import { saveAgent } from "../actions/agent";
import { saveDealer } from "../actions/dealer";
import PageTitle from "../components/PageTitle";

const CookiePolicy = () => {
  return (
    <Fragment>
      <Header />
      <Leftnav />

      <div className="main-content privacy-page">
        <div className="middle-sidebar-bottom">
          <div
            className="middle-sidebar-left pe-0"
            style={{ padding: "0 30px" }}
          >
            <br />
            <div className="text-center">
              <strong style={{ fontSize: "18px" }}>
                นโยบายการใช้คุกกี้(Cookie Privacy)
              </strong>
            </div>
            <br />
            <p>
              เว็บไซต์นี้ให้บริการโดย&nbsp;บริษัท สลากทูยู จำกัด
              บริษัทขอเรียนว่า บริษัทเคารพความเป็นส่วนตัวของผู้ใช้งาน
              และตระหนักดีว่าข้อมูลส่วนบุคคลของผู้ใช้งานแต่ละท่านมีความสำคัญอย่างยิ่ง
              บริษัทจึงต้องการที่จะชี้แจงให้ทราบเกี่ยวกับการเก็บรวบรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
            </p>
            <br />
            <h4>
              <strong>คุกกี้คืออะไร?</strong>
            </h4>

            <p>
              คุกกี้ คือ
              ข้อความขนาดเล็กที่ประกอบด้วยส่วนของข้อมูลที่มาจากการดาวน์โหลดที่อาจถูกเก็บบันทึกไว้ในเว็บเบราว์เซอร์ที่ท่านใช้งานหรืออุปกรณ์อื่น
              ๆ ที่ต่อเชื่อมอินเตอร์เน็ต (อาทิ เครื่องคอมพิวเตอร์ สมาร์ทโฟน
              หรือแท็บเล็ตของท่าน)
              โดยที่เครื่องเซิร์ฟเวอร์สามารถเรียกดูได้ในภายหลัง
              คล้ายกับหน่วยความจำของหน้าเว็บ
            </p>

            <p>
              การทำงานของคุกกี้
              ช่วยให้เรารวบรวมและจัดเก็บข้อมูลการเยี่ยมชมเว็บไซต์ของท่านดังต่อไปนี้โดยอัตโนมัติ
            </p>

            <ul>
              <li>
                อินเตอร์เน็ตโดเมนและ IP Address จากจุดที่ท่านเข้าสู่เว็บไซต์
              </li>
              <li>
                ประเภทของเบราว์เซอร์ซอฟต์แวร์
                ตลอดจนโครงสร้างและระบบการปฏิบัติงานที่ใช้ในการเข้าสู่เว็บไซต์
              </li>
              <li>วันที่และเวลาที่ท่านเข้าสู่เว็บไซต์</li>
              <li>
                ที่อยู่ของเว็บไซต์อื่นที่เชื่อมโยงท่านเข้าสู่เว็บไซต์ของเรา
              </li>
              <li>
                หน้าเว็บที่ท่านเข้า เยี่ยมชม และนำท่านออกจากเว็บไซต์ของเรา
                รวมถึงเนื้อหาบนหน้าเว็บที่ท่านเยี่ยมชมและระยะเวลาที่ท่านใช้ในการเยี่ยมชม
              </li>
            </ul>
            <br />
            <h4>
              <strong>การใช้งานคุกกี้</strong>
            </h4>

            <p>
              ประเภทของคุกกี้ที่เราใช้และในการใช้คุกกี้ดังกล่าว ประกอบด้วย
              แต่ไม่จำกัดเพียง รายการต่อไปนี้
            </p>

            <ol>
              <li>
                <strong>
                  คุกกี้ประเภทที่มีความจำเป็นอย่างยิ่ง (Strictly Necessary
                  Cookies)
                </strong>
                : คุกกี้ประเภทนี้มีความสำคัญต่อการทำงานของเว็บไซต์
                ซึ่งรวมถึงคุกกี้ที่ทำให้ท่านสามารถเข้าถึงข้อมูลและใช้งานในเว็บไซต์ของเราได้อย่างปลอดภัย
              </li>
              <li>
                <strong>
                  คุกกี้เพื่อการวิเคราะห์/วัดผลการทำงานของเว็บไซต์(Analytical/Performance
                  Cookies):
                </strong>
                ?คุกกี้ประเภทนี้จะช่วยให้เราสามารถจดจำและนับจำนวนผู้เข้าเยี่ยมชมเว็บไซต์ตลอดจนช่วยให้เราทราบถึงพฤติกรรมในการเยี่ยมชมเว็บไซต์
                เพื่อปรับปรุงการทำงานของเว็บไซต์ให้มีคุณภาพดีขึ้นและมีความเหมาะสมมากขึ้น
                อีกทั้งเพื่อรวบรวมข้อมูลทางสถิติเกี่ยวกับวิธีการเข้าและพฤติกรรมการเยี่ยมชมเว็บไซต์
                ซึ่งจะช่วยปรับปรุงการทำงานของเว็บไซต์โดยให้ผู้ใช้งานสามารถค้นหาสิ่งที่ต้องการได้อย่างง่ายดาย
                และช่วยให้เราเข้าใจถึงความสนใจของผู้ใช้
                และวัดความมีประสิทธิผลของโฆษณาของเรา
              </li>
              <li>
                <strong>
                  คุกกี้เพื่อการทำงานของเว็บไซต์ (Functionality Cookies):
                </strong>
                ?คุกกี้ประเภทนี้ใช้ในการจดจำตัวท่านเมื่อท่านกลับมาใช้งานเว็บไซต์อีกครั้ง
                ซึ่งจะช่วยให้เราสามารถปรับแต่งเนื้อหาสำหรับท่าน
                ปรับให้เว็บไซต์ของเราตอบสนองความต้องการใช้งานของท่าน
                รวมถึงจดจำการตั้งค่าของท่าน อาทิ ภาษา หรือภูมิภาค
                หรือขนาดของตัวอักษรที่ท่านเลือกใช้ในการใช้งานในเว็บไซต์
              </li>
              <li>
                <strong>
                  คุกกี้เพื่อปรับเนื้อหาเข้ากับกลุ่มเป้าหมาย (Targeting
                  Cookies):
                </strong>
                &nbsp;คุกกี้ประเภทนี้จะบันทึกการเข้าชมเว็บไซต์ของท่าน
                หน้าเว็บที่ท่านได้เยี่ยมชม และลิงค์ที่ท่านเยี่ยมชม
                เราจะใช้ข้อมูลนี้เพื่อปรับให้เว็บไซต์และเนื้อหาใด ๆ
                ที่ปรากฏอยู่บนหน้าเว็บตรงกับความสนใจของคุณมากขึ้น นอกจากนี้
                เรายังอาจแชร์ข้อมูลนี้กับบุคคลที่สามเพื่อวัตถุประสงค์ดังกล่าว
              </li>
            </ol>

            <p>
              โปรดทราบคุกกี้เหล่านี้มักจะเป็นคุกกี้เพื่อการวิเคราะห์/วัดผลการทำงาน
              หรือคุกกี้เพื่อปรับเนื้อหาเข้ากับกลุ่มเป้าหมาย
              ท่านควรต้องศึกษานโยบายการใช้คุกกี้และนโยบายส่วนบุคคลในเว็บไซต์ของบุคคลที่สาม
              เพื่อให้เข้าใจถึงวิธีการที่บุคคลที่สามอาจนำข้อมูลของท่านไปใช้
            </p>
            <br />
            <h4>
              <strong>การตั้งค่าคุกกี้</strong>
            </h4>

            <p>
              ท่านสามารถบล็อกการทำงานของคุกกี้ได้โดยการกำหนดค่าในเบราวเซอร์ของท่าน
              ซึ่งท่านอาจปฏิเสธการติดตั้งค่าคุกกี้ทั้งหมดหรือบางประเภทก็ได้
              แต่พึงตระหนักว่าหากท่านตั้งค่าเบราวเซอร์ของท่านด้วยการบล็อกคุกกี้ทั้งหมด
              (รวมถึงคุกกี้ที่จำเป็นต่อการใช้งาน)
              ท่านอาจจะไม่สามารเข้าสู่เว็บไซต์ทั้งหมดหรือบางส่วนของเราได้
            </p>

            <p>
              เมื่อใดก็ตามที่ท่านต้องการยกเลิกความยินยอมการใช้งานคุกกี้
              ท่านจะต้องตั้งค่าเบราวเซอร์ของท่านเพื่อให้ลบคุกกี้ออกจากแต่ละเบราวเซอร์ที่ท่านใช้งาน
            </p>

            <br />
            <p>
              <strong>
                การจัดการคุกกี้ในบราว์เซอร์โดยการตั้งค่าตาม ดังนี้
              </strong>
            </p>

            <p>
              <strong>- Cookies settings in Chrome</strong>&nbsp;
              <a href="https://support.google.com/chrome/answer/95647?hl=th">
                (https://support.google.com/chrome/answer/95647?hl=th)
              </a>
              <br />
              <strong>- Cookies settings in Firefox</strong>
              <br />
              <a href="https://support.mozilla.org/th-TH/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies">
                (https://support.mozilla.org/th-TH/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies)
              </a>
              <br />
              <strong>- Cookies settings in Safari&nbsp;</strong>
              <a href="https://support.apple.com/th-th/guide/safari/sfri11471/mac">
                (https://support.apple.com/th-th/guide/safari/sfri11471/mac)
              </a>
              <br />
              &nbsp;and iOS&nbsp;
              <a href="https://support.apple.com/th-th/HT201265">
                (https://support.apple.com/th-th/HT201265)
              </a>
              <br />
              <strong>- Cookies settings in Internet Explorer&nbsp;</strong>
              <a href="https://support.microsoft.com/th-th/windows/%E0%B8%A5%E0%B8%9A%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B8%88%E0%B8%B1%E0%B8%94%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%84%E0%B8%B8%E0%B8%81%E0%B8%81%E0%B8%B5%E0%B9%89-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10">
                (https://support.microsoft.com/th-th/windows/ลบและจัดการคุกกี้-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10)
              </a>
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CookiePolicy;
