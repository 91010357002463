import { API } from "./actionTypes"
import Session from "./session"

export const apiAction = ({
  url = "",
  method = "GET",
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onFailure = () => {},
  label = "",
  headersOverride = null,
  onSuccessFailureParamOverride = "",
}) => {
  // console.log(headersOverride)
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      label,
      headersOverride,
      onSuccessFailureParamOverride: onSuccessFailureParamOverride
    }
  }
}

export const apiActionWithToken = ({
  url = "",
  method = "GET",
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onFailure = () => {},
  label = "",
  headersOverride = null,
  onBackground = false,
  onSuccessFailureParamOverride = "",
}, enable_bypass_token = false) => {
  //TODO CHECK TOKEN EXPIRE AND SHOW ERROR
  // console.log("enable_bypass_token", enable_bypass_token)
  let token = Session.getUserToken()
  if(token === null && enable_bypass_token){
    //can use register token
    token = Session.getAnonymousToken()
  }
  // console.log("token", token)
  const headers = {
    ...headersOverride,
    'Authorization': `Bearer ${token}`,
  }
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      label,
      headersOverride: headers,
      onBackground: onBackground,
      onSuccessFailureParamOverride: onSuccessFailureParamOverride
    }
  }
}

export const apiActionWithTokenResponseType = ({
  url = "",
  method = "GET",
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onFailure = () => {},
  label = "",
  headersOverride = null,
  responseTypeOverride = "",
  onSuccessFailureParamOverride = "",
}, enable_bypass_token = false) => {
  //TODO CHECK TOKEN EXPIRE AND SHOW ERROR
  // console.log("enable_bypass_token", enable_bypass_token)
  let token = Session.getUserToken()
  if(token === null && enable_bypass_token){
    //can use register token
    token = Session.getAnonymousToken()
  }
  // console.log("token", token)
  const headers = {
    ...headersOverride,
    //'Authorization': `Bearer ${token}`,
  }
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      label,
      headersOverride: headers,
      responseTypeOverride: responseTypeOverride,
      onSuccessFailureParamOverride: onSuccessFailureParamOverride
    }
  }
}