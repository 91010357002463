/* eslint-disable no-lonely-if */
/* eslint-disable no-self-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as CryptoJS from 'crypto-js';
import jsFileDownload from 'js-file-download';
import moment from 'moment';

export default class Utils {
  static dateKeys = [];
  // static dateKeys = ['date', 'timestamp'];

  static transformFilter(filter: any): any {
    const result: { [k: string]: any } = {};
    for (const key in filter) {
      if (Object.prototype.hasOwnProperty.call(filter, key)) {
        const element = filter[key];
        if (element && typeof element === 'object') {
          result[key] = element.value;
        } else {
          if (typeof element !== 'string' || (typeof element === 'string' && element)) {
            result[key] = element ?? undefined;
          }
        }
      }
    }
    return result;
  }

  static capitalizeFirstLetter(string: string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  static isObject(item: any) {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  // handle date
  static transformServiceResponse<T>(original: any): T {
    if (Utils.isObject(original)) {
      for (const key in original) {
        if (Utils.isObject(original[key])) {
          original[key] = Utils.transformServiceResponse(original[key]);
        } else if (Array.isArray(original[key])) {
          original[key] = original[key].map(Utils.transformServiceResponse);
        } else if (Utils.dateKeys.some((v) => new RegExp(v, 'i').test(key))) {
          original[key] = new Date(original[key]);
        } else {
          original[key] = original[key];
        }
      }
    }
    return original;
  }

  static encryptWithKey(text: string, key = '') {
    if (key === '') {
      key = CryptoJS.AES.decrypt(process.env.REACT_APP_AUTH_HASH || '', process.env.REACT_APP_AUTH_TOKEN || '').toString(
        CryptoJS.enc.Utf8
      );
    }
    const hash = CryptoJS.SHA1(CryptoJS.enc.Utf8.parse(key));
    const secretKey = CryptoJS.enc.Hex.parse(hash.toString(CryptoJS.enc.Hex).substr(0, 32));

    const encrypted = CryptoJS.AES.encrypt(text, secretKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  }

  static decryptWithKey(text: string, key = '') {
    if (text) {
      if (key === '') {
        key = CryptoJS.AES.decrypt(process.env.REACT_APP_AUTH_HASH || '', process.env.REACT_APP_AUTH_TOKEN || '').toString(
          CryptoJS.enc.Utf8
        );
      }
      const hash = CryptoJS.SHA1(CryptoJS.enc.Utf8.parse(key));
      const secretKey = CryptoJS.enc.Hex.parse(hash.toString(CryptoJS.enc.Hex).substr(0, 32));

      const decrypt = CryptoJS.AES.decrypt(text, secretKey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      return decrypt.toString(CryptoJS.enc.Utf8);
    }
    return null;
  }

  static dynamicOriginUrl(url: string) {
    let result = url;
    if (process.env.REACT_APP_DYNAMIC_ORIGIN === 'true') {
      result = result.replace(url, window.location.origin);
    }
    return result;
  }

  static findOption(options: { value: string; label?: string }[], value: string) {
    return options.find((v) => v.value === value);
  }

  static downloadFile(file: Blob, ext: string, namePrefix = '', incldeDate = false) {
    const d = new Date();

    const name = incldeDate
      ? `${namePrefix}${d.toISOString().split('T')[0]}${d.toTimeString().split(' ')[0].replace(/:/g, '')}`
      : namePrefix;
    const filename = `${name}.${ext}`;
    jsFileDownload(file, filename);
  }
}

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYYY HH:MM");
  return buildDate;
};