import React, { Component, Fragment } from "react";
import { connect } from 'react-redux'
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Events from '../components/Events';
import { showHideLoading, showDisplayPopupError } from '../actions/display'
import { login } from "../actions/authen";
import Message from '../constants/message';
import { Link } from "react-router-dom";

class LoginCustComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '', //0123456788
      password: '', //123456
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = event => {
    const { dispatch } = this.props
    event.preventDefault();
    //TODO Validate value before submit to backend
    const validateRequired = this.validateFormRequiredField()
    if(!validateRequired.status){
      this.showErrorPopup(Message.MessageCommon.errorFound, validateRequired.message)
    }
    else{
      const reqData: LoginRequestDto = {
        username: this.state.username,
        password: this.state.password,
      }
            dispatch(login(reqData))
    }
  }

  validateFormRequiredField = () => {
    let errorArray = [];
    if(this.state.username === ""){
      errorArray.push("หมายเลขโทรศัพท์");
    }
    if(this.state.password === ""){
      errorArray.push("รหัสผ่าน");
    }
    if(errorArray.length > 0){
      return { 
        "status": false,
        "message": `<b>กรุณากรอกข้อมูล</b><br/>- ${errorArray.join("<br/>- ")}`
      }
    }
    return { 
      "status": true,
    }
  }
  
  showHideLoading = (isShow) => {
    const { dispatch } = this.props
    dispatch(showHideLoading(isShow))
  }

  showErrorPopup = (title, message) => {
    const { dispatch } = this.props
    dispatch(showDisplayPopupError({
      "title": title,
      "message": message
    }))
  }

  render() {
    return (
      <Fragment>
        <Header />
        <Leftnav />
        <div className="main-content">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              {/* CONTENT HERE */}

              <div className="row feed-body">
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                  <div className="card shadow-none border-0 ms-auto me-auto login-card">
                    <div className="card-body rounded-0 text-left">

                      <h2 className="fw-700 display1-size display2-md-size mb-3">เข้าสู่ระบบ</h2>
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group icon-input mb-3">
                          <i className="font-sm ti-mobile text-red pe-0"></i>
                          <input type="text" name="username" value={this.state.username} onChange={this.handleInputChange}
                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="หมายเลขโทรศัพท์" />
                        </div>
                        <div className="form-group icon-input mb-1">
                          <input type="Password" name="password" value={this.state.password} onChange={this.handleInputChange}
                            className="style2-input ps-5 form-control text-grey-900 font-xss ls-3" placeholder="รหัสผ่าน" />
                          <i className="font-sm ti-lock text-red pe-0"></i>
                        </div>
                        <div className="form-check text-left mb-3">
                          {/* <input type="checkbox" className="form-check-input mt-2" id="exampleCheck5" />
                          <label className="form-check-label font-xsss text-grey-500">Remember me</label> */}
                          <Link to="/forgot-password" className="fw-600 font-xsss text-grey-700 mt-1 float-right">ลืมรหัสผ่าน?</Link>
                        </div>

                        <div className="col-sm-12 p-0 text-left">
                          <div className="form-group mb-1">
                            {/* <a href="/login" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 ">Login</a> */}
                            <input type="submit" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 " value="เข้าสู่ระบบ" />
                          </div>
                          <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">คุณยังไม่ได้เป็นสมาชิก? <a href="/register-cust" className="fw-700 ms-1">สมัครสมาชิก</a></h6>
                        </div>

                        {/* <div className="col-sm-12 p-0 text-center mt-2">
                        <h6 className="mb-0 d-inline-block bg-white fw-500 font-xsss text-grey-500 mb-3">Or, Sign in with your social account </h6>
                        <div className="form-group mb-1"><a href="/register" className="form-control text-left style2-input text-white fw-600 bg-facebook border-0 p-0 mb-2"><img src="/assets/images/icon-1.png" alt="icon" className="ms-2 w40 mb-1 me-5" /> Sign in with Google</a></div>
                        <div className="form-group mb-1"><a href="/register" className="form-control text-left style2-input text-white fw-600 bg-twiiter border-0 p-0 "><img src="/assets/images/icon-3.png" alt="icon" className="ms-2 w40 mb-1 me-5" /> Sign in with Facebook</a></div>
                      </div> */}

                      </form>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                  <Events />
                </div>
              </div>

              {/* CONTENT ABOVE */}
            </div>
          </div>
        </div>
        {/* POPUP HERE */}
        {/* POPUP ABOVE */}
        <Appfooter />
      </Fragment>
    );
  }
}


const mapStateToProps = function (state, props) {
  return {
  }
}

const LoginCust = connect(mapStateToProps)(LoginCustComponent)
export default LoginCust;