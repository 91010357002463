import Message from "../constants/message";
import { logout } from "../services/authService";
import { showDisplayPopupError, showDisplayPopupMessage } from "./display";
import { ErrConst } from "../constants"

const CLASSNAME = `actions:actionUtils`;

export const onCommonFailure = (
  statusCode,
  statusText,
  data,
  onFailParam = null
) => {
  // console.log(`[${CLASSNAME}] onCommonFailure statusCode: ${statusCode}`)
  return async (dispatch) => {
    handleError(dispatch, statusCode, data, null, onFailParam);
  };
};

export const onApiError = (requestData, error, onFailParam = null) => {
  return async (dispatch) => {
    let title = Message.MessageCommon.errorFound;
    if (onFailParam !== null && onFailParam !== "") {
      let serviceDes = onFailParam.SERVICE_CODE;
      if (onFailParam.SERVICE_ATTR === null) {
        serviceDes = serviceDes + "_N";
      }
      title = title + " [" + serviceDes + "]";
    }
    let message = ""
    if (error.code === ErrConst.Axios_ERR_NETWORK) {
      message = Message.MessageCommon.networkError
    } else {
      message = Message.MessageCommon.systemError + " - " + error;
    }
    
    dispatch(
      showDisplayPopupError({
        title: title,
        message: message,
      })
    );
  };
};

export const handleError = (
  dispatch,
  statusCode,
  data,
  submit_function = null,
  onFailParam = null
) => {
  let title = "";
  let message = "";
  if (statusCode === 401) {
    //EX. 401 {"status":"UNAUTHORIZED","message":"Access is denied","errors":["Access is denied"]}
    //EX. 401 {"error":"invalid_token","error_description":""}
    if (data.status !== undefined) {
      title = data.status;
      message = data.message;
    } else if (data.error !== undefined) {
      title = Message.MessageCommon.errorFound;
      message = data.error + ": " + Message.MessageAuthen.tokenFail;
    } else if (data.message !== undefined) {
      title = Message.MessageCommon.errorFound;
      message = data.message + ": " + Message.MessageAuthen.tokenFail;
    } else {
      title = Message.MessageCommon.errorFound;
      message = Message.MessageAuthen.tokenFail;
    }
    if (onFailParam !== null && onFailParam !== "") {
      let serviceDes = onFailParam.SERVICE_CODE;
      if (onFailParam.SERVICE_ATTR === null) {
        serviceDes = serviceDes + "_N";
      }
      title = title + " [" + serviceDes + "]";
    }
    message = "การ login ของท่านหมดอายุ กรุณา login ใหม่อีกครั้ง";
    /*dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: title,
        message: message,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "error",
        submit_function: async () => {
          if (submit_function !== null) {
            submit_function();
          } else {
            dispatch(logout("/login"));
          }
        },
      })
    );*/
  } else {
    if (data.status !== undefined) {
      //400 {"status":"BAD_REQUEST","message":"Failed to convert ","errors":["id should be of type int"]}
      //500 {"statusDescription": "2021-11-08","errorCode":"","status":"Internal Server Error","statusCode":500}
      title = data.status;
      if (data.message !== undefined) {
        message = data.message;
      } else if (data.statusDescription !== undefined) {
        message = data.statusDescription;
      } else {
        message = Message.MessageCommon.systemError;
      }
    } else {
      title = Message.MessageCommon.errorFound;
      message = Message.MessageCommon.systemError;
    }
    if (onFailParam !== null && onFailParam !== "") {
      let serviceDes = onFailParam.SERVICE_CODE;
      if (onFailParam.SERVICE_ATTR === null) {
        serviceDes = serviceDes + "_N";
      }
      title = title + " [" + serviceDes + "]";
    }
    if (submit_function !== null) {
      dispatch(
        showDisplayPopupMessage({
          is_show: true,
          title: title,
          message: message,
          is_show_submit_button: true,
          is_show_cancel_button: false,
          submit_button_text: "Ok",
          cancel_button_text: null,
          status: "error",
          submit_function: async () => {
            submit_function();
          },
        })
      );
    } else {
      dispatch(
        showDisplayPopupError({
          title: title,
          message: message,
        })
      );
    }
  }
};

const formatDateToSend_dash2slash = (dateIn) => {
  //FROM yyyy-mm-dd to mm/dd/yyyy
  const dateList = dateIn.split("-");
  return `${dateList[2]}/${dateList[1]}/${dateList[0]}`;
};

export const actionUtils = {
  onCommonFailure,
  handleError,
  formatDateToSend_dash2slash,
  onApiError,
};

export default actionUtils;
