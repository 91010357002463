import React, { Component, Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Popupchat from "../components/Popupchat";
import { showDisplayPopupError } from "../actions/display";
import { getAgentPricingData, saveAgentPricingData } from "../actions/agent";
import PageTitle from "../components/PageTitle";
import { useParams } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import InputNumber from "react-input-number";
import Switch from "react-switch";
import { TbCurrencyBaht, TbPlus, TbTrashX } from "react-icons/tb";

const AgentPriceSetupFC = (props) => {
  const minPrice = 90;
  const { agent_pricings, match, user_detail, current_installment } = props;
  const { agent_id } = useParams();

  const [priceData, setPriceData] = useState([]);
  const [agentDefaultPriceData, setAgentDefaultPriceData] = useState([]);
  const [agentInstPriceData, setAgentInstPriceData] = useState([]);
  const [agentRangePriceData, setAgentRangePriceData] = useState([]);

  const dispatch = useDispatch();

  // const fetchAgentPacknumData = (agent_packnum) => {
  //   setPriceData(agent_packnum.datas ?? []);
  //   // { "num" : 1,"cost":  100, "active":"Y", "installment_id": 1 }
  // };

  const initialPage = () => {
    dispatch(getAgentPricingData(agent_id ?? user_detail.agent_id));
  };

  const extractPriceData = (prices) => {
    const defaultData = prices.find((d) => d.installmentId === 0);
    setAgentDefaultPriceData(
      defaultData?.pricingData.sort((a, b) => a.packNum - b.packNum) || []
    );
    const installmentData = prices.find((d) => d.installmentId !== 0);

    if (installmentData) {
      const instData = installmentData.pricingData.filter(
        (item) => !item.range
      );
      setAgentInstPriceData(
        instData.sort((a, b) => a.packNum - b.packNum) || []
      );

      const rangeData = [];
      const indexMap = {};
      installmentData.pricingData
        .filter((item) => item.range)
        .forEach((item) => {
          item.range = item.range.join(",");
          const range = item.range;
          const indexKeys = Object.keys(indexMap);
          if (!indexKeys.includes(range)) {
            const newIndex = rangeData.length;
            indexMap[range] = newIndex;
            rangeData[newIndex] = [item];
          } else {
            rangeData[indexMap[range]] = [
              ...rangeData[indexMap[range]],
              item,
            ].sort((a, b) => a.packNum - b.packNum);
          }
        });
      setAgentRangePriceData(rangeData || []);
    }
  };

  // const transformData = (num, type, dataName, value) => {
  //   console.log(num, type, dataName, value);

  //   let { defaultData, installmentData, rangeData } = extractPriceData();

  //   let focused = null;

  //   if (type === "installment") {
  //     focused = installmentData.find((d) => d.num === num);
  //     installmentData = installmentData.filter((d) => d.num !== num);
  //   } else if (type === "range") {
  //     focused = rangeData.find((d) => d.num === num);
  //     rangeData = rangeData.filter((d) => d.num !== focused.num);
  //   } else {
  //     focused = defaultData.find((d) => d.num === num);
  //     defaultData = defaultData.filter((d) => d.num !== num);
  //   }

  //   if (!focused) {
  //     focused = {
  //       num,
  //       cost: minPrice,
  //       active: "N",
  //       installment_id: type === "installment" || type === "range" ? 0 : null,
  //     };
  //   }

  //   focused[dataName] = value;

  //   return [...defaultData, ...rangeData, ...installmentData, focused].sort(
  //     (a, b) => a.num - b.num
  //   );
  // };

  const addPrice = (isDefaultPrice = true, isRange = false) => {
    if (isDefaultPrice === true) {
      setAgentDefaultPriceData([
        ...agentDefaultPriceData,
        {
          packNum: agentDefaultPriceData.length + 1,
          price: (agentDefaultPriceData.length + 1) * 90,
        },
      ]);
    } else if (isRange === true) {
      setAgentRangePriceData([
        ...agentRangePriceData,
        [
          {
            packNum: 1,
            price: 90,
            range: null,
          },
        ],
      ]);
    } else {
      setAgentInstPriceData([
        ...agentInstPriceData,
        {
          packNum: agentInstPriceData.length + 1,
          price: (agentInstPriceData.length + 1) * 90,
        },
      ]);
    }
  };

  const handleInputChange = (event) => {
    console.log("handleInputChange");
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const nameArr = name.split("-");

    if (nameArr.length === 4) {
      const [type, dataName, key, num] = nameArr;
      if (type === "range") {
        const data = [...agentRangePriceData];
        const tmpData = data[key].find(
          (d) => Number(d.packNum) === Number(num)
        );

        if (tmpData) {
          tmpData[dataName] = value;
          if (dataName === "range") {
            data[key] = data[key].map((obj) => {
              obj.range = value.trim();
              return obj;
            });
          }
          data[key] = [
            ...data[key].filter((d) => Number(d.packNum) !== Number(num)),
            tmpData,
          ].sort((a, b) => a.packNum - b.packNum);
          setAgentRangePriceData(data);
        }
      }
    } else if (nameArr.length === 3) {
      const [type, dataName, num] = nameArr;
      if (type === "installment") {
        const tmpData = agentInstPriceData.find(
          (d) => Number(d.packNum) === Number(num)
        );
        tmpData[dataName] = value;
        const data = [
          ...agentInstPriceData.filter(
            (d) => Number(d.packNum) !== Number(num)
          ),
          tmpData,
        ].sort((a, b) => a.packNum - b.packNum);
        setAgentInstPriceData(data);
      } else if (type === "default") {
        const tmpData = agentDefaultPriceData.find(
          (d) => Number(d.packNum) === Number(num)
        );
        tmpData[dataName] = value;
        const data = [
          ...agentDefaultPriceData.filter(
            (d) => Number(d.packNum) !== Number(num)
          ),
          tmpData,
        ].sort((a, b) => a.packNum - b.packNum);
        setAgentDefaultPriceData(data);
      }
    }
  };

  const validateFormRequiredField = () => {
    return {
      status: true,
    };
  };

  const validateInteger = () => {
    const validated = priceData.every((pd) => {
      return Number.isInteger(pd.cost);
    });

    if (!validated) {
      return {
        status: false,
      };
    }

    return {
      status: true,
    };
  };

  const validateForm = () => {
    const validated = validateFormRequiredField();
    if (!validated.status || !validateInteger().status) {
      return validated;
    }
    return {
      status: true,
    };
  };

  const inputValidation = (e) => {
    // console.log(e.key, e.keyCode, Number.isInteger(e.key-0))
    const nameArr = e.target.name.split("-");
    const [type, dataName] = nameArr;
    if (
      ![
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
        ...(dataName === "range" ? [","] : []),
      ].includes(e.key) &&
      !Number.isInteger(e.key - 0)
    ) {
      e.stopPropagation();
      e.preventDefault();

      return false;
    }

    return true;
  };

  const convertStateToBodyData = () => {
    const rangeData = agentRangePriceData
      .reduce((curr, prev) => [...curr, ...prev])
      .map((item) => {
        if (typeof item.range === "string")
          item.range = item.range
            .split(",")
            .map((n) => n.trim())
            .filter((n) => n !== "");

        return item;
      });
    const saveData = [
      {
        agentId: (agent_id ?? user_detail.agent_id) - 0,
        installmentId: 0,
        pricingData: agentDefaultPriceData,
      },
      {
        agentId: (agent_id ?? user_detail.agent_id) - 0,
        installmentId: current_installment.installmentId,
        pricingData: [...agentInstPriceData, ...rangeData],
      },
    ];

    return saveData;
  };

  const showErrorPopup = (title, message) => {
    const { dispatch } = this.props;
    dispatch(
      showDisplayPopupError({
        title: title,
        message: message,
      })
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // console.log("data", priceData);
    const validated = validateForm();
    if (!validated.status) {
      showErrorPopup("Error!!", validated.message);
    } else {
      //TODO
      const data = convertStateToBodyData();
      dispatch(saveAgentPricingData(data));
    }
  };

  useEffect(() => {
    if (user_detail.is_agent || user_detail.is_admin) {
      initialPage();
    }
  }, [user_detail]);

  // useEffect(() => {
  //   fetchAgentPacknumData(agent_packnum);
  // }, [agent_packnum]);

  useEffect(() => {
    if (agent_pricings) {
      extractPriceData(agent_pricings);
    }
  }, [agent_pricings]);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      {/* <Rightchat /> */}

      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row lottery-search">
              <div className="col-xl-12">
                <PageTitle title="ตัวแทนตั้งราคา" />

                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                  <div className="row">
                    <div className="col-sm-4 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          รหัสตัวแทน:{" "}
                        </label>
                        &nbsp;
                        {/* {agent_packnum.agentCode} */}
                      </div>
                    </div>

                    <div className="col-sm-4 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          ชื่อตัวแทน:{" "}
                        </label>
                        &nbsp;
                        {/* {agent_packnum.agentName} */}
                      </div>
                    </div>

                    <div className="col-sm-4 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          งวดล่าสุด:{" "}
                        </label>
                        &nbsp;
                        {/* {agent_packnum.latestInstallmentdate} */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row payment-list">
                  <div className="col-lg-12 mb-3">
                    <Tabs>
                      <TabList>
                        <Tab>ราคาชุด</Tab>
                        <Tab>ราคาเลขท้าย</Tab>
                        <Tab>ราคาฐาน</Tab>
                      </TabList>

                      <TabPanel>
                        <div className="table-content table-responsive">
                          <h3>ราคาชุด</h3>
                          <p>
                            เป็นราคาเปลี่ยนแปลงจากราคาฐานเพียงชั่วคราว
                            <br />
                            ราคาในส่วนนี้จะอยู่แค่เพียงงวดปัจจุบันเท่านั้น
                          </p>
                          <div className="number-price-container">
                            <div className="number-price-head">
                              <div>ชุด</div>
                              <div>ราคา (ต่อชุด)</div>
                            </div>
                            <div className="number-price-item">
                              {(() => {
                                const loopData = agentInstPriceData;

                                if (loopData.length === 0) return <></>;

                                return new Array(
                                  loopData[loopData.length - 1].packNum
                                )
                                  .fill()
                                  .map((itemx, indexx) => {
                                    itemx =
                                      loopData.find(
                                        (item) => item.packNum === indexx + 1
                                      ) ?? {};

                                    return (
                                      <div
                                        key={`inst-${indexx}`}
                                        className="number-price-set"
                                      >
                                        <div>
                                          <span>{itemx.packNum} ใบ</span>
                                        </div>
                                        <div>
                                          <InputNumber
                                            className="form-control"
                                            min={5}
                                            step={1}
                                            name={`installment-price-${itemx.packNum}`}
                                            value={itemx.price}
                                            onKeyDown={inputValidation}
                                            onClick={(e) => {
                                              e.target.select();
                                            }}
                                            onChange={(value) =>
                                              handleInputChange({
                                                target: {
                                                  type: "text",
                                                  name: `installment-price-${itemx.packNum}`,
                                                  value,
                                                },
                                              })
                                            }
                                            enableMobileNumericKeyboard
                                          />
                                        </div>
                                        {itemx.packNum ===
                                          loopData[loopData.length - 1]
                                            .packNum && (
                                          <div
                                            className="position-absolute"
                                            style={{ top: 0, left: 0 }}
                                          >
                                            <button
                                              className="text-red font-sm fw-400 border-0"
                                              style={{ background: "none" }}
                                              onClick={() => {
                                                setAgentInstPriceData(
                                                  agentInstPriceData.filter(
                                                    (p) =>
                                                      !(
                                                        p.packNum ===
                                                          itemx.packNum &&
                                                        p.installmentId ===
                                                          itemx.installmentId
                                                      )
                                                  )
                                                );
                                              }}
                                            >
                                              <TbTrashX />
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  });
                              })()}
                            </div>
                            <div style={{ marginTop: "25px" }}>
                              <button
                                className="nav-content-bttn rounded-3 bg-cyan open-font me-3 d-inline-block float-right lotto-view-btn hide w125 text-white border-0"
                                onClick={() => {
                                  addPrice(false);
                                }}
                              >
                                <i className="feather-plus btn-round-xs w15"></i>
                                เพิ่มชุดราคา
                              </button>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="table-content table-responsive">
                          <h3>ราคาเลขท้าย</h3>
                          <p>
                            เป็นราคาที่ตั้งเฉพาะเลขท้าย
                            ถ้าทำการขายล๊อตเตอรี่ที่มีเลขท้ายตามนี้
                            ราคาที่ได้จะเป็นไปตามที่กำหนด
                            <br />
                            ราคาในส่วนนี้จะอยู่แค่เพียงงวดปัจจุบันเท่านั้น
                          </p>
                          <div className="number-price-container">
                            <div className="number-price-head">
                              <div>เลขท้าย</div>
                              <div>ราคา (ต่อชุด)</div>
                            </div>
                            <div className="number-price-item">
                              {(() => {
                                const keys = Object.keys(agentRangePriceData);

                                if (keys.length === 0) return <></>;

                                return keys.map((key) => {
                                  const loopData = agentRangePriceData[key];

                                  return (
                                    <>
                                      {loopData.map((itemx, indexx) => {
                                        itemx =
                                          loopData.find(
                                            (item) =>
                                              item.packNum === indexx + 1
                                          ) ?? {};
                                        return (
                                          <div
                                            key={`range-${key}-${indexx}`}
                                            className="number-price-set"
                                          >
                                            <div>
                                              {itemx.packNum === 1 && (
                                                <input
                                                  type="text"
                                                  name={`range-range-${key}-${itemx.packNum}`}
                                                  value={itemx.range}
                                                  className="form-control"
                                                  style={{ fontSize: "2em" }}
                                                  onKeyDown={inputValidation}
                                                  onChange={handleInputChange}
                                                />
                                              )}
                                              {itemx.packNum > 1 && (
                                                <span>{itemx.packNum} ใบ</span>
                                              )}
                                            </div>
                                            <div>
                                              <InputNumber
                                                className="form-control"
                                                min={5}
                                                step={1}
                                                name={`range-price-${key}-${itemx.packNum}`}
                                                value={itemx.price}
                                                onKeyDown={inputValidation}
                                                onClick={(e) => {
                                                  e.target.select();
                                                }}
                                                onChange={(value) =>
                                                  handleInputChange({
                                                    target: {
                                                      type: "text",
                                                      name: `range-price-${key}-${itemx.packNum}`,
                                                      value,
                                                    },
                                                  })
                                                }
                                                enableMobileNumericKeyboard
                                              />
                                            </div>
                                            {itemx.packNum ===
                                              loopData[loopData.length - 1]
                                                .packNum && (
                                              <div
                                                className="position-absolute"
                                                style={{ top: 0, left: 0 }}
                                              >
                                                <button
                                                  className="text-red font-sm fw-400 border-0"
                                                  style={{ background: "none" }}
                                                  onClick={() => {
                                                    let dataSet = [
                                                      ...agentRangePriceData,
                                                    ];
                                                    dataSet[key] = dataSet[
                                                      key
                                                    ].filter(
                                                      (p) =>
                                                        !(
                                                          p.packNum ===
                                                            itemx.packNum &&
                                                          p.installmentId ===
                                                            itemx.installmentId
                                                        )
                                                    );
                                                    setAgentRangePriceData(
                                                      dataSet.filter(
                                                        (d) => d.length > 0
                                                      )
                                                    );
                                                  }}
                                                >
                                                  <TbTrashX />
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        );
                                      })}
                                      <div className="number-price-set">
                                        <div class="add-row">
                                          <button
                                            class="nav-content-bttn rounded-3 bg-cyan open-font me-3 d-inline-block float-right lotto-view-btn hide w125 text-white border-0"
                                            onClick={() => {
                                              const dataSet = [
                                                ...agentRangePriceData,
                                              ];
                                              dataSet[key] = [
                                                ...dataSet[key],
                                                {
                                                  packNum:
                                                    dataSet[key].length + 1,
                                                  price:
                                                    (dataSet[key].length + 1) *
                                                    90,
                                                  range: loopData[0].range,
                                                },
                                              ];
                                              setAgentRangePriceData(dataSet);
                                            }}
                                          >
                                            <i class="feather-plus btn-round-xs w15"></i>
                                            {loopData.length + 1} ใบ
                                          </button>
                                        </div>
                                        <div></div>
                                        <div></div>
                                      </div>
                                      <hr
                                        style={{
                                          height: "2px",
                                          borderWidth: 0,
                                          color: "gray",
                                          backgroundColor: "gray",
                                          marginTop: 0,
                                        }}
                                      />
                                    </>
                                  );
                                });
                              })()}
                            </div>
                            <div style={{ marginTop: "25px" }}>
                              <button
                                className="nav-content-bttn rounded-3 bg-cyan open-font me-3 d-inline-block float-right lotto-view-btn hide w125 text-white border-0"
                                onClick={() => {
                                  addPrice(false, true);
                                }}
                              >
                                <i className="feather-plus btn-round-xs w15"></i>
                                เพิ่มราคาเลขท้าย
                              </button>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="table-content table-responsive">
                          <h3>ราคาฐาน</h3>
                          <p>
                            เป็นราคาอ้างอิงกรณีที่ไม่ได้ตั้งราคาประจำงวด หรือ
                            ราคาเลขท้าย
                            <br />
                            ราคาในส่วนนี้จะอยู่ในทุกๆงวด
                            จากการตั้งค่าเพียงครั้งเดียว
                          </p>
                          <div className="number-price-container">
                            <div className="number-price-head">
                              <div>ชุด</div>
                              <div>ราคา (ต่อชุด)</div>
                            </div>
                            <div className="number-price-item">
                              {(() => {
                                // const loopData =
                                //   extractPriceData(priceData).defaultData;
                                const loopData = agentDefaultPriceData;

                                if (loopData.length === 0) return <></>;

                                return new Array(
                                  loopData[loopData.length - 1].packNum
                                )
                                  .fill()
                                  .map((itemx, indexx) => {
                                    itemx =
                                      loopData.find(
                                        (item) => item.packNum === indexx + 1
                                      ) ?? {};

                                    return (
                                      <div
                                        key={`default-${indexx}`}
                                        className="number-price-set"
                                      >
                                        <div>
                                          <span>{itemx.packNum} ใบ</span>
                                        </div>
                                        <div>
                                          <InputNumber
                                            className="form-control"
                                            min={5}
                                            step={1}
                                            name={`default-price-${itemx.packNum}`}
                                            value={itemx.price}
                                            onKeyDown={inputValidation}
                                            onClick={(e) => {
                                              e.target.select();
                                            }}
                                            onChange={(value) =>
                                              handleInputChange({
                                                target: {
                                                  type: "text",
                                                  name: `default-price-${itemx.packNum}`,
                                                  value,
                                                },
                                              })
                                            }
                                            enableMobileNumericKeyboard
                                          />
                                        </div>
                                        {itemx.packNum ===
                                          loopData[loopData.length - 1]
                                            .packNum && (
                                          <div
                                            className="position-absolute"
                                            style={{ top: 0, left: 0 }}
                                          >
                                            <button
                                              className="text-red font-sm fw-400 border-0"
                                              style={{ background: "none" }}
                                              onClick={() => {
                                                setAgentDefaultPriceData(
                                                  agentDefaultPriceData.filter(
                                                    (p) =>
                                                      !(
                                                        p.packNum ===
                                                          itemx.packNum &&
                                                        p.installmentId ===
                                                          itemx.installmentId
                                                      )
                                                  )
                                                );
                                              }}
                                            >
                                              <TbTrashX />
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  });
                              })()}
                            </div>
                            <div style={{ marginTop: "25px" }}>
                              <button
                                class="nav-content-bttn rounded-3 bg-cyan open-font me-3 d-inline-block float-right lotto-view-btn hide w125 text-white border-0"
                                onClick={() => {
                                  addPrice(true);
                                }}
                              >
                                <i class="feather-plus btn-round-xs w15"></i>
                                เพิ่มชุดราคา
                              </button>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    {/* <a href="/accountinformation" 
              className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">บันทึก</a> */}
                    <button
                      className="update-cart d-block  bg-cyan text-white fw-600 text-uppercase 
              font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"
                      onClick={handleSubmit}
                    >
                      บันทึก
                    </button>
                    {/* <input type="submit" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 " value="บันทึก" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      {/* <Appfooter />  */}
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    user_detail: state.user.user_detail || {},
    agent_pricings: state.agent.agent_pricings || [],
    current_installment: state.number.current_installment || {},
  };
};

const AgentPriceSetup = connect(mapStateToProps)(AgentPriceSetupFC);
export default AgentPriceSetup;
