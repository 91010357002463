import { gql } from "@apollo/client";

export const GET_REVENUE_SUBSCRIPTION = gql`
  subscription GetRevenue($date: date!) {
    transflow_trn_payment_data_aggregate(
      where: { payment_status: { _eq: "S" }, payment_date: { _gte: $date } }
    ) {
      aggregate {
        sum {
          total_amount
        }
      }
    }
  }
`;

export const GET_DOC_COUNT_SUBSCRIPTION = gql`
  subscription GetDocCount {
    transflow_trn_number_doc_aggregate(
      where: {
        mst_installment: { is_active: { _eq: "1" } }
        is_active: { _eq: "1" }
      }
    ) {
      aggregate {
        count(columns: number_id)
      }
    }
  }
`;

export const GET_DOC_SOLD_COUNT_SUBSCRIPTION = gql`
  subscription GetDocSoldCount {
    transflow_trn_number_doc_aggregate(
      where: {
        mst_installment: { is_active: { _eq: "1" } }
        is_active: { _eq: "1" }
        payment_id: { _is_null: false }
        trn_payment_data: { payment_status: { _eq: "S" } }
      }
    ) {
      aggregate {
        count(columns: number_id)
      }
    }
  }
`;

export const GET_DOC_BASKET_COUNT_SUBSCRIPTION = gql`
  subscription GetDocBasketCount {
    transflow_trn_number_doc_aggregate(
      where: {
        mst_installment: { is_active: { _eq: "1" } }
        is_active: { _eq: "1" }
        payment_id: { _is_null: true }
        basket_id: { _is_null: false }
      }
    ) {
      aggregate {
        count(columns: number_id)
      }
    }
  }
`;

export const GET_DOC_PAYMENT_COUNT_SUBSCRIPTION = gql`
  subscription GetDocPaymentCount {
    transflow_trn_number_doc_aggregate(
      where: {
        mst_installment: { is_active: { _eq: "1" } }
        is_active: { _eq: "1" }
        payment_id: { _is_null: false }
        trn_payment_data: { payment_status: { _eq: "P" } }
      }
    ) {
      aggregate {
        count(columns: number_id)
      }
    }
  }
`;

export const GET_ACTIVE_CUST_COUNT = gql`
  subscription GetActiveCustCount {
    transflow_trn_vault_aggregate(
      distinct_on: cust_id
      where: {
        trn_number_doc: { mst_installment: { is_active: { _eq: "1" } } }
      }
    ) {
      aggregate {
        count(columns: cust_id)
      }
    }
  }
`;

export const GET_BASKET_COUNT_SUBSCRIPTION = gql`
  subscription GetCustBasketCount($cust_id: bigint) {
    transflow_trn_cust_basket_aggregate(
      where: {
        cust_id: { _eq: $cust_id }
        is_active: { _eq: "1" }
        payment_id: { _is_null: true }
        trn_number_doc: { mst_installment: { is_active: { _eq: "1" } } }
      }
    ) {
      aggregate {
        sum {
          order_total
        }
      }
    }
  }
`;

export const GET_RESULT_FOR_INSTALLMENT_QUERY = gql`
  query GetResult($instmentId: bigint!) {
    transflow_trn_result(where: { installment_id: { _eq: $instmentId } }) {
      result_id
      result_number
      result_price
      result_type
      round
      installment_id
      created_date
    }
  }
`;
