import { ServerInfo, ServerInfo_Code } from "../constants/config";
import Message from "../constants/message";
import { apiActionWithToken } from "./actions";
import {
  showDisplayPopupMessage,
  showDisplayPopupError,
  closePopupData,
  hideDisplayPopupVerifyCheck,
} from "./display";
import formData from "form-data";
import actionUtils from "./actionUtils";

const CLASSNAME = `actions:stock`;

const formatDateToSend = (dateIn) => {
  //FROM yyyy-mm-dd to mm/dd/yyyy
  const dateList = dateIn.split("-");
  return `${dateList[2]}/${dateList[1]}/${dateList[0]}`;
};

//GET STOCK LIST DATA
export const getStockListData = (data) => {
  // console.log(`[${CLASSNAME}] getStockListData`)
  // console.log(data)
  let params = {
    agentname: "",
    filename: "",
    startdate: "",
    enddate: "",
  };
  if (data !== undefined) {
    if (data.agentname !== "" && data.agentname !== undefined) {
      params.agentname = data.agentname;
    }
    if (data.filename !== "" && data.filename !== undefined) {
      params.filename = data.filename;
    }
    if (data.startdate !== "" && data.startdate !== undefined) {
      params.startdate = formatDateToSend(data.startdate);
    }
    if (data.enddate !== "" && data.enddate !== undefined) {
      params.enddate = formatDateToSend(data.enddate);
    }
  }
  // trans/api/stock/searchstock?agentname=&filename=&startdate=&enddate=
  // console.log("params", JSON.stringify(params))
  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] getStockListData`)
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_STOCK_SEARCH}`,
        method: `GET`,
        data: params,
        onSuccess: onGetStockListResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onGetStockListResponse = (statusCode, statusText, data) => {
  // console.log(`[${CLASSNAME}] onGetStockListResponse statusCode: ${statusCode}`)
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "STOCK_SET_STOCK_LIST_DATA",
      value: data.result,
    });
    // {"result":[{"agent_name":"ตัวแทน1","upload_id":66,"file_name":"AG001 600x600 2.zip","totaldoc":100,"verified_success":null,"verified_error":null,"created_date":"10/10/2021"},{"agent_name":"แฟ้ม lotto","upload_id":67,"file_name":"300x300 BW.zip","totaldoc":100,"verified_success":null,"verified_error":null,"created_date":"10/10/2021"}],"statusDescription":"200 OK","status":"SUCCESS","statusCode":200}
    //{"result":{"content":[{"basketId":10,"packId":1,"packNum":5,"packAmount":600,"total":1,"amount":600,"orderdate":"01/08/2021 06:16","numberNo":"000001","basketStatus":"W","basketStatusText":"GEP001_W","agentName":"ตัวแทน1"}],"pageable":{"sort":{"sorted":false,"unsorted":true,"empty":true},"pageSize":20,"pageNumber":0,"offset":0,"unpaged":false,"paged":true},"totalElements":1,"totalPages":1,"last":true,"first":true,"sort":{"sorted":false,"unsorted":true,"empty":true},"number":0,"numberOfElements":1,"size":20,"empty":false},"statusDescription":"200 OK","sumtopay":600,"status":"SUCCESS","statusCode":200}
    // dispatch({
    //   type: "NUMBER_SET_SEARCH_RESULT",
    //   value: data.result.content
    // })
  };
};

// export const onGetStockListFailure = (statusCode, statusText, data) => {
//   console.log(`[${CLASSNAME}] onGetStockListFailure statusCode: ${statusCode}`)
//   return async (dispatch) => {
//     console.log(data)
//     dispatch(showDisplayPopupError({
//       "title": Message.MessageCommon.systemError,
//       "message": data.error + ": " + data.error_description
//     }))
//   }
// }

export const saveCheckStock = (data) => {
  console.log(data);
  // dataForm.append('no', data.no)
  var indt_id = parseInt(data.indt_id);
  var set = parseInt(data.set);
  var book = parseInt(data.book);

  const item = [
    {
      detail_id: data.detail_id,
      no: data.no,
      indt_id: indt_id,
      set: set,
      book: book,
    },
  ];

  return async (dispatch) => {
    console.log(`[${CLASSNAME}] saveAgent`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_STOCK_CHECK}?agentid=${data.agent_id}`,
        method: `PUT`,
        data: item,
        onSuccess: onsaveCheckStockResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onsaveCheckStockResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onsaveCheckStockResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: Message.MessageCommon.success,
        message: `บันทึกสำเร็จ`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          dispatch(closePopupData());
          dispatch(hideDisplayPopupVerifyCheck());
          dispatch(setDisplayStockCheckLottoReload(true));
        },
        // submit_function: async () => {
        //   // window.location = '/admin/stock-detail';
        // }
      })
    );
  };
};

// export const onsaveCheckStockFailure = (statusCode, statusText, data) => {
//   console.log(`[${CLASSNAME}] onSaveAgentFailure statusCode: ${statusCode}`)
//   return async (dispatch) => {
//     console.log(data)
//     //EX. 401 {"status":"UNAUTHORIZED","message":"Access is denied","errors":["Access is denied"]}
//     //EX. 401 {"error":"invalid_token","error_description":""}
//     //EX. 400 {"error":"invalid_request","error_description":"Missing grant type"}
//     //401 {"error":"invalid_token","error_description":"A"}
//     if (statusCode === 401) {
//       if (data.status !== undefined) {
//         dispatch(showDisplayPopupError({
//           "title": data.status,
//           "message": data.message
//         }))
//       } else {
//         dispatch(showDisplayPopupError({
//           "title": Message.MessageCommon.errorFound,
//           "message": data.error + ": " + Message.MessageAuthen.tokenFail
//         }))
//       }
//     } else {
//       dispatch(showDisplayPopupError({
//         "title": Message.MessageCommon.systemError,
//         "message": data.status + ": " + (data.statusDescription ? data.statusDescription : data.message)
//       }))
//     }
//   }
// }

export const setDisplayStockCheckLottoReload = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "DISPLAY_SET_POPUP_STOCKCHECK_LOTTO_RELOAD",
      value: data,
    });
  };
};

//UPLOAD LOTTO
export const stockUpload = (data) => {
  console.log(`[${CLASSNAME}] stockUpload`);
  var dataForm = new formData();
  // dataForm.append('file', data.file, data.file.name);
  dataForm.append("agentid", data.agentId);
  for (let index = 0; index < data.files.length; index++) {
    const file = data.files[index];
    dataForm.append("file", file, file.name);
  }
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] stockUpload`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_STOCK_UPZIP}`,
        method: `POST`,
        data: dataForm,
        onSuccess: onStockUploadResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_STOCK_UPZIP,
        },
        // headersOverride: {
        //   ...dataForm.getHeaders(),
        // }
      })
    );
  };
};

export const onStockUploadResponse = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onStockUploadResponse statusCode: ${statusCode}`);
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: Message.MessageCommon.success,
        message: `บันทึกสำเร็จ`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          window.location = "/admin/stock-in";
        },
      })
    );
    // dispatch(showDisplayPopupMessage({
    //   "title": Message.MessageCommon.success,
    //   "message": Message.MessageCommon.saveSuccess
    // }))
    // setTimeout(function () {
    //   window.location = "/admin/stock-in"
    // }, 5000);
  };
};

// export const onStockUploadFailure = (statusCode, statusText, data) => {
//   console.log(`[${CLASSNAME}] onStockUploadFailure statusCode: ${statusCode}`)
//   return async (dispatch) => {
//     console.log(data)
//     //EX. 401 {"status":"UNAUTHORIZED","message":"Access is denied","errors":["Access is denied"]}
//     //EX. 401 {"error":"invalid_token","error_description":""}
//     // //EX. 400 {"error":"invalid_request","error_description":"Missing grant type"}
//     //401 {"error":"invalid_token","error_description":"A"}
//     if(statusCode === 401){
//       if(data.status !== undefined){
//         dispatch(showDisplayPopupError({
//           "title": data.status,
//           "message": data.message
//         }))
//       }
//       else{
//         dispatch(showDisplayPopupError({
//           "title": Message.MessageCommon.errorFound,
//           "message": data.error + ": " + Message.MessageAuthen.tokenFail
//         }))
//         //TODO LOgout here
//       }
//     }
//     else {
//       // {"statusDescription":" File 14885357111415.jpg is not zip file.","errorCode":"","status":"Bad Request","statusCode":400}
//       dispatch(showDisplayPopupError({
//         "title": Message.MessageCommon.systemError,
//         "message": data.status + ": " + data.statusDescription
//       }))
//     }
//   }
// }

//SET STOCK DETAIL
export const setStockDetailSelected = (data) => {
  // console.log(`[${CLASSNAME}] setStockDetailSelected`)
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "STOCK_SET_STOCK_DETAIL_DATA",
      value: data,
    });
  };
};

export const setStockDetailSelecteds = (data) => {
  // console.log(`[${CLASSNAME}] setStockDetailSelected`)
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "STOCK_SET_STOCK_DETAIL_LIST_DATA",
      value: data,
    });
  };
};

//GET STOCK DETAIL LIST DATA
export const getStockDetailListData = (upload_id, verified) => {
  console.log(`[${CLASSNAME}] getStockDetailListData`);
  let params = {
    uploadId: upload_id,
    verified: verified,
  };
  // trans/api/stock/historiesDetail?uploadId=67&verified=
  // console.log("params", JSON.stringify(params))
  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] getStockDetailListData`)
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_STOCK_DETAIL_LIST}`,
        method: `GET`,
        data: params,
        onSuccess: onGetStockDetailResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_STOCK_DETAIL_LIST,
        },
      })
    );
  };
};

export const onGetStockDetailResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetStockDetailResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "STOCK_SET_STOCK_DETAIL_LIST_DATA",
      value: data.result,
    });
    // {"result":[{"agent_name":"ตัวแทน1","upload_id":66,"file_name":"AG001 600x600 2.zip","totaldoc":100,"verified_success":null,"verified_error":null,"created_date":"10/10/2021"},{"agent_name":"แฟ้ม lotto","upload_id":67,"file_name":"300x300 BW.zip","totaldoc":100,"verified_success":null,"verified_error":null,"created_date":"10/10/2021"}],"statusDescription":"200 OK","status":"SUCCESS","statusCode":200}
    //{"result":{"content":[{"basketId":10,"packId":1,"packNum":5,"packAmount":600,"total":1,"amount":600,"orderdate":"01/08/2021 06:16","numberNo":"000001","basketStatus":"W","basketStatusText":"GEP001_W","agentName":"ตัวแทน1"}],"pageable":{"sort":{"sorted":false,"unsorted":true,"empty":true},"pageSize":20,"pageNumber":0,"offset":0,"unpaged":false,"paged":true},"totalElements":1,"totalPages":1,"last":true,"first":true,"sort":{"sorted":false,"unsorted":true,"empty":true},"number":0,"numberOfElements":1,"size":20,"empty":false},"statusDescription":"200 OK","sumtopay":600,"status":"SUCCESS","statusCode":200}
    // dispatch({
    //   type: "NUMBER_SET_SEARCH_RESULT",
    //   value: data.result.content
    // })
  };
};

// export const onGetStockDetailFailure = (statusCode, statusText, data) => {
//   console.log(`[${CLASSNAME}] onGetStockDetailFailure statusCode: ${statusCode}`)
//   return async (dispatch) => {
//     console.log(data)
//     dispatch(showDisplayPopupError({
//       "title": Message.MessageCommon.systemError,
//       "message": data.error + ": " + data.error_description
//     }))
//   }
// }

export const getWaitReleaseData = (agentId) => {
  console.log(`[${CLASSNAME}] getWaitReleaseData`);
  // trans/api/stock/historiesDetail?uploadId=67&verified=
  // console.log("params", JSON.stringify(params))
  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] getStockDetailListData`)
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_STOCK_WAITRELEASE}?agentid=${agentId}`,
        method: `GET`,
        onSuccess: onGetWaitReleaseResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_STOCK_DETAIL_LIST,
        },
      })
    );
  };
};

export const onGetWaitReleaseResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetWaitReleaseResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "STOCK_WAIT_RELEASE",
      value: data.result,
    });
    // {"result":[{"agent_name":"ตัวแทน1","upload_id":66,"file_name":"AG001 600x600 2.zip","totaldoc":100,"verified_success":null,"verified_error":null,"created_date":"10/10/2021"},{"agent_name":"แฟ้ม lotto","upload_id":67,"file_name":"300x300 BW.zip","totaldoc":100,"verified_success":null,"verified_error":null,"created_date":"10/10/2021"}],"statusDescription":"200 OK","status":"SUCCESS","statusCode":200}
    //{"result":{"content":[{"basketId":10,"packId":1,"packNum":5,"packAmount":600,"total":1,"amount":600,"orderdate":"01/08/2021 06:16","numberNo":"000001","basketStatus":"W","basketStatusText":"GEP001_W","agentName":"ตัวแทน1"}],"pageable":{"sort":{"sorted":false,"unsorted":true,"empty":true},"pageSize":20,"pageNumber":0,"offset":0,"unpaged":false,"paged":true},"totalElements":1,"totalPages":1,"last":true,"first":true,"sort":{"sorted":false,"unsorted":true,"empty":true},"number":0,"numberOfElements":1,"size":20,"empty":false},"statusDescription":"200 OK","sumtopay":600,"status":"SUCCESS","statusCode":200}
    // dispatch({
    //   type: "NUMBER_SET_SEARCH_RESULT",
    //   value: data.result.content
    // })
  };
};

export const stockRelease = (agentId) => {
  return async (dispatch) => {
    console.log(`[${CLASSNAME}] stockRelease`);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_STOCK_RELEASE}`,
        method: `POST`,
        data: { agentid: agentId },
        onSuccess: onReleaseResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_STOCK_UPZIP,
        },
        // headersOverride: {
        //   ...dataForm.getHeaders(),
        // }
      })
    );
  };
};

export const onReleaseResponse = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onReleaseResponse statusCode: ${statusCode}`);
  return async (dispatch) => {
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: Message.MessageCommon.success,
        message: `ปล่อยสำเร็จ`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          window.location = "/admin/stock-new-in";
        },
      })
    );
    // dispatch(showDisplayPopupMessage({
    //   "title": Message.MessageCommon.success,
    //   "message": Message.MessageCommon.saveSuccess
    // }))
    // setTimeout(function () {
    //   window.location = "/admin/stock-in"
    // }, 5000);
  };
};

export const getAgentList = () => {
  console.log(`[${CLASSNAME}] getAgentList`);
  // trans/api/stock/historiesDetail?uploadId=67&verified=
  // console.log("params", JSON.stringify(params))
  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] getStockDetailListData`)
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_ADMIN_AGENT_LIST}`,
        method: `GET`,
        onSuccess: onGetAgentListResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_ADMIN_AGENT_LIST,
        },
      })
    );
  };
};

export const onGetAgentListResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetAgentListResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "STOCK_AGENT_LIST_DATA",
      value: data.result,
    });
    // {"result":[{"agent_name":"ตัวแทน1","upload_id":66,"file_name":"AG001 600x600 2.zip","totaldoc":100,"verified_success":null,"verified_error":null,"created_date":"10/10/2021"},{"agent_name":"แฟ้ม lotto","upload_id":67,"file_name":"300x300 BW.zip","totaldoc":100,"verified_success":null,"verified_error":null,"created_date":"10/10/2021"}],"statusDescription":"200 OK","status":"SUCCESS","statusCode":200}
    //{"result":{"content":[{"basketId":10,"packId":1,"packNum":5,"packAmount":600,"total":1,"amount":600,"orderdate":"01/08/2021 06:16","numberNo":"000001","basketStatus":"W","basketStatusText":"GEP001_W","agentName":"ตัวแทน1"}],"pageable":{"sort":{"sorted":false,"unsorted":true,"empty":true},"pageSize":20,"pageNumber":0,"offset":0,"unpaged":false,"paged":true},"totalElements":1,"totalPages":1,"last":true,"first":true,"sort":{"sorted":false,"unsorted":true,"empty":true},"number":0,"numberOfElements":1,"size":20,"empty":false},"statusDescription":"200 OK","sumtopay":600,"status":"SUCCESS","statusCode":200}
    // dispatch({
    //   type: "NUMBER_SET_SEARCH_RESULT",
    //   value: data.result.content
    // })
  };
};
