import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";

import { logout } from "../actions/authen";

class SettingsComponent extends Component {
  logoutClick = (event) => {
    const { dispatch } = this.props;
    event.preventDefault();
    dispatch(logout());
  };

  render() {
    const { is_customer, is_dealer, is_agent } = this.props.user_detail;

    return (
      <Fragment>
        <Header />
        <Leftnav />
        {/* <Rightchat />  right-chat-active */}

        <div className="main-content bg-lightblue theme-dark-bg">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="middle-wrap">
                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                  <div className="card-body p-lg-5 p-4 w-100 border-0">
                    <div className="row">
                      <div className="col-lg-12">
                        <h4 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">
                          ตั้งค่า
                        </h4>
                        <div className="nav-caption fw-600 font-xssss text-grey-500 mb-2">
                          ทั่วไป
                        </div>
                        <ul className="list-inline mb-4">
                          {is_customer && !is_dealer && (
                            <li className="list-inline-item d-block border-bottom me-0">
                              <Link
                                to="/cust-profile"
                                className="pt-2 pb-2 d-flex align-items-center"
                              >
                                <i className="btn-round-md bg-primary-gradiant text-white feather-home font-md me-3"></i>{" "}
                                <h4 className="fw-600 font-xsss mb-0 mt-0">
                                  ข้อมูลโปรไฟล์
                                </h4>
                                <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                              </Link>
                            </li>
                          )}

                          {is_dealer && (
                            <li className="list-inline-item d-block border-bottom me-0">
                              <Link
                                to="/dealer/detail"
                                className="pt-2 pb-2 d-flex align-items-center"
                              >
                                <i className="btn-round-md bg-primary-gradiant text-white feather-home font-md me-3"></i>{" "}
                                <h4 className="fw-600 font-xsss mb-0 mt-0">
                                  โปรไฟล์ดีลเลอร์
                                </h4>
                                <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                              </Link>
                            </li>
                          )}
                          {is_agent && (
                            <li className="list-inline-item d-block border-bottom me-0">
                              <Link
                                to="/agent/detail"
                                className="pt-2 pb-2 d-flex align-items-center"
                              >
                                <i className="btn-round-md bg-primary-gradiant text-white feather-home font-md me-3"></i>{" "}
                                <h4 className="fw-600 font-xsss mb-0 mt-0">
                                  {/* Agent Profile */}
                                  โปรไฟล์ตัวแทน
                                </h4>
                                <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                              </Link>
                            </li>
                          )}

                          {/* <li className="list-inline-item d-block border-bottom me-0"><Link to="/accountinformation" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-primary-gradiant text-white feather-home font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Acount Information</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li> */}
                          {/* <li className="list-inline-item d-block border-bottom me-0"><Link to="/contactinformation" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-gold-gradiant text-white feather-map-pin font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Saved Address</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li> */}
                          {/* <li className="list-inline-item d-block me-0"><Link to="/socialaccount" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-red-gradiant text-white feather-twitter font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Social Acount</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li> */}
                        </ul>

                        <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">
                          บัญชี
                        </div>
                        <ul className="list-inline mb-4">
                          {/* <li className="list-inline-item d-block border-bottom me-0"><Link to="/payment" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-mini-gradiant text-white feather-credit-card font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">My Cards</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li> */}
                          <li className="list-inline-item d-block  me-0">
                            <Link
                              to="/password"
                              className="pt-2 pb-2 d-flex align-items-center"
                            >
                              <i className="btn-round-md bg-blue-gradiant text-white feather-inbox font-md me-3"></i>{" "}
                              <h4 className="fw-600 font-xsss mb-0 mt-0">
                                รหัสผ่าน
                              </h4>
                              <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </Link>
                          </li>
                        </ul>

                        <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">
                          อื่นๆ
                        </div>
                        <ul className="list-inline">
                          <li className="list-inline-item d-block border-bottom me-0">
                            <Link
                              to="/defaultnoti"
                              className="pt-2 pb-2 d-flex align-items-center"
                            >
                              <i className="btn-round-md bg-gold-gradiant text-white feather-bell font-md me-3"></i>{" "}
                              <h4 className="fw-600 font-xsss mb-0 mt-0">
                                {/* Notification */}
                                การแจ้งเตือน
                              </h4>
                              <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </Link>
                          </li>
                          <li className="list-inline-item d-block border-bottom me-0">
                            <a
                              href="/helpbox"
                              className="pt-2 pb-2 d-flex align-items-center"
                            >
                              <i className="btn-round-md bg-primary-gradiant text-white feather-help-circle font-md me-3"></i>{" "}
                              <h4 className="fw-600 font-xsss mb-0 mt-0">
                                {/* Help */}
                                ช่วยเหลือ
                              </h4>
                              <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </a>
                          </li>
                          <li className="list-inline-item d-block me-0">
                            <a
                              href="/"
                              onClick={this.logoutClick}
                              className="pt-2 pb-2 d-flex align-items-center"
                            >
                              <i className="btn-round-md bg-red-gradiant text-white feather-lock font-md me-3"></i>
                              <h4 className="fw-600 font-xsss mb-0 mt-0">
                                {/* Logout */}
                                ออกจากระบบ
                              </h4>
                              <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Popupchat />
        <Appfooter />
      </Fragment>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {
    user_detail: state.user.user_detail || {},
  };
};

const Settings = connect(mapStateToProps)(SettingsComponent);
export default Settings;
