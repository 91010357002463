import React, { Component, Fragment } from "react";
// import Chart from "react-apexcharts";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';

class Appnews extends Component {
    constructor(props) {
        super(props);

        this.state = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            series: [{
                name: '',
                data: [35, 66, 34, 56, 18, 35, 66, 34, 56, 18, 56, 18]
            }, {
                name: '',
                data: [12, 34, 12, 11, 7, 12, 34, 12, 11, 7, 11, 7]
            }],
            options: {
                chart: {
                    type: 'bar',
                    //   width:'100%',
                    height: 250,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        horizontal: false,
                    },
                },
                legend: {
                    show: false
                },
                fill: {
                    opacity: 1
                },
            },

        };
    }
    render() {
        return (
            <Fragment>
                <Header />
                <Leftnav />

                <div className="main-content bg-white right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card w-100 border-0 shadow-none p-2 rounded-xxl bg-lightblue2 mb-3">
                                        <div className="row">
                                            <div className="col-lg-12 ps-lg-6">
                                                <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">ประวัติความเป็นมา</h2>
                                                <p className="font-xsss fw-500 text-grey-600 lh-26">
                                                    บริษัท สลากทูยู จำกัด ได้ก่อตั้งแต่ปี พ.ศ.2564 ซึ่งมีความสนใจในเรื่องการจำหน่ายสลากกินแบ่งรัฐบาลและการบริการ
                                                    เริ่มเห็นความสำคัญของระบบ e commerce โดยเฉพาะอย่างยิ่ง การจำหน่ายสลากกินแบ่งรัฐบาลบนระบบ e commerce
                                                    เราจึงเริ่มหาเครื่องมือเพื่อพัฒนาและตอบสนองความต้องการของตลาด ซึ่งในขณะนั้นยังคงเป็นเรื่องยาก ซับซ้อน และลงทุนสูงมาก
                                                    </p><br />
                                                    <p className="font-xsss fw-500 text-grey-600 lh-26">
                                                    เราจะเป็นผู้ประสานความร่วมมือจากกลุ่มผู้จำหน่ายสลากกินแบ่งรัฐบาลที่มีเป้าหมายและความสนใจในการจำหน่ายสลากกินแบ่งรัฐบาลจากทั่วประเทศ
                                                    เพื่อให้บรรลุเป้าหมายและร่วมพัฒนาไปพร้อมๆกัน เพื่อให้ผู้ซื้อสลากกินแบ่งรัฐบาลได้รับประโยชน์และบริการอย่างสูงสุด
                                                    </p><br />
                                                    <p className="font-xsss fw-500 text-grey-600 lh-26">
                                                    ชื่อบริษัท : บริษัท สลากทูยู จำกัด <br />
                                                    ทุนจดทะเบียน : ทุนจดทะเบียน 1,000,000 บาท <br />
                                                    วันที่ก่อตั้ง : 3 ธันวาคม 2564 <br />
                                                    เลขที่จดทะเบียน : 0115564028073  <br />
                                                    กรรมการบริษัท : นายคเณศ สังข์ชม 
                                                    </p><br />
                                                    <p className="font-xsss fw-500 text-grey-600 lh-26">
                                                    บริษัท สลากทูยู จำกัด เจ้าของ เว็บไซด์ mahachonlottery.com ซึ่งเป็นบริษัทเอกชน ผู้ให้บริการจัดหา จำหน่าย เก็บรักษา
                                                    และขึ้นรางวัลสลากกินแบ่งรัฐบาล เพื่ออำนวยความสะดวกให้กับผู้ซื้อ(ลูกค้า) ซึ่งทางบริษัท ขอรับรองว่าสลากกินแบ่งรัฐบาลที่จัดจำหน่ายโดย
                                                    เว็บไซด์ มหาชน ลอตเตอรี่ มีฉบับจริงทุกฉบับ ซึ่งผ่านการบริการ จัดหา และ แสกนสู่ระบบคอมพิวเตอร์ ซึ่งทำการเก็บรักษาไว้ในระบบ Cloud
                                                    ซึ่งในขณะเดียวกันสลากกินแบ่งรัฐบาลฉบับจริง ได้ถูกเก็บรักษาไว้โดยโดยบริษัท สลากทูยู จำกัด 
                                                </p>
                                                <br />
                                                <div className="col-lg-6">
                                                <img src={`/assets/images/p1.jpeg`} alt="banner" className="w-100" />
                                            </div>
                                            <br />
                                            <div className="col-lg-6 ">
                                                <img src={`/assets/images/p2.jpeg`} alt="banner" className="w-100" />
                                            </div>
                                            <br />
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popupchat />
                <Appfooter />
            </Fragment>
        );
    }
}

export default Appnews;