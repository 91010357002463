import axios from "axios";
import { API } from "../actions/actionTypes";
import actionUtils from "../actions/actionUtils";
import { apiStart, apiEnd, apiError } from "../actions/api";
import { showHideLoading } from "../actions/display";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  getDealerDetailData,
  getDealerProfile,
  getDealerSession,
} from "../actions/dealer";
import qs from "qs";
// import cookie from "cookiejs";
import cookie from "react-cookies";
import { ServerInfo, ServerInfo_Code } from "../constants/config";
import { apiActionWithToken } from "../actions/actions";

const dealerMiddleware =
  ({ dispatch, getState, setState }) =>
  (next) =>
  (action) => {
    next(action);

    const { search } = window.location;
    const queryString = search.replace("?", "");

    const obj = qs.parse(queryString);
    const dealer_ref = cookie.load("dealer_ref");
    const dealerCode = obj.dealer ?? dealer_ref;
    if (
      dealerCode &&
      dealerCode.startsWith("d") &&
      !getState().dealer.dealer_ref
    ) {
      const dealerId = dealerCode.replace("d", "") - 0;
      cookie.save("dealer_ref", dealerCode, {
        path: "/",
        expires: new Date(Date.now() + 10 * 365 * 24 * 60 * 60 * 1000),
        secure: true,
      });
      dispatch({
        type: "DEALER_REF",
        value: dealerCode,
      });
      dispatch(getDealerDetailData(dealerCode, "code", true));
      // dispatch(getDealerProfile(dealerCode, true));
      // console.log('current cookies', cookie.load("dealer_ref"));
    }

    if (getState().user.user_detail.is_dealer) {
      const dealer_ref = cookie.load("dealer_ref");
      const loaded = cookie.load("dealer_ref_loaded");
      if (
        !loaded &&
        getState().user.user_detail.dealer_id !==
          getState().dealer.dealer_ref_detail?.id
      ) {
        cookie.save("dealer_ref_loaded", true);
        dispatch(
          getDealerDetailData(getState().user.user_detail.dealer_id, "id", true)
        );
      }
    }
  };

export default dealerMiddleware;
