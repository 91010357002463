import React, { Component, Fragment, useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import moment from "moment-timezone";
import "moment/locale/th";
import { toBuddhistYear } from "../libs/buddhist-year";

import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import { getPaymentListData, uploadPaymentSlip } from "../actions/payment";
import PopupPayment from "../components/PopupPayment";
import Lottery from "../components/Lottery";
import PaymentQR from "../components/PaymentQR";

const CustomerPaymentComponent = (props) => {
  const { payment_list, payment_qr_data } = props;

  moment.locale("th");

  const dispatch = useDispatch();

  const [popup, setPopup] = useState({
    is_show: false,
    data: null,
    type: "",
  });

  const [isShow, setIsShow] = useState(false);

  const initialPage = () => {
    dispatch(getPaymentListData({ status: "P" }));
  };

  const handleViewLotto = (index) => {
    let ele = document.getElementsByClassName(`lotto-pane-${index}`)[0];
    if (ele.classList.contains("hide")) {
      ele.classList.remove("hide");
      ele.classList.add("active");
    } else {
      ele.classList.add("hide");
      ele.classList.remove("active");
    }
  };

  const handleViewLottoTable = (index) => {
    let ele = document.getElementsByClassName(`lotto-pane-table-${index}`)[0];
    if (ele.classList.contains("hide")) {
      ele.classList.remove("hide");
      ele.classList.add("active");
    } else {
      ele.classList.add("hide");
      ele.classList.remove("active");
    }
  };

  const handleAddSlip = (data) => {
    console.log("handleAddSlip");
    setPopup({
      is_show: true,
      data: data,
    });
  };

  const handlePopupAddSlipClose = () => {
    console.log("handlePopupAddSlipClose");
    setPopup({
      is_show: false,
      data: null,
    });
  };

  const handlePopupAddSlipSubmit = (data) => {
    console.log("handlePopupAddSlipSubmit");
    dispatch(uploadPaymentSlip(data));
    handlePopupAddSlipClose();
  };

  const handleClose = () => {
    setIsShow(false);
  };

  const handlePopupQR = (data) => {
    const paymentQR = {
      paymentNo: data.paymentNo,
      totalAmount: data.totalAmount,
      qrFileId: data.qrFileId,
      qrExpire: data.qrExpire,
      isForcePay: false,
    };
    dispatch({
      type: "PAYMENT_SET_PAYMENT_QR_DATA",
      value: paymentQR,
    });
  };

  useEffect(() => {
    if (payment_qr_data) {
      setIsShow(true);
    }
  }, [payment_qr_data]);

  useEffect(() => {
    initialPage();
  }, []);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Modal centered backdrop="static" show={isShow}>
        <ModalBody>
          <div style={{ background: "rgba(255, 255, 255, 0.8)" }}>
            <div style={{ textAlign: "right", width: "100%" }}>
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => {
                  handleClose();
                }}
              >
                <span style={{ fontWeight: "bold", color: "black" }}>X</span>
              </button>
            </div>
            <PaymentQR />
          </div>
        </ModalBody>
      </Modal>
      <div className="main-content payment-page">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            {/* CONTENT HERE */}

            <div className="row">
              <div className="col-xl-12 cart-wrapper mb-0">
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="card p-4 bg-cyan rounded-3 shadow-xss bg-pattern border-0 overflow-hidden">
                      <div className="bg-pattern-div"></div>
                      <h2 className="display2-size display2-md-size fw-700 text-white mb-0 mt-0 ">
                        คำสั่งซื้อรอชำระ
                        {/* <span className="fw-700 ls-3 text-grey-200 font-xsssss mt-2 d-block">4 PRODUCT FOUND</span> */}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row payment-list mb-show">
              <div className="col-xl-12 cart-wrapper mb-0">
                {payment_list !== null &&
                  payment_list.map((data, index) => {
                    return (
                      <div key={index} className="row">
                        <div className="col-lg-12 mb-3 ">
                          <div className="card p-3 pb-0 rounded-3 shadow-xs border-0 bg-grey font-xss">
                            <div className="">
                              วันที่:{" "}
                              <strong>
                                {toBuddhistYear(
                                  moment(data.createdDate),
                                  "DD/MM/YYYY HH:mmน."
                                )}
                              </strong>
                            </div>
                            <div className="">
                              หมายเลขคำสั่งซื้อ:{" "}
                              <strong>{data.paymentNo}</strong>
                            </div>
                            <div className="">
                              สถานะ: <strong>{data.statusText}</strong>
                            </div>
                            <div className="">
                              จำนวนเงิน: <strong>{data.totalAmount}</strong> บาท
                            </div>
                            <div className="">
                              จำนวนสลาก: <strong>{data.totalOrder}</strong>
                              <div
                                className={`nav-content-bttn open-font me-3 d-inline-block float-right lotto-view-btn lotto-view-btn-${index} hide`}
                                onClick={() => handleViewLotto(index)}
                              >
                                <i className="feather-zoom-in btn-round-xs"></i>
                                <span>ดูสลาก</span>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`card rounded-3 shadow-xs border-0 bg-grey font-xss lotto-pane lotto-pane-${index} hide`}
                          >
                            {data.items.map((item, indexItem) => {
                              const lottoData = {
                                numberDate: item.paymentDate,
                                numberNo: item.numberNo,
                                numberIds: item.numberIds,
                                agentName: item.agentName,
                                packAmount: item.packAmount,
                                packNum: item.packNum,
                                packId: item.packId,
                                fileIds: item.fileIds,
                                totalNumber: item.totalNumber,
                                totalAmount: item.totalAmount,
                              };
                              return (
                                <div
                                  className="lottery-search-result pt-2 bg-grey"
                                  key={indexItem}
                                >
                                  <Lottery data={lottoData} page="payment" />
                                </div>
                              );
                            })}
                          </div>
                          <div className="card p-3 rounded-3 shadow-xs border-0 bg-grey font-xss">
                            <div className="">
                              {data.paymentType === "QR" && (
                                <button
                                  href="#"
                                  onClick={() => handlePopupQR(data)}
                                  className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block float-right 
                              rounded-xl bg-cyan font-xss fw-700 ls-lg text-white"
                                >
                                  แสดงคิวอาร์
                                </button>
                              )}
                              {/* <button
                                href="#"
                                onClick={() => handleAddSlip(data)}
                                className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block float-right w-100
                                      rounded-xl bg-skype font-xss fw-700 ls-lg text-white"
                              >
                                แจ้งชำระเงิน
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="row payment-list mb-hide">
              <div className="col-lg-12 mb-3">
                <div className="table-content table-responsive">
                  <table className="table text-center">
                    <thead className="bg-greyblue rounded-3">
                      <tr>
                        <th className="border-0 p-4">ลำดับ</th>
                        <th className="border-0 p-4 text-left">
                          หมายเลขคำสั่งซื้อ
                        </th>
                        <th className="border-0 p-4">วันที่ทำรายการ</th>
                        <th className="border-0 p-4">จำนวนสลาก</th>
                        <th className="border-0 p-4">จำนวนเงิน</th>
                        <th className="border-0 p-4">สถานะ</th>
                        {/* <th className="border-0 p-4">&nbsp;</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {payment_list !== null &&
                        payment_list.map((data, index) => {
                          return [
                            <tr key={index} className="data-row">
                              <td className="product-thumbnail ps-0">
                                {index + 1}
                              </td>
                              <td className="product-headline text-left wide-column">
                                <h3>{data.paymentNo}</h3>
                              </td>
                              <td className="product-quantity">
                                {toBuddhistYear(
                                  moment(data.createdDate),
                                  "DD/MM/YYYY HH:mmน."
                                )}
                              </td>
                              <td className="product-quantity">
                                {data.items.reduce(
                                  (a, b) => a + b.totalNumber,
                                  0
                                )}{" "}
                                <br />
                                <div
                                  className={`nav-content-bttn open-font me-3 d-inline-block float-right lotto-view-btn lotto-view-btn-${index} hide`}
                                  onClick={() => handleViewLottoTable(index)}
                                >
                                  <i className="feather-zoom-in btn-round-xs"></i>
                                  <span>ดูสลาก</span>
                                </div>
                              </td>
                              <td className="product-quantity">
                                {data.totalAmount}
                              </td>
                              <td className="product-quantity">
                                {data.statusText}
                                <br />
                                {data.paymentType === "QR" && (
                                  <button
                                    href="#"
                                    onClick={() => handlePopupQR(data)}
                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block float-right 
                                rounded-xl bg-cyan font-xss fw-700 ls-lg text-white"
                                  >
                                    แสดงคิวอาร์
                                  </button>
                                )}
                                {/* <button
                                    href="#"
                                    onClick={() => this.handleAddSlip(data)}
                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block float-right 
                                rounded-xl bg-cyan font-xss fw-700 ls-lg text-white"
                                  >
                                    ชำระเงิน
                                  </button> */}
                              </td>
                              {/* <td className="product-quantity">
                            <button href="#" onClick={() => this.handleAddSlip(data)}
                              className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block float-right 
                                rounded-xl bg-skype font-xss fw-700 ls-lg text-white">แจ้งชำระเงิน</button>
                          </td> */}
                            </tr>,
                            <tr key="-1">
                              <td colSpan="6">
                                <div
                                  className={`lotto-pane lotto-pane-table-${index} hide`}
                                >
                                  {data.items.map((item, indexItem) => {
                                    const lottoData = {
                                      numberDate: item.paymentDate,
                                      numberNo: item.numberNo,
                                      numberIds: item.numberIds,
                                      agentName: item.agentName,
                                      packAmount: item.packAmount,
                                      packNum: item.packNum,
                                      packId: item.packId,
                                      fileIds: item.fileIds,
                                      totalNumber: item.totalNumber,
                                      totalAmount: item.totalAmount,
                                    };
                                    return (
                                      <div
                                        className="lottery-search-result d-inline-block float-left"
                                        key={indexItem}
                                      >
                                        <Lottery
                                          data={lottoData}
                                          page="payment"
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                            </tr>,
                          ];
                        })}
                      {/* <tr>
                      <td className="product-thumbnail text-left ps-0">
                          <img src="https://via.placeholder.com/75x100.png" alt="Product Thumnail" className="w75 rounded-3" />
                      </td>
                      <td className="product-headline text-left wide-column">
                          <h3>
                              <a href="/cart" className="text-grey-900 fw-600 font-xsss">Super skinny blazer</a>
                          </h3>
                      </td>
                      <td className="product-p">
                          <span className="product-price-wrapper">
                              <span className="money text-grey-500 fw-600 font-xsss"><span className="font-xsssss">$</span> 49.00</span>
                          </span>
                      </td>
                      <td className="product-quantity">
                          <div className="quantity">
                              <input type="number" className="quantity-input open-font fw-600" name="qty" id="qty-1" placeholder="1" min="1" /> 
                          <div className="dec qtybutton">-</div><div className="inc qtybutton">+</div></div>
                      </td>
                      <td className="product-total-price">
                          <span className="product-price-wrapper">
                              <span className="money fmont"><strong><span className="font-xsssss">$ </span>49.00</strong></span>
                          </span>
                      </td>
                      <td className="product-remove text-right"><a href="/cart"><i className="ti-trash font-xs text-grey-500"></i></a></td>
                    </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {(payment_list === null || payment_list.length === 0) && (
              <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 text-center">
                ไม่พบคำสั่งซื้อรอชำระ
              </div>
            )}

            {/* CONTENT ABOVE */}
          </div>
        </div>
      </div>
      {/* POPUP HERE */}

      <PopupPayment
        is_show={popup.is_show}
        data={popup.data}
        onClose={handlePopupAddSlipClose}
        onSubmit={handlePopupAddSlipSubmit}
      />

      {/* POPUP ABOVE */}
      <Appfooter />
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    payment_list: state.payment.payment_list || [],
    payment_qr_data: state.payment.payment_qr || null,
  };
};

const CustomerPayment = connect(mapStateToProps)(CustomerPaymentComponent);
export default CustomerPayment;
