import React, { Component, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { showDisplayPopupError } from "../actions/display";
import { getNumberSearchList } from "../actions/number";

const LotterySearchAreaComponent = (props) => {
  const { searchState, setSearchState, dealer_ref_detail, resultPage } = props;

  const dispatch = useDispatch();

  const [dealerId, setDealerId] = useState();

  const [bg_type_3F, setBgType3F] = useState("bg-grey");
  const [bg_type_3E, setBgType3E] = useState("bg-grey");
  const [bg_type_2E, setBgType2E] = useState("bg-grey");
  const [bg_set_1, setBgSet1] = useState("bg-grey");
  const [bg_set_2, setBgSet2] = useState("bg-grey");
  const [bg_set_3, setBgSet3] = useState("bg-grey");
  const [bg_set_4, setBgSet4] = useState("bg-grey");
  const [bg_set_5, setBgSet5] = useState("bg-grey");
  const [digit_1_disable, setDigit1Disable] = useState(false);
  const [digit_2_disable, setDigit2Disable] = useState(false);
  const [digit_3_disable, setDigit3Disable] = useState(false);
  const [digit_4_disable, setDigit4Disable] = useState(false);
  const [digit_5_disable, setDigit5Disable] = useState(false);
  const [digit_6_disable, setDigit6Disable] = useState(false);

  const input_digit_1 = useRef();
  const input_digit_2 = useRef();
  const input_digit_3 = useRef();
  const input_digit_4 = useRef();
  const input_digit_5 = useRef();
  const input_digit_6 = useRef();

  const setQueryData = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("prefill") != null) {
      console.log(queryParams.get("d1"));
      setSearchState({
        ...searchState,
        digit_1: queryParams.get("d1"),
        digit_2: queryParams.get("d2"),
        digit_3: queryParams.get("d3"),
        digit_4: queryParams.get("d4"),
        digit_5: queryParams.get("d5"),
        digit_6: queryParams.get("d6"),
        lotto_type: queryParams.get("type"),
        lotto_set: queryParams.get("set"),
      });
    }
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setSearchState({
      ...searchState,
      [name]: value,
    });
  };

  const handleDigitKeyDown = (event) => {
    if (event.keyCode === 8) {
      event.preventDefault();
      const target = event.target;
      const name = target.name;
      const value = target.value;
      if (value !== "") {
        setSearchState({
          ...searchState,
          [name]: "",
        });
      } else {
        if (name !== "digit_1") {
          console.log("back");
          const curr_digit = parseInt(name.split("digit_")[1]);
          setSearchState({
            ...searchState,
            [`digit_${curr_digit - 1}`]: "",
          });
          autoFocusDigit(name, "back");
          // this.setState(
          //   {
          //     [`digit_${curr_digit - 1}`]: "",
          //   },
          //   function () {
          //     this.autoFocusDigit(name, "back");
          //   }
          // );
        }
      }
    }
  };

  const autoFocusDigit = (digit_name, nextBack) => {
    // console.log("autoFocusDigit", digit_name)
    const curr_digit = parseInt(digit_name.split("digit_")[1]);
    if (nextBack === "next") {
      if (curr_digit < 6) {
        const focus_digit = curr_digit + 1;
        // refs[`input_digit_${focus_digit}`].current?.focus();
        eval(`input_digit_${focus_digit}.current.focus()`);
      }
    } else {
      // back
      if (curr_digit > 1) {
        const focus_digit = curr_digit - 1;
        // refs[`input_digit_${focus_digit}`].current?.focus();
        eval(`input_digit_${focus_digit}.current.focus()`);
      }
    }
  };

  const handleDigitKeyUp = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (value.length > 0) {
      setSearchState({
        ...searchState,
        [name]: value.split("")[0],
      });
      autoFocusDigit(name, "next");
    }
  };

  const handleLottoTypeClick = (event) => {
    const target = event.target;
    const name = target.name;
    const value = name.split("search_type_")[1];
    // console.log("handleLottoTypeClick", value)
    const type = value === searchState.lotto_type ? "none" : value;
    // console.log(value)
    if (value === "3F") {
      setSearchState({
        ...searchState,
        digit_4: "",
        digit_5: "",
        digit_6: "",
        lotto_type: type,
      });
      input_digit_1.current.focus();
    } else if (value === "3E") {
      setSearchState({
        ...searchState,
        digit_1: "",
        digit_2: "",
        digit_3: "",
        lotto_type: type,
      });
      input_digit_4.current.focus();
    } else if (value === "2E") {
      setSearchState({
        ...searchState,
        digit_1: "",
        digit_2: "",
        digit_3: "",
        digit_4: "",
        lotto_type: type,
      });
      input_digit_5.current.focus();
    }
  };

  const handleLottoSetClick = (event) => {
    const target = event.target;
    const name = target.name;
    const value = name.split("search_set_")[1];
    // console.log("handleLottoSetClick", value)
    if (value === searchState.lotto_set) {
      setSearchState({
        ...searchState,
        lotto_set: "1",
      });
    } else {
      setSearchState({
        ...searchState,
        lotto_set: value,
      });
    }
  };

  const handleResetClick = (event) => {
    event.preventDefault();
    setSearchState({
      ...searchState,
      digit_1: "",
      digit_2: "",
      digit_3: "",
      digit_4: "",
      digit_5: "",
      digit_6: "",
    });
  };

  const validateType = () => {
    const { lotto_type, digit_1, digit_2, digit_3, digit_4, digit_5, digit_6 } =
      searchState;
    let errorArray = [];
    if (lotto_type === "3F") {
      if (digit_1 === "" || digit_2 === "" || digit_3 === "") {
        errorArray.push("เลขหน้า 3 ตัว ให้ครบ");
      }
    }
    if (lotto_type === "3E") {
      if (digit_4 === "" || digit_5 === "" || digit_6 === "") {
        errorArray.push("เลขท้าย 3 ตัว ให้ครบ");
      }
    }
    if (lotto_type === "2E") {
      if (digit_5 === "" || digit_6 === "") {
        errorArray.push("เลขท้าย 2 ตัว ให้ครบ");
      }
    }
    if (errorArray.length > 0) {
      return {
        status: false,
        message: `<b>กรุณากรอกข้อมูล</b><br/>- ${errorArray.join("<br/>- ")}`,
      };
    }
    return {
      status: true,
    };
  };

  const validateDigits = () => {
    let errorArray = [];
    const checkDigit = [
      searchState.digit_1,
      searchState.digit_2,
      searchState.digit_3,
      searchState.digit_4,
      searchState.digit_5,
      searchState.digit_6,
    ];
    checkDigit.map((x) => {
      // console.log("x" + x + "x")
      if (x !== "") {
        let isNum = /^\d+$/.test(x);
        if (!isNum) {
          errorArray.push("ใส่ได้แค่ตัวเลข 0-9 เท่านั้น");
        } else {
          if (x < -1 || x > 9) {
            errorArray.push("ใส่ได้แค่ตัวเลข 0-9 เท่านั้น");
          }
        }
      }
      return null;
    });
    let uniqueErrorArray = [...new Set(errorArray)];
    if (uniqueErrorArray.length > 0) {
      return {
        status: false,
        message: `<b>กรุณาตรวจสอบข้อมูล</b><br/>- ${uniqueErrorArray.join(
          "<br/>- "
        )}`,
      };
    }
    return {
      status: true,
    };
  };

  const showErrorPopup = (title, message) => {
    dispatch(
      showDisplayPopupError({
        title: title,
        message: message,
      })
    );
  };

  const validateForm = () => {
    const validatedType = validateType();
    if (!validatedType.status) {
      return validatedType;
    }
    const validatedDigits = validateDigits();
    if (!validatedDigits.status) {
      return validatedDigits;
    }
    return {
      status: true,
    };
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validatedForm = validateForm();
    if (!validatedForm.status) {
      showErrorPopup("Error!!", validatedForm.message);
    } else {
      // console.log("resultPage")
      // console.log("resultPage:", resultPage)
      if (resultPage === "") {
        dispatch(
          getNumberSearchList({
            ...searchState,
            dealerId: dealer_ref_detail.id,
          })
        );
      } else {
        var param = "?prefill=y";
        param += "&d1=" + searchState.digit_1;
        param += "&d2=" + searchState.digit_2;
        param += "&d3=" + searchState.digit_3;
        param += "&d4=" + searchState.digit_4;
        param += "&d5=" + searchState.digit_5;
        param += "&d6=" + searchState.digit_6;
        param += "&type=" + searchState.lotto_type;
        param += "&set=" + searchState.lotto_set;
        window.location.href = "/lottery-search" + param;
        console.log(param);
      }
    }
  };

  useEffect(() => {
    const bg_type_3F =
      searchState.lotto_type === "3F" ? "bg-whatsup" : "bg-grey";
    const bg_type_3E =
      searchState.lotto_type === "3E" ? "bg-whatsup" : "bg-grey";
    const bg_type_2E =
      searchState.lotto_type === "2E" ? "bg-whatsup" : "bg-grey";
    const bg_set_1 = searchState.lotto_set === "1" ? "bg-whatsup" : "bg-grey";
    const bg_set_2 = searchState.lotto_set === "2" ? "bg-whatsup" : "bg-grey";
    const bg_set_3 = searchState.lotto_set === "3" ? "bg-whatsup" : "bg-grey";
    const bg_set_4 = searchState.lotto_set === "4" ? "bg-whatsup" : "bg-grey";
    const bg_set_5 = searchState.lotto_set === "5" ? "bg-whatsup" : "bg-grey";
    const digit_1_disable =
      searchState.lotto_type !== "3F" && searchState.lotto_type !== "none";
    const digit_2_disable =
      searchState.lotto_type !== "3F" && searchState.lotto_type !== "none";
    const digit_3_disable =
      searchState.lotto_type !== "3F" && searchState.lotto_type !== "none";
    const digit_4_disable =
      searchState.lotto_type !== "3E" && searchState.lotto_type !== "none";
    const digit_5_disable =
      searchState.lotto_type !== "3E" &&
      searchState.lotto_type !== "2E" &&
      searchState.lotto_type !== "none";
    const digit_6_disable =
      searchState.lotto_type !== "3E" &&
      searchState.lotto_type !== "2E" &&
      searchState.lotto_type !== "none";

    setBgType3F(bg_type_3F);
    setBgType3E(bg_type_3E);
    setBgType2E(bg_type_2E);
    setBgSet1(bg_set_1);
    setBgSet2(bg_set_2);
    setBgSet3(bg_set_3);
    setBgSet4(bg_set_4);
    setBgSet5(bg_set_5);
    setDigit1Disable(digit_1_disable);
    setDigit2Disable(digit_2_disable);
    setDigit3Disable(digit_3_disable);
    setDigit4Disable(digit_4_disable);
    setDigit5Disable(digit_5_disable);
    setDigit6Disable(digit_6_disable);
  }, [searchState]);

  useEffect(() => {
    if (dealer_ref_detail?.id) {
      setDealerId(dealer_ref_detail.id);
      setSearchState({
        ...searchState,
        dealer_id: dealer_ref_detail.id,
      });

      //dispatch(
      //  getNumberSearchList({
      //    ...searchState,
      //    dealer_id: dealer_ref_detail.id,
      //  })
      //);
    }
  }, [dealer_ref_detail]);

  useEffect(() => {
    setQueryData();
  }, []);

  return (
    <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
      <div className="row lottery-search-digits">
        <div className="col-sm-2 col-xs-2">
          <div className="form-group lottery-digit">
            <label className="mont-font fw-600 font-xsss mb-2">
              <span>หลัก</span>แสน
            </label>
            <input
              type="number"
              name="digit_1"
              value={searchState.digit_1}
              onChange={handleInputChange}
              onKeyUp={handleDigitKeyUp}
              onKeyDown={handleDigitKeyDown}
              // disabled={this.state.lotto_type === "2E" || this.state.lotto_type === "3E" || this.state.lotto_type === "3F"}
              disabled={digit_1_disable}
              ref={input_digit_1}
              className="bg-none border-3 rounded-3 form-control bg-color-none border-light-md font-lg fw-700"
              maxLength="1"
              min="0"
              max="9"
            />
          </div>
        </div>

        <div className="col-sm-2 col-xs-2">
          <div className="form-group lottery-digit">
            <label className="mont-font fw-600 font-xsss mb-2">
              <span>หลัก</span>หมื่น
            </label>
            <input
              type="number"
              name="digit_2"
              value={searchState.digit_2}
              onChange={handleInputChange}
              onKeyUp={handleDigitKeyUp}
              onKeyDown={handleDigitKeyDown}
              // disabled={this.state.lotto_type === "2E" || this.state.lotto_type === "3E" || this.state.lotto_type === "3F"}
              disabled={digit_2_disable}
              ref={input_digit_2}
              className="bg-none border-3 rounded-3 form-control bg-color-none border-light-md font-lg fw-700"
              maxLength="1"
              min="0"
              max="9"
            />
          </div>
        </div>

        <div className="col-sm-2 col-xs-2">
          <div className="form-group lottery-digit">
            <label className="mont-font fw-600 font-xsss mb-2">
              <span>หลัก</span>พัน
            </label>
            <input
              type="number"
              name="digit_3"
              value={searchState.digit_3}
              onChange={handleInputChange}
              onKeyUp={handleDigitKeyUp}
              onKeyDown={handleDigitKeyDown}
              // disabled={this.state.lotto_type === "2E" || this.state.lotto_type === "3E" || this.state.lotto_type === "3F"}
              disabled={digit_3_disable}
              ref={input_digit_3}
              className="bg-none border-3 rounded-3 form-control bg-color-none border-light-md font-lg fw-700"
              maxLength="1"
              min="0"
              max="9"
            />
          </div>
        </div>

        <div className="col-sm-2 col-xs-2">
          <div className="form-group lottery-digit">
            <label className="mont-font fw-600 font-xsss mb-2">
              <span>หลัก</span>ร้อย
            </label>
            <input
              type="number"
              name="digit_4"
              value={searchState.digit_4}
              onChange={handleInputChange}
              onKeyUp={handleDigitKeyUp}
              onKeyDown={handleDigitKeyDown}
              // disabled={this.state.lotto_type === "2E" || this.state.lotto_type === "3E" || this.state.lotto_type === "3F"}
              disabled={digit_4_disable}
              ref={input_digit_4}
              className="bg-none border-3 rounded-3 form-control bg-color-none border-light-md font-lg fw-700"
              maxLength="1"
              min="0"
              max="9"
            />
          </div>
        </div>

        <div className="col-sm-2 col-xs-2">
          <div className="form-group lottery-digit">
            <label className="mont-font fw-600 font-xsss mb-2">
              <span>หลัก</span>สิบ
            </label>
            <input
              type="number"
              name="digit_5"
              value={searchState.digit_5}
              onChange={handleInputChange}
              onKeyUp={handleDigitKeyUp}
              onKeyDown={handleDigitKeyDown}
              // disabled={this.state.lotto_type === "2E" || this.state.lotto_type === "3E" || this.state.lotto_type === "3F"}
              disabled={digit_5_disable}
              ref={input_digit_5}
              className="bg-none border-3 rounded-3 form-control bg-color-none border-light-md font-lg fw-700"
              maxLength="1"
              min="0"
              max="9"
            />
          </div>
        </div>

        <div className="col-sm-2 col-xs-2">
          <div className="form-group lottery-digit">
            <label className="mont-font fw-600 font-xsss mb-2">
              <span>หลัก</span>หน่วย
            </label>
            <input
              type="number"
              name="digit_6"
              value={searchState.digit_6}
              onChange={handleInputChange}
              onKeyUp={handleDigitKeyUp}
              onKeyDown={handleDigitKeyDown}
              // disabled={this.state.lotto_type === "2E" || this.state.lotto_type === "3E" || this.state.lotto_type === "3F"}
              disabled={digit_6_disable}
              ref={input_digit_6}
              className="bg-none border-3 rounded-3 form-control bg-color-none border-light-md font-lg fw-700"
              maxLength="1"
              min="0"
              max="9"
            />
          </div>
        </div>
      </div>

      <div className="row lottery-search-type">
        <div className="col-sm-4 col-xs-4">
          <button
            name="search_type_3F"
            onClick={handleLottoTypeClick}
            //disabled={this.state.lotto_type === "2E" || this.state.lotto_type === "3E"}
            className={`p-2 lh-20 ${bg_type_3F} text-grey-800 text-center font-xss fw-600 ls-1 rounded-xxl`}
          >
            เลขหน้า 3 ตัว
          </button>
        </div>
        <div className="col-sm-4 col-xs-4">
          <button
            name="search_type_3E"
            onClick={handleLottoTypeClick}
            //disabled={this.state.lotto_type === "2E" || this.state.lotto_type === "3F"}
            className={`p-2 lh-20 ${bg_type_3E} text-grey-800 text-center font-xss fw-600 ls-1 rounded-xxl`}
          >
            เลขท้าย 3 ตัว
          </button>
        </div>
        <div className="col-sm-4 col-xs-4">
          <button
            name="search_type_2E"
            onClick={handleLottoTypeClick}
            //disabled={this.state.lotto_type === "3E" || this.state.lotto_type === "3F"}
            className={`p-2 lh-20 ${bg_type_2E} text-grey-800 text-center font-xss fw-600 ls-1 rounded-xxl`}
          >
            เลขท้าย 2 ตัว
          </button>
        </div>
      </div>

      <div className="row lottery-search-set">
        <div className="col-sm-2 text-center col-xs-2 lottery-search-set-label">
          ชุด
        </div>
        <div className="col-sm-2 col-xs-2">
          <button
            name="search_set_1"
            onClick={handleLottoSetClick}
            className={`p-2 lh-20 ${bg_set_1} text-grey-800 text-center font-xss fw-600 ls-1 rounded-xxl`}
          >
            1
          </button>
        </div>
        <div className="col-sm-2 col-xs-2">
          <button
            name="search_set_2"
            onClick={handleLottoSetClick}
            className={`p-2 lh-20 ${bg_set_2} text-grey-800 text-center font-xss fw-600 ls-1 rounded-xxl`}
          >
            2
          </button>
        </div>
        <div className="col-sm-2 col-xs-2">
          <button
            name="search_set_3"
            onClick={handleLottoSetClick}
            className={`p-2 lh-20 ${bg_set_3} text-grey-800 text-center font-xss fw-600 ls-1 rounded-xxl`}
          >
            3
          </button>
        </div>
        <div className="col-sm-2 col-xs-2">
          <button
            name="search_set_4"
            onClick={handleLottoSetClick}
            className={`p-2 lh-20 ${bg_set_4} text-grey-800 text-center font-xss fw-600 ls-1 rounded-xl`}
          >
            4
          </button>
        </div>
        <div className="col-sm-2 col-xs-2">
          <button
            name="search_set_5"
            onClick={handleLottoSetClick}
            className={`p-2 lh-20 ${bg_set_5} text-grey-800 text-center font-xss fw-600 ls-1 rounded-xl`}
          >
            5
          </button>
        </div>
      </div>

      <div className="row lottery-search-button">
        <div className="col-sm-8 col-xs-8 pt-3">
          <a
            href="/#"
            onClick={handleSubmit}
            className="update-cart bg-cyan d-block text-white fw-600 text-uppercase 
              font-xs rounded-3 border-size-md  p-3 text-center ls-3"
          >
            ค้นหา<span>ลอตเตอรี่</span>
          </a>
        </div>
        <div className="col-sm-4 col-xs-4 pt-3">
          <a
            href="/#"
            onClick={handleResetClick}
            className="update-cart bg-white d-block text-cyan fw-600 text-uppercase 
              font-xs rounded-3 border-size-md  p-3 text-center ls-3 border-success border"
          >
            ลบ
            {/* <span>ตัวเลข</span> */}
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state, props) {
  return {
    dealer_ref_detail: state.dealer.dealer_ref_detail || null,
  };
};

const LotterySearchArea = connect(mapStateToProps)(LotterySearchAreaComponent);
export default LotterySearchArea;
