
const defaultState = {
  popup: {
    is_show: false,
    title: "",
    message: "",
    submit_button_text: "",
    cancel_button_text: "",
    submit_function: null,
    cancel_function: null,
    close_function: null,
    is_show_submit_button: false,
    is_show_cancel_button: false,
    status: "success", //success, fail, warning
  },
  loading: {
    is_show: false,
  },
  popup_image: {
    is_show: false,
    image_url: "",
    image_name: "",
  },
  popup_slip_check: {
    is_show: false,
    image_url: "",
    image_name: "",
  },
  popup_verify_check: {
    is_show: false,
    image_url: "",
    lotto_data: "",
    lotto_reload: false,
    // image_name: "",
    // data: "",
    // data_list: ""
  },
  popup_qr: {
    is_show: false,
    image_url: "",
    qr_title: "",
    qr_label: "",
  },
  popup_buynow: {
    is_show: false,
  },
}

const display = (state = defaultState, action = "") => {
  switch (action.type) {
    case 'DISPLAY_SET_POPUP':
      return {
        ...state,
        popup: action.value
      }
    case 'DISPLAY_SET_LOADING_IS_SHOW':
      return {
        ...state,
        loading: {
          is_show: action.value
        }
      }

    /////////
    case 'DISPLAY_SET_POPUP_IMAGE':
      return {
        ...state,
        popup_image: action.value
      }
    case 'DISPLAY_SET_POPUP_IMAGE_ISSHOW':
      return {
        ...state,
        popup_image: {
          ...state.popup_image,
          is_show: action.value
        }
      }
    case 'DISPLAY_SET_POPUP_IMAGE_URL':
      return {
        ...state,
        popup_image: {
          ...state.popup_image,
          image_url: action.value
        }
      }
    case 'DISPLAY_SET_POPUP_IMAGE_NAME':
      return {
        ...state,
        popup_image: {
          ...state.popup_image,
          image_name: action.value
        }
      }
    /////////
    case 'DISPLAY_SET_POPUP_SLIPCHECK':
      return {
        ...state,
        popup_slip_check: action.value
      }
    case 'DISPLAY_SET_POPUP_SLIPCHECK_ISSHOW':
      return {
        ...state,
        popup_slip_check: {
          ...state.popup_slip_check,
          is_show: action.value
        }
      }
    case 'DISPLAY_SET_POPUP_SLIPCHECK_URL':
      return {
        ...state,
        popup_slip_check: {
          ...state.popup_slip_check,
          image_url: action.value
        }
      }
    /////////
    case 'DISPLAY_SET_POPUP_STOCKCHECK':
      return {
        ...state,
        popup_verify_check: action.value
      }
    case 'DISPLAY_SET_POPUP_STOCKCHECK_ISSHOW':
      return {
        ...state,
        popup_verify_check: {
          ...state.popup_verify_check,
          is_show: action.value
        }
      }
    case 'DISPLAY_SET_POPUP_STOCKCHECK_URL':
      return {
        ...state,
        popup_verify_check: {
          ...state.popup_verify_check,
          image_url: action.value
        }
      }
    case 'DISPLAY_SET_POPUP_STOCKCHECK_LOTTO_DATA':
      return {
        ...state,
        popup_verify_check: {
          ...state.popup_verify_check,
          lotto_data: action.value
        }
      }
    case 'DISPLAY_SET_POPUP_STOCKCHECK_LOTTO_RELOAD':
      return {
        ...state,
        popup_verify_check: {
          ...state.popup_verify_check,
          lotto_reload: action.value
        }
      }
    /////////
    // case 'DISPLAY_SET_POPUP_IMAGE_NAME':
    //   return {
    //     ...state,
    //     popup_image: {
    //       ...state.popup_image,
    //       image_name: action.value
    //     }
    //   }

    /////////
    case 'DISPLAY_SET_POPUP_QR':
      return {
        ...state,
        popup_qr: action.value
      }
    /////////
    case 'DISPLAY_SET_POPUP_BUYNOW':
      return {
        ...state,
        popup_buynow: action.value
      }
    /////////
    default:
      return state
  }
}

export default display