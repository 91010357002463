import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import PageTitle from "../components/PageTitle";
import "react-datepicker/dist/react-datepicker.css";
import { onGetAgent, setAgentSelected } from "../actions/agent";
import { Link } from "react-router-dom";
import { default as NumberFormat } from "react-number-format";
import { getQRImageAndShow } from "../actions/file";

const statuslist = [
  {
    id: "",
    name: "ดูทั้งหมด",
  },
  {
    id: "1",
    name: "ใช้งาน",
  },
  {
    id: "0",
    name: "ยกเลิก",
  },
];

class Agentpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      name: "",
      registdate: "",
      install_date: "",
      sumdoc: "",
      sumusage: "",
      status: "",
    };
  }

  componentDidMount() {
    this.initialPage();
  }

  initialPage = () => {
    const { dispatch } = this.props;
    dispatch(setAgentSelected({}));
    dispatch(onGetAgent());
  };

  handleInputChange = (event) => {
    console.log("handleInputChange");
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log(name);
    this.setState({
      [name]: value,
    });
    console.log(this.setState);
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    console.log(this.state);
    dispatch(onGetAgent(this.state));
  };

  handleClickSetprice = (data) => {
    // console.log(data);
    window.location = `agent/detail/${data}`;
  };

  handleClickViewQR = (data) => {
    const { dispatch } = this.props;
    dispatch(setAgentSelected(data));
    dispatch(getQRImageAndShow(data, "agent"));
    //data.img = 'agentqr'
  };

  render() {
    const { agent_list } = this.props;
    return (
      <Fragment>
        <Header />
        <Leftnav />
        {/* <Rightchat /> */}

        <div className="main-content">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row lottery-search">
                <div className="col-xl-12">
                  <PageTitle title="ค้นหาผู้ค้าส่งสลาก" />

                  <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                    <h4 className="mont-font fw-600 font-md mb-2 mb-0 mt-0">
                      ค้นหาตัวแทน
                    </h4>
                    <div className="row">
                      <div className="col-sm-3 col-xs-12">
                        <div className="form-group lottery-digit">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            รหัสตัวแทน
                          </label>
                          <input
                            type="text"
                            name="code"
                            value={this.state.code}
                            onChange={this.handleInputChange}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-sm-4 col-xs-6">
                        <div className="form-group lottery-digit">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            ชื่อตัวแทน
                          </label>
                          <input
                            type="text"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleInputChange}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-sm-4 col-xs-6">
                        <div className="form-group lottery-digit">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            สถานะ
                          </label>
                          <select
                            value={this.state.status}
                            onChange={this.handleInputChange}
                            name="status"
                            className="form-control text-grey-900 font-xsss fw-600"
                          >
                            {statuslist.map((data, i) => {
                              return (
                                <option key={i} no={i + 1} value={data.id}>
                                  {data.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-3 col-xs-12">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          &nbsp;
                        </label>
                        <button
                          onClick={this.handleSubmit}
                          className="update-cart bg-cyan d-block text-white fw-600 text-uppercase 
                        font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"
                        >
                          ค้นหาผู้ค้าส่ง
                        </button>
                      </div>

                      <div className="col-sm-3 col-xs-12">
                        <label className="mont-font fw-600 font-xsss mb-2 ">
                          &nbsp;
                        </label>
                        <Link
                          to="/userpage"
                          className="update-cart bg-white d-block text-cyan fw-600 text-uppercase 
                        font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-success border"
                        >
                          เพิ่ม
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="row payment-list mb-show">
                    <div className="col-xl-12 cart-wrapper mb-0">
                      {agent_list != null &&
                        agent_list.map((data, index) => {
                          return (
                            <div key={index} className="row">
                              <div className="col-lg-12 mb-3 ">
                                <div className="card p-3 pb-0 rounded-3 shadow-xs border-0 bg-grey font-xss">
                                  <div className="">
                                    รหัสตัวแทน: <strong>{data.code}</strong>
                                  </div>
                                  <div className="">
                                    ชื่อตัวแทน: <strong>{data.name}</strong>
                                  </div>
                                  <div className="">
                                    วันที่ลงทะเบียน:{" "}
                                    <strong>{data.registdate}</strong>
                                  </div>

                                  <div className="">
                                    งวดวันที่:{" "}
                                    <strong>{data.installDate}</strong>
                                  </div>

                                  <div className="">
                                    จำนวนสลากงวดล่าสุด:
                                    <Link
                                      to={`/agent/number-search?agentCode=${data.code}`}
                                    >
                                      <strong>
                                        <NumberFormat
                                          value={data.sumdoc}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={""}
                                        />
                                      </strong>
                                    </Link>
                                  </div>
                                  <div className="">
                                    จำนวนสลากที่ขายแล้วงวดล่าสุด:
                                    <Link
                                      to={`/agent/number-search?agentCode=${data.code}&numberStatus=S`}
                                    >
                                      <strong>
                                        <NumberFormat
                                          value={data.sumusage}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={""}
                                        />
                                      </strong>
                                    </Link>
                                  </div>
                                  <div className="">
                                    จำนวนสลากคงเหลือ:{" "}
                                    <strong>
                                      {" "}
                                      <NumberFormat
                                        value={data.sumremain}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={""}
                                      />
                                    </strong>
                                  </div>
                                  <div className="">
                                    สถานะ:{" "}
                                    <strong>
                                      {data.status === "1" && (
                                        <span className="text-cyan">
                                          ใช้งาน
                                          <br />
                                        </span>
                                      )}
                                      {data.status !== "1" && (
                                        <span className="text-red">
                                          ยกเลิก
                                          <br />
                                        </span>
                                      )}
                                    </strong>
                                  </div>

                                  <div className="">
                                    <div className="row">
                                      <div className="col-sm-3 col-xs-3">
                                        Action:
                                      </div>
                                      <div className="col-sm-3 col-xs-2 pr-10">
                                        <button
                                          className="nav-content-bttn rounded-3 bg-orange open-font me-3 d-inline-block float-right lotto-view-btn hide w75 
                                          text-center border-0 "
                                        >
                                          <Link
                                            className="text-white"
                                            to={`/agent/price_setup/${data.id}`}
                                          >
                                            <i className="feather-tag btn-round-xs w15"></i>
                                            ตั้งราคา
                                          </Link>
                                        </button>
                                      </div>
                                      <div className="col-sm-3 col-xs-2">
                                        <button
                                          className="nav-content-bttn rounded-3 bg-cyan open-font me-3 d-inline-block float-right lotto-view-btn hide w125 
                                          text-center border-0 "
                                        >
                                          <Link
                                            className="text-white"
                                            to={`/agent/detail/${data.id}`}
                                          >
                                            <i className="feather-zoom-in btn-round-xs w15"></i>
                                            ดูรายละเอียด
                                          </Link>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className="row payment-list mb-hide">
                    <div className="col-lg-12 mb-3">
                      <div className="table-content table-responsive">
                        <table className="table text-center">
                          <thead className="bg-greyblue rounded-3">
                            <tr>
                              <th className="border-0 p-4">ลำดับ</th>
                              <th className="border-0 p-4 text-left">
                                รหัสตัวแทน
                              </th>
                              <th className="border-0 p-4 text-left">
                                ชื่อตัวแทน
                              </th>
                              <th className="border-0 p-4">วันที่ลงทะเบียน</th>
                              <th className="border-0 p-4">งวดวันที่</th>
                              <th className="border-0 p-4">
                                จำนวนสลากงวดล่าสุด
                              </th>
                              <th className="border-0 p-4">
                                จำนวนสลากที่ขายแล้วงวดล่าสุด
                              </th>
                              <th className="border-0 p-4">จำนวนสลากคงเหลือ</th>
                              <th className="border-0 p-4">สถานะ</th>
                              <th className="border-0 p-4">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {agent_list != null &&
                              agent_list.map((data, index) => {
                                const rowClass =
                                  index % 2 === 0 ? "" : "bg-lightblue";
                                return (
                                  <tr
                                    key={index}
                                    className={`data-row ${rowClass}`}
                                  >
                                    <td className="product-thumbnail ps-0">
                                      {index + 1}
                                    </td>
                                    {/* <td >
                                      <div className="row pl-7">
                                        <div className="col-sm-3 col-xs-12" >
                                          <div className={`nav-content-bttn rounded-3 text-white bg-cyan open-font me-3 d-inline-block w90 float-right lotto-view-btn lotto-view-btn-${index} hide`}
                                              onClick={() => this.handleClickViewQR(data.id)} >
                                              <h3>{data.code}</h3>
                                            </div>
                                        </div>
                                      </div>
                                    </td> */}
                                    <td className="product-thumbnail ps-0">
                                      <div
                                        className={`nav-content-bttn rounded-3 text-white bg-cyan open-font me-3 d-inline-block w90 float-right lotto-view-btn lotto-view-btn-${index} hide`}
                                        onClick={() =>
                                          this.handleClickViewQR(data)
                                        }
                                      >
                                        <h3 className={`text-white`}>
                                          {data.code}
                                        </h3>
                                      </div>
                                    </td>
                                    <td className="product-quantity">
                                      {data.name}
                                    </td>
                                    <td className="product-quantity">
                                      {data.registdate}
                                    </td>
                                    <td className="product-quantity">
                                      {data.installDate}
                                    </td>

                                    <td className="product-quantity">
                                      <Link
                                        to={`/agent/number-search?agentCode=${data.code}`}
                                      >
                                        <NumberFormat
                                          value={data.sumdoc}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={""}
                                        />
                                      </Link>
                                    </td>
                                    <td className="product-quantity">
                                      <Link
                                        to={`/agent/number-search?agentCode=${data.code}&numberStatus=S`}
                                      >
                                        <NumberFormat
                                          value={data.sumusage}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={""}
                                        />
                                      </Link>
                                    </td>
                                    <td className="product-quantity">
                                      <NumberFormat
                                        value={data.sumremain}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={""}
                                      />
                                    </td>
                                    <td className="product-quantity mr-15 pl-30">
                                      {data.status === "1" && (
                                        <div className="text-cyan">
                                          ใช้งาน
                                          <br />
                                        </div>
                                      )}
                                      {data.status !== "1" && (
                                        <div className="text-red">
                                          ยกเลิก
                                          <br />
                                        </div>
                                      )}
                                    </td>
                                    {/* <td >
                                      <div className="row pl-7">
                                        <div className="col-sm-3 col-xs-12" >
                                          <div className="nav-content-bttn rounded-3 bg-orange open-font me-3 d-inline-block float-right lotto-view-btn hide w75">
                                            <Link className="text-white" to={`/agent/price_setup/${data.id}`} >
                                              <i className="feather-tag btn-round-xs w15"></i>ตั้งราคา</Link>
                                          </div>
                                        </div>
                                        <div className="col-sm-9 col-xs-12" >
                                          <div className="nav-content-bttn rounded-3 w125 bg-cyan open-font me-3 d-inline-block float-right lotto-view-btn hide w100">
                                            <Link className="text-white" to={`/agent/detail/${data.id}`} >
                                              <i className="feather-zoom-in btn-round-xs w15"></i>ดูรายละเอียด</Link>
                                          </div>
                                        </div>
                                      </div>
                                    </td> */}

                                    <td>
                                      <div className="nav-content-bttn rounded-3 bg-orange open-font me-3 d-inline-block float-right lotto-view-btn hide w125 mb-3">
                                        <Link
                                          className="text-white"
                                          to={`/agent/price_setup/${data.id}`}
                                        >
                                          <i className="feather-tag btn-round-xs w15"></i>
                                          ตั้งราคา
                                        </Link>
                                      </div>
                                      <div className="nav-content-bttn rounded-3 bg-cyan open-font me-3 d-inline-block float-right lotto-view-btn hide w125 ">
                                        <Link
                                          className="text-white"
                                          to={`/agent/detail/${data.id}`}
                                        >
                                          <i className="feather-zoom-in btn-round-xs w15"></i>
                                          ดูรายละเอียด
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* CONTENT ABOVE */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        {/* <Appfooter />  */}
      </Fragment>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {
    agent_list: state.agent.agent_list || [],
  };
};

const Agentlist = connect(mapStateToProps)(Agentpage);
export default Agentlist;
