const defaultState = {
  report: {
    winner_list: false,
    dealer_profit_list: false,
  },
};

const user = (state = defaultState, action = "") => {
  switch (action.type) {
    case "REPORT_WINNER_LIST_DATA":
      return {
        ...state,
        winner_list: action.value,
      };

    case "REPORT_DEALER_PROFIT_LIST_DATA":
      return {
        ...state,
        dealer_profit_list: action.value,
      };

    default:
      return state;
  }
};

export default user;
