import React,{ Component } from 'react';
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { 
  setDisplayPopupData,
} from '../actions/display'

class PopupMessageComponent extends Component {

  close = () => {
    const { dispatch } = this.props
    dispatch(setDisplayPopupData({
      is_show: false,
      title: "",
      message: "",
      submit_button_text: "",
      cancel_button_text: "", 
      submit_function: null,
      cancel_function: null,
      close_function: null,
      is_show_submit_button: false,
      is_show_cancel_button: false,
    }))
  }

  handleSubmitButton = () => {
    const { submit_function } = this.props
    if(submit_function !== null){
      submit_function()
    }
    else{
      this.close()
    }
  }

  handleCancelButton = () => {
    const { cancel_function } = this.props
    if(cancel_function !== null){
      cancel_function()
    }
    else{
      this.close()
    }
  }

  renderSubmitButton = () => {
    const { 
      is_show_submit_button,
      submit_button_text
    } = this.props
    if(!is_show_submit_button){
      return ""
    }
    return <div className="col-sm-8 col-xs-7 pt-3 pe-3 d-inline-block">
        <button className="button is-success w-100" onClick={() => this.handleSubmitButton()}>{submit_button_text}</button>
        {/* <Button variant="primary" onClick={() => this.handleSubmitButton()}>{submit_button_text}</Button> */}
      </div>
  }

  renderCancelButton = () => {
    const { 
      is_show_cancel_button,
      cancel_button_text
    } = this.props
    if(!is_show_cancel_button){
      return ""
    }
    return <div className="col-sm-4 col-xs-5 pt-3 d-inline-block">
        <button className="button is-danger w-100" onClick={() => this.handleCancelButton()}>{cancel_button_text}</button>
        {/* <Button variant="warning" onClick={() => this.handleCancelButton()}>{cancel_button_text}</Button> */}
      </div>
  }

  renderButtonSection = () => {
    // const renderButtonSectionPadding = (buttonCount) => {
    //   if(buttonCount === 1) {
    //     return <div className="column is-one-third"></div>
    //   }
    //   return <div className="column is-one-quarter"></div>
    // }
    const { 
      is_show_submit_button,
      is_show_cancel_button,
    } = this.props
    let buttonCount = 0
    buttonCount += (is_show_submit_button) ? 1 : 0
    buttonCount += (is_show_cancel_button) ? 1 : 0
    if(buttonCount === 0){
      return ""
    }
    return <div className="pt-2">
        {/* {
          renderButtonSectionPadding(buttonCount)
        } */}
        {
          this.renderSubmitButton()
        }
        {
          this.renderCancelButton()
        }
        {/* {
          renderButtonSectionPadding(buttonCount)
        } */}
    </div>
  }

  render() {
    const { 
      is_show, title, message,
      status
    } = this.props
    let statusClass = ""
    switch (status) {
      case "confirm":
        statusClass = "is-info"; break;
      case "success":
        statusClass = "is-success"; break;
      case "error":
        statusClass = "is-danger"; break;
      case "notfound":
        statusClass = "is-warning"; break;
      default:
        break;
    }
    return (
      <Modal centered backdrop="static" show={is_show} >
        <article className={`message ${statusClass}`}>
          <div className="message-header">
            <p>{title}</p>
            {/* <button className="delete" aria-label="delete"></button> */}
          </div>
          <div className="message-body">
            <span dangerouslySetInnerHTML={{__html: message}} />
            {
              this.renderButtonSection()
            }
          </div>
        </article>
      </Modal>
    );
  }
}


const mapStateToProps = function(state, props){
  return {
    is_show: state.display.popup.is_show || false,
    title: state.display.popup.title || "",
    message: state.display.popup.message || "",
    submit_button_text: state.display.popup.submit_button_text || "",
    cancel_button_text: state.display.popup.cancel_button_text || "",
    submit_function: state.display.popup.submit_function || null,
    cancel_function: state.display.popup.cancel_function || null,
    is_show_submit_button: state.display.popup.is_show_submit_button || false,
    is_show_cancel_button: state.display.popup.is_show_cancel_button || false,
    status: state.display.popup.status || "success",
  }
}

const PopupMessage = connect(mapStateToProps)(PopupMessageComponent)
export default PopupMessage;