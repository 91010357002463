import React, { Component } from 'react';
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import { Modal } from 'react-bootstrap'
import { showDisplayPopupError } from '../actions/display'
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";

class PopupPaymentComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      paymentNo: "",
      file_name: "No file uploaded",
      file: null,
      refno: "",
      refdate: "",
      refdate_input: "",
      amount: "",
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  close = () => {
    const { onClose } = this.props
    //Reset data
    this.setState({
      paymentNo: "",
      file_name: "No file uploaded",
      file: null,
      refno: "",
      refdate: "",
      refdate_input: "",
      amount: "",
    })
    onClose()
  }

  handleInputChange = event => {
    console.log("handleInputChange")
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleInputDateChange = (date, inputValueName, inputName) => {
    console.log("handleInputDateChange", date)
    const dateFormatted = moment(date).format('yyyy-MM-DDTHH:mm');
    // refdate: "2021-08-10T00:59" 
    this.setState({
      [inputValueName]: dateFormatted,
      [inputName]: date
    });
  }

  handleFileChange = (e) => {
    console.log("handleFileChange")
    if (e.target.files.length > 0) {
      const file = e.target.files[0]
      const fileName = file.name
      this.setState({
        file_name: fileName,
        file: file
      })
    }
  }

  handleSubmit = () => {
    const { data, onSubmit } = this.props
    console.log(this.state)
    //TODO Validate value before submit to backend
    const validate = this.validateForm()
    if (!validate.status) {
      this.showErrorPopup("Error!!", validate.message)
    }
    else {
      const dataSubmit = {
        paymentNo: data.paymentNo,
        file_name: this.state.file_name,
        file: this.state.file,
        refno: this.state.refno,
        refdate: this.state.refdate,
        amount: this.state.amount,
      }
      onSubmit(dataSubmit)
    }
  }

  validateForm = () => {
    const validateFormRequiredField = this.validateFormRequiredField()
    if (!validateFormRequiredField.status) {
      return validateFormRequiredField
    }
    // const validateFormPaymentDate = this.validateFormPaymentDate()
    // if(!validateFormPaymentDate.status){
    //   return validateFormPaymentDate
    // }
    return {
      "status": true,
    }
  }

  // validateFormPaymentDate = () => {
  //   // const nowDate = moment() //.format('YYYY-MM-DDThh:mm');
  //   // const dateCheck = this.state.refdate
  //   // const dateCheckDate = moment(dateCheck)
  //   // console.log(nowDate)
  //   // console.log(dateCheckDate)
  //   // 2021-08-06T02:47
  //   // return { 
  //   //   "status": false,
  //   //   "message": `<b>วัน-เวลาที่ชำระไม่ถูกต้อง</b>`
  //   // }
  //   return { 
  //     "status": true,
  //   }
  // }
// refdate: "2021-08-10T00:59" 
  validateFormRequiredField = () => {
    let errorArray = [];
    if (this.state.file_name === "No file uploaded") {
      errorArray.push("Attach slip");
    }
    if (this.state.refno === "") {
      errorArray.push("เลขที่อ้างอิง");
    }
    if (this.state.refdate === "") {
      errorArray.push("วัน-เวลาที่ชำระ");
    }
    if (this.state.amount === "") {
      errorArray.push("จำนวนเงิน");
    }
    if (errorArray.length > 0) {
      return {
        "status": false,
        "message": `<b>กรุณากรอกข้อมูล</b><br/>- ${errorArray.join("<br/>- ")}`
      }
    }
    return {
      "status": true,
    }
  }

  showErrorPopup = (title, message) => {
    const { dispatch } = this.props
    dispatch(showDisplayPopupError({
      "title": title,
      "message": message
    }))
  }

  render() {
    const {
      is_show, data,
    } = this.props
    // const nowDate = new Date().toISOString().slice(0, 10);  //2017-06-30T16:30
    // var isoString = new Date().toLocaleString();//.toISOString()
    // const nowDate = isoString.substring(0, (isoString.indexOf("T")|0) + 6|0);
    // const nowDate = moment().format('MMMM Do YYYY, h:mm:ss a');
    // const nowDate = moment().format('YYYY-MM-DDThh:mm');
    // console.log("nowDate", nowDate)
    // console.log("isoString", isoString)

    // maxTime={setHours(setMinutes(new Date(), 30), 20)}
    // console.log("isoString", setHours(setMinutes(new Date(), 30), 20))
    const filterPassedTime = (time) => {
      const currentDate = new Date();
      const selectedDate = new Date(time);
  
      return currentDate.getTime() > selectedDate.getTime();
    };

    return (
      // <Modal centered backdrop="static" show={is_show} >

      // </Modal>
      <Modal centered backdrop="static" show={is_show} >
        <article className={`message is-info`}>
          <div className="message-header">
            <p>แจ้งชำระเงิน</p>
          </div>
          <div className="message-body">
            <h3>Payment No: {data !== null ? data.paymentNo : ""}</h3>
            <h3>จำนวนที่ต้องชำระ: {data !== null ? data.totalAmount : ""} บาท</h3>
            <div className="col-lg-12 col-sm-12 pt-3">

              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="form-group">
                    <label className="mont-font fw-600 font-xsss mb-2">Attach slip</label>
                    <input type="file" className="form-control" onChange={this.handleFileChange} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="form-group">
                    <label className="mont-font fw-600 font-xsss mb-2">เลขที่อ้างอิง</label>
                    <input type="text" name="refno" value={this.state.refno} onChange={this.handleInputChange} className="form-control" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="form-group">
                    <label className="mont-font fw-600 font-xsss mb-2">วัน-เวลาที่ชำระ</label>
                    {/* <input type="datetime-local" name="refdate" value={this.state.refdate} onChange={this.handleInputChange}
                      max={nowDate}
                      className="form-control" /> */}

                    <DatePicker selected={this.state.refdate_input} closeOnScroll={true} showTimeSelect
                            dateFormat="dd/MM/yyyy h:mm aa"
                      maxDate={new Date()} 
                      // minTime="00:00" maxTime="5:30"
                      filterTime={filterPassedTime}
                      // maxTime={new Date()}
                      className="form-control"
                      showPopperArrow={false}
                      placeholderText="dd/MM/yyyy h:mm aa"  timeIntervals={1}
                      // dateFormat="MMMM d, yyyy h:mm aa"
                      onChange={(date) => this.handleInputDateChange(date, "refdate", "refdate_input")} />

                    <input type="hidden" id="timezone" name="timezone" value="+07:00" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="form-group">
                    <label className="mont-font fw-600 font-xsss mb-2">จำนวนเงิน</label>
                    <input type="number" min="0" name="amount" value={this.state.amount} onChange={this.handleInputChange} className="form-control" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-8 col-xs-7 pt-3">
                  <button className="button is-success w-100" onClick={() => this.handleSubmit()}>แจ้งชำระเงิน</button>
                  {/* <button onClick={this.handleSubmit} className="rounded-3 bg-current p-2 w-100 fw-600 fw-700 border-0
                  text-center font-xs mont-font text-uppercase ls-3 text-white d-block">แจ้งชำระเงิน</button> */}
                </div>
                <div className="col-sm-4 col-xs-5 pt-3">
                  <button className="button is-danger w-100" onClick={() => this.close()}>ปิด</button>
                  {/* <button onClick={() => this.close()} className="rounded-3 bg-red-gradiant p-2 w-100 fw-600 fw-700 border-0
                  text-center font-xs mont-font text-uppercase ls-3 text-white d-block">ปิด</button> */}
                </div>
              </div>
            </div>
          </div>

          {/* <Alert className="lottery-search-result" show={is_show} variant={variant}>
          <h1>แจ้งชำระเงิน</h1>
          <h3>Payment No: {data !== null ? data.paymentNo: ""}</h3>
          <h3>จำนวนที่ต้องชำระ: {data !== null ? data.totalAmount: ""} บาท</h3>
          <div className="col-lg-12 col-sm-12 pt-3">
            
            <div className="row">
              <div className="col-lg-12 mb-3">
                <div className="form-group">
                  <label className="mont-font fw-600 font-xsss mb-2">Attach slip</label>
                  <input type="file" className="form-control" onChange={this.handleFileChange} />
                </div>        
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 mb-3">
                <div className="form-group">
                  <label className="mont-font fw-600 font-xsss mb-2">เลขที่อ้างอิง</label>
                  <input type="text" name="refno" value={this.state.refno} onChange={this.handleInputChange} className="form-control" />
                </div>        
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 mb-3">
                <div className="form-group">
                  <label className="mont-font fw-600 font-xsss mb-2">วัน-เวลาที่ชำระ</label>
                  <input type="datetime-local" name="refdate" value={this.state.refdate} onChange={this.handleInputChange} 
                    max={nowDate}
                    className="form-control" />
                  <input type="hidden" id="timezone" name="timezone" value="+07:00" />
                </div>        
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 mb-3">
                <div className="form-group">
                  <label className="mont-font fw-600 font-xsss mb-2">จำนวนเงิน</label>
                  <input type="number" min="0" name="amount" value={this.state.amount} onChange={this.handleInputChange} className="form-control" />
                </div>        
              </div>
            </div>

            <div className="row">
              <div className="col-sm-8 col-xs-7 pt-3">
                <button onClick={this.handleSubmit} className="rounded-3 bg-current p-2 w-100 fw-600 fw-700 border-0
                  text-center font-xs mont-font text-uppercase ls-3 text-white d-block">แจ้งชำระเงิน</button>
              </div>
              <div className="col-sm-4 col-xs-5 pt-3">
                <button onClick={() => this.close()} className="rounded-3 bg-red-gradiant p-2 w-100 fw-600 fw-700 border-0
                  text-center font-xs mont-font text-uppercase ls-3 text-white d-block">ปิด</button>
              </div>
            </div>
          </div>
          
        </Alert> */}
        </article>
      </Modal>
    );
  }
}


const mapStateToProps = function (state, props) {
  return {
    // is_show: state.display.popup.is_show || false,
    // title: state.display.popup.title || "",
    // message: state.display.popup.message || "",
    // submit_button_text: state.display.popup.submit_button_text || "",
    // cancel_button_text: state.display.popup.cancel_button_text || "",
    // submit_function: state.display.popup.submit_function || null,
    // is_show_submit_button: state.display.popup.is_show_submit_button || false,
    // is_show_cancel_button: state.display.popup.is_show_cancel_button || false,
    // status: state.display.popup.status || "success",
  }
}

const PopupPayment = connect(mapStateToProps)(PopupPaymentComponent)
export default PopupPayment;