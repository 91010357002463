import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";

import { getCustomerData, setCustomerData } from "../actions/customer";
import { getBankAccountList } from "../actions/bank";
import { showDisplayPopupError } from "../actions/display";
import { CustomerProfileUpdateRequestDto } from "../dto/CustomerDTO";

const defaultFormData = {
  telephoneno: "",
  firstname: "",
  lastname: "",
  email: "",
  address: "",
  bankAccount: "",
  bankAccountName: "",
  bankId: "",
  bankCode: "",
  bankName: "",
};

const CustomerProfileComponent = (props) => {
  const { user_detail, customer_data, bank_list } = props;

  const dispatch = useDispatch();

  const [formData, setFormData] = useState(defaultFormData);

  const initialPage = () => {
    dispatch(getCustomerData(user_detail.user_id));
    dispatch(getBankAccountList());
  };

  const initFormData = (customer_data) => {
    setFormData({
      telephoneno: customer_data.telephoneno,
      firstname: customer_data.firstname,
      lastname: customer_data.lastname,
      email:
        customer_data.email === null || customer_data.email === undefined
          ? ""
          : customer_data.email,
      address:
        customer_data.address === null || customer_data.address === undefined
          ? ""
          : customer_data.address,
      bankId:
        customer_data.bankId === null || customer_data.bankId === undefined
          ? ""
          : customer_data.bankId,
      bankAccount:
        customer_data.bankAccount === null ||
        customer_data.bankAccount === undefined
          ? ""
          : customer_data.bankAccount,
      bankAccountName:
        customer_data.bankAccountName === null ||
        customer_data.bankAccountName === undefined
          ? ""
          : customer_data.bankAccountName,
    });
  };

  const validateWithRegex = (data, regEx) => {
    return regEx.test(data);
  };

  const validateFormFieldFormat = () => {
    let errorArray = [];
    var firstname = formData.firstname;
    var lastname = formData.lastname;
    var email = formData.email;
    var bankAccount = formData.bankAccount;
    var patternNameRegEx = /^[A-Za-zก-๙]+$/;
    var patternEmailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    var patternNumberOnlyRegEx = /^[0-9]+$/;

    // if (!validateWithRegex(firstname, patternNameRegEx)) {
    //   errorArray.push("ชื่อ ต้องเป็นภาษาไทยหรือภาษาอังกฤษเท่านั้น");
    // }
    // if (!validateWithRegex(lastname, patternNameRegEx)) {
    //   errorArray.push("นามสกุล ต้องเป็นภาษาไทยหรือภาษาอังกฤษเท่านั้น");
    // }
    // if (email !== "") {
    //   if (!validateWithRegex(email, patternEmailRegEx)) {
    //     errorArray.push("Email Address ไม่ถูกรูปแบบ");
    //   }
    // }
    if (!validateWithRegex(bankAccount, patternNumberOnlyRegEx)) {
      errorArray.push("เลขที่บัญชี ต้องเป็นตัวเลขเท่านั้น");
    }
    if (errorArray.length > 0) {
      return {
        status: false,
        message: `<b>กรุณากรอกข้อมูลให้ถูกต้อง</b><br/>- ${errorArray.join(
          "<br/>- "
        )}`,
      };
    }
    return {
      status: true,
    };
  };

  const validateFormRequiredField = () => {
    let errorArray = [];
    if (formData.firstname === "") {
      errorArray.push("ชื่อ");
    }
    if (formData.lastname === "") {
      errorArray.push("นามสกุล");
    }
    if (formData.bankId !== "0" && formData.bankAccountName !== undefined) {
      if (
        formData.bankAccountName === "" ||
        formData.bankAccountName === undefined
      ) {
        errorArray.push("ชื่อบัญชี");
      }
      if (formData.bankAccount === "" || formData.bankAccount === undefined) {
        errorArray.push("เลขที่บัญชี");
      }
    }
    if (errorArray.length > 0) {
      return {
        status: false,
        message: `<b>กรุณากรอกข้อมูล</b><br/>- ${errorArray.join("<br/>- ")}`,
      };
    }
    return {
      status: true,
    };
  };

  const validateForm = () => {
    const validateRequired = validateFormRequiredField();
    if (!validateRequired.status) {
      return validateRequired;
    }
    const validateFormFieldFormated = validateFormFieldFormat();
    if (!validateFormFieldFormated.status) {
      return validateFormFieldFormated;
    }
    return {
      status: true,
    };
  };

  const showErrorPopup = (title, message) => {
    dispatch(
      showDisplayPopupError({
        title: title,
        message: message,
      })
    );
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormData({
      ...formData,
      [name]: value,
    });
    // this.setState({
    //   customer_data: {
    //     ...this.state.customer_data,
    //     [name]: value,
    //   },
    // });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //TODO Validate value before submit to backend
    const validated = validateForm();
    if (!validated.status) {
      showErrorPopup("Error!!", validated.message);
    } else {
      const reqData = {
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        address: formData.address,
        bankId: formData.bankId,
        bankAccount: formData.bankAccount,
        bankAccountName: formData.bankAccountName,
      };
      dispatch(setCustomerData(reqData));
    }
  };

  useEffect(() => {
    if (user_detail?.user_id) {
      initialPage();
    }
  }, [user_detail]);

  useEffect(() => {
    if (customer_data) {
      initFormData(customer_data);
    }
  }, [customer_data]);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      {/* <Rightchat />  right-chat-active*/}

      <div className="main-content bg-lightblue theme-dark-bg">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                  <Link to="/defaultsettings" className="d-inline-block mt-2">
                    <i className="ti-arrow-left font-sm text-white"></i>
                  </Link>
                  <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                    โปรไฟล์
                  </h4>
                </div>

                <div className="card-body rounded-0 text-left">
                  <form onSubmit={handleSubmit}>
                    <h4 className="fw-700 display1-size display2-md-size mb-4">
                      ข้อมูลส่วนตัว
                    </h4>

                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            หมายเลขโทรศัพท์
                          </label>
                          <input
                            type="text"
                            value={formData.telephoneno}
                            disabled
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            ชื่อ
                          </label>
                          <input
                            type="text"
                            name="firstname"
                            value={formData.firstname}
                            onChange={handleInputChange}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            นามสกุล
                          </label>
                          <input
                            type="text"
                            name="lastname"
                            value={formData.lastname}
                            onChange={handleInputChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            อีเมล (ในกรณีที่ไม่มีอีเมลไม่จำเป็นต้องกรอก)
                          </label>
                          <input
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            ที่อยู่
                          </label>
                          <input
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="form-group icon-input mb-3 error">
                      <i className="font-sm ti-mobile text-grey-500 pe-0"></i>
                      <input type="text" name="custtelephoneno" value={this.state.customer_data.telephoneno} disabled
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="หมายเลขโทรศัพท์" />
                  </div>
                  <div className="form-group icon-input mb-3">
                      <i className="font-sm ti-user text-grey-500 pe-0"></i>
                      <input type="text" name="custfirstname" value={this.state.customer_data.firstname} onChange={this.handleInputChange}
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="ชื่อ" />
                  </div>
                  <div className="form-group icon-input mb-3">
                      <i className="font-sm ti-user text-grey-500 pe-0"></i>
                      <input type="text" name="custlastname" value={this.state.customer_data.lastname} onChange={this.handleInputChange}
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="นามสกุล" />
                  </div> */}
                    {/* <div className="form-group icon-input mb-3">
                      <i className="font-sm ti-email text-grey-500 pe-0"></i>
                      <input type="text" name="custemail" value={this.state.customer_data.email} onChange={this.handleInputChange}
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="Email Address" />
                  </div>
                  <div className="form-group icon-input mb-3">
                      <i className="font-sm ti-email text-grey-500 pe-0"></i>
                      <input type="text" name="custaddress" value={this.state.customer_data.address} onChange={this.handleInputChange}
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="ที่อยู่" />
                  </div>  */}

                    <h4 className="fw-700 display1-size display2-md-size mb-4">
                      ข้อมูลบัญชีธนาคาร
                    </h4>

                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            ธนาคาร
                          </label>
                          <select
                            value={formData.bankId}
                            onChange={handleInputChange}
                            name="bankId"
                            className="style2-select form-control text-grey-900 font-xsss fw-600"
                          >
                            <option key="0" value="0">
                              -- กรุณาเลือก --
                            </option>
                            {bank_list.map((data, i) => {
                              return (
                                <option key={i} no={i + 1} value={data.bankId}>
                                  {data.bankName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            ชื่อบัญชี
                          </label>
                          <input
                            type="text"
                            name="bankAccountName"
                            value={formData.bankAccountName}
                            onChange={handleInputChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            เลขที่บัญชี
                          </label>
                          <input
                            type="text"
                            name="bankAccount"
                            value={formData.bankAccount}
                            onChange={handleInputChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        {/* <a href="/accountinformation" 
                        className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">บันทึก</a> */}
                        <input
                          type="submit"
                          className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 "
                          value="บันทึก"
                        />
                      </div>
                    </div>

                    {/* <div className="form-group icon-input mb-3">
                      <i className="font-sm ti-user text-grey-500 pe-0"></i>
                      <select value={this.state.customer_data.bankId}
                        className="style2-select ps-5 form-control text-grey-900 font-xsss fw-600" >
                        {
                          bank_list.map((data, i) => {
                            return <option key={i} no={i+1} value={data.bankId} >{data.bankName}</option>
                          })
                        }
                      </select>
                  </div>
                  <div className="form-group icon-input mb-3">
                      <i className="font-sm ti-user text-grey-500 pe-0"></i>
                      <input type="text" name="custlastname" value={this.state.customer_data.bankAccount} onChange={this.handleInputChange}
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="เลขที่บัญชี" />
                  </div>
                  <div className="form-group icon-input mb-3">
                      <i className="font-sm ti-user text-grey-500 pe-0"></i>
                      <input type="text" name="custlastname" value={this.state.customer_data.bankAccountName} onChange={this.handleInputChange}
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="ชื่อบัญชี" />
                  </div> */}
                  </form>
                </div>

                {/* <div className="card-body p-lg-5 p-4 w-100 border-0 ">

                <div className="row justify-content-center">
                    <div className="col-lg-4 text-center">
                        <figure className="avatar ms-auto me-auto mb-0 mt-2 w100"><img src="https://via.placeholder.com/300x300.png" alt="avater" className="shadow-sm rounded-3 w-100" /></figure>
                        <h2 className="fw-700 font-sm text-grey-900 mt-3">Surfiya Zakir</h2>
                        <h4 className="text-grey-500 fw-500 mb-3 font-xsss mb-4">Brooklyn</h4>    
                        
                    </div>
                </div>

                <form action="#">
                    <div className="row">
                        <div className="col-lg-6 mb-3">
                            <div className="form-group">
                                <label className="mont-font fw-600 font-xsss mb-2">First Name</label>
                                <input type="text" className="form-control" />
                            </div>        
                        </div>

                    <div className="col-lg-6 mb-3">
                            <div className="form-group">
                                <label className="mont-font fw-600 font-xsss mb-2">Last Name</label>
                                <input type="text" className="form-control" />
                            </div>        
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 mb-3">
                            <div className="form-group">
                                <label className="mont-font fw-600 font-xsss mb-2">Email</label>
                                <input type="text" className="form-control" />
                            </div>        
                        </div>

                        <div className="col-lg-6 mb-3">
                            <div className="form-group">
                                <label className="mont-font fw-600 font-xsss mb-2">Phone</label>
                                <input type="text" className="form-control" />
                            </div>        
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 mb-3">
                            <div className="form-group">
                                <label className="mont-font fw-600 font-xsss mb-2">Country</label>
                                <input type="text" className="form-control" />
                            </div>        
                        </div>

                        <div className="col-lg-12 mb-3">
                            <div className="form-group">
                                <label className="mont-font fw-600 font-xsss mb-2">Address</label>
                                <input type="text" className="form-control" />
                            </div>        
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 mb-3">
                            <div className="form-group">
                                <label className="mont-font fw-600 font-xsss mb-2">Twon / City</label>
                                <input type="text" className="form-control" />
                            </div>        
                        </div>

                        <div className="col-lg-6 mb-3">
                            <div className="form-group">
                                <label className="mont-font fw-600 font-xsss mb-2">Postcode</label>
                                <input type="text" className="form-control" />
                            </div>        
                        </div>

                        <div className="col-lg-12 mb-3">
                            <div className="card mt-3 border-0">
                                <div className="card-body d-flex justify-content-between align-items-end p-0">
                                    <div className="form-group mb-0 w-100">
                                        <input type="file" name="file" id="file" className="input-file" />
                                        <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                        <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                        <span className="js-fileName">Drag and drop or click to replace</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 mb-3">
                            <label className="mont-font fw-600 font-xsss mb-2 text-dark">Description</label>
                            <textarea className="form-control mb-0 p-3 h100 bg-greylight lh-16" rows="5" placeholder="Write your message..." ></textarea>
                        </div>

                        <div className="col-lg-12">
                            <a href="/accountinformation" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">Save</a>
                        </div>
                    </div>

                </form>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    customer_data: state.customer.customer_data || {},
    bank_list: state.bank.bank_list || {},
    user_detail: state.user.user_detail || null,
  };
};

const CustomerProfile = connect(mapStateToProps)(CustomerProfileComponent);
export default CustomerProfile;
