import React, { Component, Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Lottery from "../components/Lottery";
import { showDisplayPopupError } from "../actions/display";
import { getNumberSearchList, resetNumberSearchList } from "../actions/number";
import { addToCart, buyNow, buyNowForcePay } from "../actions/cart";
import PopupLottery from "../components/PopupLottery";
import { initAnonymousToken } from "../actions/authen";
import SectionTitle from "../components/SectionTitle";
import LotterySearchArea from "../components/LotterySearch";
import PopupBuyNow from "../components/PopupBuyNow";
import cookie from "react-cookies";
import { Button, Spinner, Modal, ModalBody } from "react-bootstrap";
import PaymentQR from "../components/PaymentQR";
import moment from "moment";
import "moment/locale/th";
import { Link } from "react-router-dom";
import { toBuddhistYear } from "../libs/buddhist-year";

const LotterySearchComponent = (props) => {
  moment.locale("th");
  const {
    dealer_ref_detail,
    user_detail,
    payment_qr_data,
    search_result,
    current_installment,
  } = props;

  const dispatch = useDispatch();

  const [searchState, setSearchState] = useState({
    digit_1: "",
    digit_2: "",
    digit_3: "",
    digit_4: "",
    digit_5: "",
    digit_6: "",
    lotto_type: "none",
    lotto_set: "0",
  });

  const [popup, setPopup] = useState({
    is_show: false,
    data: null,
    type: "",
  });

  const [popupBuynow, setPopupBuynow] = useState({
    is_show: false,
    data: null,
  });

  const [isShow, setIsShow] = useState(false);

  const validateType = () => {
    const {
      digit_1,
      digit_2,
      digit_3,
      digit_4,
      digit_5,
      digit_6,
      lotto_type,
      lotto_set,
    } = searchState;
    let errorArray = [];
    if (lotto_type === "3F") {
      if (digit_1 === "" || digit_2 === "" || digit_3 === "") {
        errorArray.push("เลขหน้า 3 ตัว ให้ครบ");
      }
    }
    if (lotto_type === "3E") {
      if (digit_4 === "" || digit_5 === "" || digit_6 === "") {
        errorArray.push("เลขท้าย 3 ตัว ให้ครบ");
      }
    }
    if (lotto_type === "2E") {
      if (digit_5 === "" || digit_6 === "") {
        errorArray.push("เลขท้าย 2 ตัว ให้ครบ");
      }
    }
    if (errorArray.length > 0) {
      return {
        status: false,
        message: `<b>กรุณากรอกข้อมูล</b><br/>- ${errorArray.join("<br/>- ")}`,
      };
    }
    return {
      status: true,
    };
  };

  const validateDigits = () => {
    const { digit_1, digit_2, digit_3, digit_4, digit_5, digit_6 } =
      searchState;
    let errorArray = [];
    const checkDigit = [digit_1, digit_2, digit_3, digit_4, digit_5, digit_6];
    checkDigit.map((x) => {
      if (x !== "") {
        let isNum = /^\d+$/.test(x);
        if (!isNum) {
          errorArray.push("ใส่ได้แค่ตัวเลข 0-9 เท่านั้น");
        } else {
          if (x < -1 || x > 9) {
            errorArray.push("ใส่ได้แค่ตัวเลข 0-9 เท่านั้น");
          }
        }
      }
      return null;
    });
    let uniqueErrorArray = [...new Set(errorArray)];
    if (uniqueErrorArray.length > 0) {
      return {
        status: false,
        message: `<b>กรุณาตรวจสอบข้อมูล</b><br/>- ${uniqueErrorArray.join(
          "<br/>- "
        )}`,
      };
    }
    return {
      status: true,
    };
  };

  const validateForm = () => {
    const validateTyped = validateType();
    if (!validateTyped.status) {
      return validateTyped;
    }
    const validatedDigits = validateDigits();
    if (!validatedDigits.status) {
      return validatedDigits;
    }
    return {
      status: true,
    };
  };

  const showErrorPopup = (title, message) => {
    dispatch(
      showDisplayPopupError({
        title: title,
        message: message,
      })
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(dealer_ref_detail.id);
    const validatedForm = validateForm();
    if (!validatedForm.status) {
      showErrorPopup("Error!!", validatedForm.message);
    } else {
      dispatch(
        getNumberSearchList({
          ...searchState,
          dealer_id: dealer_ref_detail.id,
        })
      );
    }
  };

  const handleAddToCart = (data) => {
    handlePopupLotterySubmit(data, "add_to_cart");
    // setPopup({
    //   is_show: true,
    //   data: data,
    //   type: "add_to_cart",
    // });
  };

  const handleBuyNow = (data) => {
    setPopup({
      is_show: true,
      data: data,
      type: "buy_now",
    });
  };

  const handlePopupLotteryClose = () => {
    setPopup({
      is_show: false,
      data: null,
      type: "",
    });
  };

  const handlePopupBuyNowClose = () => {
    setPopupBuynow({
      is_show: false,
      data: null,
    });
  };

  const isLogin = () => {
    const token = cookie.load("APP_AT");
    if (token !== undefined) {
      return true;
    }
    return false;
  };

  const handlePopupLotterySubmit = (data, type) => {
    console.log("handlePopupLotterySubmit", data, type);
    if (type === "add_to_cart") {
      dispatch(addToCart(data, dealer_ref_detail.id));
      handlePopupLotteryClose();
    } else if (type === "buy_now") {
      //CHECK LOGIN OR NOT
      // console.log("this.isLogin", this.isLogin())
      if (isLogin()) {
        dispatch(buyNow(data, dealer_ref_detail.id));
        handlePopupLotteryClose();
        // dispatch(
        //   getNumberSearchList({
        //     ...searchState,
        //     dealer_id: dealer_ref_detail.id,
        //   })
        // );
      } else {
        window.location.href = "/login";
        // this.setState(
        //   {
        //     popup_buynow: {
        //       is_show: true,
        //       data: data,
        //     },
        //   },
        //   function () {
        //     this.handlePopupLotteryClose();
        //   }
        // );
      }
    } else {
      // ERROR
    }
  };

  const handlePopupBuyNowSubmit = (data, total, custData) => {
    dispatch(buyNowForcePay(data, total, custData));
    handlePopupBuyNowClose();
  };

  const handleClose = () => {
    setIsShow(false);
    // this.setState({
    //   isShow: false,
    // });
    dispatch(
      getNumberSearchList({
        ...searchState,
        dealer_id: dealer_ref_detail.id,
      })
    );
  };

  useEffect(() => {
    dispatch({
      type: "NUMBER_SET_SEARCH_CRITERIA",
      value: searchState,
    });
  }, [searchState]);

  useEffect(() => {
    if (payment_qr_data) {
      setIsShow(true);
    }
  }, [payment_qr_data]);

  useEffect(() => {
    dispatch(resetNumberSearchList());
  }, []);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Modal centered backdrop="static" show={isShow}>
        <ModalBody>
          <div style={{ background: "rgba(255, 255, 255, 0.8)" }}>
            <div style={{ textAlign: "right", width: "100%" }}>
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => {
                  handleClose();
                }}
              >
                <span style={{ fontWeight: "bold", color: "black" }}>X</span>
              </button>
            </div>
            <PaymentQR />
          </div>
        </ModalBody>
      </Modal>
      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row lottery-search">
              <div className="col-xl-12">
                {dealer_ref_detail && (
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="card p-4 bg-cyan rounded-3 shadow-xss bg-pattern border-0 overflow-hidden position-relative">
                        <div className="bg-pattern-div"></div>
                        <div className="position-absolute right-15 top-15 w-5 h-5 d-none d-sm-block">
                          <p className="font-xs text-black">
                            <strong>ร้านค้า</strong> :{" "}
                            {dealer_ref_detail.detail_name}
                          </p>
                          <p className="font-xsss text-black">
                            <strong>รหัส</strong> :{" "}
                            {dealer_ref_detail.detail_code}
                          </p>
                          <p className="font-xsss text-black">
                            <strong>ไลน์ไอดี</strong> :{" "}
                            {dealer_ref_detail.detail_lineid}
                          </p>
                          <p className="font-xsss text-black">
                            <strong>เบอร์โทร</strong> :{" "}
                            {dealer_ref_detail.detail_telno}
                          </p>
                        </div>
                        <h2 className="display2-size fw-700 text-white mb-0 mt-0">
                          ซื้อสลาก
                        </h2>

                        <div className="dealer-detail d-block d-sm-none">
                          <span className="font-xsss text-white">
                            <strong className="text-white">ร้านค้า</strong> :{" "}
                            {dealer_ref_detail.detail_name}{" "}
                          </span>
                          <div className="font-xsss text-white">
                            <strong className="text-white">รหัส</strong> :{" "}
                            {dealer_ref_detail.detail_code}
                          </div>
                          <div className="font-xsss text-white">
                            <strong className="text-white">ไลน์ไอดี</strong> :{" "}
                            {dealer_ref_detail.detail_lineid}{" "}
                            <strong className="text-white">เบอร์โทร</strong> :{" "}
                            {dealer_ref_detail.detail_telno}
                          </div>
                        </div>

                        <span className="font-sm text-warning">
                          ประจำงวดวันที่{" "}
                          {current_installment &&
                            toBuddhistYear(
                              moment(current_installment.installmentDate),
                              "D MMMM YYYY"
                            )}
                        </span>
                        <span className="font-xsss text-white">
                          * กรณีถูกรางวัลรับเงินเต็มจำนวนไม่หักค่าธรรมเนียม{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                <LotterySearchArea
                  searchState={searchState}
                  setSearchState={setSearchState}
                  resultPage=""
                />

                <div className="row ps-2 pe-1 lottery-search-result">
                  {search_result != null &&
                    search_result.map((data, index) => {
                      const isCustomerDealer =
                        user_detail.is_dealer && user_detail.is_customer;

                      return (
                        <div
                          key={index}
                          className="col-md-4 col-sm-4 col-xs-6 pe-2 ps-2"
                        >
                          <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2">
                            <Lottery data={data} />
                            <div className="card-body">
                              {/* <h4 className="lotto-agent fw-700 font-xss mt-0 lh-28 mb-0">{data.agentName}</h4> */}
                              {isCustomerDealer && (
                                <span
                                  className="lotto-price font-xs fw-400 mt-0 pe-3 ls-2 lh-32 d-inline-block text-red float-left"
                                  style={{ textDecoration: "line-through" }}
                                >
                                  {data.totalAmount}
                                </span>
                              )}
                              <span className="lotto-price font-lg fw-700 mt-0 pe-3 ls-2 lh-32 d-inline-block text-facebook float-left">
                                {isCustomerDealer
                                  ? data.dealerTotalAmount
                                  : data.totalAmount}{" "}
                                <br />
                                <span className="font-xss">บาท</span>
                              </span>
                              {/* 
                              <div className="clearfix mb-show"></div>
                              <div className="clearfix"></div> */}

                              {/* <div className="clearfix"></div> */}

                              {/* <div className="lotto-buttons mb-hide">
                                <button href="#" onClick={() => this.handleBuyNow(data)}
                                  className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 
                            d-inline-block float-right rounded-xl bg-danger font-xss fw-700 ls-lg text-white">ซื้อทันที</button>
                                <button href="#" onClick={() => this.handleAddToCart(data)}
                                  className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block float-right 
                              rounded-xl bg-skype font-xss fw-700 ls-lg text-white">หยิบลงตะกร้า</button>
                              </div>
                              <div className="lotto-buttons mb-show"> */}
                              {isLogin() && (
                                <div className="lotto-buttons">
                                  <button
                                    href="#"
                                    onClick={() => handleBuyNow(data)}
                                    className="lotto-button-buy mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 
                            d-inline-block float-right rounded-xl bg-cyan font-xss fw-700 ls-lg text-white"
                                  >
                                    ซื้อ
                                  </button>
                                  <button
                                    href="#"
                                    onClick={() => handleAddToCart(data)}
                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block float-right 
                              rounded-xl bg-white font-xss fw-700 ls-lg text-cyan border-success border"
                                  >
                                    ลงตะกร้า
                                  </button>
                                </div>
                              )}
                              {!isLogin() && (
                                <div className="lotto-buttons">
                                  <Link
                                    to="/login"
                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block float-right 
                              rounded-xl bg-white font-xss fw-700 ls-lg text-cyan border-success border"
                                  >
                                    เข้าสู่ระบบ
                                  </Link>
                                </div>
                              )}

                              {/* </div> */}

                              <div className="clearfix"></div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>

                {search_result !== null && search_result.length === 0 && (
                  <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 text-center">
                    ไม่พบข้อมูลที่ค้นหา
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <PopupLottery
        is_show={popup.is_show}
        data={popup.data}
        type={popup.type}
        onClose={handlePopupLotteryClose}
        onSubmit={handlePopupLotterySubmit}
      />
      <PopupBuyNow
        is_show={popupBuynow.is_show}
        data={popupBuynow.data}
        onClose={handlePopupBuyNowClose}
        onSubmit={handlePopupBuyNowSubmit}
      />

      <Appfooter />
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    search_result: state.number.search_result || null,
    payment_qr_data: state.payment.payment_qr || null,
    dealer_ref_detail: state.dealer.dealer_ref_detail || null,
    user_detail: state.user.user_detail || null,
    current_installment: state.number.current_installment || null,
  };
};

const LotterySearch = connect(mapStateToProps)(LotterySearchComponent);
export default LotterySearch;
