import qs from "qs";
import { ServerInfo, Constants, ServerInfo_Code } from "../constants/config";
import Message from "../constants/message";
import { apiAction, apiActionWithToken } from "./actions";
import { showDisplayPopupError } from "./display";
import Session from "./session";
import actionUtils from "./actionUtils";

const CLASSNAME = `actions:authen`;

//Get anonymous token
export const initAnonymousToken = () => {
  // console.log(`[${CLASSNAME}] initAnonymousToken`)
  return async (dispatch) => {
    const userToken = Session.getUserToken();
    if (userToken === undefined || userToken === null) {
      const token = Session.getAnonymousToken();
      // console.log(`[${CLASSNAME}] token`,token)
      if (token === undefined || token === null) {
        //DO GET TOKEN
        const data: LoginRequestDto = {
          username: Constants.anonymous_username,
          password: Constants.anonymous_password,
          grant_type: Constants.grant_type,
        };
        dispatch(
          apiAction({
            url: `${ServerInfo.URL_LOGIN}`,
            method: `POST`,
            data: qs.stringify(data),
            onSuccess: onInitAnonymousTokenResponse,
            onFailure: onLoginFailure,
            label: "APICALLER_CALL_API",
            headersOverride: {
              "content-type": "application/x-www-form-urlencoded;charset=utf-8",
              Authorization: `${ServerInfo.URL_LOGIN_AUTH}`,
            },
            onSuccessFailureParamOverride: {
              SERVICE_CODE: ServerInfo_Code.URL_LOGIN,
            },
          })
        );
      }
    }
  };
};

export const onInitAnonymousTokenResponse = (statusCode, statusText, data) => {
  // console.log(`[${CLASSNAME}] onInitAnonymousTokenResponse statusCode: ${statusCode}`)
  return async (dispatch) => {
    // console.log(data)
    Session.saveUserTokenToMemory(
      {
        accessToken: data.access_token,
        tokenType: data.token_type,
        refreshToken: data.refresh_token,
      },
      true
    );
  };
};

export const onLoginFailure = (
  statusCode,
  statusText,
  data,
  onFailParam = null
) => {
  // console.log(`[${CLASSNAME}] onLoginFailure statusCode: ${statusCode}`)
  return async (dispatch) => {
    //EX. 401 {timestamp: "2021-07-24T20:32:51.151+0000", status: 401, error: "Unauthorized", message: "Unauthorized", path: "/auth/oauth/token"}
    //EX. 400 {"error":"invalid_request","error_description":"Missing grant type"}
    if (statusCode === 401) {
      let title = Message.MessageCommon.errorFound;
      if (onFailParam !== null) {
        let serviceDes = onFailParam.SERVICE_CODE;
        if (onFailParam.SERVICE_ATTR === null) {
          serviceDes = serviceDes + "_N";
        }
        title = title + " [" + serviceDes + "]";
      }
      dispatch(
        showDisplayPopupError({
          title: title,
          message: Message.MessageAuthen.loginFail,
        })
      );
    } else {
      let title = Message.MessageCommon.errorFound;
      if (onFailParam !== null) {
        let serviceDes = onFailParam.SERVICE_CODE;
        if (onFailParam.SERVICE_ATTR === null) {
          serviceDes = serviceDes + "_N";
        }
        title = title + " [" + serviceDes + "]";
      }
      dispatch(
        showDisplayPopupError({
          title: title,
          message: data.error + ": " + data.error_description,
        })
      );
    }
  };
};

//Login
export const login = (data: LoginRequestDto) => {
  // console.log(`[${CLASSNAME}] login`)
  data.grant_type = Constants.grant_type;
  return async (dispatch) => {
    dispatch(
      apiAction({
        url: `${ServerInfo.URL_LOGIN}`,
        method: `POST`,
        data: qs.stringify(data),
        onSuccess: onLoginResponse,
        onFailure: onLoginFailure,
        label: "APICALLER_CALL_API",
        headersOverride: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: `${ServerInfo.URL_LOGIN_AUTH}`,
        },
        onSuccessFailureParamOverride: {
          SERVICE_CODE: ServerInfo_Code.URL_LOGIN,
        },
      })
    );
  };
};

export const onLoginResponse = (statusCode, statusText, data) => {
  // console.log(`[${CLASSNAME}] onLoginResponse statusCode: ${statusCode}`)
  console.log("login data:", data);
  return async (dispatch) => {
    // console.log('login data:', data)
    Session.saveUserTokenToMemory(
      {
        accessToken: data.access_token,
        tokenType: data.token_type,
        refreshToken: data.refresh_token,
      },
      false
    );
    window.location = "/";
  };
};

// Logout
export const logout = () => {
  // console.log(`[${CLASSNAME}] logout`)
  return async (dispatch) => {
    // dispatch(
    //   apiActionWithToken({
    //     url: `${ServerInfo.URL_LOGOUT}`,
    //     method: `GET`,
    //     onSuccess: onLogoutResponse,
    //     onFailure: actionUtils.onCommonFailure,
    //     label: "APICALLER_CALL_API",
    //     onSuccessFailureParamOverride: {
    //       SERVICE_CODE: ServerInfo_Code.URL_LOGOUT,
    //     },
    //   })
    // );
    Session.removeAppWebSession();
    window.location = "/";
  };
};

export const onLogoutResponse = (statusCode, statusText, data) => {
  // console.log(`[${CLASSNAME}] onLoginResponse statusCode: ${statusCode}`)
  // console.log("logout data:", data);
  return async (dispatch) => {
    // console.log('login data:', data)
    Session.removeAppWebSession();
    window.location = "/";
  };
};

// //CHANGE PASSWORD
// export const changePassword = () => {
//   console.log(`[${CLASSNAME}] changePassword`)

// }
