const defaultState = {
  dealer_list: [],
  dealer_packnum: {},
  dealer_minimum_profit: 0,
  dealer_detail: {
    id: "",
    detail: "",
    bank: "",
  },
  dealer_number_list: [],
  dealer_selected: {},
  dealer_ref: null,
  dealer_ref_detail: null,
  dealer_winner: [],
  dealer_pricings: [],
};

const dealer = (state = defaultState, action = "") => {
  switch (action.type) {
    case "DEALER_MINIMUM_PROFIT":
      // console.log('AGENT_SET_AGENT_PACKNUM_DATA')
      return {
        ...state,
        dealer_minimum_profit: action.value,
      };
    case "DEALER_LIST_DATA":
      // console.log("dealer_list", action.value);
      return {
        ...state,
        dealer_list: action.value,
      };
    case "DEALER_SET_DEALER_PACKNUM_DATA":
      // console.log('AGENT_SET_AGENT_PACKNUM_DATA')
      return {
        ...state,
        dealer_packnum: action.value,
      };
    case "DEALER_SET_DEALER_DETAIL_DATA":
      // console.log('AGENT_SET_AGENT_DETAIL_DATA')
      return {
        ...state,
        dealer_detail: action.value,
      };
    case "DEALER_NUMBER_LIST_DATA":
      // console.log('AGENT_NUMBER_LIST_DATA')
      return {
        ...state,
        dealer_number_list: action.value,
      };
    case "DEALER_SET_AGENT_SELECTED":
      // console.log('AGENT_SET_AGENT_SELECTED')
      return {
        ...state,
        dealer_selected: action.value,
      };
    case "DEALER_REF":
      console.log("DEALER_REF", action.value);
      return {
        ...state,
        dealer_ref: action.value,
      };
    case "DEALER_SET_DEALER_REF_DETAIL_DATA":
      // console.log('AGENT_SET_AGENT_DETAIL_DATA')
      return {
        ...state,
        dealer_ref_detail: action.value,
      };
    case "DEALER_WINNER_DATA":
      // console.log('AGENT_SET_AGENT_DETAIL_DATA')
      return {
        ...state,
        dealer_winner: action.value,
      };
    case "DEALER_SET_DEALER_PRICINGS_DATA":
      // console.log('AGENT_SET_AGENT_DETAIL_DATA')
      return {
        ...state,
        dealer_pricings: action.value,
      };
    default:
      return state;
  }
};

export default dealer;
