import React, { Component, Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import { cartSubmit, getCartData, removeFromCart } from "../actions/cart";
import Lottery from "../components/Lottery";
import PageTitle from "../components/PageTitle";
import { Link, redirect } from "react-router-dom";
import { Button, Spinner, Modal, ModalBody } from "react-bootstrap";
import PaymentQR from "../components/PaymentQR";

const CartComponent = (props) => {
  const { payment_qr_data, cart_list, cart_sumtopay } = props;

  const [isShow, setIsShow] = useState(false);

  const dispatch = useDispatch();

  const initialPage = () => {
    dispatch(getCartData());
  };

  const handleRemoveFromCart = (basketId) => {
    console.log("handleRemoveFromCart");
    dispatch(removeFromCart(basketId));
  };

  const handleCartSubmit = () => {
    console.log("handleCartSubmit");
    dispatch(cartSubmit());
  };

  const handleClose = () => {
    setIsShow(false);
    window.location.reload();
  };

  useEffect(() => {
    console.log("payment_qr_data", payment_qr_data);
    if (payment_qr_data) {
      setIsShow(true);
      // console.log('redirect');
      // redirect("/payment-qr");
    }
  }, [payment_qr_data]);

  useEffect(() => {
    initialPage();
  }, []);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Modal centered backdrop="static" show={isShow}>
        <ModalBody>
          <div style={{ background: "rgba(255, 255, 255, 0.8)" }}>
            <div style={{ textAlign: "right", width: "100%" }}>
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => {
                  handleClose();
                }}
              >
                <span style={{ fontWeight: "bold", color: "black" }}>X</span>
              </button>
            </div>
            <PaymentQR />
          </div>
        </ModalBody>
      </Modal>
      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            {/* CONTENT HERE */}

            <div className="row">
              <div className="col-xl-12 cart-wrapper mb-4">
                <PageTitle title="ตะกร้า" />

                <div className="row">
                  <div className="col-sm-12">
                    <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2">
                      {
                        // agentName: "ตัวแทน1"
                        // amount: 700
                        // basketId: 12
                        // basketStatus: "W"
                        // basketStatusText: "GEP001_W"
                        // numberNo: "000002"
                        // orderdate: "01/08/2021 06:21"
                        // packAmount: 350
                        // packId: 2
                        // packNum: 3
                        // total: 2
                        cart_list != null &&
                          cart_list
                            .filter((x) => x.basketStatus === "W")
                            .map((data, index) => {
                              const lottoData = {
                                agentName: data.agentName,
                                packAmount: data.packAmount,
                                packNum: data.packNum,
                                numberId: data.numberNo,
                                packId: data.packId,
                                fileIds: data.fileIds,
                              };
                              return (
                                <div
                                  className="row lottery-search-result mb-3 border-bottom-xs pb-2 border-size-lg border-primary"
                                  key={index}
                                >
                                  <div className="col-md-6 col-sm-6">
                                    <Lottery data={lottoData} />
                                  </div>
                                  <div className="col-md-6 col-sm-6 font-md pt-3">
                                    <div className="">
                                      <strong>ชุด : </strong> {data.packNum}{" "}
                                      <strong>ใบ ราคา : </strong>{" "}
                                      {data.basisAmount} <strong>บาท</strong>
                                    </div>
                                    <div className="">
                                      <strong>ค่าฝากลอตเตอรี่ :</strong>{" "}
                                      {data.feeAmount} <strong>บาท</strong>
                                    </div>
                                    <div className="">
                                      <strong>ราคารวม :</strong>{" "}
                                      {data.totalAmount} <strong>บาท</strong>
                                    </div>
                                    <div className="">
                                      <strong>สถานะ :</strong>{" "}
                                      {data.basketStatusText}
                                    </div>
                                    {data.basketStatus === "W" && (
                                      <div className="col-md-3 col-sm-12">
                                        <button
                                          onClick={() =>
                                            handleRemoveFromCart(data.basketId)
                                          }
                                          className="rounded-3 bg-red-gradiant p-2 w-100 fw-600 fw-700 border-0
                                  text-center font-xsss mont-font text-uppercase ls-3 text-white d-block"
                                        >
                                          ยกเลิก
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })
                      }
                    </div>
                  </div>
                </div>

                {cart_sumtopay !== 0 && (
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="row p-4 rounded-3 bg-greyblue border-0 ">
                        <div className="col-md-6 col-sm-6 font-lg float-left">
                          ราคารวมทั้งหมด <strong>{cart_sumtopay}</strong> บาท
                        </div>
                        <div className="col-md-6 col-sm-6 float-left">
                          <button
                            onClick={handleCartSubmit}
                            className="bg-cyan text-white fw-600 text-uppercase font-xl  border rounded-3 w-100
                          border-size-md d-block p-3 text-center ls-3"
                          >
                            สั่งซื้อ
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {cart_sumtopay === 0 && (
                  <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 text-center">
                    ไม่มีสินค้าในตะกร้า
                    <div>
                      <Link to="/vault">ไปหน้าตู้เซฟ</Link>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* CONTENT ABOVE */}
          </div>
        </div>
      </div>
      {/* POPUP HERE */}

      {/* POPUP ABOVE */}
      <Appfooter />
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    cart_list: state.cart.cart_list || [],
    cart_sumtopay: state.cart.cart_sumtopay || 0,
    payment_qr_data: state.payment.payment_qr || null,
  };
};

const Cart = connect(mapStateToProps)(CartComponent);
export default Cart;
