import React, { Component, Fragment, useEffect } from "react";
import { redirect, useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { getFileByFileIdAndSetStage } from "../actions/file";
import Countdown from "./Countdown";
import { Prompt } from "react-router";
import { checkPaymentStatus } from "../actions/payment";
import { showDisplayPopupMessage } from "../actions/display";
import cookie from "react-cookies";
import download from "downloadjs";

const PaymentQRComponent = (props) => {
  // const { qrFileId } = useParams();

  const { payment_qr_data, payment_qr_file } = props;
  const dispatch = useDispatch();

  const isLogin = () => {
    const token = cookie.load("APP_AT");
    if (token !== undefined) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (payment_qr_data) {
        startRecheckStatus();
        if (payment_qr_data?.qrExpire) {
          if (Date.now() > payment_qr_data?.qrExpire) {
            onTimeout();
            clearInterval(interval);
          }
        }
      }
    }, 1000);
    return () => interval && clearInterval(interval);
  }, []);

  useEffect(() => {
    console.log("payment_qr_data", payment_qr_data);
    if (payment_qr_data?.qrFileId) {
      dispatch(getFileByFileIdAndSetStage(payment_qr_data.qrFileId));
    }
  }, [payment_qr_data]);

  const startRecheckStatus = () => {
    dispatch(checkPaymentStatus(payment_qr_data));
  };

  const onTimeout = () => {
    if (!payment_qr_data.isForcePay) {
      window.location.href = "/order-history";
    } else {
      //SHOW TIME OUT AND goto / home page
      dispatch(
        showDisplayPopupMessage({
          is_show: true,
          title: "หมดเวลา",
          message: "QR หมดเวลา กรุณาทำรายการใหม่อีกครั้ง",
          is_show_submit_button: true,
          is_show_cancel_button: false,
          submit_button_text: "Ok",
          cancel_button_text: null,
          status: "error",
          submit_function: async () => {
            window.location.href = "/";
          },
        })
      );
    }
  };

  return (
    <Fragment>
      <div className="payment-qr-content">
        {/* CONTENT HERE */}

        <h3>
          Payment No:{" "}
          <span>
            {payment_qr_data !== null ? payment_qr_data.paymentNo : ""}
          </span>
        </h3>
        <h3>
          จำนวนที่ต้องชำระ:{" "}
          <span>
            {payment_qr_data !== null ? payment_qr_data.totalAmount : ""} บาท
          </span>
        </h3>
        {/* <Countdown timeTillDate="12 07 2021, 01:00 am" timeFormat="MM DD YYYY, h:mm a" /> */}
        {payment_qr_file?.file_url && (
          <div className="qr-image">
            <h3>กรุณาชำระเงิน ภายใน </h3>
            {/* <Countdown timeTillDate="1638817052000" /> */}
            <Countdown
              timeTillDate={
                payment_qr_data !== null ? payment_qr_data.qrExpire : 0
              }
              onTimeout={onTimeout}
            />
            <img src={payment_qr_file.file_url} alt="" />
            <br />
            {/* <a
              href={payment_qr_file.file_url}
              download={
                payment_qr_data !== null ? payment_qr_data.paymentNo : "Pay QR"
              }
              className="update-cart bg-primary-gradiant d-block text-white fw-600 text-uppercase 
                font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"
            >
              <span>Save QR</span>
            </a> */}
            <button
              className="update-cart bg-primary-gradiant d-block text-white fw-600 text-uppercase 
                font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"
              onClick={() => {
                download(payment_qr_file.file_url);
              }}
            >
              <span>Save QR</span>
            </button>
            <br />
            หรือ Capture หน้าจอจากมือถือ <br />
            เพื่อบันทึก QR ลงอัลบั้มรูป
          </div>
        )}

        {/* CONTENT ABOVE */}
      </div>
      {/* POPUP HERE */}
      {/* POPUP ABOVE */}
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  // const paymentQR = {
  //   // paymentNo: "201200000331",
  //   // totalAmount: 1,
  //   // qrFileId: data.result.qrFileId,
  //   // qrExpire: data.result.qrExpire,
  //   // qrcode: 8817,
  //   // qrexpire: "1638811130139"
  //   // paymentDate: "06/12/2021 03:06",
  //   // totalAmount: 11,
  //   // paymentNo: "201200000331",
  //   // qrExpire: 1640379224518,
  //   // // qrExpire: 1640378563000,
  //   // qrFileId: 13538,
  //   // isForcePay: true,
  //   // telno: "0123456711",
  //   // custFirstname: "s",
  //   // custLastname: "tt"
  // }//paymentQR, //
  return {
    payment_qr_data: state.payment.payment_qr || null, //paymentQR,
    payment_qr_file: state.file.current_file || {},
  };
};

const PaymentQR = connect(mapStateToProps)(PaymentQRComponent);
export default PaymentQR;
