import React, { Component, Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Popupchat from "../components/Popupchat";
import { showDisplayPopupError } from "../actions/display";
import {
  getDealerPricingData,
  setDealerPacknumData,
  getDealerMinimumProfitData,
  saveDealerPricingData,
} from "../actions/dealer";
import { getAgentPacknumData, getAllAgentPricingData } from "../actions/agent";
import PageTitle from "../components/PageTitle";
import { useParams } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import InputNumber from "react-input-number";
import Switch from "react-switch";
import { TbCurrencyBaht, TbPlus, TbTrashX, TbFilePlus } from "react-icons/tb";
import DealerCostTab from "./DealerCostTab";
import { TbArrowBigUpFilled, TbArrowBigDownFilled } from "react-icons/tb";

const DealerPriceSetupFC = (props) => {
  const {
    agent_packnum,
    dealer_pricings,
    dealer_minimum_profit,
    match,
    user_detail,
    current_installment,
    all_agent_pricings,
  } = props;
  const { dealer_id } = useParams();

  const [priceData, setPriceData] = useState([]);
  const [dealerDefaultPriceData, setDealerDefaultPriceData] = useState([]);
  const [dealerInstPriceData, setDealerInstPriceData] = useState([]);
  const [dealerRangePriceData, setDealerRangePriceData] = useState([]);
  const [agentPriceData, setAgentPriceData] = useState(null);

  const dispatch = useDispatch();

  // const fetchDealerPacknumData = (dealer_pricings) => {
  //   setDealerPriceData(dealer_pricings ?? []);
  // };
  const fetchAgentPacknumData = (agent_packnum) => {
    setAgentPriceData(
      (agent_packnum.datas ?? []).sort((a, b) => a.num - b.num)
    );
  };

  const initialPage = () => {
    dispatch(getDealerMinimumProfitData());
    // dispatch(getAgentPacknumData(1));
    dispatch(getAllAgentPricingData());
    dispatch(getDealerPricingData(dealer_id ?? user_detail.dealer_id));
  };

  const extractPriceData = (prices) => {
    const defaultData = prices.find((d) => d.installmentId === 0);
    setDealerDefaultPriceData(
      defaultData?.pricingData.sort((a, b) => a.packNum - b.packNum) || []
    );
    const installmentData = prices.find((d) => d.installmentId !== 0);

    if (installmentData) {
      const instData = installmentData.pricingData.filter(
        (item) => !item.range
      );
      setDealerInstPriceData(
        instData.sort((a, b) => a.packNum - b.packNum) || []
      );
      const rangeData = [];
      const indexMap = {};
      installmentData.pricingData
        .filter((item) => item.range)
        .forEach((item) => {
          item.range = item.range.join(",");
          const range = item.range;
          const indexKeys = Object.keys(indexMap);
          if (!indexKeys.includes(range)) {
            const newIndex = rangeData.length;
            indexMap[range] = newIndex;
            rangeData[newIndex] = [item];
          } else {
            rangeData[indexMap[range]] = [
              ...rangeData[indexMap[range]],
              item,
            ].sort((a, b) => a.packNum - b.packNum);
          }
        });
      // console.log(indexMap);
      setDealerRangePriceData(rangeData || []);
    }
  };

  const getAgentsBoundingPrices = () => {
    return {
      min: 85,
      max: 102,
    };
  };

  const addPrice = (isDefaultPrice = true, isRange = false) => {
    if (isDefaultPrice === true) {
      setDealerDefaultPriceData([
        ...dealerDefaultPriceData,
        {
          packNum: dealerDefaultPriceData.length + 1,
          price_added: 5,
          price_fixed: (dealerInstPriceData.length + 1) * 100,
          price_type: "fixed",
        },
      ]);
    } else if (isRange === true) {
      setDealerRangePriceData([
        ...dealerRangePriceData,
        [
          {
            packNum: 1,
            price_added: 5,
            price_fixed: 100,
            price_type: "added",
            range: null,
          },
        ],
      ]);
    } else {
      setDealerInstPriceData([
        ...dealerInstPriceData,
        {
          packNum: dealerInstPriceData.length + 1,
          price_added: 5,
          price_fixed: (dealerInstPriceData.length + 1) * 100,
          price_type: "added",
        },
      ]);
    }
  };

  const handleInputChange = (event) => {
    console.log("handleInputChange");
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const nameArr = name.split("-");

    if (nameArr.length === 4) {
      const [type, dataName, key, num] = nameArr;
      if (type === "range") {
        const data = [...dealerRangePriceData];
        const tmpData = data[key].find(
          (d) => Number(d.packNum) === Number(num)
        );

        if (tmpData) {
          tmpData[dataName] = value;
          if (dataName === "range") {
            data[key] = data[key].map((obj) => {
              obj.range = value.trim();
              return obj;
            });
          }
          data[key] = [
            ...data[key].filter((d) => Number(d.packNum) !== Number(num)),
            tmpData,
          ].sort((a, b) => a.packNum - b.packNum);
          setDealerRangePriceData(data);
        }
      }
    } else if (nameArr.length === 3) {
      const [type, dataName, num] = nameArr;
      if (type === "installment") {
        const tmpData = dealerInstPriceData.find(
          (d) => Number(d.packNum) === Number(num)
        );
        tmpData[dataName] = value;
        const data = [
          ...dealerInstPriceData.filter(
            (d) => Number(d.packNum) !== Number(num)
          ),
          tmpData,
        ].sort((a, b) => a.packNum - b.packNum);
        setDealerInstPriceData(data);
      } else if (type === "default") {
        const tmpData = dealerDefaultPriceData.find(
          (d) => Number(d.packNum) === Number(num)
        );
        tmpData[dataName] = value;
        const data = [
          ...dealerDefaultPriceData.filter(
            (d) => Number(d.packNum) !== Number(num)
          ),
          tmpData,
        ].sort((a, b) => a.packNum - b.packNum);
        setDealerDefaultPriceData(data);
      }
      // const [type, dataName, num] = nameArr;
      // console.log(nameArr, value);
      // const newPriceData = transformData(num - 0, type, dataName, value);
      // setPriceData(newPriceData);
    }
  };

  const validateFormRequiredField = () => {
    return {
      status: true,
    };
  };

  const validateProfit = () => {
    console.log("validateProfit", priceData.length);
    const validated = priceData.every((pd) => {
      return pd.cost >= dealer_minimum_profit && Number.isInteger(pd.cost);
    });

    if (!validated) {
      return {
        status: false,
      };
    }

    return {
      status: true,
    };
  };

  const validateForm = () => {
    const validated = validateFormRequiredField();
    if (!validated.status || !validateProfit().status) {
      return validated;
    }
    return {
      status: true,
    };
  };

  const inputValidation = (e) => {
    // console.log(e.key, e.keyCode, Number.isInteger(e.key-0))
    const nameArr = e.target.name.split("-");
    const [type, dataName] = nameArr;
    if (
      ![
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
        ...(dataName === "range" ? [","] : []),
      ].includes(e.key) &&
      !Number.isInteger(e.key - 0)
    ) {
      e.stopPropagation();
      e.preventDefault();

      return false;
    }

    return true;
  };

  const convertStateToBodyData = () => {
    const rangeData = dealerRangePriceData
      .reduce((curr, prev) => [...curr, ...prev], [])
      .map((item) => {
        if (typeof item.range === "string")
          item.range = item.range
            .split(",")
            .map((n) => n.trim())
            .filter((n) => n !== "");

        return item;
      });
    const saveData = [
      {
        dealerId: (dealer_id ?? user_detail.dealer_id) - 0,
        installmentId: 0,
        pricingData: dealerDefaultPriceData,
      },
      {
        dealerId: (dealer_id ?? user_detail.dealer_id) - 0,
        installmentId: current_installment.installmentId,
        pricingData: [...dealerInstPriceData, ...rangeData],
      },
    ];

    console.log("saveData", saveData);

    return saveData;
  };

  const showErrorPopup = (title, message) => {
    const { dispatch } = this.props;
    dispatch(
      showDisplayPopupError({
        title: title,
        message: message,
      })
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("test");
    const validated = validateForm();
    if (!validated.status) {
      showErrorPopup("Error!!", validated.message);
    } else {
      //TODO
      const data = convertStateToBodyData();
      dispatch(saveDealerPricingData(data));
    }
  };

  useEffect(() => {
    if (user_detail.is_dealer || user_detail.is_admin) {
      initialPage();
    }
  }, [user_detail]);

  // useEffect(() => {
  //   // fetchDealerPacknumData(dealer_pricings);
  //   fetchAgentPacknumData(agent_packnum);
  //   console.log("agent_packnum", agent_packnum);
  // }, [agent_packnum]);

  // useEffect(() => {
  //   if (dealerPriceData && agentPriceData) {
  //     // mergePrices();
  //   }
  // }, [dealerPriceData, agentPriceData]);

  useEffect(() => {
    if (dealer_pricings) {
      extractPriceData(dealer_pricings);
    }
  }, [dealer_pricings]);

  // useEffect(() => {
  //   console.log("priceData", priceData);
  // }, [priceData]);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      {/* <Rightchat /> */}

      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row lottery-search">
              <div className="col-xl-12">
                <PageTitle title="ตั้งราคาขายร้านค้า" />

                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                  <div className="row">
                    <div className="col-sm-4 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          รหัสร้านค้า:{" "}
                        </label>
                        &nbsp;
                        {/* {dealer_packnum.dealerCode} */}
                      </div>
                    </div>

                    <div className="col-sm-4 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          ชื่อร้านค้า:{" "}
                        </label>
                        &nbsp;
                        {/* {dealer_packnum.dealerName} */}
                      </div>
                    </div>

                    <div className="col-sm-4 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          งวดล่าสุด:{" "}
                        </label>
                        &nbsp;
                        {/* {dealer_packnum.latestInstallmentdate} */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <Tabs>
                      <TabList id="dealer-tab-head">
                        <Tab>ราคาชุด</Tab>
                        <Tab>ราคาเลขท้าย</Tab>
                        <Tab>ราคาขั้นต่ำ</Tab>
                        <Tab>ตารางต้นทุน</Tab>
                      </TabList>

                      <TabPanel>
                        <div className="table-content table-responsive">
                          <h3>ราคาชุด</h3>
                          <p>
                            เป็นราคาเปลี่ยนแปลงจากราคาฐานเพียงชั่วคราว
                            <br />
                            ราคาในส่วนนี้จะอยู่แค่เพียงงวดปัจจุบันเท่านั้น
                          </p>
                          <div className="number-price-container">
                            <div className="number-price-head">
                              <div>ชุด</div>
                              <div>
                                <p>
                                  <TbPlus color="black" size={18} /> = ราคาบวก
                                </p>
                                <p>
                                  <TbCurrencyBaht color="black" size={18} /> =
                                  ราคาขาย
                                </p>
                              </div>
                              <div>ราคา (ต่อชุด)</div>
                              <div>ต้นทุน -&gt; ขาย</div>
                            </div>
                            <div className="number-price-item">
                              {(() => {
                                const loopData = dealerInstPriceData;

                                if (loopData.length === 0) return <></>;

                                return new Array(
                                  loopData[loopData.length - 1].packNum
                                )
                                  .fill()
                                  .map((itemx, indexx) => {
                                    itemx =
                                      loopData.find(
                                        (item) => item.packNum === indexx + 1
                                      ) ?? {};

                                    return (
                                      <div className="number-price-set">
                                        <div>
                                          <span>{itemx.packNum} ใบ</span>
                                        </div>
                                        <div>
                                          <Switch
                                            onChange={(value) =>
                                              handleInputChange({
                                                target: {
                                                  type: "text",
                                                  name: `installment-price_type-${itemx.packNum}`,
                                                  value: value
                                                    ? "added"
                                                    : "fixed",
                                                },
                                              })
                                            }
                                            checked={
                                              itemx.price_type === "added"
                                            }
                                            className="react-switch"
                                            onColor="#080"
                                            offColor="#f2994a"
                                            height={25}
                                            checkedIcon={
                                              <TbPlus
                                                color="white"
                                                size={25}
                                                style={{
                                                  display: "block",
                                                  margin: "auto",
                                                }}
                                              />
                                            }
                                            uncheckedIcon={
                                              <TbCurrencyBaht
                                                color="white"
                                                size={25}
                                                style={{
                                                  display: "block",
                                                  margin: "auto",
                                                }}
                                              />
                                            }
                                          />
                                        </div>
                                        <div>
                                          <InputNumber
                                            className="form-control"
                                            min={5}
                                            step={1}
                                            name={`installment-price_${itemx.price_type}-${itemx.packNum}`}
                                            value={
                                              itemx.price_type === "added"
                                                ? itemx.price_added
                                                : itemx.price_fixed
                                            }
                                            onKeyDown={inputValidation}
                                            onClick={(e) => {
                                              e.target.select();
                                            }}
                                            onChange={(value) =>
                                              handleInputChange({
                                                target: {
                                                  type: "text",
                                                  name: `installment-price_${itemx.price_type}-${itemx.packNum}`,
                                                  value,
                                                },
                                              })
                                            }
                                            enableMobileNumericKeyboard
                                          />
                                        </div>
                                        <div style={{ lineHeight: "12px" }}>
                                          {(() => {
                                            const cost =
                                              all_agent_pricings.find(
                                                (p) =>
                                                  p.range === "" &&
                                                  p.packNum === itemx.packNum
                                              );
                                            if (cost) {
                                              const forcastMinPriceAdded =
                                                cost.minPrice +
                                                itemx.price_added;
                                              const forcastMaxPriceAdded =
                                                cost.maxPrice +
                                                itemx.price_added;
                                              const forcastMinPriceFixed =
                                                cost.minPrice >=
                                                itemx.price_fixed
                                                  ? cost.minPrice +
                                                    5 * itemx.packNum
                                                  : itemx.price_fixed;
                                              const forcastMaxPriceFixed =
                                                cost.maxPrice >=
                                                itemx.price_fixed
                                                  ? cost.maxPrice +
                                                    5 * itemx.packNum
                                                  : itemx.price_fixed;
                                              const minSell =
                                                itemx.price_type === "added"
                                                  ? forcastMinPriceAdded
                                                  : forcastMinPriceFixed;
                                              const maxSell =
                                                itemx.price_type === "added"
                                                  ? forcastMaxPriceAdded
                                                  : forcastMaxPriceFixed;
                                              return (
                                                <>
                                                  <TbArrowBigDownFilled color="red" />
                                                  {cost.minPrice}-&gt;{minSell}
                                                  <br />
                                                  <TbArrowBigUpFilled color="green" />
                                                  {cost.maxPrice}-&gt;{maxSell}
                                                </>
                                              );
                                            } else {
                                              const minSell =
                                                itemx.price_type === "added"
                                                  ? "xxx"
                                                  : itemx.price_fixed;
                                              const maxSell =
                                                itemx.price_type === "added"
                                                  ? "xxx"
                                                  : itemx.price_fixed;
                                              return (
                                                <>
                                                  <TbArrowBigDownFilled color="red" />
                                                  xxx-&gt;{minSell}
                                                  <br />
                                                  <TbArrowBigUpFilled color="green" />
                                                  xxx-&gt;{maxSell}
                                                </>
                                              );
                                            }
                                          })()}
                                        </div>
                                        {itemx.packNum ===
                                          loopData[loopData.length - 1]
                                            .packNum && (
                                          <div
                                            className="position-absolute"
                                            style={{ top: 0, left: 0 }}
                                          >
                                            <button
                                              className="text-red font-sm fw-400 border-0"
                                              style={{ background: "none" }}
                                              onClick={() => {
                                                setDealerInstPriceData(
                                                  dealerInstPriceData.filter(
                                                    (p) =>
                                                      !(
                                                        p.packNum ===
                                                          itemx.packNum &&
                                                        p.installmentId ===
                                                          itemx.installmentId
                                                      )
                                                  )
                                                );
                                              }}
                                            >
                                              <TbTrashX />
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  });
                              })()}
                            </div>
                            <div style={{ marginTop: "25px" }}>
                              <button
                                class="nav-content-bttn rounded-3 bg-cyan open-font me-3 d-inline-block float-right lotto-view-btn hide w125 text-white border-0"
                                onClick={() => {
                                  addPrice(false);
                                }}
                              >
                                <i class="feather-plus btn-round-xs w15"></i>
                                เพิ่มชุดราคา
                              </button>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="table-content table-responsive">
                          <h3>ราคาเลขท้าย</h3>
                          <p>
                            เป็นราคาที่ตั้งเฉพาะเลขท้าย
                            ถ้าทำการขายล๊อตเตอรี่ที่มีเลขท้ายตามนี้
                            ราคาที่ได้จะเป็นไปตามที่กำหนด
                            <br />
                            ราคาในส่วนนี้จะอยู่แค่เพียงงวดปัจจุบันเท่านั้น
                          </p>
                          <div className="number-price-container">
                            <div className="number-price-head">
                              <div>เลขท้าย</div>
                              <div>
                                <p>
                                  <TbPlus color="black" size={18} /> = ราคาบวก
                                </p>
                                <p>
                                  <TbCurrencyBaht color="black" size={18} /> =
                                  ราคาขาย
                                </p>
                              </div>
                              <div>ราคา (ต่อชุด)</div>
                              <div>ต้นทุน -&gt; ขาย</div>
                            </div>
                            <div className="number-price-item">
                              {(() => {
                                const keys = Object.keys(dealerRangePriceData);

                                if (keys.length === 0) return <></>;

                                return keys.map((key) => {
                                  const loopData = dealerRangePriceData[key];

                                  return (
                                    <>
                                      {loopData.map((itemx, indexx) => {
                                        itemx =
                                          loopData.find(
                                            (item) =>
                                              item.packNum === indexx + 1
                                          ) ?? {};
                                        return (
                                          <div className="number-price-set">
                                            <div>
                                              {itemx.packNum === 1 && (
                                                <input
                                                  type="text"
                                                  name={`range-range-${key}-${itemx.packNum}`}
                                                  value={itemx.range}
                                                  className="form-control"
                                                  style={{ fontSize: "2em" }}
                                                  onKeyDown={inputValidation}
                                                  onChange={handleInputChange}
                                                />
                                              )}
                                              {itemx.packNum > 1 && (
                                                <span>{itemx.packNum} ใบ</span>
                                              )}
                                            </div>
                                            <div>
                                              <Switch
                                                onChange={(value) =>
                                                  handleInputChange({
                                                    target: {
                                                      type: "text",
                                                      name: `range-price_type-${key}-${itemx.packNum}`,
                                                      value: value
                                                        ? "added"
                                                        : "fixed",
                                                    },
                                                  })
                                                }
                                                checked={
                                                  itemx.price_type === "added"
                                                }
                                                name={`range-price_type-${key}-${itemx.packNum}`}
                                                className="react-switch"
                                                onColor="#080"
                                                offColor="#f2994a"
                                                height={25}
                                                checkedIcon={
                                                  <TbPlus
                                                    color="white"
                                                    size={25}
                                                    style={{
                                                      display: "block",
                                                      margin: "auto",
                                                    }}
                                                  />
                                                }
                                                uncheckedIcon={
                                                  <TbCurrencyBaht
                                                    color="white"
                                                    size={25}
                                                    style={{
                                                      display: "block",
                                                      margin: "auto",
                                                    }}
                                                  />
                                                }
                                              />
                                            </div>
                                            <div>
                                              <InputNumber
                                                className="form-control"
                                                min={5}
                                                step={1}
                                                name={`range-price_${itemx.price_type}-${key}-${itemx.packNum}`}
                                                value={
                                                  itemx.price_type === "added"
                                                    ? itemx.price_added
                                                    : itemx.price_fixed
                                                }
                                                onKeyDown={inputValidation}
                                                onClick={(e) => {
                                                  e.target.select();
                                                }}
                                                onChange={(value) =>
                                                  handleInputChange({
                                                    target: {
                                                      type: "text",
                                                      name: `range-price_${itemx.price_type}-${key}-${itemx.packNum}`,
                                                      value,
                                                    },
                                                  })
                                                }
                                                enableMobileNumericKeyboard
                                              />
                                            </div>
                                            <div style={{ lineHeight: "12px" }}>
                                              {(() => {
                                                const range = itemx.range;
                                                const rangeArr =
                                                  itemx.range &&
                                                  typeof itemx.range !==
                                                    "object"
                                                    ? itemx.range.split(",")
                                                    : [];
                                                const costs =
                                                  all_agent_pricings.filter(
                                                    (p) =>
                                                      rangeArr.includes(
                                                        p.range
                                                      ) &&
                                                      p.packNum ===
                                                        itemx.packNum
                                                  );
                                                const cost = {
                                                  minPrice: costs.reduce(
                                                    (a, b) => {
                                                      return a < b.minPrice &&
                                                        a > 0
                                                        ? a
                                                        : b.minPrice;
                                                    },
                                                    0
                                                  ),
                                                  maxPrice: costs.reduce(
                                                    (a, b) => {
                                                      return a > b.maxPrice
                                                        ? a.maxPrice
                                                        : b.maxPrice;
                                                    },
                                                    0
                                                  ),
                                                };
                                                if (cost) {
                                                  const forcastMinPriceAdded =
                                                    cost.minPrice +
                                                    itemx.price_added;
                                                  const forcastMaxPriceAdded =
                                                    cost.maxPrice +
                                                    itemx.price_added;
                                                  const forcastMinPriceFixed =
                                                    cost.minPrice >=
                                                    itemx.price_fixed
                                                      ? cost.minPrice +
                                                        5 * itemx.packNum
                                                      : itemx.price_fixed;
                                                  const forcastMaxPriceFixed =
                                                    cost.maxPrice >=
                                                    itemx.price_fixed
                                                      ? cost.maxPrice +
                                                        5 * itemx.packNum
                                                      : itemx.price_fixed;
                                                  const minSell =
                                                    itemx.price_type === "added"
                                                      ? forcastMinPriceAdded
                                                      : forcastMinPriceFixed;
                                                  const maxSell =
                                                    itemx.price_type === "added"
                                                      ? forcastMaxPriceAdded
                                                      : forcastMaxPriceFixed;
                                                  return (
                                                    <>
                                                      <TbArrowBigDownFilled color="red" />
                                                      {cost.minPrice}-&gt;
                                                      {minSell}
                                                      <br />
                                                      <TbArrowBigUpFilled color="green" />
                                                      {cost.maxPrice}-&gt;
                                                      {maxSell}
                                                    </>
                                                  );
                                                }
                                              })()}
                                            </div>
                                            {itemx.packNum ===
                                              loopData[loopData.length - 1]
                                                .packNum && (
                                              <div
                                                className="position-absolute"
                                                style={{ top: 0, left: 0 }}
                                              >
                                                <button
                                                  className="text-red font-sm fw-400 border-0"
                                                  style={{ background: "none" }}
                                                  onClick={() => {
                                                    let dataSet = [
                                                      ...dealerRangePriceData,
                                                    ];
                                                    dataSet[key] = dataSet[
                                                      key
                                                    ].filter(
                                                      (p) =>
                                                        !(
                                                          p.packNum ===
                                                            itemx.packNum &&
                                                          p.installmentId ===
                                                            itemx.installmentId
                                                        )
                                                    );
                                                    setDealerRangePriceData(
                                                      dataSet.filter(
                                                        (d) => d.length > 0
                                                      )
                                                    );
                                                  }}
                                                >
                                                  <TbTrashX />
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        );
                                      })}
                                      <div className="number-price-set">
                                        <div class="add-row">
                                          <button
                                            class="nav-content-bttn rounded-3 bg-cyan open-font me-3 d-inline-block float-right lotto-view-btn hide w125 text-white border-0"
                                            onClick={() => {
                                              const dataSet = [
                                                ...dealerRangePriceData,
                                              ];
                                              dataSet[key] = [
                                                ...dataSet[key],
                                                {
                                                  packNum:
                                                    dataSet[key].length + 1,
                                                  price_added: 5,
                                                  price_fixed:
                                                    (dataSet[key].length + 1) *
                                                    100,
                                                  price_type: "added",
                                                  range: loopData[0].range,
                                                },
                                              ];
                                              setDealerRangePriceData(dataSet);
                                            }}
                                          >
                                            <i class="feather-plus btn-round-xs w15"></i>
                                            {loopData.length + 1} ใบ
                                          </button>
                                        </div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                      </div>
                                      <hr
                                        style={{
                                          height: "2px",
                                          borderWidth: 0,
                                          color: "gray",
                                          backgroundColor: "gray",
                                          marginTop: 0,
                                        }}
                                      />
                                    </>
                                  );
                                });
                              })()}
                            </div>
                            <div style={{ marginTop: "25px" }}>
                              <button
                                class="nav-content-bttn rounded-3 bg-cyan open-font me-3 d-inline-block float-right lotto-view-btn hide w125 text-white border-0"
                                onClick={() => {
                                  addPrice(false, true);
                                }}
                              >
                                <i class="feather-plus btn-round-xs w15"></i>
                                เพิ่มราคาเลขท้าย
                              </button>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="table-content table-responsive">
                          <h3>ราคาขั้นต่ำ</h3>
                          <p>
                            เป็นราคาอ้างอิงกรณีที่ไม่ได้ตั้งราคาประจำงวด หรือ
                            ราคาเลขท้าย
                            <br />
                            ราคาในส่วนนี้จะอยู่ในทุกๆงวด
                            จากการตั้งค่าเพียงครั้งเดียว
                          </p>
                          <div className="number-price-container">
                            <div className="number-price-head">
                              <div>ชุด</div>
                              {/* <div>
                                <p>
                                  <TbPlus color="black" size={18} /> = ราคาบวก
                                </p>
                                <p>
                                  <TbCurrencyBaht color="black" size={18} /> =
                                  ราคาขาย
                                </p>
                              </div> */}
                              <div>ราคา (ต่อชุด)</div>
                            </div>
                            <div className="number-price-item">
                              {(() => {
                                // const loopData =
                                //   extractPriceData(priceData).defaultData;
                                const loopData = dealerDefaultPriceData;

                                if (loopData.length === 0) return <></>;

                                return new Array(
                                  loopData[loopData.length - 1].packNum
                                )
                                  .fill()
                                  .map((itemx, indexx) => {
                                    itemx =
                                      loopData.find(
                                        (item) => item.packNum === indexx + 1
                                      ) ?? {};

                                    return (
                                      <div className="number-price-set">
                                        <div>
                                          <span>{itemx.packNum} ใบ</span>
                                        </div>
                                        {/* <div>
                                          <Switch
                                            onChange={(value) =>
                                              handleInputChange({
                                                target: {
                                                  type: "text",
                                                  name: `default-price_type-${itemx.packNum}`,
                                                  value: value
                                                    ? "added"
                                                    : "fixed",
                                                },
                                              })
                                            }
                                            checked={
                                              itemx.price_type === "added"
                                            }
                                            className="react-switch"
                                            onColor="#080"
                                            offColor="#f2994a"
                                            height={25}
                                            checkedIcon={
                                              <TbPlus
                                                color="white"
                                                size={25}
                                                style={{
                                                  display: "block",
                                                  margin: "auto",
                                                }}
                                              />
                                            }
                                            uncheckedIcon={
                                              <TbCurrencyBaht
                                                color="white"
                                                size={25}
                                                style={{
                                                  display: "block",
                                                  margin: "auto",
                                                }}
                                              />
                                            }
                                          />
                                        </div> */}
                                        <div>
                                          <InputNumber
                                            className="form-control"
                                            min={5}
                                            step={1}
                                            name={`default-price_${itemx.price_type}-${itemx.packNum}`}
                                            value={
                                              itemx.price_type === "added"
                                                ? itemx.price_added
                                                : itemx.price_fixed
                                            }
                                            onKeyDown={inputValidation}
                                            onClick={(e) => {
                                              e.target.select();
                                            }}
                                            onChange={(value) =>
                                              handleInputChange({
                                                target: {
                                                  type: "text",
                                                  name: `default-price_${itemx.price_type}-${itemx.packNum}`,
                                                  value,
                                                },
                                              })
                                            }
                                            enableMobileNumericKeyboard
                                          />
                                        </div>
                                        {itemx.packNum ===
                                          loopData[loopData.length - 1]
                                            .packNum && (
                                          <div
                                            className="position-absolute"
                                            style={{ top: 0, left: 0 }}
                                          >
                                            <button
                                              className="text-red font-sm fw-400 border-0"
                                              style={{ background: "none" }}
                                              onClick={() => {
                                                setDealerDefaultPriceData(
                                                  dealerDefaultPriceData.filter(
                                                    (p) =>
                                                      !(
                                                        p.packNum ===
                                                          itemx.packNum &&
                                                        p.installmentId ===
                                                          itemx.installmentId
                                                      )
                                                  )
                                                );
                                              }}
                                            >
                                              <TbTrashX />
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  });
                              })()}
                            </div>
                            <div style={{ marginTop: "25px" }}>
                              <button
                                class="nav-content-bttn rounded-3 bg-cyan open-font me-3 d-inline-block float-right lotto-view-btn hide w125 text-white border-0"
                                onClick={() => {
                                  addPrice(true);
                                }}
                              >
                                <i class="feather-plus btn-round-xs w15"></i>
                                เพิ่มชุดราคา
                              </button>
                            </div>
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel>
                        {all_agent_pricings && (
                          <DealerCostTab
                            all_agent_pricings={all_agent_pricings}
                          />
                        )}
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    {/* <a href="/accountinformation" 
              className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">บันทึก</a> */}
                    <button
                      className="update-cart d-block bg-cyan text-white fw-600 text-uppercase 
              font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"
                      onClick={handleSubmit}
                    >
                      บันทึก
                    </button>
                    {/* <input type="submit" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 " value="บันทึก" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      {/* <Appfooter />  */}
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    user_detail: state.user.user_detail || {},
    dealer_pricings: state.dealer.dealer_pricings || [],
    dealer_minimum_profit: state.dealer.dealer_minimum_profit || 0,
    agent_packnum: state.agent.agent_packnum || {},
    current_installment: state.number.current_installment || {},
    all_agent_pricings: state.agent.all_agent_pricings || [],
  };
};

const DealerPriceSetup = connect(mapStateToProps)(DealerPriceSetupFC);
export default DealerPriceSetup;
