import React, { Component, Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import PageTitle from "../components/PageTitle";
import "react-datepicker/dist/react-datepicker.css";
import { onGetUserList } from "../actions/userlist";
import { Link } from "react-router-dom";
import { default as NumberFormat } from "react-number-format";

import PopupAdminChangePass from "../components/PopupAdminChangePass";
import PopupCustomerToDealerForm from "../components/PopupCustomerToDealerForm";

const statuslist = [
  {
    id: "",
    name: "ดูทั้งหมด",
  },
  {
    id: "1",
    name: "ใช้งาน",
  },
  {
    id: "0",
    name: "ยกเลิก",
  },
];

const UserListpage = (props) => {
  const { user_list } = props;

  const dispatch = useDispatch();

  const [popup, setPopup] = useState({
    is_show: false,
    data: null,
  });

  const [popupCreateDealer, setPopupCreateDealer] = useState({
    is_show: false,
    data: {},
  });

  const [formData, setFormData] = useState({
    username: "",
    firstname: "",
    rolename: "",
  });

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     popup: {
  //       is_show: false,
  //       data: null,
  //     },
  //     popupCreateDealer: {
  //       is_show: false,
  //       data: null,
  //     },
  //   };
  // }

  const initialPage = () => {
    dispatch(onGetUserList());
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    dispatch(onGetUserList(formData));
  };

  const handlePopupClose = () => {
    setPopup({
      is_show: false,
      data: null,
    });
  };

  const handleCreateDealerPopupClose = () => {
    setPopupCreateDealer({
      is_show: false,
      data: {},
    });
  };

  const handleChangePasswordClick = (data) => {
    setPopup({ is_show: true, data: data });
  };

  const handleCreateDealerClick = (data) => {
    setPopupCreateDealer({ is_show: true, data: data });
  };

  useEffect(() => {
    initialPage();
  }, []);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      {/* <Rightchat /> */}

      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row lottery-search">
              <div className="col-xl-12">
                <PageTitle title="ค้นหา User" />

                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                  <div className="row">
                    <div className="col-sm-3 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          username
                        </label>
                        <input
                          type="text"
                          name="username"
                          value={formData.username}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-sm-4 col-xs-6">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          ชื่อ user
                        </label>
                        <input
                          type="text"
                          name="firstname"
                          value={formData.firstname}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-sm-4 col-xs-6">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          สถานะ
                        </label>
                        <select
                          value={formData.rolename}
                          onChange={handleInputChange}
                          name="status"
                          className="form-control text-grey-900 font-xsss fw-600"
                        >
                          {statuslist.map((data, i) => {
                            return (
                              <option key={i} no={i + 1} value={data.id}>
                                {data.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-3 col-xs-12">
                      <label className="mont-font fw-600 font-xsss mb-2">
                        &nbsp;
                      </label>
                      <button
                        onClick={handleSubmit}
                        className="update-cart bg-cyan d-block text-white fw-600 text-uppercase 
                        font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"
                      >
                        ค้นหา User
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row payment-list mb-show">
                  <div className="col-xl-12 cart-wrapper mb-0">
                    {user_list != null &&
                      user_list.map((data, index) => {
                        return (
                          <div key={index} className="row">
                            <div className="col-lg-12 mb-3 ">
                              <div className="card p-3 pb-0 rounded-3 shadow-xs border-0 bg-grey font-xss">
                                <div className="">
                                  รหัสตัวแทน: <strong>{data.code}</strong>
                                </div>
                                <div className="">
                                  ชื่อตัวแทน: <strong>{data.name}</strong>
                                </div>
                                <div className="">
                                  วันที่ลงทะเบียน:{" "}
                                  <strong>{data.registdate}</strong>
                                </div>

                                <div className="">
                                  งวดวันที่: <strong>{data.installDate}</strong>
                                </div>

                                <div className="">
                                  จำนวนสลากงวดล่าสุด:
                                  <Link
                                    to={`/dealer/number-search?dealerCode=${data.code}`}
                                  >
                                    <strong>
                                      <NumberFormat
                                        value={data.sumdoc}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={""}
                                      />
                                    </strong>
                                  </Link>
                                </div>
                                <div className="">
                                  จำนวนสลากที่ขายแล้วงวดล่าสุด:
                                  <Link
                                    to={`/dealer/number-search?dealerCode=${data.code}&numberStatus=S`}
                                  >
                                    <strong>
                                      <NumberFormat
                                        value={data.sumusage}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={""}
                                      />
                                    </strong>
                                  </Link>
                                </div>
                                <div className="">
                                  จำนวนสลากคงเหลือ:{" "}
                                  <strong>
                                    {" "}
                                    <NumberFormat
                                      value={data.sumremain}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={""}
                                    />
                                  </strong>
                                </div>
                                <div className="">
                                  สถานะ:{" "}
                                  <strong>
                                    {data.status === "1" && (
                                      <span className="text-cyan">
                                        ใช้งาน
                                        <br />
                                      </span>
                                    )}
                                    {data.status !== "1" && (
                                      <span className="text-red">
                                        ยกเลิก
                                        <br />
                                      </span>
                                    )}
                                  </strong>
                                </div>

                                <div className="">
                                  <div className="row">
                                    <div className="col-sm-3 col-xs-3">
                                      Action:
                                    </div>
                                    <div className="col-sm-3 col-xs-2 pr-10">
                                      <button
                                        className="nav-content-bttn rounded-3 bg-orange open-font me-3 d-inline-block float-right lotto-view-btn hide w75 
                                          text-center border-0 "
                                      >
                                        <Link
                                          className="text-white"
                                          to={`/dealer/price_setup/${data.id}`}
                                        >
                                          <i className="feather-tag btn-round-xs w15"></i>
                                          ตั้งราคา
                                        </Link>
                                      </button>
                                    </div>
                                    <div className="col-sm-3 col-xs-2">
                                      <button
                                        className="nav-content-bttn rounded-3 bg-cyan open-font me-3 d-inline-block float-right lotto-view-btn hide w125 
                                          text-center border-0 "
                                      >
                                        <Link
                                          className="text-white"
                                          to={`/dealer/detail/${data.id}`}
                                        >
                                          <i className="feather-zoom-in btn-round-xs w15"></i>
                                          ดูรายละเอียด
                                        </Link>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="row payment-list mb-hide">
                  <div className="col-lg-12 mb-3">
                    <div className="table-content table-responsive">
                      <table className="table text-center">
                        <thead className="bg-greyblue rounded-3">
                          <tr>
                            <th className="border-0 p-4">ลำดับ</th>
                            <th className="border-0 p-4">Username</th>
                            <th className="border-0 p-4 text-left">ชื่อ</th>
                            <th className="border-0 p-4 text-left">นามสกุล</th>
                            <th className="border-0 p-4">สถานะ</th>
                            <th className="border-0 p-4">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {user_list != null &&
                            user_list.map((data, index) => {
                              const rowClass =
                                index % 2 === 0 ? "" : "bg-lightblue";
                              return (
                                <tr
                                  key={index}
                                  className={`data-row ${rowClass}`}
                                >
                                  <td className="product-thumbnail ps-0">
                                    {index + 1}
                                  </td>
                                  {/* <td >
                                      <div className="row pl-7">
                                        <div className="col-sm-3 col-xs-12" >
                                          <div className={`nav-content-bttn rounded-3 text-white bg-cyan open-font me-3 d-inline-block w90 float-right lotto-view-btn lotto-view-btn-${index} hide`}
                                              onClick={() => this.handleClickViewQR(data.id)} >
                                              <h3>{data.code}</h3>
                                            </div>
                                        </div>
                                      </div>
                                    </td> */}
                                  <td className="product-thumbnail ps-0">
                                    <div
                                      className={`nav-content-bttn rounded-3 open-font me-3 d-inline-block lotto-view-btn lotto-view-btn-${index} hide`}
                                    >
                                      {data.username}
                                    </div>
                                  </td>
                                  <td className="product-quantity">
                                    {data.firstname}
                                  </td>
                                  <td className="product-quantity">
                                    {data.lastname}
                                  </td>
                                  <td className="product-quantity">
                                    {data.rolename}
                                    {!/dealer/g.test(data.rolename) &&
                                      /customer/g.test(data.rolename) && (
                                        <div className="nav-content-bttn rounded-3 bg-blue-gradiant open-font me-3 d-inline-block float-right lotto-view-btn hide w125 mb-3">
                                          <span
                                            className="text-white"
                                            onClick={() =>
                                              handleCreateDealerClick({
                                                userId: data.userId,
                                              })
                                            }
                                          >
                                            สร้างดีลเลอร์
                                          </span>
                                        </div>
                                      )}
                                  </td>

                                  {/* <td >
                                      <div className="row pl-7">
                                        <div className="col-sm-3 col-xs-12" >
                                          <div className="nav-content-bttn rounded-3 bg-orange open-font me-3 d-inline-block float-right lotto-view-btn hide w75">
                                            <Link className="text-white" to={`/dealer/price_setup/${data.id}`} >
                                              <i className="feather-tag btn-round-xs w15"></i>ตั้งราคา</Link>
                                          </div>
                                        </div>
                                        <div className="col-sm-9 col-xs-12" >
                                          <div className="nav-content-bttn rounded-3 w125 bg-cyan open-font me-3 d-inline-block float-right lotto-view-btn hide w100">
                                            <Link className="text-white" to={`/dealer/detail/${data.id}`} >
                                              <i className="feather-zoom-in btn-round-xs w15"></i>ดูรายละเอียด</Link>
                                          </div>
                                        </div>
                                      </div>
                                    </td> */}

                                  <td>
                                    <div className="nav-content-bttn rounded-3 bg-orange open-font me-3 d-inline-block float-right lotto-view-btn hide w125 mb-3">
                                      {/* <Link
                                          className="text-white"
                                          to={`/userlist/changepassword/${data.userId}`}
                                        >
                                          <i className="feather-tag btn-round-xs w15"></i>
                                          เปลี่ยนรหัสผ่าน
                                        </Link> */}
                                      <span
                                        className="text-white"
                                        onClick={() =>
                                          handleChangePasswordClick({
                                            userId: data.userId,
                                            userName: data.username,
                                          })
                                        }
                                      >
                                        เปลี่ยนรหัสผ่าน
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                {/* CONTENT ABOVE */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <PopupAdminChangePass
        is_show={popup.is_show}
        data={popup.data}
        onClose={handlePopupClose}
      />

      <PopupCustomerToDealerForm
        is_show={popupCreateDealer.is_show}
        data={popupCreateDealer.data}
        currentSearch={formData}
        onClose={handleCreateDealerPopupClose}
      />

      {/* <Appfooter />  */}
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    user_list: state.userlist.user_list || [],
  };
};

const Userlist = connect(mapStateToProps)(UserListpage);
export default Userlist;
