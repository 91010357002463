/* eslint-disable consistent-return */
/* eslint-disable no-alert */
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/prefer-default-export */
// import React from "react"
import { create } from 'apisauce';
import Utils from '../Utils';
import { customAxios, 
  // monitor 
} from './common';
import axios from 'axios';
import { LoginRequestDto, LoginResponseDto, RefreshResponseDto } from '../dto/AuthDto';
// import store from '../store';
import { getUserToken, removeAppWebSession, saveTokenToMemory } from '../appSessionStorage';
import qs from 'qs'
import { ServerInfo, Constants } from '../constants/config';
import { saveUserTokenToMemory } from '../actions/session';

let preserveCounting = 0;
const preserveCount = 3;

const authenticationUrl = Utils.dynamicOriginUrl(process.env.REACT_APP_AUTHENTICATION_API_URL);
const apiAuth = create({
  baseURL: authenticationUrl,
});

//apiAuth.addMonitor(monitor);

apiAuth.addRequestTransform((request) => {
  // custom headers
  if (request.url === '/authentication-service/oauth/token') {
    request.headers.Authorization = `Basic ${process.env.REACT_APP_AUTH_TOKEN}`;
  } else {
    const accessToken = getUserToken()?.accessToken;
    request.headers.Authorization = `Bearer ${accessToken}`;
  }
});

// apiAuth.addResponseTransform((response) => {
//   // transform iso date to JSDate
//   if (response.data) {
//     response.data = Utils.transformServiceResponse(response.data);
//   }
// });

export const authServices2 = {
    async login(data: URLSearchParams) {  
      console.log("URLSearchParams")
      console.log(data)
    //customAxios.post<LoginResponseDto>(apiAuth, '/authentication-service/oauth/token', data, {
    //  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //})
    let res = await axios.post('http://103.13.230.197:8080/auth/oauth/token', data, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded'
                    ,'Authorization':'Basic YWRtaW5hcHA6MTIzNDU2'
                 },
    });
    return res.data;
  },
  logout: () => customAxios.post(apiAuth, '/authentication-service/oauth/logout'),
  refresh: (data: FormData) => customAxios.post<RefreshResponseDto>(apiAuth, '/authentication-service/oauth/token', data),
};

export const authServices = {
  async loginSystem(data: LoginRequestDto) { 
    return axios({
      method: 'post',
      url:  ServerInfo.URL_LOGIN,
      data: qs.stringify(data),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        'Authorization': `${ServerInfo.URL_LOGIN_AUTH}`,
      }
    })
    .then((result) => {
      console.log("A loginSystem", result)
      // Login success and return token
      const loginResult: LoginResponseDto = {
        access_token: result.data.access_token,
        refresh_token: result.data.refresh_token,
        token_type: result.data.token_type,
      }
      const response = {
        "code": result.status,
        "data": loginResult,
      }
      saveUserTokenToMemory({
        accessToken: loginResult.access_token,
        tokenType: loginResult.token_type,
        refreshToken: loginResult.refresh_token,
      }, false)
      return Promise.resolve(response);
    })
    .catch((error) => {
      console.log("B loginSystem", error)
      // Login fail, create response obj and response back to front
      const response = {
        "code": error.response.status,
        "error": error.response.data.error,
        "error_description": error.response.data.error_description
      }
      return Promise.error(response);
    });
  },
  async login(data: LoginRequestDto) { 
    return axios({
      method: 'post',
      url:  ServerInfo.URL_LOGIN,
      data: qs.stringify(data),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        'Authorization': `${ServerInfo.URL_LOGIN_AUTH}`,
      }
    })
    .then((result) => {
      // Login success save cookie and redirect to home page
      var loginResult = result.data
      saveTokenToMemory({
        accessToken: loginResult.access_token,
        tokenType: loginResult.token_type,
        refreshToken: loginResult.refresh_token,
      });
      window.location = '/';
      return Promise.resolve(result);
    })
    .catch((error) => {
      // Login fail, create response obj and response back to front
      const response = {
        "code": error.response.status,
        "error": error.response.data.error,
        "error_description": error.response.data.error_description
      }
      return Promise.resolve(response);
    });

    // var moreJobs = JSON.parse(res.data)
    // TODO checkResponse status = 200
    // Map to LoginResponseDto{
    // const loginResult: LoginResponseDto = {
    //   access_token: res.data.access_token,
    //   refresh_token: res.data.refresh_token,
    //   token_type: res.data.token_type,
    // }
    // saveTokenToMemory({
    //   accessToken: loginResult.access_token,
    //   tokenType: loginResult.token_type,
    //   refreshToken: loginResult.refresh_token,
    // });
    // console.log("res", res)
    // console.log("data", res.data.access_token)
    // console.log("moreJobs", moreJobs)
    // return loginResult;
  },
  logout: (goTo = "/") => {
    removeAppWebSession()
    window.location = goTo;
  },
}

export const loginWrapper = (data: LoginRequestDto) => {
  console.log("LoginRequestDto")
  console.log(data)
  const urlencoded = new URLSearchParams();
  //urlencoded.append('username', Utils.encryptWithKey(data.username));
  //urlencoded.append('password', Utils.encryptWithKey(data.password));
  urlencoded.append('username', data.username);
  urlencoded.append('password', data.password);
  urlencoded.append('grant_type', 'password');
  return authServices.login(urlencoded);
};

export const getFirstToken = () => {
  const data: LoginRequestDto = {
    username: Constants.anonymous_username,
    password: Constants.anonymous_password,
    grant_type: Constants.grant_type,
  }
  return authServices.loginSystem(data)
}

export const loginByLoginRequestDto = (data: LoginRequestDto) => {
  data.grant_type = Constants.grant_type
  return authServices.login(data);
};

export const logout = (goto) => {
  return authServices.logout(goto);
};

export const refreshWrapper = async (refreshToken: string, isPreserve = false) => {
  const formData = new FormData();
  formData.append('refresh_token', refreshToken);
  formData.append('grant_type', 'refresh_token');

  authServices
    .refresh(formData)
    .then((result) => {
      const { access_token, token_type, refresh_token } = !result.data;
      saveTokenToMemory({
        accessToken: access_token,
        tokenType: token_type,
        refreshToken: refresh_token,
      });
      // return rest;
      preserveCounting = 0;
      return Promise.resolve(result);
    })
    .catch((error) => {
      if (error.response.status === 500) {
        preserveCounting = 0;
        if (error.response.data.error === 'server_error') {
          removeAppWebSession();
          // redirectToPortal();
          // globalVar.isChangePage = true;
          // Object.freeze(globalVar);
          window.location = '/';
        }
        return Promise.reject(error);
      } else {
        if (!isPreserve) {
          if (error.response.status === 403 || error.response.status === 401) {
            // clearAllDomainData();
            // redirectToPortal();
            // window.location = '/';
            preserveCounting = 0;
          }
        } else {
          if (error.response.status === 403 || error.response.status === 401) {
            if (preserveCounting < preserveCount) {
              preserveCounting += 1;
              if (preserveCounting < 3) {
                // callRefresh();
                refreshWrapper(refreshToken, isPreserve);
              } else {
                preserveCounting = 0;
                // alert(error.response.data.error_description ? error.response.data.error_description : 'Your Session has Expired');
                alert('กรุณาตรวจสอบระบบเครือข่ายของท่าน และทำการ เข้าสู่ระบบอีกครั้ง');
                removeAppWebSession();
                // redirectToPortal();
                // globalVar.isChangePage = true;
                // Object.freeze(globalVar);
                window.location = '/';
                return Promise.reject(error);
              }
            } else {
              preserveCounting = 0;
              return Promise.reject(error);
            }
          }
        }
      }
    });
};

export default authServices;
