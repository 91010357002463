import React, { Component } from "react";
import { connect } from "react-redux";

import { Modal } from "react-bootstrap";
import { hideDisplayPopupVerifyCheck } from "../actions/display";
import { saveCheckStock } from "../actions/stock";

class StockCheckComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agent_id: "",
      detail_id: "",
      no: "",
      indt_id: "",
      set: "",
      book: "",
    };
  }

  close = () => {
    const { dispatch } = this.props;
    dispatch(hideDisplayPopupVerifyCheck());
    // window.location.href = "/admin/stock-detail"

    //Reset data
  };

  handleInputChange = (event) => {
    // console.log("handleInputChange")
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    // console.log(name);
    this.setState({
      [name]: value,
    });
    // console.log(this.setState);
  };

  handleSubmit = () => {
    // this.state, data_list.detail_id
    // const { dispatch, lotto_data, data, data_list } = this.props
    // console.log(lotto_data);
    // console.log(data);
    // console.log(data_list);

    // console.log(this.state);

    const { dispatch, lotto_data } = this.props;
    this.setState(
      {
        agent_id: lotto_data.agent_id,
        detail_id: lotto_data.detail_id,
      },
      function () {
        dispatch(saveCheckStock(this.state));
        this.setState({
          agent_id: "",
          detail_id: "",
          no: "",
          indt_id: "",
          set: "",
          book: "",
        });
      }
    );
    // let b = "";

    // console.log(data_list.detail_id);
    // const a = data_list.map((n) => {
    //     console.log(n);
    //     console.log(data.file_id)
    //     console.log(n.file_id)

    //     if(data.agent_id == n.agent_id){
    //         b = n.file_id
    // console.log(b);

    //     }
    // });

    // console.log(b);

    // console.log(data_list);
    // this.setState({
    //   detail_id: data.detail_id,
    //   agent_id: data_list.agent_id
    // }, function() {
    //   // dispatch(saveCheckStock(this.state))
    // })
    // this.state.detail_id = data.detail_id
    // this.state.agent_id = data_list.agent_id
    // console.log(this.state.detail_id);
  };

  render() {
    const {
      is_show,
      image_url,
      // data_list
    } = this.props;
    return (
      <Modal centered backdrop="static" show={is_show}>
        <article className={`message is-primary`}>
          <div className="message-header">
            ตรวจสอบ สลาก
            <button
              className="delete"
              aria-label="delete"
              onClick={this.close}
            ></button>
          </div>
          <div className="message-body text-black">
            <img src={image_url} alt="" />

            <div className="form-group mb-1">
              <label className="text-dark-color text-grey-600 font-xss mb-2 fw-600">
                เลขสลาก
              </label>
              <div className="form-group icon-tab">
                <input
                  type="number"
                  name="no"
                  value={this.state.no}
                  onChange={this.handleInputChange}
                  className="bg-none  rounded-3 border-2 form-control bg-color-none  font-lg fw-700"
                />
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-5">
                <div className="form-group mb-1">
                  <label className="text-dark-color text-grey-600 font-xss mb-2 fw-600">
                    งวดที่
                  </label>
                  <div className="form-group icon-tab">
                    <input
                      type="number"
                      name="indt_id"
                      value={this.state.indt_id}
                      onChange={this.handleInputChange}
                      className="bg-none rounded-3 border-2 form-control bg-color-none  font-lg fw-700"
                    />
                  </div>
                </div>
              </div>
              <div className="col-5">
                <div className="form-group mb-1">
                  <label className="text-dark-color text-grey-600 font-xss mb-2 fw-600">
                    ชุดที่
                  </label>
                  <div className="form-group icon-tab">
                    <input
                      type="number"
                      name="set"
                      value={this.state.set}
                      onChange={this.handleInputChange}
                      className="bg-none  rounded-3 border-2 form-control bg-color-none  font-lg fw-700"
                    />
                  </div>
                </div>
              </div>

              <div className="col-5">
                <div className="form-group mb-1">
                  <label className="text-dark-color text-grey-600 font-xss mb-2 fw-600">
                    เล่มที่
                  </label>
                  <div className="form-group icon-tab">
                    <input
                      type="number"
                      name="book"
                      value={this.state.book}
                      onChange={this.handleInputChange}
                      className="bg-none  rounded-3 border-2 form-control bg-color-none  font-lg fw-700"
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-8 col-xs-7 pt-3">
                <button
                  className="button is-success w-100 text-center"
                  onClick={() => this.handleSubmit()}
                >
                  บันทึกการตรวจสอบ
                </button>
              </div>
            </div>
          </div>
        </article>
      </Modal>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {
    is_show: state.display.popup_verify_check.is_show || false,
    image_url: state.display.popup_verify_check.image_url || "",
    // image_name: state.display.popup_verify_check.image_name || "",
    lotto_data: state.display.popup_verify_check.lotto_data || "",
    // data: state.stock.stock_detail || "",
    // data_list: state.stock.stock_detail_list || []
  };
};

const PopupVerify = connect(mapStateToProps)(StockCheckComponent);
export default PopupVerify;
