// React Required
import React from "react";
import ReactDOM from "react-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { client } from "./graphql/client";

// Create Import File
import "./main.scss";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";

// redux
import store from "./store";
import App from "./App";

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);
serviceWorker.register();
