import React, { Component , Fragment } from "react";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Events from '../components/Events';
// import ListResultLatest from '../components/ListResultLatest';
// import SectionTitle from "../components/SectionTitle";
// import LotterySearchArea from "../components/LotterySearch";

const lastdocList = [
  {   
      bgUrl: 'story.png',
      imageUrl: 'user.png',
      name: 'รางวัลที่ 1',
      value: '578171',
      detail: 'รางวัลละ 6,000,000 บาท'
  },
  {   
      bgUrl: 'story.png',
      imageUrl: 'user.png',
      name: 'เลขหน้า 3 ตัว',
      value: '361 285',
      detail: '2 รางวัลๆละ   4,000 บาท'
  },
  {   
      bgUrl: 'story.png',
      imageUrl: 'user.png',
      name: 'เลขหลัง 3 ตัว',
      value: '379 449',
      detail: '2 รางวัลๆละ   4,000 บาท'
  },
  {   
      bgUrl: 'story.png',
      imageUrl: 'user.png',
      name: 'เลขท้าย 2 ตัว',
      value: '83',
      detail: 'รางวัลละ     2,000 บาท'
  }
]

class Home extends Component {
  render() {
    return (
      <Fragment> 
        <Header />
        <Leftnav />
        {/* <Rightchat /> */}

        {/* <div className="main-content right-chat-active"> */}
        <div className="main-content">
        <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">

            <div className="row feed-body">
              <div className="col-xl-8 col-xxl-9 col-lg-8">
                <div className="card-body p-0">
                    <a href="/" className="font-xss fw-600 text-black-500 card-body p-0 d-flex align-items-center"><i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>ผลสลากกินแบ่งรัฐบาล 16 พฤศจิกายน 2564</a>
                </div>  
                <p/>
                <div className="row ps-2 pe-1">
                    {lastdocList.map((value , index) => (

                    <div key={index} className="col-md-3 col-sm-6 pe-2 ps-2">
                        <div className="card w150 d-block border-0 shadow-xss rounded-4 overflow-hidden mb-4">
                            <div className="card-body d-block w-100 p-4 text-center">                                                          
                                <h4 className="fw-700 font-xss mt-3 mb-0">{value.name}</h4>
                                <div className="clearfix"></div>
                                <h2>
                                <a href="#follow" className="text-center p-2 lh-20 w100 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xss fw-700 ls-lg text-white">{value.value}</a>
                                </h2>
                                <div className="clearfix"></div>
                                <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">{value.detail}</p>                               
                            </div>
                        </div>
                    </div>

                    ))}                  
                </div>

                <div className="col-lg-3 pe-2">
                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e5f6ff`}}>
                        <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-primary-gradiant feather-home font-md text-white"></i>
                            <h4 className="text-primary font-xl fw-700">2.3M <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">day visiter</span></h4>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 pe-2 ps-2">
                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#f6f3ff`}}>
                        <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-lock font-md text-white"></i>
                            <h4 className="text-secondary font-xl fw-700">44.6K <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">total user</span></h4>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 pe-2 ps-2">
                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e2f6e9`}}>
                        <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-success feather-command font-md text-white"></i>
                            <h4 className="text-success font-xl fw-700">603 <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">monthly sale</span></h4>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 ps-2">
                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#fff0e9`}}>
                        <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-warning feather-shopping-bag font-md text-white"></i>
                            <h4 className="text-warning font-xl fw-700">3M <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">day visiter</span></h4>
                        </div>
                    </div>
                </div>

                <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4 text-center">
                    <a href="/defaultmember" className="p-2 lh-20 w300 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">ตรวจผลสลากทั้งหมด</a>
                </div>
                
              </div>
              <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                <Events />
                {/* <CustBasket/> */}
              </div>
            </div>

        </div>
        </div>
        </div>
        {/* <Popupchat /> */}
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default Home;