import React, { Component, Fragment } from "react";
import { connect } from 'react-redux'
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Popupchat from '../components/Popupchat';
import SectionTitle from "../components/SectionTitle";
import { getStockDetailListData, setStockDetailSelected, setStockDetailSelecteds, saveCheckStock, setDisplayStockCheckLottoReload } from "../actions/stock";
import { getImageFileAndShowCheck, getZipFileAndDownload, getImageFileAndShow } from "../actions/file";
import StockCheck from "../admin_pages/StockCheck";
import { hideDisplayPopupImage } from '../actions/display';


class StockDetailComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      status: "",

      is_show: false,
      data: "",
      type: "",
      img_name: "",
      agent_id: "",
      detail_id: "",
      popup: {
        is_show: false,
        data: null,
        type: "",
      }
    }


  }

  componentDidMount() {
    this.initialPage()
  }

  componentDidUpdate() {
    const { dispatch, stock_detail, stock_zip_url, lotto_reload } = this.props
    // console.log(stock_detail)
    // console.log("componentDidUpdate stock_zip_url:", stock_zip_url)
    if (stock_zip_url !== "") {
      const link = document.createElement('a');
      link.setAttribute('id', 'download-link');
      // link.innerHTML = "LINK"
      link.href = stock_zip_url;
      link.setAttribute('download', `${stock_detail.file_name}`); //or any other extension
      // document.body.appendChild(link);
      document.getElementById('link-pane').appendChild(link);
      link.click();
      setTimeout(function () {
        document.getElementById('download-link').remove();
        dispatch({
          type: "STOCK_SET_STOCK_ZIP_URL",
          value: ""
        })
      }, 3000);
    }
    if(lotto_reload !== false) {
      this.reloadData()
    }
  }

  handlevVerifymanual = (data) => {
    // console.log(data);
    const { dispatch, stock_detail } = this.props

    dispatch(getImageFileAndShowCheck(data))
    // dispatch(setStockDetailSelected(data))
    // dispatch(setStockDetailSelecteds(stock_detail))

    // this.props.history.push(`/admin/stock-check`)

    // var { agent_id } = this.props.match.params
    // this.state.agent_id = agent_id;
    // data.agent_id = Number(this.state.agent_id)
    // this.setState({
    //   popup: {
    //     is_show: true,
    //     data: stock_detail,
    //     type: "veritymanual",
    //   }
    // });

  }

  close = () => {
    const { dispatch } = this.props
    dispatch(hideDisplayPopupImage())
  }

  // handlePopupStockCheckSubmit = (data, total, type) => {
  //   const { dispatch } = this.props
  //   console.log("handlePopupLotterySubmit")
  //   console.log("data", data)
  //   console.log("total", total)
  //   console.log("type", type)
  //   if (type === "verifymaual") {
  //     dispatch(saveCheckStock(data))
  //     this.handlePopupStockCheckClose()
  //   }
  //   else {
  //     // ERROR
  //   }
  // }

  componentWillMount() {
    const { stock_detail } = this.props
    if (stock_detail === null) {
      window.location.href = "/admin/stock-list"
    }
  }

  initialPage = () => {
    const { dispatch, stock_detail } = this.props
    dispatch(getStockDetailListData(stock_detail.upload_id, this.state.status))
  }

  reloadData = () => {
    this.initialPage()
    const { dispatch } = this.props
    dispatch(setDisplayStockCheckLottoReload(false))
  }

  handleInputChange = event => {
    console.log("handleInputChange")
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    }, function () {
      this.initialPage()
    })
  }

  handleClickViewDetail = (data) => {
    const { dispatch } = this.props
    // console.log(data);
    dispatch(getImageFileAndShow(data.file_id, data.file_name))
  }

  handleClickDownload = (file_id) => {
    const { dispatch } = this.props
    dispatch(getZipFileAndDownload(file_id))
  }

  render() {
    const { stock_detail, stock_detail_list } = this.props
    return (
      <Fragment>
        <Header />
        <Leftnav />
        {/* <Rightchat /> */}

        <div className="main-content">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">

              <div className="row lottery-search">
                <div className="col-xl-12">

                  <SectionTitle title="Stock detail" />

                  <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">

                    <div className="row">
                      <div className="col-sm-4 col-xs-12">
                        <div className="form-group lottery-digit">
                          <label className="mont-font fw-600 font-xsss mb-2">ชื่อตัวแทน: </label>&nbsp;
                          {stock_detail.agent_name}
                        </div>
                      </div>

                      <div className="col-sm-4 col-xs-12">
                        <div className="form-group lottery-digit">
                          <label className="mont-font fw-600 font-xsss mb-2">ชื่อไฟล์: </label>&nbsp;
                          {stock_detail.file_name}
                        </div>
                      </div>

                      <div className="col-sm-4 col-xs-12">
                        <div className="form-group lottery-digit">
                          <label className="mont-font fw-600 font-xsss mb-2">วันที่นำเข้า: </label>&nbsp;
                          {stock_detail.created_date}
                        </div>
                      </div>

                    </div>

                    <div className="row">
                      <div className="col-sm-4 col-xs-12">
                        <div className="form-group lottery-digit">
                          <label className="mont-font fw-600 font-xsss mb-2">จำนวน: </label>&nbsp;
                          {stock_detail.totaldoc} ใบ
                        </div>
                      </div>

                      <div className="col-sm-4 col-xs-12">
                        <div className="form-group lottery-digit">
                          <label className="mont-font fw-600 font-xsss mb-2">สถานะ</label>&nbsp;
                          <select className="form-control w-auto" name="status" value={this.state.status} onChange={this.handleInputChange} >
                            <option value="">ทั้งหมด</option>
                            <option value="T">สำเร็จ</option>
                            <option value="F">ไม่สำเร็จ</option>
                          </select>
                        </div>
                      </div>


                      <div className="col-sm-4 col-xs-12" id="link-pane">
                        <button onClick={() => this.handleClickDownload(stock_detail.file_id)}
                          className="update-cart bg-primary-gradiant d-block text-white fw-600 text-uppercase 
                    font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"><span>Download Zip</span></button>
                      </div>

                    </div>

                  </div>



                  <div className="row payment-list mb-hide">
                    <div className="col-lg-12 mb-3">
                      <div className="table-content table-responsive">
                        <table className="table text-center">
                          <thead className="bg-greyblue rounded-3">
                            <tr>
                              <th className="border-0 p-4">ลำดับ</th>
                              <th className="border-0 p-4">ชื่อไฟล์</th>
                              <th className="border-0 p-4">งวดวันที่</th>
                              <th className="border-0 p-4">สถานะการตรวจสอบ</th>
                              <th className="border-0 p-4">วันที่ตรวจสอบ</th>
                              <th className="border-0 p-4">เลขสลาก</th>
                              <th className="border-0 p-4">งวดที่</th>
                              <th className="border-0 p-4">ชุดที่</th>
                              <th className="border-0 p-4">Action</th>
                              {/* <th className="border-0 p-4">&nbsp;</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              stock_detail_list != null &&
                              stock_detail_list.map((data, index) => {
                                //   {
                                //     "file_name": "AG001 B W_000.jpg",
                                //     "verified": "T",
                                //     "verified_date": "11/10/2021",
                                //     "number_no": "262105",
                                //     "install_id": 40,
                                //     "set_id": 58,
                                //     "file_id": 2537
                                // },
                                return [
                                  <tr key={index} className="data-row">
                                    <td className="product-thumbnail ps-0">{index + 1}</td>
                                    <td className="product-headline text-left wide-column">
                                      <h3>{data.file_name}</h3>
                                    </td>
                                    <td className="product-quantity">
                                      {data.installDate}
                                    </td>
                                    <td className="product-quantity">
                                      {data.verified}
                                    </td>
                                    <td className="product-quantity">
                                      {data.verified_date}
                                    </td>
                                    <td className="product-quantity">
                                      {data.number_no}
                                    </td>
                                    <td className="product-quantity">
                                      {data.install_id}
                                    </td>
                                    <td className="product-quantity  pr-10">
                                      {data.set_id}
                                    </td>
                                    <td className="product-quantity">
                                      {
                                        data.verified !== "F" &&
                                        <div className={`nav-content-bttn rounded-3 text-white bg-cyan open-font me-3 d-inline-block w90 float-right lotto-view-btn lotto-view-btn-${index} hide`}
                                          onClick={() => this.handleClickViewDetail(data)} >
                                          <i className="feather-zoom-in btn-round-xs"></i><span>ดูสลาก</span>
                                        </div>
                                      }

                                      {
                                        data.verified === "F" &&
                                        <div className="row">
                                          <div className="col-sm-5 col-xs-12" >
                                            <div className={`nav-content-bttn rounded-3 text-white bg-cyan open-font me-3 d-inline-block w90 float-right lotto-view-btn lotto-view-btn-${index} hide`}
                                              onClick={() => this.handleClickViewDetail(data)} >
                                              <i className="feather-zoom-in btn-round-xs"></i><span>ดูสลาก</span>
                                            </div>
                                          </div>


                                          <div className="col-sm-7 col-xs-12"  >

                                            <div className={`nav-content-bttn rounded-3 text-white bg-orange open-font me-3 d-inline-block w125 float-right lotto-view-btn lotto-view-btn-${index} hide`}
                                              onClick={() => this.handlevVerifymanual(data)}
                                            >
                                              <i className="feather-edit btn-round-xs"></i><span>ตรวจสอบใหม่</span>
                                            </div>

                                          </div>
                                          <div className="clearfix"></div>
                                        </div>
                                      }



                                    </td>
                                  </tr>
                                ]
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>



                </div>
              </div>

            </div>
          </div>
        </div>



        {/* <StockCheck is_show={this.state.popup.is_show} data={this.state.popup.data} type={this.state.popup.type}
          onClose={this.close} onSubmit={this.handlePopupStockCheckSubmit} /> */}
        <Popupchat />
        {/* <Appfooter />  */}
      </Fragment>
    );
  }
}


const mapStateToProps = function (state, props) {
  return {
    stock_detail: state.stock.stock_detail || null,
    stock_detail_list: state.stock.stock_detail_list || [],
    stock_zip_url: state.stock.stock_zip_url || "",
    data: state.stock.stock_detail || "",
    data_list: state.stock.stock_detail_list || [],

    lotto_reload: state.display.popup_verify_check.lotto_reload || false,
  }
}

const StockDetail = connect(mapStateToProps)(StockDetailComponent)
export default StockDetail;
