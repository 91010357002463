import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useSubscription } from "@apollo/client";
import { GET_BASKET_COUNT_SUBSCRIPTION } from "../graphql/query";
import { NavLink } from "react-router-dom";

const HeaderBastketComponent = (props) => {
  const { user_detail } = props;

  const { data: basketData } = useSubscription(GET_BASKET_COUNT_SUBSCRIPTION, {
    variables: { cust_id: user_detail.customer_id },
  });

  return (
    <>
      {" "}
      <NavLink
        activeClassName="active"
        to="/cart"
        className="p-2 text-center ms-0 menu-icon center-menu-icon d-none d-lg-block"
      >
        {basketData?.transflow_trn_cust_basket_aggregate?.aggregate.sum
          .order_total && (
          <span className="num-count bg-danger">
            {
              basketData.transflow_trn_cust_basket_aggregate.aggregate.sum
                .order_total
            }
          </span>
        )}

        <i className="feather-shopping-cart font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-900 "></i>
      </NavLink>
      {/* <NavLink
        activeClassName="active"
        to="/cart"
        className="p-2 mr-2 text-center ms-0 menu-icon center-menu-icon d-md-none"
      >
        {basketData?.transflow_trn_cust_basket_aggregate?.aggregate.sum
          .order_total && (
          <span className="num-count bg-danger">
            {
              basketData.transflow_trn_cust_basket_aggregate.aggregate.sum
                .order_total
            }
          </span>
        )}

        <i className="feather-shopping-cart font-sm"></i>
      </NavLink> */}
    </>
  );
};

const mapStateToProps = function (state, props) {
  return {
    user_detail: state.user.user_detail || null,
  };
};

const HeaderBastket = connect(mapStateToProps)(HeaderBastketComponent);
export default HeaderBastket;
