// React Required
import React, { Component, useEffect, useState } from "react";

import { connect, useDispatch } from "react-redux";

// Create Import File
import "./main.scss";

// Common Layout
import Home from "./pages/Home";
import News from "./pages/Appnews";
import Badge from "./pages/Badge";
import Group from "./pages/Group";
import Storie from "./pages/Storie";
import Member from "./pages/Member";
import Email from "./pages/Email";
import Emailopen from "./pages/Emailopen";
import Settings from "./pages/Settings";
import Account from "./pages/Account";
import Contactinfo from "./pages/Contactinfo";
import Socialaccount from "./pages/Socialaccount";
import Password from "./pages/Password";
import Notification from "./pages/Notification";
import Helpbox from "./pages/Helpbox";
import RegisterCust from "./pages/RegisterCust";
import Forgot from "./pages/Forgot";
import Notfound from "./pages/Notfound";
import CustomerProfile from "./pages/CustomerProfile";

import ShopOne from "./pages/ShopOne";
import ShopTwo from "./pages/ShopTwo";
import ShopThree from "./pages/ShopThree";
import Singleproduct from "./pages/Singleproduct";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Chat from "./pages/Chat";
import Live from "./pages/Live";
import Job from "./pages/Job";
import Event from "./pages/Event";
import Hotel from "./pages/Hotel";
import Videos from "./pages/Videos";
import Comingsoon from "./pages/Comingsoon";

import Grouppage from "./pages/Grouppage";
import Userpage from "./pages/Userpage";
import Agentpage from "./pages/Agentpage";
import Dealerpage from "./pages/Dealerpage";
import Authorpage from "./pages/Authorpage";
import Hotelsingle from "./pages/Hotelsingle";

import PopupMessage from "./components/PopupMessage";
import Loading from "./components/Loading";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import LotterySearch from "./pages/LotterySearch";
import CustomerPayment from "./pages/CustomerPayment";
import OrderHistory from "./pages/OrderHistory";
import LoginCust from "./pages/LoginCust";
import ForgotPassword from "./pages/ForgotPassword";
import CustomerList from "./admin_pages/CustomerList";
import StockUpload from "./admin_pages/StockUpload";
import StockList from "./admin_pages/StockList";
import StockDetail from "./admin_pages/StockDetail";
import StockCheck from "./admin_pages/StockCheck";
import PopupImage from "./components/PopupImage";
import AgentPriceSetup from "./agent_pages/AgentPriceSetup";
import PaymentList from "./admin_pages/PaymentList";
import PaymentDetail from "./admin_pages/PaymentDetail";
import PopupSlipCheck from "./components/PopupSlipCheck";
import PopupQR from "./components/PopupQR";
import AgentProfile from "./agent_pages/AgentProfile";
import AgentNumberSearch from "./agent_pages/AgentNumberSearch";
import NumberGroupSearch from "./admin_pages/NumberGroupSearch";
import UserListpage from "./pages/UserListPage";
import PrivacyPage from "./pages/Privacy";
import Terms from "./pages/Terms";
import CookiePrivacyPage from "./pages/CookiePolicy";

import withClearCache from "./ClearCache";
import OutOfStock from "./pages/OutOfStock";
import PaymentQR from "./components/PaymentQR";
import CheckNumber from "./pages/CheckNumber";
import DealerProfile from "./dealer_pages/DealerProfile";
import DealerPriceSetup from "./dealer_pages/DealerPriceSetup";
import DealerPaymentList from "./dealer_pages/DealerPaymentList";
import Vault from "./pages/Vault";
import StockUploadNew from "./admin_pages/StockUploadNew";
import DealerWinner from "./dealer_pages/DealerWinner";
import Dashboard from "./admin_pages/Dashboard";
import store from "./store";
import Result from "./admin_pages/Result";
import Report from "./admin_pages/Report";

const MainApp = (props) => {
  const { user_detail } = props;

  const [liffReady, setLiffReady] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("start liff.init()...");
    const liff = window.liff;

    // (async () => {
    //   const liff = await import("@line/liff");

    //   setLiffReady(liff);
    // })();
    if (liff) {
      setLiffReady(liff);
    }
  }, []);

  useEffect(() => {
    if (liffReady) {
      liffReady
        .init({ liffId: process.env.REACT_APP_LIFF_ID })
        .then(() => {
          console.log("liff.init() done");
          dispatch({
            type: "LIFF",
            value: liffReady,
          });
          // setLiffObject(liff);
        })
        .catch((error) => {
          console.log(`liff.init() failed: ${error}`);
          if (!process.env.liffId) {
            console.info(
              "LIFF Starter: Please make sure that you provided `LIFF_ID` as an environmental variable."
            );
          }
          dispatch({
            type: "LIFF_ERROR",
            value: error.toString(),
          });
          // setLiffError(error.toString());
        });
    }
  }, [liffReady]);

  return (
    <BrowserRouter basename={"/"}>
      <Routes>
        <Route
          path="/"
          element={
            process.env.REACT_APP_IS_OUTOFSTOCK === "Y" ? (
              <OutOfStock />
            ) : user_detail.is_admin ? (
              <Dashboard />
            ) : (
              <LotterySearch />
            )
          }
        />

        {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={OutOfStock} />
          <Route exact path={`${process.env.PUBLIC_URL}/home`} component={OutOfStock} /> */}

        {process.env.REACT_APP_IS_OUTOFSTOCK !== "Y" && (
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/lottery-search`}
            element={<LotterySearch />}
          />
        )}

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/news`}
          element={<News />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/login`}
          element={<LoginCust />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/register-cust`}
          element={<RegisterCust />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/forgot-password`}
          element={<ForgotPassword />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/cust-profile`}
          element={<CustomerProfile />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/cart`}
          element={<Cart />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/payment`}
          element={<CustomerPayment />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/order-history`}
          element={<OrderHistory />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin`}
          element={<Dashboard />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/result`}
          element={<Result />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/customer-list`}
          element={<CustomerList />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/stock-in`}
          element={<StockUpload />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/stock-new-in`}
          element={<StockUploadNew />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/stock-list`}
          element={<StockList />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/stock-detail`}
          element={<StockDetail />}
        />
        {/* <Route exact path={`${process.env.PUBLIC_URL}/admin/stock-check`} component={StockCheck} /> */}

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/payment-list`}
          element={<PaymentList />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/payment-detail`}
          element={<PaymentDetail />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/report`}
          element={<Report />}
        />

        {/*<Route exact path={`${process.env.PUBLIC_URL}/out-of-stock`} component={OutOfStock} />*/}

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/defaultbadge`}
          element={<Badge />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/defaultgroup`}
          element={<Group />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/defaultstorie`}
          element={<Storie />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/defaultemailbox`}
          element={<Email />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/defaultemailopen`}
          element={<Emailopen />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/defaultsettings`}
          element={<Settings />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/defaultvideo`}
          element={<Videos />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/accountinformation`}
          element={<Account />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/defaultmember`}
          element={<Member />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/contactinformation`}
          element={<Contactinfo />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/socialaccount`}
          element={<Socialaccount />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/password`}
          element={<Password />}
        />
        {/* <Route exact path={`${process.env.PUBLIC_URL}/payment`} component={Payment}/> */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/defaultnotification`}
          element={<Notification />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/helpbox`}
          element={<Helpbox />}
        />
        {/* <Route exact path={`${process.env.PUBLIC_URL}/register`} component={Register}/> */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/forgot`}
          element={<Forgot />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/notfound`}
          element={<Notfound />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/shop1`}
          element={<ShopOne />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/shop2`}
          element={<ShopTwo />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/shop3`}
          element={<ShopThree />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/singleproduct`}
          element={<Singleproduct />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/checkout`}
          element={<Checkout />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/defaultmessage`}
          element={<Chat />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/defaultlive`}
          element={<Live />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/defaultjob`}
          element={<Job />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/defaultevent`}
          element={<Event />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/defaulthotel`}
          element={<Hotel />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/grouppage`}
          element={<Grouppage />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/userpage`}
          element={<Userpage />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/userpage/:userType`}
          element={<Userpage />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/agentpage`}
          element={<Agentpage />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/agent/price_setup/:agent_id`}
          element={<AgentPriceSetup />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/agent/price_setup`}
          element={<AgentPriceSetup />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/agent/detail/:agent_id`}
          element={<AgentProfile />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/agent/detail`}
          element={<AgentProfile />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/agent/number-search`}
          element={<AgentNumberSearch />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/agent/numbergroup-search`}
          element={<NumberGroupSearch />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dealerpage`}
          element={<Dealerpage />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dealer/price_setup/:dealer_id`}
          element={<DealerPriceSetup />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dealer/price_setup`}
          element={<DealerPriceSetup />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dealer/detail/:dealer_id`}
          element={<DealerProfile />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dealer/detail`}
          element={<DealerProfile />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dealer/paymenthistory`}
          element={<DealerPaymentList />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dealer/winner`}
          element={<DealerWinner />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/authorpage`}
          element={<Authorpage />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/comingsoon`}
          element={<Comingsoon />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/defaulthoteldetails`}
          element={<Hotelsingle />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/checknumber`}
          element={<CheckNumber />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/vault`}
          element={<Vault />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/userlist`}
          element={<UserListpage />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/terms`}
          element={<Terms />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/privacy`}
          element={<PrivacyPage />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/cookie-policy`}
          element={<CookiePrivacyPage />}
        />
      </Routes>

      <Loading />
      <PopupMessage />
      <PopupImage />
      <PopupSlipCheck />
      <PopupQR />
      <StockCheck />
    </BrowserRouter>
  );
};

// const ClearCacheComponent = withClearCache(MainApp);

// function AppWithClearCache() {
//   return <ClearCacheComponent />;
// }

const mapStateToProps = function (state, props) {
  return {
    user_detail: state.user.user_detail || null,
  };
};

const App = connect(mapStateToProps)(MainApp);
export default App;
