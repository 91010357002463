import React, { Component, Fragment, useEffect, useState } from "react";
import { ServerInfo, ServerInfo_Code } from "../constants/config";
import session from "../actions/session";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import PageTitle from "../components/PageTitle";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { useDispatch, connect } from "react-redux";
import { getVaultList } from "../actions/vault";
import Lottery from "../components/Lottery";
import { Modal, ModalBody } from "react-bootstrap";
import { getCustomerDataByTelephone } from "../actions/customer";
import { sendNumberToCustomer } from "../actions/number";
import moment from "moment";
import "moment/locale/th";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import VaultItem from "../components/VaultItem";
import { toBuddhistYear } from "../libs/buddhist-year";

const VaultFC = (props) => {
  moment.locale("th");
  const { vault_list, user_detail, customer_search_data, installment_list } =
    props;

  const [isOpen, setIsOpen] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [tel, setTel] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [sendNum, setSendNum] = useState(1);
  const [vaultSelectedData, setVaultSeletedData] = useState(null);
  const [installmentId, setInstallmentId] = useState();
  const [history, setHistory] = useState(null);

  const dispatch = useDispatch();

  const loadHistory = () => {
    const token = session.getUserToken();
    fetch(
      `${ServerInfo.URL_VAULT_HISTORY}/${user_detail.customer_id}/${installmentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        setHistory(data.result);
      });
  };

  const handleHistoryOpen = () => {
    if (!history) {
      loadHistory();
    }
    setIsHistoryOpen(true);
  };

  const handleHistoryClose = () => {
    setIsHistoryOpen(false);
  };

  const handleOnclose = () => {
    setTel("");
    setVaultSeletedData(null);
    setCustomerId(null);
    setSendNum(1);
    setIsOpen(false);
  };

  const handleInstallmentChange = (instId) => {
    setInstallmentId(instId);
  };

  useEffect(() => {
    if (installmentId) {
      dispatch(getVaultList(user_detail.customer_id, installmentId));
      setHistory(null);
      setIsHistoryOpen(false);
    }
  }, [installmentId]);

  useEffect(() => {
    if (user_detail?.customer_id && installment_list) {
      const current = installment_list.find((inst) => inst.active === "1");
      if (current) {
        setInstallmentId(current.installmentId);
        dispatch(getVaultList(user_detail.customer_id, current.installmentId));
      }
    }
  }, [user_detail, installment_list]);

  useEffect(() => {
    if (tel !== "") {
      dispatch(getCustomerDataByTelephone(tel));
    }
  }, [tel]);

  useEffect(() => {
    if (customer_search_data) {
      console.log("customer_search_data", customer_search_data);
      setCustomerId(customer_search_data.custId);
    } else {
      setCustomerId(null);
    }
  }, [customer_search_data]);

  return (
    <Fragment>
      <Header />
      <Leftnav />

      <Modal centered backdrop="static" show={isOpen}>
        <ModalBody>
          <article className={`message is-primary`}>
            <div className="message-header">
              <button
                className="delete"
                aria-label="delete"
                onClick={handleOnclose}
              ></button>
              <p>โอนลอตเตอรี่ให้ลูกค้า</p>
            </div>
            <div className="message-body">
              {vaultSelectedData && (
                <div>
                  <div className="mb-4">
                    <label style={{ display: "block" }} for="findtel">
                      ค้นจากเบอร์โทร{" "}
                      <span style={{ color: "#f00" }}>
                        (กรุณาตรวจสอบเบอร์โทรของลูกค้าให้ถูกต้อง)
                      </span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="findtel"
                      onBlur={(e) => setTel(e.target.value.trim())}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          setTel(e.target.value.trim());
                        }
                      }}
                    />
                  </div>
                  {tel.trim() !== "" && customerId && (
                    <div>
                      <div className="mb-4">
                        ชื่อลูกค้า : {customer_search_data.fullname}
                      </div>
                      <div className="mb-4">
                        <div style={{ color: "#f00" }}>
                          กรุณาตรวจสอบชื่อลูกค้าที่รับโอนลอตเตอรี่ให้ถูกต้องครบถ้วนทาง
                          มหาชนจะไม่รับผิดชอบในกรณีร้านค้าที่โอนลอตเตอรี่ผิด
                        </div>
                        <label
                          style={{ display: "block" }}
                          for="installment_id"
                        >
                          จำนวน
                        </label>
                        <select
                          className="form-control w-auto"
                          style={{ minWidth: "150px" }}
                          name="quantity"
                          onChange={(e) => {
                            setSendNum(e.target.value);
                          }}
                        >
                          {[...Array(vaultSelectedData.packNum).keys()].map(
                            (num, index) => (
                              <option key={`q-${index}`} value={num - 0 + 1}>
                                {num - 0 + 1}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div>
                        <button
                          className=" bg-cyan d-block text-white fw-600 text-uppercase  font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"
                          onClick={() => {
                            confirmAlert({
                              title: "ยืนยันการโอนล๊อตเตอรี่",
                              message: `หมายเลข: ${vaultSelectedData.numberNo} จำนวน ${sendNum} ใบ`,
                              buttons: [
                                {
                                  label: "โอนเลย",
                                  onClick: () => {
                                    const nids = vaultSelectedData.numberIds
                                      .map((number, index) => {
                                        return index < sendNum ? number : null;
                                      })
                                      .filter((number) => number);

                                    dispatch(
                                      sendNumberToCustomer(
                                        customerId,
                                        nids,
                                        installmentId
                                      )
                                    );
                                    handleOnclose();
                                  },
                                },
                                {
                                  label: "ยกเลิก",
                                  onClick: () => handleOnclose(),
                                },
                              ],
                            });
                          }}
                        >
                          โอนทันที
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </article>
        </ModalBody>
      </Modal>
      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <PageTitle title="ตู้เซฟ" />

                <div className="row ps-2 pe-1">
                  <div
                    style={{
                      textAlign: "center",
                      padding: "15px",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        textAlign: "right",
                        padding: "10px 15px",
                      }}
                    >
                      <label for="installment_id">ประจำงวด</label>&nbsp;
                      <select
                        className="form-control w-auto"
                        style={{
                          minWidth: "150px",
                          height: "40px",
                          lineHeight: "25px",
                        }}
                        name="installment_id"
                        onChange={(e) => {
                          handleInstallmentChange(e.target.value);
                        }}
                      >
                        {installment_list?.map((inst) => (
                          <option
                            key={`inst-list-${inst.installmentId}`}
                            value={inst.installmentId}
                            selected={inst.active === "1"}
                          >
                            {toBuddhistYear(
                              moment(inst.installmentDate),
                              "D MMMM YYYY"
                            )}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        textAlign: "left",
                        padding: "18px 15px 10px",
                      }}
                    >
                      <span>
                        จำนวนทั้งหมด{" "}
                        {vault_list.reduce((a, b) => a + b.packNum, 0)} ใบ
                      </span>
                    </div>
                    {user_detail.is_dealer && (
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (isHistoryOpen) {
                            handleHistoryClose();
                          } else {
                            handleHistoryOpen();
                          }
                        }}
                      >
                        <i
                          className="feather-file-text btn-round-xs bg-white me-3"
                          style={{
                            boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {user_detail.is_dealer && isHistoryOpen && (
                    <div
                      style={{
                        borderRadius: "4px",
                        border: "1px solid",
                        marginBottom: "15px",
                        maxHeight: "calc(100vh - 200px)",
                        overflowY: "auto",
                      }}
                    >
                      {history?.length > 0 && (
                        <table className="table" style={{ minWidth: "700px" }}>
                          <thead>
                            <tr>
                              <th>วันที่</th>
                              <th>รายการ</th>
                              <th>เลข</th>
                              <th>ราคาซื้อ</th>
                              <th>ผู้โอน</th>
                              <th>ผู้รับโอน</th>
                              {/* <th></th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {history.map((item, index) => (
                              <tr key={`history-item-${index}`}>
                                <td>
                                  {toBuddhistYear(
                                    moment(item.date),
                                    "D/MM/YYYY HH:mmน."
                                  )}
                                </td>
                                <td>
                                  {item.type === "entry"
                                    ? "เข้าระบบตู้เซฟ"
                                    : "โอน"}
                                </td>
                                <td>{item.numberNo}</td>
                                <td>{item.boughtPrice}</td>
                                <td>
                                  {item.type === "transfer" &&
                                    (item.owner.email === item.dealer.code
                                      ? item.dealer.name
                                      : item.owner.fullname)}
                                </td>
                                <td>
                                  {item.type === "transfer"
                                    ? item.holder.firstname +
                                      " " +
                                      item.holder.telephoneno
                                    : ""}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                      {history?.length === 0 && <>ยังไม่มีประวัติ</>}
                    </div>
                  )}
                  <div className="row ps-2 pe-1 lottery-search-result">
                    {vault_list?.map((vault, index) => {
                      vault = { ...vault, fid: vault.fileId };
                      return (
                        <VaultItem
                          vault={vault}
                          setVaultSeletedData={setVaultSeletedData}
                          setIsOpen={setIsOpen}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    vault_list: state.vault.vault_list || [],
    user_detail: state.user.user_detail || null,
    customer_search_data: state.customer.customer_search_data || null,
    installment_list: state.number.installment_list || null,
  };
};

const Vault = connect(mapStateToProps)(VaultFC);
export default Vault;
