import { ServerInfo } from "../constants/config";
import Message from "../constants/message";
import { apiActionWithToken, apiAction } from "./actions";
import {
  showDisplayPopupMessage,
  showDisplayPopupError,
  closePopupData,
} from "./display";
import actionUtils from "./actionUtils";

const CLASSNAME = `actions:customer`;

//REGISTER CUSTOMER
export const registerCustomer = (data: RegisterRequestDto) => {
  console.log(`[${CLASSNAME}] registerCustomer`);
  return async (dispatch) => {
    dispatch(
      apiAction(
        {
          url: `${ServerInfo.URL_REGISTER}`,
          method: `POST`,
          data: data,
          onSuccess: onRegisterCustomerResponse,
          onFailure: onRegisterCustomerFailure,
          label: "APICALLER_CALL_API",
        },
        true
      )
    );
  };
};

export const onRegisterCustomerResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onRegisterCustomerResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: Message.MessageCommon.success,
        message: `ลงทะเบียนสำเร็จ กรุณาเข้าสู่ระบบเพื่อเปิดใช้งาน`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          console.log("testtstes");
          dispatch(closePopupData());
          window.location = "/login";
        },
      })
    );
  };
};

export const onRegisterCustomerFailure = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onRegisterCustomerFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    // {"statusDescription":"CER0011","errorCode":"CER0011","status":"Bad Request","statusCode":400}
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.status + ": " + data.statusDescription,
      })
    );
    //EX. 401 {"status":"UNAUTHORIZED","message":"Access is denied","errors":["Access is denied"]}
    //EX. 401 {"error":"invalid_token","error_description":""}
    // //EX. 400 {"error":"invalid_request","error_description":"Missing grant type"}
    // if(statusCode === 401){
    //   if(data.status !== undefined){
    //     dispatch(showDisplayPopupError({
    //       "title": data.status,
    //       "message": data.message
    //     }))
    //   }
    //   else{
    //     dispatch(showDisplayPopupError({
    //       "title": Message.MessageCommon.errorFound,
    //       "message": data.error + ": " + Message.MessageAuthen.tokenFail
    //     }))
    //     //TODO LOgout here
    //   }
    // }
  };
};

//FORGET PASS CUSTOMER
export const forgetpassCustomer = (data: RegisterRequestDto) => {
  console.log(`[${CLASSNAME}] forgetPass`);
  return async (dispatch) => {
    dispatch(
      apiAction(
        {
          url: `${ServerInfo.URL_FORGETPASS}`,
          method: `POST`,
          data: data,
          onSuccess: onForgetpassCustomerResponse,
          onFailure: actionUtils.onCommonFailure,
          label: "APICALLER_CALL_API",
        },
        true
      )
    );
  };
};

export const onForgetpassCustomerResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onRegisterCustomerResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: Message.MessageCommon.success,
        message: `กรุณาตรวจสอบรหัสผ่านที่ส่งให้ email ที่ท่านใช้ลงทะเบียน`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: async () => {
          dispatch(closePopupData());
          window.location = "/login";
        },
      })
    );
  };
};

// export const onForgetpassCustomerFailure = (statusCode, statusText, data) => {
//   console.log(`[${CLASSNAME}] onRegisterCustomerFailure statusCode: ${statusCode}`)
//   return async (dispatch) => {
//     console.log(data)
//     // {"statusDescription":"CER0011","errorCode":"CER0011","status":"Bad Request","statusCode":400}
//     dispatch(showDisplayPopupError({
//       "title": Message.MessageCommon.systemError,
//       "message": data.status + ": " + data.statusDescription
//     }))
//   }
// }

//GET CUSTOMER DATA
export const getCustomerData = (id) => {
  // console.log(`[${CLASSNAME}] getCustomerDataAA`)
  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] getCustomerDataA`)
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_PROFILE}/${id}`,
        method: `GET`,
        data: {},
        onSuccess: onGetCustomerDataResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onGetCustomerDataResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetCustomerDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "CUSTOMER_SET_CUSTOMER_DATA",
      value: data.result,
    });
  };
};

//GET CUSTOMER DATA
export const getCustomerDataByTelephone = (telno) => {
  // console.log(`[${CLASSNAME}] getCustomerDataAA`)
  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] getCustomerDataA`)
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_PROFILE_BY_TEL}/${telno}`,
        method: `GET`,
        data: {},
        onSuccess: onGetCustomerByTelDataResponse,
        onFailure: onGetCustomerByTelDataFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onGetCustomerByTelDataResponse = (
  statusCode,
  statusText,
  data
) => {
  console.log(
    `[${CLASSNAME}] onGetCustomerByTelDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "CUSTOMER_SEARCH_CUSTOMER",
      value: data.result,
    });
  };
};

export const onGetCustomerByTelDataFailure = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onGetCustomerByTelDataFailure statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    // console.log(data)
    dispatch({
      type: "CUSTOMER_SEARCH_CUSTOMER",
      value: null,
    });
  };
};

//SET CUSTOMER DATA
export const setCustomerData = (data: CustomerProfileUpdateRequestDto) => {
  console.log(`[${CLASSNAME}] setCustomerData`);
  return async (dispatch) => {
    console.log(data);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_PROFILE_UPDATE}`,
        method: `POST`,
        data: data,
        onSuccess: onSetCustomerDataResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onSetCustomerDataResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onSetCustomerDataResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    // dispatch(showDisplayPopupMessage({
    //   "title": Message.MessageCommon.success,
    //   "message": Message.MessageCommon.saveSuccess
    // }))
    dispatch(
      showDisplayPopupMessage({
        is_show: true,
        title: Message.MessageCommon.success,
        message: `บันทึกสำเร็จ`,
        is_show_submit_button: true,
        is_show_cancel_button: false,
        submit_button_text: "Ok",
        cancel_button_text: null,
        status: "success",
        submit_function: null,
      })
    );
    // dispatch({
    //   type: "CUSTOMER_SET_CUSTOMER_DATA",
    //   value: data.result
    // })
  };
};

// export const onSetCustomerDataFailure = (statusCode, statusText, data) => {
//   console.log(`[${CLASSNAME}] onSetCustomerDataFailure statusCode: ${statusCode}`)
//   return async (dispatch) => {
//     console.log(data)
//     //EX. 401 {"status":"UNAUTHORIZED","message":"Access is denied","errors":["Access is denied"]}
//     //EX. 401 {"error":"invalid_token","error_description":""}
//     // //EX. 400 {"error":"invalid_request","error_description":"Missing grant type"}
//     if(statusCode === 401){
//       if(data.status !== undefined){
//         dispatch(showDisplayPopupError({
//           "title": data.status,
//           "message": data.message
//         }))
//       }
//       else{
//         dispatch(showDisplayPopupError({
//           "title": Message.MessageCommon.errorFound,
//           "message": data.error + ": " + Message.MessageAuthen.tokenFail
//         }))
//         //TODO LOgout here
//       }
//     }
//     // else {
//     //   dispatch(showDisplayPopupError({
//     //     "title": Message.MessageCommon.systemError,
//     //     "message": data.error + ": " + data.error_description
//     //   }))
//     // }
//   }
// }

//CHANGE PASSWORD
export const changePassword = (data) => {
  console.log(`[${CLASSNAME}] changePassword`);
  let params = {
    oldpassword: data.current,
    newpassword: data.new,
    confirmpassword: data.confirmnew,
  };
  return async (dispatch) => {
    console.log(data);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_CHANGEPASS}`,
        method: `POST`,
        data: params,
        onSuccess: onChangePasswordResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onChangePasswordResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onChangePasswordResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    if (data.result === "S") {
      dispatch(
        showDisplayPopupMessage({
          is_show: true,
          title: Message.MessageCommon.success,
          message: `เปลี่ยนรหัสผ่านสำเร็จ`,
          is_show_submit_button: true,
          is_show_cancel_button: false,
          submit_button_text: "Ok",
          cancel_button_text: null,
          status: "success",
          submit_function: null,
        })
      );
    } else {
      dispatch(
        showDisplayPopupError({
          title: Message.MessageCommon.errorFound,
          message: data.result,
        })
      );
    }
  };
};
