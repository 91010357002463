import { combineReducers } from "redux";
import display from "./display";
import customer from "./customer";
import bank from "./bank";
import number from "./number";
import cart from "./cart";
import payment from "./payment";
import stock from "./stock";
import agent from "./agent";
import dealer from "./dealer";
import file from "./file";
import user from "./user";
import userlist from "./userlist";
import vault from "./vault";
import report from "./report";

export default combineReducers({
  display,
  customer,
  bank,
  number,
  cart,
  payment,
  stock,
  agent,
  dealer,
  file,
  user,
  vault,
  userlist,
  report,
});
