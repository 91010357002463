import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Events from "../components/Events";
import { RegisterRequestDto } from "../dto/RegisterDto";

import {
  showHideLoading,
  showDisplayPopupError,
  showDisplayPopupMessage,
} from "../actions/display";
import { registerCustomer } from "../actions/customer";
import { getBankAccountList } from "../actions/bank";

class RegisterCustComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      custtelephoneno: "",
      custpassno: "",
      custfirmpassno: "",
      custfirstname: "",
      custlastname: "",
      // custaddress: '',
      custemail: "",
      custacceptterm: "",
      custline: "",
      custBankId: "",
      custBankAccountName: "",
      custBankAccount: "",
      custDealerId: "",
      // showHide : false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.setQueryData();
  };

  handleModalShowHide() {
    const { dispatch } = this.props;
    // this.setState({ showHide: !this.state.showHide })
    dispatch(
      showDisplayPopupError({
        title: "",
        message: "File type is not allow",
      })
    );
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  initialPage = () => {
    const { dispatch } = this.props;
    dispatch(getBankAccountList());
  };

  setQueryData = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (
      queryParams.get("telno") != null ||
      queryParams.get("custFirstname") != null ||
      queryParams.get("custLastname") != null
    ) {
      let telnoIn = "";
      let custFirstnameIn = "";
      let custLastnameIn = "";
      if (queryParams.get("telno") != null) {
        telnoIn = queryParams.get("telno");
      }
      if (queryParams.get("custFirstname") != null) {
        custFirstnameIn = queryParams.get("custFirstname");
      }
      if (queryParams.get("custLastname") != null) {
        custLastnameIn = queryParams.get("custLastname");
      }
      this.setState(
        {
          custtelephoneno: telnoIn,
          custfirstname: custFirstnameIn,
          custlastname: custLastnameIn,
        },
        function () {
          this.initialPage();
        }
      );
    } else {
      this.initialPage();
    }
  };

  handleSubmit = (event) => {
    const { dispatch, dealer_ref } = this.props;
    event.preventDefault();
    //TODO Validate value before submit to backend
    const validate = this.validateForm();
    if (!validate.status) {
      this.showErrorPopup("Error!!", validate.message);
    } else {
      const reqData: RegisterRequestDto = {
        dealerId: dealer_ref.replace("d", "") - 0,
        telephoneno: this.state.custtelephoneno,
        password: this.state.custpassno,
        firstname: this.state.custfirstname,
        lastname: this.state.custlastname,
        email: this.state.custemail,
        lineid: this.state.custline,
        bankId: this.state.custBankId,
        bankAccount: this.state.custBankAccount,
        bankAccountName: this.state.custBankAccountName,
      };
      dispatch(registerCustomer(reqData));
    }
    // else {
    //   console.log("handleSubmit")
    //   const reqData: RegisterRequestDto = {
    //     telephoneno: this.state.custtelephoneno,
    //     password: this.state.custpassno,
    //     firstname: this.state.custfirstname,
    //     lastname: this.state.custlastname,
    //     // address: this.state.custaddress,
    //     // email: this.state.custemail,
    //   }
    //   this.showHideLoading(true)
    //   RegisterWrapper(reqData)
    //     .then(res => {
    //       console.log(res)
    //       this.showHideLoading(false)
    //       if (res.code === 200) {
    //         // alert("ลงทะเบียนสำเร็จ กรุณาเข้าสู่ระบบเพื่อเปิดใช้งาน")
    //         this.showMessagePopup("สำเร็จ!!", "ลงทะเบียนสำเร็จ กรุณาเข้าสู่ระบบเพื่อเปิดใช้งาน")
    //         setTimeout(function () {
    //           window.location = "/login"
    //         }, 5000);
    //       }
    //       else {
    //         this.showErrorPopup("Error [" + res.data.errorCode + "]", res.data.statusDescription)
    //         // alert(res.data.errorCode+": " + res.data.statusDescription)
    //       }
    //       // console.log(res)
    //       // alert(JSON.stringify(res));
    //       // alert("ลงทะเบียนสำเร็จ กรุณาเข้าสู่ระบบเพื่อเปิดใช้งาน")
    //       // alert(JSON.stringify(res));
    //     });
    // }
  };

  // login2 = e => {
  //     alert(this.state.custtelephoneno);
  //     const urlencoded = new URLSearchParams();
  //     urlencoded.append('username', '0861862622');
  //     urlencoded.append('password', '123456');
  //     urlencoded.append('grant_type', 'password');

  //     console.log(urlencoded)
  //     // authServices.login(urlencoded)
  //     loginWrapper({username: "ss", password: "pass"})
  //     .then(res => {
  //         alert(JSON.stringify(res));
  //     });
  //   }

  validateForm = () => {
    const validateRequired = this.validateFormRequiredField();
    if (!validateRequired.status) {
      return validateRequired;
    }
    const validatePassword = this.validateFormPasswordMatch();
    if (!validatePassword.status) {
      return validatePassword;
    }
    const validateFormFieldFormat = this.validateFormFieldFormat();
    if (!validateFormFieldFormat.status) {
      return validateFormFieldFormat;
    }
    return {
      status: true,
    };
  };

  validateWithRegex = (data, regEx) => {
    return regEx.test(data);
  };

  validateFormFieldFormat = () => {
    let errorArray = [];
    var phoneno = this.state.custtelephoneno;
    var firstname = this.state.custfirstname;
    var lastname = this.state.custlastname;
    var email = this.state.custemail;
    var bankAccount = this.state.custBankAccount;
    var patternNameRegEx = /^[A-Za-zก-๙]+$/;
    var patternEmailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    var patternNumberOnlyRegEx = /^[0-9]+$/;

    if (!this.validateWithRegex(phoneno, patternNumberOnlyRegEx)) {
      errorArray.push("หมายเลขโทรศัพท์ ต้องเป็นตัวเลขเท่านั้น");
    }
    // if (!this.validateWithRegex(firstname, patternNameRegEx)) {
    //   errorArray.push("ชื่อ ต้องเป็นภาษาไทยหรือภาษาอังกฤษเท่านั้น");
    // }
    // if (!this.validateWithRegex(lastname, patternNameRegEx)) {
    //   errorArray.push("นามสกุล ต้องเป็นภาษาไทยหรือภาษาอังกฤษเท่านั้น");
    // }
    // if (email !== "") {
    //   if (!this.validateWithRegex(email, patternEmailRegEx)) {
    //     errorArray.push("Email Address ไม่ถูกรูปแบบ");
    //   }
    // }
    if (bankAccount !== "") {
      if (!this.validateWithRegex(bankAccount, patternNumberOnlyRegEx)) {
        errorArray.push("เลขที่บัญชี ต้องเป็นตัวเลขเท่านั้น");
      }
    }
    if (errorArray.length > 0) {
      return {
        status: false,
        message: `<b>กรุณากรอกข้อมูลให้ถูกต้อง</b><br/>- ${errorArray.join(
          "<br/>- "
        )}`,
      };
    }
    return {
      status: true,
    };
  };

  validateFormPasswordMatch = () => {
    if (this.state.custpassno !== this.state.custfirmpassno) {
      return {
        status: false,
        message: "รหัสผ่าน ไม่ตรงกับ ยืนยันรหัสผ่าน",
      };
    }
    return {
      status: true,
    };
  };

  validateFormRequiredField = () => {
    let errorArray = [];
    if (this.state.custtelephoneno === "") {
      errorArray.push("หมายเลขโทรศัพท์");
    }
    if (this.state.custpassno === "") {
      errorArray.push("รหัสผ่าน");
    }
    if (this.state.custfirmpassno === "") {
      errorArray.push("ยืนยันรหัสผ่าน");
    }
    if (this.state.custfirstname === "") {
      errorArray.push("ชื่อ");
    }
    if (this.state.custlastname === "") {
      errorArray.push("นามสกุล");
    }
    // if (this.state.custemail === "") {
    //   errorArray.push("email");
    // }
    if (this.state.custline === "") {
      errorArray.push("Line id");
    }
    if (
      this.state.custacceptterm === "" ||
      this.state.custacceptterm === "false" ||
      this.state.custacceptterm === false
    ) {
      errorArray.push("ยอมรับข้อตกลงและเงื่อนไข");
    }
    if (errorArray.length > 0) {
      return {
        status: false,
        message: `<b>กรุณากรอกข้อมูล</b><br/>- ${errorArray.join("<br/>- ")}`,
      };
    }
    return {
      status: true,
    };
  };

  showHideLoading = (isShow) => {
    const { dispatch } = this.props;
    dispatch(showHideLoading(isShow));
  };

  showErrorPopup = (title, message) => {
    const { dispatch } = this.props;
    dispatch(
      showDisplayPopupError({
        title: title,
        message: message,
      })
    );
  };

  showMessagePopup = (title, message) => {
    const { dispatch } = this.props;
    dispatch(
      showDisplayPopupMessage({
        title: title,
        message: message,
      })
    );
  };

  render() {
    const { bank_list } = this.props;
    return (
      <Fragment>
        <Header />
        <Leftnav />
        <div className="main-content">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              {/* CONTENT HERE */}

              <div className="row feed-body">
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                  <div className="card shadow-none border-0 ms-auto me-auto login-card">
                    <div className="card-body rounded-0 text-left">
                      <h2 className="fw-700 display1-size display2-md-size mb-4">
                        สมัครสมาชิก
                      </h2>

                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group icon-input mb-3 error">
                          <i className="font-sm ti-mobile text-red pe-0"></i>
                          <input
                            type="text"
                            name="custtelephoneno"
                            value={this.state.custtelephoneno}
                            onChange={this.handleInputChange}
                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                            placeholder="หมายเลขโทรศัพท์"
                          />
                        </div>
                        <div className="form-group icon-input mb-3">
                          <input
                            type="Password"
                            name="custpassno"
                            value={this.state.custpassno}
                            onChange={this.handleInputChange}
                            className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                            placeholder="รหัสผ่าน"
                          />
                          <i className="font-sm ti-lock text-red  pe-0"></i>
                        </div>
                        <div className="form-group icon-input mb-3">
                          <input
                            type="Password"
                            name="custfirmpassno"
                            value={this.state.custfirmpassno}
                            onChange={this.handleInputChange}
                            className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                            placeholder="ยืนยันรหัสผ่าน"
                          />
                          <i className="font-sm ti-lock text-red  pe-0"></i>
                        </div>
                        <div className="form-group icon-input mb-3">
                          <i className="font-sm ti-user text-red  pe-0"></i>
                          <input
                            type="text"
                            name="custfirstname"
                            value={this.state.custfirstname}
                            onChange={this.handleInputChange}
                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                            placeholder="ชื่อ"
                          />
                        </div>
                        <div className="form-group icon-input mb-3">
                          <i className="font-sm ti-user text-red  pe-0"></i>
                          <input
                            type="text"
                            name="custlastname"
                            value={this.state.custlastname}
                            onChange={this.handleInputChange}
                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                            placeholder="นามสกุล"
                          />
                        </div>
                        {/* <div className="form-group icon-input mb-3">
                        <i className="font-sm ti-email text-grey-500 pe-0"></i>
                        <input type="text" name="custaddress" value={this.state.custaddress} onChange={this.handleInputChange}
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="ที่อยู่" />
                    </div> */}
                        {/* <div className="form-group icon-input mb-3">
                          <i className="font-sm ti-email text-grey-500 pe-0"></i>
                          <input
                            type="text"
                            name="custemail"
                            value={this.state.custemail}
                            onChange={this.handleInputChange}
                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                            placeholder="Email Address"
                          />
                        </div> */}
                        <div className="form-group icon-input mb-3">
                          <i className="font-sm ti-rss-alt text-red  pe-0"></i>
                          <input
                            type="text"
                            name="custline"
                            value={this.state.custline}
                            onChange={this.handleInputChange}
                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                            placeholder="Line ID"
                          />
                        </div>

                        <div className="form-group icon-input mb-3">
                          <i className="font-sm ti-money text-grey-500 pe-0"></i>
                          <select
                            value={this.state.custBankId}
                            onChange={this.handleInputChange}
                            name="custBankId"
                            className="style2-select form-control text-grey-900 font-xsss fw-600 ps-5"
                          >
                            <option key="0" value="0">
                              -- กรุณาเลือกธนาคาร --
                            </option>
                            {bank_list.map((data, i) => {
                              return (
                                <option key={i} no={i + 1} value={data.bankId}>
                                  {data.bankName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-group icon-input mb-3">
                          <i className="font-sm ti-money text-grey-500 pe-0"></i>
                          <input
                            type="text"
                            name="custBankAccountName"
                            value={this.state.custBankAccountName}
                            onChange={this.handleInputChange}
                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                            placeholder="ชื่อบัญชี"
                          />
                        </div>
                        <div className="form-group icon-input mb-3">
                          <i className="font-sm ti-money text-grey-500 pe-0"></i>
                          <input
                            type="text"
                            name="custBankAccount"
                            value={this.state.custBankAccount}
                            onChange={this.handleInputChange}
                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                            placeholder="เลขที่บัญชี"
                          />
                          <span className="font-xsss text-grey-600">
                            เลขที่บัญชีใส่เพื่อไว้สำหรับโอนเงินกรณีถูกรางวัล
                            ท่านสามารถบันทึกข้อมูลส่วนนี้ในภายหลังได้
                          </span>
                        </div>

                        <div className="form-check text-left mb-3">
                          <input
                            type="checkbox"
                            name="custacceptterm"
                            value={this.state.custacceptterm}
                            onChange={this.handleInputChange}
                            className="form-check-input mt-2"
                            id="exampleCheck2"
                          />
                          <label className="form-check-label font-xss text-grey-700">
                            ยอมรับข้อตกลงและเงื่อนไข
                          </label>
                        </div>

                        <div className="col-sm-12 p-0 text-left">
                          <div className="form-group mb-1">
                            {/* <a onClick={e => this.login(e)}  className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 ">Register</a> */}
                            <input
                              type="submit"
                              className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 "
                              value="สมัครสมาชิก"
                            />
                          </div>
                          <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                            คุณเป็นสมาชิกแล้ว?{" "}
                            <a href="/login" className="fw-700 ms-1">
                              เข้าสู่ระบบ
                            </a>
                          </h6>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                  <Events />
                </div>
              </div>

              {/* CONTENT ABOVE */}
            </div>
          </div>
        </div>
        {/* POPUP HERE */}
        {/* POPUP ABOVE */}
        <Appfooter />
      </Fragment>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {
    bank_list: state.bank.bank_list || {},
    dealer_ref: state.dealer.dealer_ref || null,
  };
};

const RegisterCust = connect(mapStateToProps)(RegisterCustComponent);
export default RegisterCust;
