import React, { Component, Fragment, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import PageTitle from "../components/PageTitle";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import "moment/locale/th";
import { toBuddhistYear } from "../libs/buddhist-year";
import qs from "qs";
import Lottery from "../components/Lottery";
import "react-datepicker/dist/react-datepicker.css";
import {
  getAdminPaymentListData,
  getDealerPaymentListData,
  setAdminPaymentDetailSelected,
} from "../actions/payment";
import {
  getImageFileAndShowSlipCheck,
  getImageFileAndShow,
} from "../actions/file";
import display from "../reducers/display";

const DealerPaymentListComponent = (props) => {
  const dispatch = useDispatch();

  moment.locale("th");
  moment.tz.setDefault("Asia/Bangkok");

  const {
    dealer_payment_list,
    current_installment,
    installmentDetail,
    user_detail,
  } = props;
  // console.log("props => ", installmentDetail.installment_list);
  const [paymentno, setPaymentno] = useState(null);
  const [personname, setPersonname] = useState(null);
  const [startdate, setStartdate] = useState(null);
  const [enddate, setEnddate] = useState(null);
  const [installmentId, setInstallmentId] = useState(null);

  const initialPage = () => {
    // dispatch(setAdminPaymentDetailSelected(null))
    dispatch(
      getDealerPaymentListData({
        dealerid: user_detail.dealer_id,
        paymentno,
        personname,
        startdate,
        enddate,
        installmentId,
      })
    );
  };

  const setQueryData = () => {
    const { search } = window.location;
    const queryString = search.replace("?", "");

    const { paymentno, personname, startdate, enddate, installmentId } =
      qs.parse(queryString);

    const queryParams = new URLSearchParams(window.location.search);
    // paymentno: "21", personname: "Th", startdate: "2021-12-06", enddate: "2021-12-07
    setPaymentno(paymentno ?? "");
    setPersonname(personname ?? "");
    setStartdate(startdate ?? "");
    setEnddate(enddate ?? "");
    setInstallmentId(installmentId ?? "");
    // setStartdateInput(startdateIn === ""? "" :moment(startdateIn, 'yyyy-MM-DD').toDate())
    // setEnddateInput(enddateIn === ""? "" :moment(enddateIn, 'yyyy-MM-DD').toDate())
  };

  useEffect(() => {
    setQueryData();
  }, []);

  useEffect(() => {
    // console.log(paymentno, personname, startdate, enddate)
    // console.log(paymentno && personname && startdate && enddate)
    // if (paymentno && personname && startdate && enddate) {
    //   console.log('init')
    if (user_detail?.dealer_id) {
      initialPage();
    }

    // }
  }, [user_detail]);

  const handleClear = () => {
    setPaymentno("");
    setPersonname("");
    setStartdate("");
    setEnddate("");
    setInstallmentId("");
  };

  const handleSubmit = () => {
    console.log(startdate, moment(startdate).format("yyyy-MM-DD"));
    dispatch(
      getDealerPaymentListData({
        dealerid: user_detail.dealer_id,
        paymentno,
        personname,
        startdate: startdate ? moment(startdate).format("yyyy-MM-DD") : "",
        enddate: enddate ? moment(enddate).format("yyyy-MM-DD") : "",
        installmentId: installmentId,
      })
    );
    //TODO Validate value before submit to backend
    // const validate = this.validateFormRequiredField()
    // if(!validate.status){
    //   this.showErrorPopup("Error!!", validate.message)
    // }
    // else{
    //   const dataSubmit = {
    //     paymentNo: data.paymentNo,
    //     file_name: this.state.file_name,
    //     file: this.state.file,
    //     refno: this.state.refno,
    //     refdate: this.state.refdate,
    //     amount: this.state.amount,
    //   }
    //   onSubmit(dataSubmit)
    // }
  };

  // const handleClickViewDetail = (data) => {
  //   // console.log(data);

  //   dispatch(setAdminPaymentDetailSelected(data));
  //   // paymentno: "21", personname: "Th", startdate: "2021-12-06", enddate: "2021-12-07

  //   let searchParam = "?";
  //   if (this.state.paymentno !== "") {
  //     searchParam = searchParam + "&paymentno=" + this.state.paymentno;
  //   }
  //   if (this.state.personname !== "") {
  //     searchParam = searchParam + "&personname=" + this.state.personname;
  //   }
  //   if (this.state.startdate !== "") {
  //     searchParam = searchParam + "&startdate=" + this.state.startdate;
  //   }
  //   if (this.state.enddate !== "") {
  //     searchParam = searchParam + "&enddate=" + this.state.enddate;
  //   }
  //   this.props.history.push("/admin/payment-detail" + searchParam);
  // };
  const handleInstallationIdChange = (e) => {
    const selected = installmentDetail.installment_list.find(
      (i) => i.installmentId === e.target.value - 0
    );
    const previous = installmentDetail.installment_list.find(
      (i) => i.installmentId === e.target.value - 1
    );
    if (selected) {
      const installmentDate = selected.installmentDate;
      const endDate = moment(installmentDate).toDate();
      let startDate = moment(installmentDate).add(-16, "day").toDate();
      if (previous) {
        startDate = moment(previous.installmentDate).add(1, "day").toDate();
      }

      setStartdate(startDate);
      setEnddate(endDate);
    } else {
      setStartdate("");
      setEnddate("");
    }
    setInstallmentId(e.target.value);
  };
  const handleStartDate = (date) => {
    console.log(date);
    setStartdate(date);
  };
  const handleEndDate = (date) => {
    setEnddate(date);
  };
  const handleClickViewDetail = (data) => {
    // console.log(data);
    // lotto-pane lotto-pane-table-26 hide
    let ele = document.getElementsByClassName(`lotto-pane-table-${data.id}`)[0];
    let eleDT = document.getElementsByClassName(
      `lotto-pane-table-dt-${data.id}`
    )[0];

    if (ele.classList.contains("hide")) {
      ele.classList.remove("hide");
      ele.classList.add("active");
    } else {
      ele.classList.add("hide");
      ele.classList.remove("active");
    }

    if (eleDT.classList.contains("hide")) {
      eleDT.classList.remove("hide");
      eleDT.classList.add("active");
    } else {
      eleDT.classList.add("hide");
      eleDT.classList.remove("active");
    }
  };

  const handleClickCheckSlip = (data) => {
    const { dispatch } = this.props;
    dispatch(setAdminPaymentDetailSelected(data));
    dispatch(getImageFileAndShowSlipCheck(data.fileslip));
  };

  return (
    <Fragment>
      <Header />
      <Leftnav />
      {/* <Rightchat /> */}

      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row lottery-search">
              <div className="col-xl-12">
                <PageTitle title="รายการคำสั่งซื้อของลูกค้า" />

                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                  <h4 className="mont-font fw-600 font-md mb-2 mb-0 mt-0">
                    ค้นหา
                  </h4>

                  <div className="row">
                    <div className="row">
                      <div className="col-sm-3 col-xs-12">
                        <div className="form-group lottery-digit">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            เลขที่คำสั่งซื้อ
                          </label>
                          <input
                            type="text"
                            name="paymentno"
                            value={paymentno}
                            onChange={(e) => {
                              setPaymentno(e.target.value);
                            }}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-sm-3 col-xs-12">
                        <div className="form-group lottery-digit">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            ชื่อลูกค้า
                          </label>
                          <input
                            type="text"
                            name="personname"
                            value={personname}
                            onChange={(e) => {
                              setPersonname(e.target.value);
                            }}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-3 col-xs-12">
                        <div className="form-group">
                          {/* {"installmentDetail" +
                          installmentDetail.installment_list} */}
                          <label className="mont-font fw-600 font-xsss mb-2">
                            งวดวันที่
                          </label>
                          <select
                            name="installmentId"
                            id="installmentId"
                            className="form-select"
                            onChange={handleInstallationIdChange}
                            value={installmentId}
                          >
                            <option value="">ทุกงวด</option>
                            {installmentDetail.installment_list?.map(
                              (data, index) => {
                                // console.log(data.installmentDate);
                                // console.log(data.installmentId);
                                return (
                                  <option value={data.installmentId}>
                                    {toBuddhistYear(
                                      moment(data.installmentDate),
                                      "DD/MM/YYYY"
                                    )}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-3 col-xs-12">
                        <div className="form-group lottery-digit">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            วันที่คำสั่งซื้อ (ตั้งแต่)
                          </label>
                          <DatePicker
                            selected={startdate}
                            closeOnScroll={true}
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            className="form-control"
                            showPopperArrow={false}
                            placeholderText="dd/MM/yyyy"
                            value={startdate}
                            // dateFormat="MMMM d, yyyy h:mm aa"
                            // onChange={(date) => () => {
                            //   setStartdate(date);
                            // }}
                            onChange={handleStartDate}
                          />
                          {/* <input type="text" name="startdate" value={this.state.startdate} onChange={this.handleInputChange} className="form-control" /> */}
                        </div>
                      </div>

                      <div className="col-sm-3 col-xs-12">
                        <div className="form-group lottery-digit">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            วันที่คำสั่งซื้อ (ถึง)
                          </label>
                          <DatePicker
                            selected={enddate}
                            closeOnScroll={true}
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            className="form-control"
                            showPopperArrow={false}
                            placeholderText="dd/MM/yyyy"
                            // dateFormat="MMMM d, yyyy h:mm aa"
                            // onChange={(date) => () => {
                            //   setEnddate(date);
                            // }}
                            onChange={handleEndDate}
                          />
                          {/* <input type="text" name="enddate" value={this.state.enddate} onChange={this.handleInputChange} className="form-control" /> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3 col-xs-12">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          &nbsp;
                        </label>
                        <button
                          onClick={handleSubmit}
                          className="update-cart bg-cyan d-block text-white fw-600 text-uppercase 
                    font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"
                        >
                          ค้นหา
                        </button>
                      </div>

                      <div className="col-sm-3 col-xs-12">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          &nbsp;
                        </label>
                        <button
                          onClick={handleClear}
                          className="update-cart bg-white d-block text-cyan fw-600  font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-success border"
                        >
                          ล้างข้อมูล
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    marginBlock: "15px",
                  }}
                >
                  <div style={{ flex: 6, textAlign: "right", padding: "5px" }}>
                    รายได้ประจำงวด
                  </div>
                  <div
                    style={{
                      flex: 1,
                      border: "1px dashed",
                      borderRadius: "2px",
                      padding: "5px",
                      textAlign: "right",
                    }}
                  >
                    {dealer_payment_list
                      .filter((p) => {
                        return (
                          moment(current_installment.installmentDate).format(
                            "YYYYMMDD"
                          ) === moment(p.install_date).format("YYYYMMDD")
                        );
                      })
                      .reduce((a, b) => a + b.request_dealer_add_amount, 0)}
                  </div>
                </div>

                <div className="row payment-list mb-show">
                  <div className="col-xl-12 cart-wrapper mb-0">
                    {dealer_payment_list &&
                      dealer_payment_list.map((data, index) => {
                        let pay_status = "";
                        pay_status =
                          data.pay_status === "P" ? "รอชำระ" : pay_status;
                        pay_status =
                          data.pay_status === "V" ? "รอตรวจสอบ" : pay_status;
                        pay_status =
                          data.pay_status === "S"
                            ? "Payment success"
                            : pay_status;
                        pay_status =
                          data.pay_status === "C" ? "Cancel" : pay_status;
                        return (
                          <div key={index} className="row">
                            <div className="col-lg-12 mb-3 ">
                              <div className="card p-3 rounded-3 shadow-xs border-0 bg-grey font-xss">
                                <div className="">
                                  เลขที่คำสั่งซื้อ:{" "}
                                  <strong>{data.pay_no}</strong>
                                </div>
                                <div className="">
                                  วันที่คำสั่งซื้อ:{" "}
                                  <strong>
                                    {toBuddhistYear(
                                      moment(data.payment_date),
                                      "DD/MM/YYYY HH:mmน."
                                    )}
                                  </strong>
                                </div>
                                <div className="">
                                  งวดประจำวันที่:{" "}
                                  <strong>
                                    {toBuddhistYear(
                                      moment(data.install_date),
                                      "DD/MM/YYYY"
                                    )}
                                  </strong>
                                </div>
                                <div className="">
                                  ชื่อลูกค้า: <strong>{data.custname}</strong>
                                </div>
                                <div className="">
                                  จำนวนทั้งหมด: <strong>{data.total}</strong>
                                </div>
                                {/* <div className="">
                                  จำนวนสลาก: <strong>{data.totaldoc}</strong>
                                  &nbsp;(นำเข้า={data.verified_success} /
                                  ไม่ถูกต้อง={data.verified_error})
                                </div> */}

                                <div className="">
                                  จำนวนเงิน: <strong>{data.amount}</strong>
                                </div>

                                <div className="">
                                  กำไร(ตามส่วน):{" "}
                                  <strong>
                                    {data.request_dealer_add_amount}
                                  </strong>
                                </div>

                                <div className="">
                                  วิธีชำระเงิน:{" "}
                                  <strong>
                                    {data.pay_method === "B"
                                      ? "โอนผ่านธนาคาร"
                                      : data.pay_method}
                                  </strong>
                                </div>
                                <div className="">
                                  สถานะ: <strong>{pay_status}</strong>
                                  <div
                                    className={`nav-content-bttn open-font me-3 d-inline-block float-right lotto-view-btn lotto-view-btn-${index} hide`}
                                    onClick={() => handleClickViewDetail(data)}
                                  >
                                    <i className="feather-zoom-in btn-round-xs"></i>
                                    <span>ดูรายละเอียด</span>
                                  </div>
                                </div>
                                <div className="border-0 bg-grey font-xss">
                                  <div
                                    className={`lotto-pane lotto-pane-table-${data.id} hide`}
                                    style={{ paddingTop: "15px" }}
                                  >
                                    {data.items.map((item, indexItem) => {
                                      const lottoData = {
                                        numberDate: item.numberDate,
                                        numberIds: item.numberIds,
                                        numberNo: item.numberNo,
                                        agentName: item.agentName,
                                        packAmount: item.packAmount,
                                        packNum: item.packNum,
                                        packId: item.packId,
                                        fileIds: item.fileIds,
                                        totalNumber: item.totalNumber,
                                        totalAmount: item.totalAmount,
                                        dealerName: item.dealerName,
                                        dealerCode: item.dealerCode,
                                      };
                                      return (
                                        <div
                                          className="lottery-search-result d-inline-block float-left"
                                          key={indexItem}
                                        >
                                          <Lottery
                                            data={lottoData}
                                            page="dealerPayment"
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="row payment-list mb-hide">
                  <div className="col-lg-12 mb-3">
                    <div className="table-content table-responsive">
                      <table className="table text-center">
                        <thead className="bg-grey rounded-3">
                          <tr>
                            <th className="border-0 p-4">เลขที่คำสั่งซื้อ</th>
                            <th className="border-0 p-4 text-left">
                              วันที่คำสั่งซื้อ
                            </th>
                            <th className="border-0 p-4">งวดประจำวันที่</th>
                            <th className="border-0 p-4">ชื่อลูกค้า</th>
                            <th className="border-0 p-4">จำนวนทั้งหมด</th>
                            <th className="border-0 p-4">จำนวนเงิน</th>
                            <th className="border-0 p-4">กำไร(ตามส่วน)</th>
                            <th className="border-0 p-4">สถานะ</th>
                            <th className="border-0 p-4">วิธีชำระเงิน</th>
                            <th className="border-0 p-4">Slip</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dealer_payment_list != null &&
                            dealer_payment_list.map((data, index) => {
                              const rowClass =
                                index % 2 === 0 ? "" : "bg-lightblue";
                              let pay_status = "";
                              pay_status =
                                data.pay_status === "P" ? "รอชำระ" : pay_status;
                              pay_status =
                                data.pay_status === "V"
                                  ? "รอตรวจสอบ"
                                  : pay_status;
                              pay_status =
                                data.pay_status === "S"
                                  ? "Payment success"
                                  : pay_status;
                              pay_status =
                                data.pay_status === "C" ? "Cancel" : pay_status;
                              return [
                                <Fragment key={`fragment-${index}`}>
                                  <tr
                                    key={index}
                                    className={`data-row ${rowClass}`}
                                  >
                                    {/* <td className="product-thumbnail ps-0">{index + 1}</td> */}
                                    <td className="product-headline text-left wide-column">
                                      <h3
                                        className="lotto-view-btn"
                                        onClick={() =>
                                          handleClickViewDetail(data)
                                        }
                                      >
                                        <strong>{data.pay_no}</strong>
                                      </h3>
                                    </td>
                                    <td className="product-quantity">
                                      {toBuddhistYear(
                                        moment(data.payment_date),
                                        "DD/MM/YYYY HH:mmน."
                                      )}
                                    </td>
                                    <td className="product-quantity">
                                      {toBuddhistYear(
                                        moment(data.install_date),
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td className="product-quantity">
                                      {data.custname}
                                    </td>
                                    <td className="product-quantity">
                                      {data.total}
                                    </td>
                                    <td className="product-quantity">
                                      {data.amount}
                                    </td>
                                    <td className="product-quantity">
                                      {data.request_dealer_add_amount}
                                    </td>
                                    <td className="product-quantity">
                                      {pay_status}
                                    </td>
                                    <td className="product-quantity">
                                      {data.pay_method === "B"
                                        ? "โอนผ่านธนาคาร"
                                        : data.pay_method}
                                    </td>
                                    <td className="product-quantity">
                                      {data.fileslip !== null && (
                                        <div
                                          className={`nav-content-bttn open-font me-3 d-inline-block float-right lotto-view-btn lotto-view-btn-${index} hide`}
                                          onClick={() =>
                                            this.handleClickCheckSlip(data)
                                          }
                                        >
                                          <i className="feather-zoom-in btn-round-xs"></i>
                                          <span>ตรวจสอบ Slip</span>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                  <tr
                                    className={`lotto-pane-dt lotto-pane-table-dt-${data.id} hide`}
                                  >
                                    <td colSpan={9}>
                                      <div
                                        className={``}
                                        style={{ paddingTop: "15px" }}
                                      >
                                        {data.items.map((item, indexItem) => {
                                          const lottoData = {
                                            numberDate: item.numberDate,
                                            numberIds: item.numberIds,
                                            numberNo: item.numberNo,
                                            agentName: item.agentName,
                                            packAmount: item.packAmount,
                                            packNum: item.packNum,
                                            packId: item.packId,
                                            fileIds: item.fileIds,
                                            totalNumber: item.totalNumber,
                                            totalAmount: item.totalAmount,
                                            dealerName: item.dealerName,
                                            dealerCode: item.dealerCode,
                                          };
                                          return (
                                            <div
                                              className="lottery-search-result d-inline-block float-left"
                                              key={indexItem}
                                            >
                                              <Lottery
                                                data={lottoData}
                                                page="dealerPayment"
                                              />
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </td>
                                  </tr>
                                </Fragment>,
                              ];
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                {/* CONTENT ABOVE */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Popupchat /> */}
      {/* <Appfooter />  */}
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    dealer_payment_list: state.payment.dealer_payment_list || [],
    user_detail: state.user.user_detail || null,
    current_installment: state.number.current_installment || null,
    installmentDetail: state.number || null,
  };
};

const DealerPaymentList = connect(mapStateToProps)(DealerPaymentListComponent);
export default DealerPaymentList;
