
const MessageCommon = {
  systemError: `พบปัญหา กรุณาติดต่อผู้ดูแลระบบ`,
  errorFound: `พบข้อผิดพลาด`,
  accessDenied: ``,
  ok: `ตกลง`,
  close: `ปิด`,
  success: `สำเร็จ`,
  saveSuccess: `บันทึกเรียบร้อย`,
  networkError: `ขออภัยไม่สามารถเชื่อมต่อระบบได้ขณะนี้`
}

const MessageAuthen = {
  loginFail: `หมายเลขโทรศัพท์หรือรหัสผ่านไม่ถูกต้อง`,
  tokenFail: `Session ไม่ถูกต้องหรือหมดอายุกรุณาเข้าระบบใหม่อีกครั้ง`,
}

module.exports = {
  MessageCommon,
  MessageAuthen,
}