
const defaultState = {
  dealer_payment_list: [],
  payment_list: [],
  admin_payment_list: [],
  admin_payment: null,
  admin_payment_detail: [],
  payment_qr: null
}

const payment = (state = defaultState, action = "") => {
  switch(action.type) {
    case 'PAYMENT_SET_DEALER_PAYMENT_LIST_DATA':
      // console.log('PAYMENT_SET_PAYMENT_LIST_DATA')
      return {
        ...state,
        dealer_payment_list: action.value
      }
    case 'PAYMENT_SET_PAYMENT_LIST_DATA':
      // console.log('PAYMENT_SET_PAYMENT_LIST_DATA')
      return {
        ...state,
        payment_list: action.value
      }
    case 'PAYMENT_SET_ADMIN_PAYMENT_LIST_DATA':
      // console.log('PAYMENT_SET_ADMIN_PAYMENT_LIST_DATA')
      return {
        ...state,
        admin_payment_list: action.value
      }
    case 'PAYMENT_SET_ADMIN_PAYMENT_DATA':
      // console.log('PAYMENT_SET_ADMIN_PAYMENT_DATA')
      return {
        ...state,
        admin_payment: action.value
      }
    case 'PAYMENT_SET_ADMIN_PAYMENT_DETAIL_DATA':
      // console.log('PAYMENT_SET_ADMIN_PAYMENT_DETAIL_DATA')
      return {
        ...state,
        admin_payment_detail: action.value
      }
    case 'PAYMENT_SET_PAYMENT_QR_DATA':
      // console.log('PAYMENT_SET_PAYMENT_LIST_DATA')
      return {
        ...state,
        payment_qr: action.value
      }
    default:
      return state
  }
}

export default payment