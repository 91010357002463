export const HttpConst = {
    MethodGet : "GET",
    MethodPost : "POST",
    MethodPatch : "PATCH",
    MethodPut : "PUT",
    MethodDelete : "DELETE",
}

export const ErrConst = {
    Axios_ERR_NETWORK : "ERR_NETWORK"
}