import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { changePassword } from "../actions/customer";
import { showDisplayPopupError } from "../actions/display";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';


class PasswordComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			customer_password: {
				current: "",
				new: "",
				confirmnew: ""
			}
		}
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleInputChange = event => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.setState({
			customer_password: {
				...this.state.customer_password,
				[name]: value
			}
		});
	}

	handleSubmit = event => {
		const { dispatch } = this.props
		event.preventDefault();
		console.log(this.state)
		//TODO Validate value before submit to backend
		const validate = this.validateForm()
		if (!validate.status) {
			this.showErrorPopup("Error!!", validate.message)
		}
		else {
			dispatch(changePassword(this.state.customer_password))
		}
	}

	validateForm = () => {
		const validateRequired = this.validateFormRequiredField()
		if (!validateRequired.status) {
			return validateRequired
		}
		return {
			"status": true,
		}
	}

	validateFormRequiredField = () => {
		let errorArray = [];
		if (this.state.customer_password.current === "") {
			errorArray.push("รหัสผ่านปัจจุบัน");
		}
		if (this.state.customer_password.new === "") {
			errorArray.push("รหัสผ่านใหม่");
		}
		if (this.state.customer_password.confirmnew === "") {
			errorArray.push("ยืนยันรหัสผ่านใหม่");
		}
		if (this.state.customer_password.new !== "" &&
			this.state.customer_password.confirmnew !== ""
		) {
			if (this.state.customer_password.new !== this.state.customer_password.confirmnew) {
				errorArray.push("ยืนยันรหัสผ่านใหม่ ไม่ตรงกับ รหัสผ่านใหม่");
			}
		}
		if (errorArray.length > 0) {
			return {
				"status": false,
				"message": `<b>กรุณากรอกข้อมูล</b><br/>- ${errorArray.join("<br/>- ")}`
			}
		}
		return {
			"status": true,
		}
	}

	showErrorPopup = (title, message) => {
		const { dispatch } = this.props
		dispatch(showDisplayPopupError({
			"title": title,
			"message": message
		}))
	}

	render() {
		return (
			<Fragment>

				<div className="main-wrapper">

					<Header />
					<Leftnav />
					{/* <Rightchat /> right-chat-active */}


					<div className="main-content bg-lightblue theme-dark-bg ">

						<div className="middle-sidebar-bottom">
							<div className="middle-sidebar-left">
								<div className="middle-wrap">
									<div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
										<div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
											<Link to="/defaultsettings" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
											<h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">เปลี่ยนรหัสผ่าน</h4>
										</div>

										<div className="card-body p-lg-5 p-4 w-100 border-0">

											<form onSubmit={this.handleSubmit}>
												<div className="row">
													<div className="col-lg-12 mb-3">
														<div className="form-gorup">
															<label className="mont-font fw-600 font-xssss">รหัสผ่านปัจจุบัน</label>
															<input type="password" value={this.state.customer_password.current} onChange={this.handleInputChange}
																name="current" className="form-control" />
														</div>
													</div>

													<div className="col-lg-12 mb-3">
														<div className="form-gorup">
															<label className="mont-font fw-600 font-xssss">รหัสผ่านใหม่</label>
															<input type="password" value={this.state.customer_password.new} onChange={this.handleInputChange}
																name="new" className="form-control" />
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-lg-12 mb-3">
														<div className="form-gorup">
															<label className="mont-font fw-600 font-xssss">ยืนยันรหัสผ่านใหม่</label>
															<input type="password" value={this.state.customer_password.confirmnew} onChange={this.handleInputChange}
																name="confirmnew" className="form-control" />
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-lg-12 mb-0">
														<input type="submit" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 " value="บันทึก" />
														{/* <a href="/password" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">Save</a> */}
													</div>
												</div>


											</form>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

			</Fragment>
		);
	}
}

const mapStateToProps = function(state, props){
  return {
  }
}

const Password = connect(mapStateToProps)(PasswordComponent)
export default Password;