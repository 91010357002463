import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { adminCreateDealerFromCustomer } from "../actions/dealer";

const PopupCustomerToDealerFormComponent = (props) => {
  const { is_show, data, onClose, currentSearch } = props;
  const { userId } = data;

  const dispatch = useDispatch();

  const [idcard, setIdcard] = useState("");
  const [province, setProvince] = useState("");

  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  const close = () => {
    // this.setState({ popup: { is_show: false, data: {} }, setPasssword: "" });
    setIdcard("");
    setProvince("");
    setLoading(false);
    onClose();
  };

  // const sendingData = () => {
  //   setDisable(true);
  //   setLoading(true);
  // };

  const checkForm = () => {
    return idcard !== "" && province !== "" && userId;
  };

  const handleOnSave = () => {
    // console.log(newPassword, userName, userId);
    // sendingData();
    dispatch(
      adminCreateDealerFromCustomer({ userId, idcard, province }, currentSearch)
    );
    close();
  };

  // useEffect(() => {
  //   if (createdDealer) {
  //     setLoading(false);
  //   }
  // }, [createdDealer]);

  return (
    <Modal centered backdrop="static" show={is_show}>
      <article className={`message is-primary`}>
        <div className="message-header">
          สร้าง Dealer
          <button
            className="delete"
            aria-label="delete"
            onClick={close}
          ></button>
        </div>
        <div className="message-body">
          <div>
            <label htmlFor="idcard">รหัสบัตรประชาชน</label>
            <input
              type="text"
              name="idcard"
              className="form-control"
              disabled={loading || disable}
              value={idcard}
              onChange={(e) => setIdcard(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="idcard">จังหวัด</label>
            <input
              type="text"
              name="province"
              className="form-control"
              disabled={loading || disable}
              value={province}
              onChange={(e) => setProvince(e.target.value)}
            />
          </div>

          <div className="row mt-3">
            <div className="col-6">
              <button
                className="bg-cyan d-block text-white rounded-3 border-size-md p-2 text-center w-100 border-0"
                disabled={!checkForm() || loading || disable}
                onClick={handleOnSave}
              >
                สร้างดีลเลอร์
              </button>
            </div>
          </div>
        </div>
      </article>
    </Modal>
  );
};

const mapStateToProps = function (state, props) {
  return {
    // is_show: props.is_show || false,
    // user_name: props.data || {},
    // image_name: state.display.popup_image.image_name || "",
  };
};

const PopupCustomerToDealerForm = connect(mapStateToProps)(
  PopupCustomerToDealerFormComponent
);
export default PopupCustomerToDealerForm;
