const defaultState = {
  search_criteria: null,
  search_result: null,
  current_installment: null,
  previous_installment: null,
  installment_list: null,
};

const number = (state = defaultState, action = "") => {
  switch (action.type) {
    case "NUMBER_SET_SEARCH_CRITERIA":
      return {
        ...state,
        search_criteria: action.value,
      };
    case "NUMBER_SET_SEARCH_RESULT":
      return {
        ...state,
        search_result: action.value,
      };
    case "INSTALLMENT_SET_CURRENT":
      return {
        ...state,
        current_installment: action.value,
      };
    case "INSTALLMENT_SET_PREVIOUS":
      return {
        ...state,
        previous_installment: action.value,
      };
    case "INSTALLMENT_SET_ALL":
      return {
        ...state,
        installment_list: action.value,
      };
    default:
      return state;
  }
};

export default number;
