import React, { Component, Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import PageTitle from "../components/PageTitle";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { getImageFileAndShow } from "../actions/file";
import { getAgentNumberSearch, upstatusAgentNumber } from "../actions/agent";

const AgentNumberSearchComponent = (props) => {
  const { agent_number_list } = props;

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    numb: "",
    numberStatus: "",
    agentId: "",
    agentCode: "",
    agentName: "",
    installDate: "",
    installDate_input: "",
    paymentNo: "",
  });

  const [agentNumberList, setAgentNumberList] = useState([]);

  const [scrollTop, setScrollTop] = useState(0);

  const [page, setPage] = useState(1);
  const [reach, setReach] = useState(false);

  const initialPage = () => {
    dispatch(getAgentNumberSearch(formData));
  };

  const setQueryData = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (
      queryParams.get("agentCode") != null ||
      queryParams.get("agentName") != null ||
      queryParams.get("numb") != null ||
      queryParams.get("installDate") != null ||
      queryParams.get("numberStatus") != null
    ) {
      let agentCodeIn = "";
      let agentNameIn = "";
      let numbIn = "";
      let installDateIn = "";
      let numberStatusIn = "";
      if (queryParams.get("agentCode") != null) {
        agentCodeIn = queryParams.get("agentCode");
      }
      if (queryParams.get("agentName") != null) {
        agentNameIn = queryParams.get("agentName");
      }
      if (queryParams.get("numb") != null) {
        numbIn = queryParams.get("numb");
      }
      if (queryParams.get("installDate") != null) {
        installDateIn = queryParams.get("installDate");
      }
      if (queryParams.get("numberStatus") != null) {
        numberStatusIn = queryParams.get("numberStatus");
      }
      setFormData({
        agentCode: agentCodeIn,
        agentName: agentNameIn,
        numb: numbIn,
        installDate_input:
          installDateIn === ""
            ? ""
            : moment(installDateIn, "yyyy-MM-DD").toDate(),
        numberStatus: numberStatusIn,
      });
    }

    initialPage();
  };

  const handleInputChange = (event) => {
    // console.log("handleInputChange")
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputDateChange = (date, inputValueName, inputName) => {
    // console.log("handleInputDateChange", date)
    if (date !== null) {
      const dateFormatted = moment(date).format("yyyy-MM-DD");
      setFormData({
        ...formData,
        [inputValueName]: dateFormatted,
        [inputName]: date,
      });
    } else {
      setFormData({
        ...formData,
        [inputValueName]: "",
        [inputName]: "",
      });
    }
  };

  const handleSubmit = () => {
    // console.log(this.state)
    setAgentNumberList([]);
    dispatch(getAgentNumberSearch(formData, 1));
    //TODO Validate value before submit to backend
  };

  const handleClickViewDetail = (data) => {
    // console.log(data);
    dispatch(
      getImageFileAndShow(
        data.fid,
        `${data.numberId} [${data.agentCode}: ${data.agentName}]`
      )
    );
  };

  const handleClickUpdateStatus = (data) => {
    const { dispatch } = this.props;
    // console.log(this.state)
    dispatch(upstatusAgentNumber(data.docId, formData));
  };

  const loadMore = () => {
    dispatch(getAgentNumberSearch(formData, page + 1));
    setPage(page + 1);
  };

  const onScrollTouch = () => {
    if (
      scrollTop + window.innerHeight === document.body.clientHeight &&
      agentNumberList.length
    ) {
      console.log("touch");
      if (!reach) {
        console.log("calling");
        loadMore();
      }
    }
  };

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    onScrollTouch();

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    if (agent_number_list) {
      if (!agent_number_list.length && agentNumberList.length) {
        setReach(true);
      }
      if (agent_number_list.length) {
        setAgentNumberList([
          ...agentNumberList,
          ...agent_number_list.map((n) => ({ ...n, view: false })),
        ]);
      }
    }
  }, [agent_number_list]);

  useEffect(() => {
    setQueryData();
  }, []);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      {/* <Rightchat /> */}

      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row lottery-search">
              <div className="col-xl-12">
                <PageTitle title="ค้นหาสลาก" />

                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                  <h4 className="mont-font fw-600 font-md mb-2 mb-0 mt-0">
                    ค้นหา
                  </h4>

                  <div className="row">
                    <div className="col-sm-3 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          รหัสตัวแทน
                        </label>
                        <input
                          type="text"
                          name="agentCode"
                          value={formData.agentCode}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-sm-3 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          ชื่อตัวแทน
                        </label>
                        <input
                          type="text"
                          name="agentName"
                          value={formData.agentName}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-sm-3 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          เลขสลาก
                        </label>
                        <input
                          type="text"
                          name="numb"
                          value={formData.numb}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-sm-3 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          วันที่สลาก
                        </label>
                        <DatePicker
                          selected={formData.installDate_input}
                          closeOnScroll={true}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                          className="form-control"
                          showPopperArrow={false}
                          placeholderText="dd/MM/yyyy"
                          onChange={(date) =>
                            handleInputDateChange(
                              date,
                              "installDate",
                              "installDate_input"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="col-sm-3 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          สถานะ
                        </label>
                        <select
                          className="form-control"
                          name="numberStatus"
                          value={formData.numberStatus}
                          onChange={handleInputChange}
                        >
                          <option value="">ทั้งหมด</option>
                          <option value="A">พร้อมขาย</option>
                          <option value="W">อยู่ในตะกร้า</option>
                          <option value="P">รอชำระ</option>
                          <option value="S">ขายแล้ว</option>
                          <option value="C">ยกเลิก</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-3 col-xs-12">
                      <div className="form-group lottery-digit">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          หมายเลขคำสั่งซื้อ
                        </label>
                        <input
                          type="text"
                          name="paymentNo"
                          value={formData.paymentno}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-sm-3 col-xs-12">
                      <label className="mont-font fw-600 font-xsss mb-2">
                        &nbsp;
                      </label>
                      <button
                        onClick={handleSubmit}
                        className="update-cart bg-blue-gradiant d-block text-white fw-600 text-uppercase 
                    font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"
                      >
                        ค้นหา
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row payment-list mb-show">
                  <div className="col-xl-12 cart-wrapper mb-0">
                    {agentNumberList?.map((data, index) => {
                      // let number_status = ""
                      // number_status = data.numberStatus === 'A' ? 'พร้อมขาย' : number_status
                      // number_status = data.numberStatus === 'W' ? 'อยู่ในตะกร้า' : number_status
                      // number_status = data.numberStatus === 'P' ? 'รอชำระ' : number_status
                      // number_status = data.numberStatus === 'S' ? 'ขายแล้ว' : number_status
                      // number_status = data.numberStatus === 'C' ? 'ยกเลิก' : number_status
                      return (
                        <div key={index} className="row">
                          <div className="col-lg-12 mb-3 ">
                            <div className="card p-3 pb-0 rounded-3 shadow-xs border-0 bg-grey font-xss">
                              <div className="">
                                รหัสตัวแทน: <strong>{data.agentCode}</strong>,
                                ชื่อตัวแทน: <strong>{data.agentName}</strong>
                              </div>
                              <div className="">
                                วันที่สลาก: <strong>{data.installDate}</strong>
                              </div>
                              <div className="">
                                เลขสลาก: <strong>{data.numberId}</strong>
                              </div>
                              <div className="">
                                งวดที่: <strong>{data.instalId}</strong>,
                                ชุดที่: <strong>{data.set}</strong>
                              </div>
                              <div className="">
                                สถานะ: <strong>{data.numberStatusText}</strong>
                              </div>
                              <div className="p-3">
                                {data.fid !== null && (
                                  <div
                                    className={`nav-content-bttn rounded-3 text-white bg-cyan open-font me-3 d-inline-block w90 float-left lotto-view-btn lotto-view-btn-${index} hide`}
                                    onClick={() => handleClickViewDetail(data)}
                                  >
                                    <i className="feather-zoom-in btn-round-xs"></i>
                                    <span>ดูสลาก</span>
                                  </div>
                                )}
                                {data.numberStatus === "A" && (
                                  <div
                                    className={`nav-content-bttn rounded-3 text-white bg-orange open-font me-3 d-inline-block w90 float-left lotto-view-btn lotto-view-btn-${index} hide`}
                                    onClick={() =>
                                      handleClickUpdateStatus(data)
                                    }
                                  >
                                    <i className="feather-trash-2 btn-round-xs"></i>
                                    <span>ลบข้อมูล</span>
                                  </div>
                                )}
                                {data.numberStatus === "C" && (
                                  <div
                                    className={`nav-content-bttn rounded-3 text-white bg-current open-font me-3 d-inline-block w90 float-left lotto-view-btn lotto-view-btn-${index} hide`}
                                    onClick={() =>
                                      handleClickUpdateStatus(data)
                                    }
                                  >
                                    <i className="feather-arrow-left btn-round-xs"></i>
                                    <span>ย้อนกลับ</span>
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* <div className="card p-3 rounded-3 shadow-xs border-0 bg-grey font-xss">
                              <div className="">
                              </div>
                            </div> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="row payment-list mb-hide">
                  <div className="col-lg-12 mb-3">
                    <div className="table-content table-responsive">
                      <table className="table text-center">
                        <thead className="bg-grey rounded-3">
                          <tr>
                            <th className="border-0 p-4">No</th>
                            <th className="border-0 p-4 text-left">
                              รหัสตัวแทน
                            </th>
                            <th className="border-0 p-4">ชื่อตัวแทน</th>
                            <th className="border-0 p-4">วันที่สลาก</th>
                            <th className="border-0 p-4">เลขสลาก</th>
                            <th className="border-0 p-4">งวดที่</th>
                            <th className="border-0 p-4">ชุดที่</th>
                            <th className="border-0 p-4">สถานะ</th>
                            <th className="border-0 p-4">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {agentNumberList?.map((data, index) => {
                            const rowClass =
                              index % 2 === 0 ? "" : "bg-lightblue";
                            // let number_status = ""
                            // number_status = data.numberStatus === 'A' ? 'พร้อมขาย' : number_status
                            // number_status = data.numberStatus === 'W' ? 'อยู่ในตะกร้า' : number_status
                            // number_status = data.numberStatus === 'P' ? 'รอชำระ' : number_status
                            // number_status = data.numberStatus === 'S' ? 'ขายแล้ว' : number_status
                            // number_status = data.numberStatus === 'C' ? 'ยกเลิก' : number_status
                            return [
                              <tr
                                key={index}
                                className={`data-row ${rowClass}`}
                              >
                                <td className="product-thumbnail ps-0">
                                  {index + 1}
                                </td>
                                <td className="product-quantity">
                                  {data.agentCode}
                                </td>
                                <td className="product-quantity">
                                  {data.agentName}
                                </td>
                                <td className="product-quantity">
                                  {data.installDate}
                                </td>
                                <td className="product-quantity">
                                  {data.numberId}
                                </td>
                                <td className="product-quantity">
                                  {data.instalId}
                                </td>
                                <td className="product-quantity">{data.set}</td>
                                <td className="product-quantity">
                                  {data.numberStatusText}
                                </td>
                                <td className="product-quantity">
                                  {data.fid !== null && (
                                    <div
                                      className={`nav-content-bttn rounded-3 text-white bg-cyan open-font me-3 d-inline-block w90 float-right lotto-view-btn lotto-view-btn-${index} hide w125 mb-3`}
                                      onClick={() =>
                                        handleClickViewDetail(data)
                                      }
                                    >
                                      <i className="feather-zoom-in btn-round-xs"></i>
                                      <span>ดูสลาก</span>
                                    </div>
                                  )}
                                  {data.numberStatus === "A" && (
                                    <div
                                      className={`nav-content-bttn rounded-3 text-white bg-orange open-font me-3 d-inline-block w90 float-right lotto-view-btn lotto-view-btn-${index} w125 hide`}
                                      onClick={() =>
                                        handleClickUpdateStatus(data)
                                      }
                                    >
                                      <i className="feather-trash-2 btn-round-xs"></i>
                                      <span>ลบข้อมูล</span>
                                    </div>
                                  )}
                                  {data.numberStatus === "C" && (
                                    <div
                                      className={`nav-content-bttn rounded-3 text-white bg-current open-font me-3 d-inline-block w90 float-right lotto-view-btn lotto-view-btn-${index} w125 hide`}
                                      onClick={() =>
                                        handleClickUpdateStatus(data)
                                      }
                                    >
                                      <i className="feather-arrow-left btn-round-xs"></i>
                                      <span>ย้อนกลับ</span>
                                    </div>
                                  )}
                                </td>
                              </tr>,
                            ];
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div style={{ textAlign: "center" }}>
                  <button onClick={loadMore}>Load more</button>
                </div>

                {/* CONTENT ABOVE */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Popupchat /> */}
      {/* <Appfooter />  */}
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    agent_number_list: state.agent.agent_number_list || [],
  };
};

const AgentNumberSearch = connect(mapStateToProps)(AgentNumberSearchComponent);
export default AgentNumberSearch;
