const defaultState = {
  user_list: [],
};

const userlist = (state = defaultState, action = "") => {
  switch (action.type) {
    case "USER_LIST_DATA":
      return {
        ...state,
        user_list: action.value,
      };
    default:
      return state;
  }
};

export default userlist;
