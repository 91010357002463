import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { hideDisplayPopupSlipCheck } from "../actions/display";
import { updatePaymentStatus } from "../actions/payment";

class PopupSlipCheckComponent extends Component {
  close = () => {
    const { dispatch } = this.props;
    dispatch(hideDisplayPopupSlipCheck());
  };

  handleSubmit = (status) => {
    const { dispatch, payment_data } = this.props;
    dispatch(updatePaymentStatus(payment_data.id, status));
  };

  render() {
    const { is_show, image_url, payment_data } = this.props;
    return (
      <Modal centered backdrop="static" show={is_show}>
        <article className={`message is-primary`}>
          <div className="message-header">
            ตรวจสอบ Slip
            <button
              className="delete"
              aria-label="delete"
              onClick={this.close}
            ></button>
          </div>
          <div className="message-body">
            <img src={image_url} alt="" />

            <div className="row pt-3">
              <div className="col-7">
                <div className="form-group mb-1">
                  <label className="text-dark-color text-grey-600 font-xss mb-2 fw-600">
                    เลขที่คำสั่งซื้อ:
                  </label>
                  &nbsp;
                  {payment_data?.pay_no || ""}
                </div>
              </div>
              <div className="col-5">
                <div className="form-group mb-1">
                  <label className="text-dark-color text-grey-600 font-xss mb-2 fw-600">
                    จำนวนเงิน:
                  </label>
                  &nbsp;
                  {payment_data?.amount || ""} บาท
                </div>
              </div>
              {payment_data?.pay_status === "V" && (
                <>
                  <div className="col-sm-7 col-xs-6 pt-3">
                    <button
                      className="button is-success w-100"
                      onClick={() => this.handleSubmit("S")}
                    >
                      ตรวจสอบแล้วผ่าน
                    </button>
                  </div>
                  <div className="col-sm-5 col-xs-6 pt-3">
                    <button
                      className="button is-danger w-100"
                      onClick={() => this.handleSubmit("C")}
                    >
                      ตรวจสอบไม่ผ่าน
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </article>
      </Modal>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {
    is_show: state.display.popup_slip_check.is_show || false,
    image_url: state.display.popup_slip_check.image_url || "",
    image_name: state.display.popup_slip_check.image_name || "",
    payment_data: state.payment.admin_payment || null,
  };
};

const PopupSlipCheck = connect(mapStateToProps)(PopupSlipCheckComponent);
export default PopupSlipCheck;
