import { ServerInfo, ServerInfo_Code } from "../constants/config";
import Message from "../constants/message";
import { apiActionWithToken, apiAction } from "./actions";
import {
  showDisplayPopupMessage,
  showDisplayPopupError,
  closePopupData,
} from "./display";
import formData from "form-data";
import actionUtils from "./actionUtils";
import { redirect } from "react-router-dom";
import { getUserDetail } from "./user";
import cookie from "react-cookies";
import qs from "qs";

const CLASSNAME = `actions:userlist`;

export const onGetUserList = (data) => {
  // console.log(`[${CLASSNAME}] onGetAgent`)
  // console.log(data)
  let params = {
    username: "",
    firstname: "",
    rolename: "",
  };
  if (data) {
    if (data.username !== "" && data.username !== undefined) {
      params.username = data.username;
    }
    if (data.firstname !== "" && data.firstname !== undefined) {
      params.firstname = data.firstname;
    }
    if (data.rolename !== "" && data.rolename !== undefined) {
      params.rolename = data.rolename;
    }
  }

  return async (dispatch) => {
    // console.log(`[${CLASSNAME}] onGetAgent`)
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_ADMIN_USER_LIST}`,
        method: `GET`,
        data: params,
        onSuccess: onGetUserListResponse,
        onFailure: onGetUserListFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onGetUserListResponse = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onGetUserListResponse statusCode: ${statusCode}`);
  return async (dispatch) => {
    dispatch({
      type: "USER_LIST_DATA",
      value: data.result.content,
    });
  };
};

export const onGetUserListFailure = (statusCode, statusText, data) => {
  console.log(`[${CLASSNAME}] onGetUserListFailure statusCode: ${statusCode}`);
  return async (dispatch) => {
    console.log(data);
    dispatch(
      showDisplayPopupError({
        title: Message.MessageCommon.systemError,
        message: data.error + ": " + data.error_description,
      })
    );
  };
};

//CHANGE PASSWORD
export const changePassword = (data) => {
  console.log(`[${CLASSNAME}] changePassword`);
  let params = {
    userId: data.userId,
    newpassword: data.new,
  };
  return async (dispatch) => {
    console.log(data);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_CHANGEPASS}`,
        method: `POST`,
        data: params,
        onSuccess: onChangePasswordResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};

export const onChangePasswordResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onChangePasswordResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    if (data.result === "S") {
      dispatch(
        showDisplayPopupMessage({
          is_show: true,
          title: Message.MessageCommon.success,
          message: `เปลี่ยนรหัสผ่านสำเร็จ`,
          is_show_submit_button: true,
          is_show_cancel_button: false,
          submit_button_text: "Ok",
          cancel_button_text: null,
          status: "success",
          submit_function: null,
        })
      );
    } else {
      dispatch(
        showDisplayPopupError({
          title: Message.MessageCommon.errorFound,
          message: data.result,
        })
      );
    }
  };
};

export const adminChangePassword = (data) => {
  console.log(`[${CLASSNAME}] changePassword`);
  let params = {
    userId: data.userId,
    newpassword: data.new,
  };
  return async (dispatch) => {
    console.log(data);
    dispatch(
      apiActionWithToken({
        url: `${ServerInfo.URL_ADMIN_USER_CHANGEPASS}`,
        method: `POST`,
        data: params,
        onSuccess: onAdminChangePasswordResponse,
        onFailure: actionUtils.onCommonFailure,
        label: "APICALLER_CALL_API",
      })
    );
  };
};
export const onAdminChangePasswordResponse = (statusCode, statusText, data) => {
  console.log(
    `[${CLASSNAME}] onChangePasswordResponse statusCode: ${statusCode}`
  );
  return async (dispatch) => {
    console.log(data);
    if (data.result === true) {
      dispatch(
        showDisplayPopupMessage({
          is_show: true,
          title: Message.MessageCommon.success,
          message: `เปลี่ยนรหัสผ่านสำเร็จ`,
          is_show_submit_button: true,
          is_show_cancel_button: false,
          submit_button_text: "Ok",
          cancel_button_text: null,
          status: "success",
          submit_function: null,
        })
      );
    } else {
      dispatch(
        showDisplayPopupError({
          title: Message.MessageCommon.errorFound,
          message: data.result,
        })
      );
    }
  };
};
