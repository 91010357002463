import React, { Component, useEffect, useState, useRef } from "react";
import { ServerInfo, ServerInfo_Code } from "../constants/config";
import { connect, useDispatch } from "react-redux";
import { getFileByFileIdAndSetToImgTag } from "../actions/file";
import { apiActionWithTokenResponseType } from "../actions/actions";
import session from "../actions/session";

const LotteryComponent = (props) => {
  const { data: lotData, page, user_detail } = props;

  const [data, setData] = useState(null);

  const loadImageFromFileId = async (file_id) => {
    return await fetch(`${ServerInfo.URL_GET_FILE}/${file_id}`)
      .then((resp) => resp.json())
      .then((data) => data.imageUrl);
  };

  const convertToLottoDate = (dateIn) => {
    const monthNameTh = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ];
    const dateInSplit = dateIn.split("/");
    const d = parseInt(dateInSplit[0]);
    const m = monthNameTh[parseInt(dateInSplit[1]) - 1];
    const y = parseInt(dateInSplit[2]) + 543;
    return `${d} ${m} ${y}`;
  };

  useEffect(() => {
    if (lotData) {
      (async () => {
        if (lotData.fileIds) {
          const imagesLoads = await Promise.all(
            lotData.fileIds.map(async (fid) => {
              const url = await loadImageFromFileId(fid);
              return {
                id: fid,
                url: url ? url : null,
              };
            })
          );
          lotData.files = imagesLoads;
        }

        setData({
          ...lotData,
          ...(lotData.numberDate && {
            numberDate: convertToLottoDate(lotData.numberDate),
          }),
        });
      })();
    }
  }, [lotData]);
  return (
    <div className="lotto card d-block border-0 overflow-hidden position-relative">
      {data && (
        <>
          <span
            className={`lotto-set lotto-set-${data.packNum} fw-700 ps-3 pe-3 lh-32
          ls-2 d-inline-block text-white position-absolute z-index-1`}
          >
            {data.packNum} ใบ
          </span>

          <div
            className={`lotto-number fw-700 
          ls-2 d-inline-block position-absolute z-index-1`}
          ></div>

          {page === "payment" && (
            <div
              className={`lotto-payment 
          ls-2 d-inline-block position-absolute z-index-1`}
            >
              จำนวน: {data.totalNumber} ใบ ราคารวม: {data.totalAmount} บาท
            </div>
          )}

          {page === "adminPayment" && (
            <div
              className={`lotto-payment 
          ls-2 d-inline-block position-absolute z-index-1`}
            >
              ชื่อตัวแทน: {data.dealerName} รหัสตัวแทน: {data.dealerCode} <br />{" "}
              จำนวน: {data.totalNumber} ใบ ราคารวม: {data.totalAmount} บาท
            </div>
          )}

          {page === "dealerPayment" && (
            <div
              className={`lotto-payment 
          ls-2 d-inline-block position-absolute z-index-1`}
            >
              ชื่อตัวแทน: {data.dealerName} รหัสตัวแทน: {data.dealerCode} <br />{" "}
              จำนวน: {data.totalNumber} ใบ ราคารวม: {data.totalAmount} บาท
            </div>
          )}

          {!data.files && (
            <div className="text-right">
              {data.numberNo} จำนวน {data.packNum} ใบ
            </div>
          )}

          {data.files?.map((file, index) => {
            const style = index > 0 ? { marginTop: "-37%" } : { marginTop: 0 };
            return (
              <div
                key={`lot-image-container-${file.id}-${data.id}`}
                className="card-image"
                style={style}
              >
                {file.url && (
                  <img
                    src={file.url}
                    id={`lotto_fileid_${file.id}_${data.id}`}
                    alt={data.numberNo}
                    className="w-100"
                  />
                )}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

const mapStateToProps = function (state, props) {
  return {
    lotto_files: state.file.lotto_files || {},
    user_detail: state.user.user_detail || {},
  };
};

const Lottery = connect(mapStateToProps)(LotteryComponent);
export default Lottery;
