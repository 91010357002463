const defaultState = {
  stock_list: [],
  stock_detail: null,
  stock_detail_list: [],
  stock_zip_url: "",
  stock_wait_release: [],
  stock_agent_list: [],
};

const stock = (state = defaultState, action = "") => {
  switch (action.type) {
    case "STOCK_SET_STOCK_LIST_DATA":
      // console.log('STOCK_SET_STOCK_LIST_DATA')
      return {
        ...state,
        stock_list: action.value,
      };
    case "STOCK_AGENT_LIST_DATA":
      // console.log('AGENT_LIST_DATA')
      return {
        ...state,
        stock_agent_list: action.value,
      };
    case "DEALER_LIST_DATA":
      // console.log('AGENT_LIST_DATA')
      return {
        ...state,
        dealer_list: action.value,
      };
    case "STOCK_SET_STOCK_DETAIL_DATA":
      // console.log('STOCK_SET_STOCK_DETAIL_DATA')
      // console.log(action.value)
      return {
        ...state,
        stock_detail: action.value,
      };
    case "STOCK_SET_STOCK_DETAIL_LIST_DATA":
      // console.log('STOCK_SET_STOCK_DETAIL_LIST_DATA')
      return {
        ...state,
        stock_detail_list: action.value,
      };
    case "STOCK_SET_STOCK_ZIP_URL":
      // console.log('STOCK_SET_STOCK_ZIP_URL')
      return {
        ...state,
        stock_zip_url: action.value,
      };
    case "STOCK_WAIT_RELEASE":
      console.log("STOCK_WAIT_RELEASE", action.value);
      return {
        ...state,
        stock_wait_release: action.value,
      };
    default:
      return state;
  }
};

export default stock;
