import { ServerInfo, ServerInfo_Code } from "../constants/config";
import { apiActionWithToken } from "./actions";
import actionUtils from "./actionUtils";

const CLASSNAME = `actions:report`;

export const getWinnerList = (installmentId) => {
  // console.log(`[${CLASSNAME}] getBankAccountList`)
  return async (dispatch) => {
    dispatch(
      apiActionWithToken(
        {
          url: `${ServerInfo.URL_REPORT_WINNER}?installmentid=${installmentId}`,
          method: `GET`,
          data: {},
          onSuccess: onGetWinnerListResponse,
          onFailure: actionUtils.onCommonFailure,
          label: "APICALLER_CALL_API",
          onSuccessFailureParamOverride: {
            SERVICE_CODE: ServerInfo_Code.URL_REPORT_WINNER,
          },
        },
        true
      )
    );
  };
};

export const onGetWinnerListResponse = (statusCode, statusText, data) => {
  // console.log(`[${CLASSNAME}] onGetBankAccountListResponse statusCode: ${statusCode}`)
  return async (dispatch) => {
    console.log(data);
    dispatch({
      type: "REPORT_WINNER_LIST_DATA",
      value: data.result,
    });
  };
};

export const getDealerProfitList = (installmentId) => {
  // console.log(`[${CLASSNAME}] getBankAccountList`)
  return async (dispatch) => {
    dispatch(
      apiActionWithToken(
        {
          url: `${ServerInfo.URL_REPORT_DEALER_PROFIT}?installmentid=${installmentId}`,
          method: `GET`,
          data: {},
          onSuccess: onGetDealerProfitListResponse,
          onFailure: actionUtils.onCommonFailure,
          label: "APICALLER_CALL_API",
          onSuccessFailureParamOverride: {
            SERVICE_CODE: ServerInfo_Code.URL_REPORT_DEALER_PROFIT,
          },
        },
        true
      )
    );
  };
};

export const onGetDealerProfitListResponse = (statusCode, statusText, data) => {
  // console.log(`[${CLASSNAME}] onGetBankAccountListResponse statusCode: ${statusCode}`)
  return async (dispatch) => {
    console.log(data);
    dispatch({
      type: "REPORT_DEALER_PROFIT_LIST_DATA",
      value: data.result,
    });
  };
};
