import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import { getPaymentListData } from "../actions/payment";
import Lottery from "../components/Lottery";
import moment from "moment-timezone";
import "moment/locale/th";
import { toBuddhistYear } from "../libs/buddhist-year";
import "react-datepicker/dist/react-datepicker.css";

class OrderHistoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from_date: "",
      to_date: "",
      from_date_input: "",
      to_date_input: "",
      status: "",
    };
    // this.handleAddSlip = this.handleAddSlip.bind(this);
    // this.handlePopupAddSlipClose = this.handlePopupAddSlipClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    moment.locale("th");
  }

  componentDidMount() {
    this.initialPage();
  }

  initialPage = () => {
    const { dispatch } = this.props;
    dispatch(getPaymentListData());
  };

  handleViewLotto = (index) => {
    let ele = document.getElementsByClassName(`lotto-pane-${index}`)[0];
    if (ele.classList.contains("hide")) {
      ele.classList.remove("hide");
      ele.classList.add("active");
    } else {
      ele.classList.add("hide");
      ele.classList.remove("active");
    }
  };

  handleViewLottoTable = (index) => {
    let ele = document.getElementsByClassName(`lotto-pane-table-${index}`)[0];
    if (ele.classList.contains("hide")) {
      ele.classList.remove("hide");
      ele.classList.add("active");
    } else {
      ele.classList.add("hide");
      ele.classList.remove("active");
    }
  };

  handleInputChange = (event) => {
    console.log("handleInputChange");
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleInputDateChange = (date, inputValueName, inputName) => {
    console.log("handleInputDateChange", date);
    const dateFormatted = moment(date).format("yyyy-MM-DD");
    this.setState({
      [inputValueName]: dateFormatted,
      [inputName]: date,
    });
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    console.log(this.state);
    dispatch(getPaymentListData(this.state));
    //TODO Validate value before submit to backend
    // const validate = this.validateFormRequiredField()
    // if(!validate.status){
    //   this.showErrorPopup("Error!!", validate.message)
    // }
    // else{
    //   const dataSubmit = {
    //     paymentNo: data.paymentNo,
    //     file_name: this.state.file_name,
    //     file: this.state.file,
    //     refno: this.state.refno,
    //     refdate: this.state.refdate,
    //     amount: this.state.amount,
    //   }
    //   onSubmit(dataSubmit)
    // }
  };

  // validateFormRequiredField = () => {
  //   let errorArray = [];
  //   if(this.state.refno === ""){
  //     errorArray.push("เลขที่อ้างอิง");
  //   }
  //   if(this.state.refdate === ""){
  //     errorArray.push("วัน-เวลาที่ชำระ");
  //   }
  //   if(this.state.amount === ""){
  //     errorArray.push("จำนวนเงิน");
  //   }
  //   if(errorArray.length > 0){
  //     return {
  //       "status": false,
  //       "message": `<b>กรุณากรอกข้อมูล</b><br/>- ${errorArray.join("<br/>- ")}`
  //     }
  //   }
  //   return {
  //     "status": true,
  //   }
  // }
  // handleAddSlip = (data) => {
  //   console.log("handleAddSlip")
  //   this.setState({
  //     popup: {
  //       is_show: true,
  //       data: data,
  //     }
  //   });
  // }

  // handlePopupAddSlipClose = () => {
  //   console.log("handlePopupAddSlipClose")
  //   this.setState({
  //     popup: {
  //       is_show: false,
  //       data: null,
  //     }
  //   });
  // }

  // handlePopupAddSlipSubmit = (data) => {
  //   const { dispatch } = this.props
  //   console.log("handlePopupAddSlipSubmit")
  //   dispatch(uploadPaymentSlip(data))
  //   this.handlePopupAddSlipClose()
  // }

  render() {
    const { payment_list } = this.props;
    return (
      <Fragment>
        <Header />
        <Leftnav />
        <div className="main-content payment-page">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              {/* CONTENT HERE */}

              <div className="row">
                <div className="col-xl-12 cart-wrapper mb-4">
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="card p-4 bg-cyan rounded-3 shadow-xss bg-pattern border-0 overflow-hidden">
                        <div className="bg-pattern-div"></div>
                        <h2 className="display2-size display2-md-size fw-700 text-white mb-0 mt-0">
                          ประวัติการสั่งซื้อ
                          {/* <span className="fw-700 ls-3 text-grey-200 font-xsssss mt-2 d-block">4 PRODUCT FOUND</span> */}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                <h4 className="mont-font fw-600 font-md mb-2 mb-0 mt-0">
                  ค้นหา
                </h4>

                <div className="row">
                  <div className="col-sm-3 col-xs-12">
                    <div className="form-group lottery-digit">
                      <label className="mont-font fw-600 font-xsss mb-2">
                        ตั้งแต่วันที่
                      </label>
                      {/* <input type="date" name="from_date" value={this.state.from_date} onChange={this.handleInputChange}
                        className="form-control" /> */}

                      <DatePicker
                        selected={this.state.from_date_input}
                        closeOnScroll={true}
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        className="form-control"
                        showPopperArrow={false}
                        placeholderText="dd/MM/yyyy"
                        // dateFormat="MMMM d, yyyy h:mm aa"
                        onChange={(date) =>
                          this.handleInputDateChange(
                            date,
                            "from_date",
                            "from_date_input"
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="col-sm-3 col-xs-12">
                    <div className="form-group lottery-digit">
                      <label className="mont-font fw-600 font-xsss mb-2">
                        ถึงวันที่
                      </label>
                      {/* <input type="date" name="to_date" value={this.state.to_date} onChange={this.handleInputChange}
                        className="form-control" /> */}
                      <DatePicker
                        selected={this.state.to_date_input}
                        closeOnScroll={true}
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        className="form-control"
                        showPopperArrow={false}
                        placeholderText="dd/MM/yyyy"
                        // dateFormat="MMMM d, yyyy h:mm aa"
                        onChange={(date) =>
                          this.handleInputDateChange(
                            date,
                            "to_date",
                            "to_date_input"
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="col-sm-3 col-xs-12">
                    <div className="form-group lottery-digit">
                      <label className="mont-font fw-600 font-xsss mb-2">
                        สถานะ
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        value={this.state.status}
                        onChange={this.handleInputChange}
                      >
                        <option value="A">ทั้งหมด</option>
                        <option value="P">รอแจ้งชำระ</option>
                        <option value="S">ชำระแล้ว</option>
                        <option value="C">ยกเลิก</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3 col-xs-12">
                    <label className="mont-font fw-600 font-xsss mb-2">
                      &nbsp;
                    </label>
                    <button
                      onClick={this.handleSubmit}
                      className="update-cart bg-cyan d-block text-white fw-600 text-uppercase 
                    font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0"
                    >
                      ค้นหา
                    </button>
                  </div>
                </div>
              </div>

              <div className="row payment-list mb-show">
                <div className="col-xl-12 cart-wrapper mb-0">
                  {payment_list !== null &&
                    payment_list.map((data, index) => {
                      return (
                        <div key={index} className="row">
                          <div className="col-lg-12 mb-3 ">
                            <div className="card p-3 pb-0 rounded-3 shadow-xs border-0 bg-grey font-xss">
                              <div className="">
                                วันที่:{" "}
                                <strong>
                                  {toBuddhistYear(
                                    moment(data.createdDate),
                                    "DD/MM/YYYY HH:mmน."
                                  )}
                                </strong>
                              </div>
                              <div className="">
                                หมายเลขคำสั่งซื้อ:{" "}
                                <strong>{data.paymentNo}</strong>
                              </div>
                              <div className="">
                                สถานะ:
                                {data.status === "C" && (
                                  <strong className="text-red">
                                    {data.statusText}
                                  </strong>
                                )}
                                {data.status !== "C" && (
                                  <strong>{data.statusText}</strong>
                                )}
                              </div>
                              <div className="">
                                จำนวนเงิน: <strong>{data.totalAmount}</strong>{" "}
                                บาท
                              </div>
                              <div className="">
                                จำนวนสลาก:{" "}
                                <strong>
                                  {data.items.reduce(
                                    (a, b) => a + b.totalNumber,
                                    0
                                  )}
                                </strong>
                                <div
                                  className={`nav-content-bttn open-font me-3 d-inline-block float-right lotto-view-btn lotto-view-btn-${index} hide`}
                                  onClick={() => this.handleViewLotto(index)}
                                >
                                  <i className="feather-zoom-in btn-round-xs"></i>
                                  <span>ดูสลาก</span>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`card rounded-3 shadow-xs border-0 bg-grey font-xss lotto-pane lotto-pane-${index} hide`}
                            >
                              {data.items.map((item, indexItem) => {
                                const lottoData = {
                                  id: data.id,
                                  numberDate: item.paymentDate,
                                  numberNo: item.numberNo,
                                  agentName: item.agentName,
                                  packAmount: item.packAmount,
                                  packNum: item.packNum,
                                  packId: item.packId,
                                  fileIds: item.fileIds,
                                  numberIds: item.numberIds,
                                  totalNumber: item.totalNumber,
                                  totalAmount: item.totalAmount,
                                };
                                return (
                                  <div
                                    className="lottery-search-result pt-2 bg-grey"
                                    key={indexItem}
                                  >
                                    <Lottery data={lottoData} page="payment" />
                                  </div>
                                );
                              })}
                            </div>
                            <div className="card p-3 rounded-3 shadow-xs border-0 bg-grey font-xss">
                              <div className="">
                                {/* {
                              data.status === "P" &&
                              <button href="#" onClick={() => this.handleAddSlip(data)}
                                className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block float-right w-100
                                  rounded-xl bg-skype font-xss fw-700 ls-lg text-white">แจ้งชำระเงิน</button>
                            } */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="row payment-list mb-hide">
                <div className="col-lg-12 mb-3">
                  <div className="table-content table-responsive">
                    <table className="table text-center">
                      <thead className="bg-greyblue rounded-3">
                        <tr>
                          <th className="border-0 p-4">ลำดับ</th>
                          <th className="border-0 p-4 text-left">
                            หมายเลขคำสั่งซื้อ
                          </th>
                          <th className="border-0 p-4">วันที่ทำรายการ</th>
                          <th className="border-0 p-4">จำนวนสลาก</th>
                          <th className="border-0 p-4">จำนวนเงิน</th>
                          <th className="border-0 p-4">สถานะ</th>
                          {/* <th className="border-0 p-4">&nbsp;</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {payment_list !== null &&
                          payment_list.map((data, index) => {
                            return [
                              <tr key={index} className="data-row">
                                <td className="product-thumbnail ps-0">
                                  {index + 1}
                                </td>
                                <td className="product-headline text-left wide-column">
                                  <h3>{data.paymentNo}</h3>
                                </td>
                                <td className="product-quantity">
                                  {toBuddhistYear(
                                    moment(data.createdDate),
                                    "DD/MM/YYYY HH:mmน."
                                  )}
                                </td>
                                <td className="product-quantity">
                                  {data.items.reduce(
                                    (a, b) => a + b.totalNumber,
                                    0
                                  )}{" "}
                                  <br />
                                  <div
                                    className={`nav-content-bttn open-font me-3 d-inline-block float-right lotto-view-btn lotto-view-btn-${index} hide`}
                                    onClick={() =>
                                      this.handleViewLottoTable(index)
                                    }
                                  >
                                    <i className="feather-zoom-in btn-round-xs"></i>
                                    <span>ดูสลาก</span>
                                  </div>
                                </td>
                                <td className="product-quantity">
                                  {data.totalAmount}
                                </td>
                                <td className="product-quantity">
                                  {data.status === "C" && (
                                    <div className="text-red">
                                      {data.statusText}
                                      <br />
                                    </div>
                                  )}
                                  {data.status !== "C" && (
                                    <div>
                                      {data.statusText}
                                      <br />
                                    </div>
                                  )}
                                  {/* {
                              data.status === "P" &&
                              <button href="#" onClick={() => this.handleAddSlip(data)}
                                className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block float-right 
                                  rounded-xl bg-skype font-xss fw-700 ls-lg text-white">แจ้งชำระเงิน</button>
                            } */}
                                </td>
                                {/* <td className="product-quantity">
                            <button href="#" onClick={() => this.handleAddSlip(data)}
                              className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block float-right 
                                rounded-xl bg-skype font-xss fw-700 ls-lg text-white">แจ้งชำระเงิน</button>
                          </td> */}
                              </tr>,
                              <tr key="-1">
                                <td colSpan="6">
                                  <div
                                    className={`lotto-pane lotto-pane-table-${index} hide`}
                                  >
                                    {data.items.map((item, indexItem) => {
                                      const lottoData = {
                                        id: data.id,
                                        numberDate: item.paymentDate,
                                        numberNo: item.numberNo,
                                        agentName: item.agentName,
                                        packAmount: item.packAmount,
                                        packNum: item.packNum,
                                        packId: item.packId,
                                        fileIds: item.fileIds,
                                        numberIds: item.numberIds,
                                        totalNumber: item.totalNumber,
                                        totalAmount: item.totalAmount,
                                      };
                                      return (
                                        <div
                                          className="lottery-search-result d-inline-block float-left"
                                          key={indexItem}
                                        >
                                          <Lottery
                                            data={lottoData}
                                            page="payment"
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </td>
                              </tr>,
                            ];
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* CONTENT ABOVE */}
            </div>
          </div>
        </div>
        {/* POPUP HERE */}

        {/* POPUP ABOVE */}
        <Appfooter />
      </Fragment>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {
    payment_list: state.payment.payment_list || [],
  };
};

const OrderHistory = connect(mapStateToProps)(OrderHistoryComponent);
export default OrderHistory;
