import React, { Component } from 'react';

class PageTitle extends Component {
  render() {
    const { title } = this.props;
    return (
      <div className="row">
        <div className="col-lg-12 mb-3">
          <div className="card p-4  bg-cyan rounded-3 shadow-xss bg-pattern border-0 overflow-hidden">
            <div className="bg-pattern-div"></div>
            <h2 className="display2-size display2-md-size fw-700 text-white mb-0 mt-0">{title}</h2>
          </div>
        </div>
      </div>
    );
  }
}

export default PageTitle;
