import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Popupchat from "../components/Popupchat";
import { showDisplayPopupError } from "../actions/display";
import { saveAgent } from "../actions/agent";
import { saveDealer } from "../actions/dealer";
import PageTitle from "../components/PageTitle";

const PrivacyPage = () => {
  return (
    <Fragment>
      <Header />
      <Leftnav />

      <div className="main-content privacy-page">
        <div className="middle-sidebar-bottom">
          <div
            className="middle-sidebar-left pe-0"
            style={{ padding: "0 30px" }}
          >
            <br />
            <div className="text-center">
              <strong style={{ fontSize: "18px" }}>
                ข้อกำหนดและเงื่อนไขการใช้งาน
              </strong>
            </div>
            <br />
            <p>
              <strong>
                <u>คำนิยาม</u>
              </strong>
              &nbsp;เว้นแต่จะได้ระบุไว้เป็นอย่างอื่นเป็นการเฉพาะ
              ในข้อตกลงฉบับนี้ให้ถ้อยคำดังต่อไปนี้ตามที่อาจมีการนิยาม แก้ไข
              และ/หรือ เพิ่มเติม ในภายหลังโดยกฎหมาย กฎระเบียบ และ/หรือ
              เอกสารการเปิดเผยข้อมูลอื่น ๆ มีความหมาย ดังนี้
            </p>

            <ol>
              <li>
                <p>
                  <strong>&ldquo;ลูกค้า</strong> หมายถึง ผู้ซื้อ ผู้ขอใช้บริการ
                  ผู้ใช้บริการ สมาชิก ตลอดจนผู้มีส่วนเกี่ยวข้อง
                </p>
              </li>
              <li>
                <p>
                  <strong>&ldquo;บริษัท</strong> หมายถึง บริษัท สลากทูยู จำกัด
                </p>
              </li>
              <li>
                <p>
                  <strong>&ldquo;ลอตเตอรี่</strong> หมายถึง
                  สลากกินแบ่งรัฐบาลที่ออกโดยสำนักงานสลากกินแบ่งรัฐบาลทุกประเภท
                </p>
              </li>
              <li>
                <p>
                  <strong>&ldquo;แพลตฟอร์ม</strong> หมายถึง
                  &ldquo;https://www.mahachonlottery.com หรือ
                  ซึ่งเป็นบริษัทผู้ให้บริการจำหน่าย รับจัดเก็บ รับฝาก
                  รับขึ้นเงิน ลอตเตอรี่ ตลอดจนประกอบกิจการอื่น ๆ
                  ตามวัตถุประสงค์ของบริษัท
                </p>
              </li>
              <li>
                <p>
                  <strong>&ldquo;ธุรกรรมซื้อขายสลาก</strong> หมายถึง
                  การซื้อขายลอตเตอรี่
                </p>
              </li>
              <li>
                <p>
                  <strong>&ldquo;ซื้อซอง</strong> หมายถึง การซื้อลอตเตอรี่ ซอง
                  ค่าบริการเช่าตู้เซฟ
                  ค่าบริการบรรจุลอตเตอรี่เพื่อจัดส่งทางไปรษณีย์หรือบริษัทขนส่งอื่นตามเงื่อนไขที่บริษัทกำหนด
                  ค่าจัดส่ง ค่าค้นหาลอตเตอรี่ ตลอดจนค่าดำเนินการต่าง ๆ
                  ที่เกี่ยวข้อง แบบเหมาจ่ายเพื่อประโยชน์สูงสุดของลูกค้า
                </p>
              </li>
              <li>
                <p>
                  <strong>&ldquo;เช่าตู้เซฟ</strong> หมายถึง
                  การให้บริการเช่าพื้นที่จัดเก็บข้อมูลทางอิเล็กทรอนิกส์(Cloud)
                  และให้หมายความรวมถึงค่าแสกน ค่าค้นหา ตลอดจนค่าดำเนินการต่าง ๆ
                  ที่เกี่ยวข้องกับการซื้อขายลอตเตอรี่
                  แบบเหมาจ่ายเพื่อประโยชน์สูงสุดของลูกค้า
                </p>
              </li>
              <li>
                <p>
                  <strong>&ldquo;ข้อตกลงและเงื่อนไขการใช้บริการ</strong>
                  หมายถึง
                  ข้อตกลงและเงื่อนไขสำหรับการใช้บริการแพลตฟอร์มและแอปพลิเคชันตามเงื่อนไขที่บริษัทกำหนด
                </p>
              </li>
              <li>
                <p>
                  <strong>&ldquo;มัลแวร์</strong> หมายถึง
                  ซอฟต์แวร์ทุกรูปแบบซึ่งแทรกแซงการใช้แพลตฟอร์มให้บริการในลักษณะใด
                  ๆ หรือรบกวนการใช้งานคอมพิวเตอร์ ซึ่งรวมถึงไวรัส
                  การโจมตีทางไซเบอร์ อีเมลขยะ (phishing e-mail) สปายแวร์
                  (spyware) เวิร์ม (worm) ลอจิกซอฟต์แวร์ (logic) บอมบ์ (bombs)
                  โทรจันฮอร์ส (trojan horse)
                  หรือองค์ประกอบที่เป็นอันตรายในลักษณะเดียวกันอื่น ๆ
                </p>
              </li>
              <li>
                <p>
                  <strong>&ldquo;เหตุสุดวิสัย</strong> หมายถึง
                  เหตุการณ์ที่อยู่นอกเหนือการควบคุมของบริษัท ซึ่งรวมถึง (ก)
                  เหตุธรรมชาติ (ข) อุทกภัย อัคคีภัย แผ่นดินไหว การระเบิด
                  โรคระบาด หรือภัยธรรมชาติอื่นใด (ค) การกระทำของศัตรูระดับประเทศ
                  สงคราม การรุกราน ความเป็นปรปักษ์
                  (ไม่ว่าจะมีการประกาศสงครามหรือไม่ก็ตาม)
                  ภัยคุกคามหรือการกระทำของผู้ก่อการร้าย กบฏ การจลาจล การโจรกรรม
                  การทุจริต การขาดแคลนแรงงาน การนัดหยุดงาน
                  การกระทำของรัฐบาลหรือการก่อความไม่สงบอื่นๆ (ง)
                  ความล้มเหลวหรือความขัดข้องของอุปกรณ์การส่ง
                  อุปกรณ์การสื่อสารหรืออุปกรณ์คอมพิวเตอร์
                  หรือความขัดข้องของกระแสไฟฟ้า
                  หรือความขัดข้องของอุปกรณ์อิเล็กทรอนิกส์หรืออุปกรณ์การสื่อสาร
                  และ (จ) ไวรัสหรือการโจรกรรมข้อมูล
                  ที่อยู่เหนือการควบคุมและป้องกันของบริษัท และการกระทำ เหตุการณ์
                  หรือสถานการณ์ใด ๆ
                  ซึ่งบริษัทเห็นว่าเป็นเหตุให้การธุรกรรมซื้อขายสลากหรือการให้บริการอื่น
                  ๆ
                  บนแพลตฟอร์มหรือแอปพลิเคชันระหว่างผู้ขอใช้บริการและบริษัทไม่อยู่ในภาวะปกติ
                  เช่น การพักหรือปิดทำการของบริษัท
                  หรือเหตุขัดข้องในการออกลอตเตอรี่ เป็นต้น
                </p>
              </li>
            </ol>

            <p>&nbsp;</p>

            <p>
              <strong>ข้อตกลงและเงื่อนไขการใช้บริการ</strong>
            </p>

            <p>
              <u>
                <strong>เงื่อนไขทั่วไป</strong>
              </u>
            </p>

            <ol>
              <li>บริษัทจำหน่ายลอตเตอรี่ราคา ฉบับละ 80 บาท</li>
              <li>บริษัทไม่มีนโยบายจำหน่ายลอตเตอรี่เกินกว่าราคาที่กำหนด</li>
              <li>
                ลูกค้าต้องยืนยันตัวตนด้วยข้อมูลอันเป็นจริงและต้องมีอายุไม่ต่ำกว่า
                20 ปี บริบูรณ์
                หากฝ่าฝืนบริษัทจะดำเนินคดีตามกฎหมายจนกว่าคดีจะถึงที่สุด
              </li>
              <li>
                ขณะซื้อ ลูกค้าต้องไม่ทำรายการสั่งซื้อภายในโรงเรียน หรือสถานศึกษา
                หรือสถานที่ที่ห้ามซื้อขายลอตเตอรี่
              </li>
              <li>
                ลอตเตอรี่ทุกฉบับที่ลูกค้าทำการซื้อผ่านแพลตฟอร์ม บริษัทรับรองว่า
                มีต้นฉบับจริงและเก็บรักษาไว้ ณ ที่ทำการของบริษัททุกฉบับ
                เว้นแต่เป็นกรณีสุดวิสัย
              </li>
              <li>
                กรรมสิทธิ์ในลอตเตอรี่ตกเป็นของลูกค้า
                เมื่อลูกค้าได้ชำระเงินและปฏิบัติตามเงื่อนไขที่บริษัทกำหนดสำเร็จแล้ว
              </li>
              <li>
                บริษัทขอสงวนสิทธิ์ในการจำหน่ายลอตเตอรี่ที่ได้เลือกไว้ในระบบก่อนหน้านี้
                ให้กับลูกค้าท่านอื่นหากท่านไม่ได้ทำการชำระเงิน
                ภายในเวลาที่ระบบกำหนด
              </li>
              <li>
                ลอตเตอรี่ที่ไม่ถูกรางวัลและลูกค้าไม่ได้ขอรับต้นฉบับลอตเตอรี่ไว้ตามเงื่อนไข
                ลูกค้ายินยอมให้กรรมสิทธิ์ในลอตเตอรี่ที่ไม่ถูกรางวัลตกเป็นของบริษัทเพื่อเป็นข้อมูลสำหรับการตรวจสอบจากหน่วยงานที่เกี่ยวข้องต่อไป
              </li>
              <li>
                ห้ามลูกค้าทำการซื้อด้วยวิธีการหรือพฤติกรรมฝ่าฝืนกฎหมาย
                หรือใช้วิธีการฉ้อฉล หลอกลวงปิดบังข้อความจริงอันควรจะบอก
                หรือการทำผิดระเบียบหรือข้อบังคับต่าง ๆ
                ของบริษัทลูกค้าต้องทำการซื้อเพื่อตนเองและห้ามนำจำหน่ายต่อลูกค้าต้องปฏิบัติตามขั้นตอนเงื่อนไขในการซื้อลอตเตอรี่ตามที่บริษัทกำหนดที่มีอยู่ในขณะสมัครสมาชิกและที่จะมีขึ้นในภายหน้า
                หากลูกค้าไม่ปฏิบัติตามจนก่อให้เกิดความเสียหายแก่บริษัทบริษัทขอสงวนสิทธิ์ในการเรียกร้องค่าเสียหายจากลูกค้าตามกฎหมายต่อไป
              </li>
              <li>
                ลูกค้าต้องชำระราคาลอตเตอรี่ภายในระยะเวลาที่บริษัทกำหนดเมื่อลูกค้าชำระเงินแล้วต้องดำเนินการอัปโหลดหลักฐานการชำระเงินเข้าระบบภายในเวลาที่ระบบกำหนด
                มิฉะนั้นบริษัทจะดำเนินการยกเลิกคำสั่งซื้อดังกล่าวโดยไม่ต้องแจ้งให้ลูกค้าทราบล่วงหน้า
              </li>
              <li>
                ลูกค้าต้องตรวจสอบผลการสั่งซื้อหลังจากที่ทำรายการเสร็จสิ้น
                หากมีข้อผิดพลาดลูกค้าต้องแจ้งกลับมายังบริษัทในทันที
                มิฉะนั้นบริษัทจะไม่รับผิดในความเสียหายที่เกิดขึ้น(ถ้ามี)
              </li>
              <li>
                กรณีที่ลูกค้าอัปโหลดหลักฐานการชำระเงินที่ไม่ตรงกับคำสั่งซื้อและมีเจตนาทุจริตหรือเมื่อบริษัทตรวจสอบแล้วพบว่าคำสั่งซื้อ
                มีความผิดปกติอันอาจจะก่อให้เกิดความเสียหายแก่บริษัทหรือบุคคลอื่น
                บริษัทขอสงวนสิทธิ์และยกเลิกคำสั่งซื้อดังกล่าวในทันทีโดยไม่ต้องแจ้งให้ลูกค้าทราบล่วงหน้า
                พร้อมกับยกเลิกหรือปิดกั้นการใช้งานบัญชีดังกล่าวของลูกค้าและบริษัทจะใช้สิทธิ์ในการดำเนินคดีทั้งในทางแพ่งและในทางอาญาตามกฎหมายต่อไปจนกว่าคดีจะถึงที่สุด
              </li>
              <li>
                ลูกค้ารับว่า
                การซื้อขายลอตเตอรี่จากบริษัทเป็นสัญญาซื้อขายมีเงื่อนไขหรือเงื่อนเวลาบังคับไว้และกรรมสิทธิ์ในลอตเตอรี่จะยังไม่โอนไปยังลูกค้าและยังไม่ก่อให้เกิดสิทธิตามกฎหมายใด
                ๆ
                แก่ลูกค้าจนกว่าลูกค้าจะได้ปฏิบัติตามเงื่อนไขหรือข้อกำหนดของบริษัทครบถ้วนแล้วเท่านั้น
              </li>
              <li>
                บริษัทขอสงวนสิทธิ์ในการยกเลิก แก้ไข
                เปลี่ยนแปลงคำสั่งซื้อของลูกค้าทั้งที่เกิดขึ้นก่อนและภายหลังการประกาศรางวัล
                โดยมิต้องแจ้งให้ทราบล่วงหน้า
              </li>
              <li>
                คำสั่งซื้อจะสมบูรณ์
                เมื่อบริษัทได้ตรวจสอบข้อมูลและอนุมัติการซื้อเรียบร้อยเท่านั้น
              </li>
              <li>
                ลูกค้ายินยอมให้บริษัทบันทึกภาพและเสียงจากกิจกรรมที่บริษัทจัดขึ้นได้
              </li>
              <li>
                ลูกค้ายินยอมให้บริษัทนำข้อมูลของลูกค้าใช้ในการประชาสัมพันธ์กิจการของบริษัทได้
              </li>
              <li>
                เมื่อลูกค้าถูกรางวัล
                ลูกค้ายินยอมให้บริษัทนำผลรางวัลของลูกค้าไปใช้ประชาสัมพันธ์ในกิจการของบริษัทได้
              </li>
              <li>
                กรณีลูกค้านำผลรางวัลที่ซื้อกับบริษัทไปประชาสัมพันธ์ด้วยตนเอง
                ลูกค้าไม่มีสิทธิ์เรียกร้องค่าตอบแทนจากบริษัท
              </li>
              <li>
                ลูกค้ายินยอมให้บริษัทเก็บรวบรวม ใช้
                และเปิดเผยข้อมูลส่วนบุคคลของลูกค้า
                เพื่อวัตถุประสงค์ในการวิเคราะห์ข้อมูลเพื่อวางแผนทางการตลาด
                การนำเสนอสินค้าและบริการอื่นๆ ของบริษัทตามที่บริษัทเห็นสมควร
              </li>
              <li>
                บริษัทต้องชำระค่าตอบแทนให้แก่ลูกค้าในกรณีที่บริษัทนำข้อมูลการถูกรางวัลหรือข้อมูลต่าง
                ๆ ของลูกค้าไปใช้ในกิจการของบริษัท
                โดยอัตราค่าตอบแทนให้เป็นไปตามเงื่อนที่บริษัทกำหนด
              </li>
              <li>
                ลูกค้ายินยอมให้บริษัทหักกลบหนี้ระหว่างลูกค้ากับบริษัทได้ทันทีโดยมิต้องบอกกล่าวล่วงหน้า
              </li>

              <li>
                กรรมสิทธิ์ในลอตเตอรี่ตกเป็นของลูกค้า
                เมื่อลูกค้าได้ชำระเงินและปฏิบัติตามเงื่อนไขที่บริษัทกำหนดสำเร็จแล้ว
              </li>
              <li>
                บริษัทขอสงวนสิทธิ์ในการจำหน่ายลอตเตอรี่ที่ได้เลือกไว้ในระบบก่อนหน้านี้
                ให้กับลูกค้าท่านอื่นหากท่านไม่ได้ทำการชำระเงิน
                ภายในเวลาที่ระบบกำหนด
              </li>
              <li>
                เมื่อลูกค้าใช้บริการจิ๊ดริดหยิบโชค
                ท่านต้องทำการเปิดซองเพื่อรับทราบหมายเลขลอตเตอรี่ที่ซื้อก่อนวันออกสลากกินแบ่งรัฐบาล
                หากลูกค้าไม่ได้เปิดซองเพื่อลุ้นลอตเตอรี่ก่อนวันออกสลากกินแบ่งรัฐบาล
                บริษัทขอสงวนสิทธิ์เพื่อรักษาประโยชน์ของท่าน
                โดยทำการเปิดซองให้ท่านเมื่อถึงวันประกาศหรือก่อนเวลาการประกาศสลากกินแบ่งรัฐบาล
              </li>
              <li>
                บริษัทขอปฏิเสธความรับผิดชอบในความเสียหายใดๆที่เกิดขึ้นหรือเกี่ยวข้องกับโปรแกรมนี้
                อาทิเช่น อุปกรณ์ ซอฟต์แวร์
                หรือการเชื่อมต่อทางโทรคมนาคมของท่านไม่รองรับรายการ
                รวมถึงปัญหาทางเทคนิค
                หรือข้อผิดพลาดที่ทำให้เกิดการหยุดชะงักของรายการ
                &ldquo;จิ๊ดริดหยิบโชค&rdquo; เนื่องด้วยความไม่เหมาะสม
                ไม่น่าเชื่อถือ หรือความไม่ถูกต้องของรายการหรือ
                ไม่สามารถทำตามความประสงค์ของท่านได้
                เว้นแต่เป็นกรณีที่กฎหมายกำหนด
              </li>
              <li>
                ผู้ร่วมรายการถือว่ายอมรับกฎ กติกา
                และข้อกำหนดสำหรับการเข้าร่วมรายการนี้เรียบร้อยแล้ว
                <br />
                &nbsp;
                <p>
                  <strong>
                    <u>เงื่อนไขกรณีฝาก</u>
                  </strong>
                </p>
              </li>
              <li>
                กรณีที่ลูกค้าฝากและบริษัทได้รับฝากลอตเตอรี่ไว้กับบริษัทด้วยวิธีการใด
                ๆ
                ให้ถือว่าบริษัทมีหน้าที่เพียงผู้เก็บรักษาลอตเตอรี่ของลูกค้าไว้ด้วยวิธีการที่บริษัทกำหนดเท่านั้น
              </li>
              <li>
                การให้บริการเช่าตู้เซฟเพื่อใช้เป็นพื้นที่จัดเก็บข้อมูลทางอิเล็กทรอนิกส์(Cloud)
                ซึ่งลูกค้าสามารถนำข้อมูลคำสั่งซื้อ เช่น ภาพลอตเตอรี่
                หลักฐานการชำระเงิน ใบเสร็จค่าสินค้า ใบกำกับภาษี ฯลฯ
                จัดเก็บในตู้เซฟได้ เว้นแต่ลูกค้าได้นำข้อมูลคำสั่งซื้อ เช่น
                ภาพลอตเตอรี่ หลักฐานการชำระเงิน ใบเสร็จค่าสินค้า ใบกำกับภาษี
                เป็นต้น จัดเก็บในตู้เซฟให้สัญญาเช่าสิ้นสุดลง เวลา 24.00 น.
                ของวันที่มีการออกรางวัลในแต่ละงวดหรือตามเงื่อนไขที่บริษัทกำหนด
                เมื่อสัญญาเช่าสิ้นสุดลงข้อมูลในตู้เซฟจะถูกลบไปโดยอัตโนมัติหรือเป็นไปตามเงื่อนไขที่บริษัทกำหนด
                ก่อนสัญญาเช่าสิ้นสุดลงลูกค้าต้องดำเนินการสำรองข้อมูลด้วยตนเอง
              </li>
              <li>
                กรณีที่ลูกค้าฝากลอตเตอรี่ไว้กับบริษัทด้วยวิธีการใด ๆ
                ให้ถือว่าลูกค้ายินยอมและมอบหมายให้บริษัทเป็นตัวแทนไปขึ้นเงินรางวัลหรือจัดหาตัวแทนขึ้นเงินรางวัลหรือจัดหาผู้รับซื้อรางวัลหรือจัดหาผู้รับมอบอำนาจขึ้นเงินรางวัลหรือดำเนินการด้วยวิธีใด
                ๆ เกี่ยวกับการขึ้นเงินรางวัล
              </li>
              <li>
                ในการส่งมอบเงินรางวัลแก่ลูกค้า
                ลูกค้ายินยอมให้บริษัทนำเงินรางวัลโอนเข้าบัญชีที่ระบุไว้ภายในระยะเวลาไม่เกิน
                24 ชั่วโมง
              </li>
              <li>
                กรณีที่ลูกค้าประสงค์ให้บริษัทเป็นผู้ออกค่าอากรของลอตเตอรี่ที่ถูกรางวัลได้เฉพาะกรณีที่ลูกค้าฝากลอตเตอรี่ไว้กับบริษัทและเก็บลอตเตอรี่ที่ถูกรางวัลไว้ในตู้เซฟที่เช่าเท่านั้น
                และให้ถือว่าเงินค่าอากรดังกล่าวเป็นเงินได้ของประเภทและของปีภาษีเดียวกันกับเงินได้ที่บริษัทมีการออกเงินค่าอากรแทนให้
              </li>
              <li>
                ในกรณีลอตเตอรี่ของลูกค้าถูกรางวัล
                แต่ลูกค้ามีการระบุหมายเลขบัญชีเงินฝากผิดพลาดบริษัทจะไม่รับผิดชอบใด
                ๆ
                ในการติดตามเงินดังกล่าวคืนและให้ถือว่ามีการจ่ายเงินรางวัลให้แก่ลูกค้าแล้ว
                <br />
                &nbsp;
                <p>
                  <strong>
                    <u>เงื่อนไขกรณีจัดส่ง</u>
                  </strong>
                </p>
              </li>
              <li>
                กรณีที่ลูกค้าเลือกซื้อซองจัดส่ง
                บริษัทจะให้บริการจัดส่งลอตเตอรี่ให้แก่ลูกค้าตามที่อยู่ที่ได้ระบุไว้
                โดยค่าใช้จ่ายในการจัดส่ง ค่าจัดหา ค่าบริการต่าง ๆ
                เกี่ยวกับการจัดส่งได้คำนวณรวมอยู่กับค่าซื้อซองจัดส่งแล้ว
              </li>
              <li>
                กรณีที่ลูกค้าประสงค์ให้บริษัทจัดส่งต้นฉบับลอตเตอรี่ให้ทางไปรษณีย์หรือบริษัทขนส่งอื่นตามเงื่อนไขที่บริษัทกำหนด
                ลูกค้าต้องมีซองสำหรับบรรจุลอตเตอรี่ 1 ซอง ต่อลอตเตอรี่ 1 ฉบับ
              </li>
              <li>
                กรณีที่ลูกค้าเลือกใช้ซองบรรจุลอตเตอรี่ในการจัดส่ง
                <u>
                  ความรับผิดของบริษัทจะสิ้นสุดเมื่อส่งมอบลอตเตอรี่ให้แก่ทางไปรษณีย์หรือบริษัทขนส่งอื่นเรียบร้อยแล้ว
                </u>
                โดยบริษัทขอปฏิเสธความรับผิดชอบในกรณีลอตเตอรี่ที่ส่งเกิดการสูญหายหรือเสียหายทั้งนี้ลูกค้าสามารถเรียกร้องค่าเสียหายเอากับไปรษณีย์หรือบริษัทขนส่งได้ตามหลักเกณฑ์การรับประกันของไปรษณีย์หรือบริษัทขนส่งเท่านั้น
              </li>
              <li>
                กรณีที่ลูกค้าขึ้นเงินรางวัลเองลูกค้าต้องชำระค่าอากรแสตมป์จำนวนร้อยละ
                0.5 หรือร้อยละ 1 ของเงินรางวัลตามที่กฎหมายกำหนด
              </li>
              <li>
                การขอรับต้นฉบับลอตเตอรี่ที่ลูกค้าต้องแจ้งก่อนวันประกาศผลรางวัลในงวดดังกล่าวไม่น้อยกว่า
                1 วัน พร้อมกับชำระค่าบริการจัดส่งต้นฉบับตามที่บริษัทกำหนด
                หากเลยกำหนดเวลาดังกล่าว
                บริษัทขอสงวนสิทธิ์หรือวิธีการส่งมอบลอตเตอรี่ตามเงื่อนไขที่บริษัทกำหนด
              </li>
              <li>
                ในการจัดส่งต้นฉบับลอตเตอรี่ให้กับลูกค้าทางไปรษณีย์หรือบริการขนส่งอื่น
                ๆ พร้อมบริการแจ้งหมายเลขพัสดุภายใต้เงื่อนไขที่บริษัทกำหนด
              </li>
              <li>
                บริษัทขอปฏิเสธความรับผิดในกรณีลอตเตอรี่ที่ส่งออกไปเกิดการสูญหายหรือเสียหายและในการเรียกร้องค่าเสียหายดังกล่าวลูกค้าต้องใช้สิทธิ์เรียกร้องเอาจากไปรษณีย์หรือบริการขนส่งอื่นตามเงื่อนไขเท่านั้น
                <br />
                &nbsp;
                <p>
                  <strong>
                    <u>อื่น ๆ</u>
                  </strong>
                </p>
              </li>
              <li>
                บริษัทจะขอสงวนสิทธิ์ในการเปลี่ยนแปลงค่าบริการ
                โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
              </li>
              <li>
                ลูกค้าได้อ่านนโยบายและเงื่อนไขในการสั่งซื้อนี้โดยตลอดและเข้าใจดีแล้วยินยอมปฏิบัติตามเงื่อนไขข้างต้นทุกประการ
                และขอยืนยันการสั่งซื้อ
                ข้อมูลที่ลูกค้าได้ยืนยันการสั่งซื้อนี้จะถูกบันทึกเก็บไว้เป็นหลักฐานเพื่อการตรวจสอบและรายงานต่อหน่วยงานที่เกี่ยวข้อง
              </li>
            </ol>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PrivacyPage;
