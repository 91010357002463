import React, { Component, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import PageTitle from "../components/PageTitle";
import { useLazyQuery, useQuery, useSubscription } from "@apollo/client";
import { GET_RESULT_FOR_INSTALLMENT_QUERY } from "../graphql/query";
import moment from "moment";

const ThaiBaht = new Intl.NumberFormat("th-TH");

const ResultComponent = (props) => {
  const { current_installment } = props;

  const [isReading, setIsReading] = useState(false);

  const [getResult, { data, refetch }] = useLazyQuery(
    GET_RESULT_FOR_INSTALLMENT_QUERY
  );

  const readResult = () => {
    setIsReading(true);
    fetch(
      `${process.env.REACT_APP_SERVTRAN}/trans/api/adm/result/collectResult`,
      {
        method: "POST",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("refetch");
        refetch();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsReading(false);
      });
  };

  useEffect(() => {
    if (current_installment?.installmentId) {
      getResult({
        variables: {
          instmentId: current_installment.installmentId,
        },
      });
    }
  }, [current_installment]);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <div className="main-content">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row feed-body">
              <div className="col-xl-12 col-xxl-12 col-lg-12">
                {/* <div className="card-body p-0">
                  <a
                    href="/"
                    className="font-xss fw-600 text-black-500 card-body p-0 d-flex align-items-center"
                  >
                    <i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>
                    ผลสลากกินแบ่งรัฐบาล 16 พฤศจิกายน 2564
                  </a>
                </div> */}
                <div className="row ps-2 pe-1">
                  {/* {lastdocList.map((value, index) => (
                    <div key={index} className="col-md-3 col-sm-6 pe-2 ps-2">
                      <div className="card w150 d-block border-0 shadow-xss rounded-4 overflow-hidden mb-4">
                        <div className="card-body d-block w-100 p-4 text-center">
                          <h4 className="fw-700 font-xss mt-3 mb-0">
                            {value.name}
                          </h4>
                          <div className="clearfix"></div>
                          <h2>
                            <a
                              href="#follow"
                              className="text-center p-2 lh-20 w100 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xss fw-700 ls-lg text-white"
                            >
                              {value.value}
                            </a>
                          </h2>
                          <div className="clearfix"></div>
                          <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                            {value.detail}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))} */}
                </div>

                <div className="row ps-2 pe-1">
                  <div className="col-lg-3 ps-2">
                    <button
                      className="bg-current border-0 p-0 float-right w200 text-white fw-600 font-xssss text-uppercase"
                      disabled={isReading}
                      onClick={readResult}
                    >
                      อ่านผลการออกรางวัล
                    </button>
                  </div>
                </div>
                <div className="row ps-2 pe-1">
                  {isReading && (
                    <img
                      src="/assets/images/1495.gif"
                      width="50"
                      style={{ width: "60px" }}
                    />
                  )}
                  {data?.transflow_trn_result && (
                    <code>{JSON.stringify(data?.transflow_trn_result)}</code>
                  )}
                </div>

                {/* <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4 text-center">
                  <a
                    href="/defaultmember"
                    className="p-2 lh-20 w300 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl"
                  >
                    ตรวจผลสลากทั้งหมด
                  </a>
                </div> */}
              </div>
              {/* <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = function (state, props) {
  return {
    previous_installment: state.number.previous_installment || null,
    current_installment: state.number.current_installment || null,
  };
};

const Result = connect(mapStateToProps)(ResultComponent);
export default Result;
