
import { ActionConst } from '../constants'

const defaultState = {
  bank_list: [],
}

const bank = (state = defaultState, action = "") => {
  switch(action.type) {
    case ActionConst.BankSetBankList:
      return {
        ...state,
        bank_list: action.value
      }
    default:
      return state
  }
}

export default bank