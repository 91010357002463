const ServerInfo = {
  URL_LOGIN: process.env.REACT_APP_SERVAUTH + `/auth/oauth/token`,
  URL_LOGOUT: process.env.REACT_APP_SERVAUTH + `/auth/password/oauth/logout`,
  URL_CHECK_TOKEN: process.env.REACT_APP_SERVAUTH + `/auth/oauth/check_token`,
  URL_LOGIN_AUTH: `Basic YWRtaW5hcHA6MTIzNDU2`,
  URL_REGISTER: process.env.REACT_APP_SERVTRAN + `/trans/api/cust/register`,
  URL_FORGETPASS:
    process.env.REACT_APP_SERVTRAN + `/trans/api/custsecret/forgetpwd`,
  URL_PROFILE: process.env.REACT_APP_SERVTRAN + `/trans/api/cust/profile`,
  URL_PROFILE_BY_TEL:
    process.env.REACT_APP_SERVTRAN + `/trans/api/cust/profilebytel`,
  URL_PROFILE_UPDATE:
    process.env.REACT_APP_SERVTRAN + `/trans/api/cust/upcustprofile`,
  URL_CHANGEPASS:
    process.env.REACT_APP_SERVTRAN + `/trans/api/cust/changepasswd`,
  URL_BANK: process.env.REACT_APP_SERVTRAN + `/trans/api/bank/0`,
  URL_NUMBER: process.env.REACT_APP_SERVTRAN + `/trans/api/number/search`,
  URL_BASKET_ADD: process.env.REACT_APP_SERVTRAN + `/trans/api/basket/add`,
  URL_BASKET_SEARCH:
    process.env.REACT_APP_SERVTRAN + `/trans/api/basket/search`,
  URL_BASKET_CANCEL:
    process.env.REACT_APP_SERVTRAN + `/trans/api/basket/cancel`,
  URL_PAYMENT_ALL: process.env.REACT_APP_SERVTRAN + `/trans/api/payment/all`,
  URL_PAYMENT_NOW: process.env.REACT_APP_SERVTRAN + `/trans/api/payment/bynum`,
  URL_PAYMENT_NOW_FORCEPAY:
    process.env.REACT_APP_SERVTRAN + `/trans/api/payforce/pay`,
  URL_PAYMENT_SEARCH:
    process.env.REACT_APP_SERVTRAN + `/trans/api/payment/search`,
  URL_PAYMENT_GET: process.env.REACT_APP_SERVTRAN + `/trans/api/payment`,
  URL_PAYMENT_CHECK:
    process.env.REACT_APP_SERVTRAN + `/trans/api/payment/check`,
  URL_PAYMENT_UPLOAD_SLIP:
    process.env.REACT_APP_SERVTRAN + `/trans/api/payment/upslip`,
  URL_STOCK_UPZIP: process.env.REACT_APP_SERVTRAN + `/trans/api/stock/upzip`,
  URL_STOCK_ADDVERIFIED:
    process.env.REACT_APP_SERVTRAN + `/trans/api/stock/addVerifiedLottos`,
  URL_STOCK_WAITRELEASE:
    process.env.REACT_APP_SERVTRAN + `/trans/api/stock/getWaitRelease`,
  URL_STOCK_RELEASE:
    process.env.REACT_APP_SERVTRAN + `/trans/api/stock/release`,
  URL_STOCK_SEARCH:
    process.env.REACT_APP_SERVTRAN + `/trans/api/stock/searchstock`,
  URL_STOCK_DETAIL_LIST:
    process.env.REACT_APP_SERVTRAN + `/trans/api/stock/historiesDetail`,
  URL_STOCK_CHECK:
    process.env.REACT_APP_SERVTRAN + `/trans/api/stock/verifyManual`,
  // URL_GET_FILE:
  //   process.env.REACT_APP_SERVFILE + `/file/api/v1/documents/download`,
  URL_GET_FILE: process.env.REACT_APP_SERVFILE + `/v1/storage/download`,
  URL_SCAN_LOTTO: process.env.REACT_APP_SERVFILE + `/v1/lottery/scan`,
  URL_AGENT_SAVE: process.env.REACT_APP_SERVTRAN + `/trans/api/agent/register`,
  URL_AGENT_SEARCH:
    process.env.REACT_APP_SERVTRAN + `/trans/api/agent/searchagent`,
  URL_AGENT_PACKNUM:
    process.env.REACT_APP_SERVTRAN + `/trans/api/agent/packnum`,
  URL_AGENT_PRICINGS:
    process.env.REACT_APP_SERVTRAN + `/trans/api/agent/pricings`,
  URL_ALL_AGENT_PRICES:
    process.env.REACT_APP_SERVTRAN + `/trans/api/agent/allpricings`,
  URL_AGENT_DETAIL:
    process.env.REACT_APP_SERVTRAN + `/trans/api/adm/agent/detail`,
  URL_AGENT_UPDATE:
    process.env.REACT_APP_SERVTRAN + `/trans/api/adm/agent/update`,
  URL_AGENT_QR: process.env.REACT_APP_SERVTRAN + `/trans/api/agent/qr`,
  URL_DEALER_QR: process.env.REACT_APP_SERVTRAN + `/trans/api/dealer/qr`,
  URL_AGENT_NUMBER_SEARCH:
    process.env.REACT_APP_SERVTRAN + `/trans/api/adm/agent/searchnumber`,
  URL_ADMIN_NUMBERGROUP_SEARCH:
    process.env.REACT_APP_SERVTRAN + `/trans/api/adm/agent/searchgroupnumber`,
  URL_AGENT_NUMBER_UPSTATUS:
    process.env.REACT_APP_SERVTRAN + `/trans/api/adm/agent/upstatus`,
  URL_ADMIN_AGENT_LIST:
    process.env.REACT_APP_SERVTRAN + `/trans/api/adm/agent/list`,
  URL_ADMIN_PAYMENT_LIST:
    process.env.REACT_APP_SERVTRAN + `/trans/api/adm/paymentlist`,
  URL_ADMIN_PAYMENT_DETAIL:
    process.env.REACT_APP_SERVTRAN + `/trans/api/adm/paymentdetail`,
  URL_ADMIN_PAYMENT_UPSTATUS:
    process.env.REACT_APP_SERVTRAN + `/trans/api/payment/upstatus`,
  URL_ADMIN_USER_LIST: process.env.REACT_APP_SERVTRAN + `/trans/api/adm/user`,
  URL_ADMIN_USER_CHANGEPASS:
    process.env.REACT_APP_SERVTRAN + `/trans/api/adm/user/changepasswd`,
  URL_ADMIN_DEALER_FROM_CUSTOMER:
    process.env.REACT_APP_SERVTRAN + `/trans/api/adm/dealer/createfromcustomer`,
  URL_ADMIN_INST_NEWINST:
    process.env.REACT_APP_SERVTRAN + `/trans/api/adm/installment/newinst`,
  URL_ADMIN_INST_CLOSEINST:
    process.env.REACT_APP_SERVTRAN + `/trans/api/adm/installment/closeinst`,
  URL_DEALER_SAVE:
    process.env.REACT_APP_SERVTRAN + `/trans/api/dealer/register`,
  URL_DEALER_SEARCH:
    process.env.REACT_APP_SERVTRAN + `/trans/api/dealer/searchdealer`,
  URL_DEALER_DETAIL: process.env.REACT_APP_SERVTRAN + `/trans/api/dealer`,
  URL_DEALER_DETAIL_BY_CODE:
    process.env.REACT_APP_SERVTRAN + `/trans/api/dealer/bycode`,
  URL_DEALER_UPDATE:
    process.env.REACT_APP_SERVTRAN + `/trans/api/adm/dealer/update`,
  URL_DEALER_PACKNUM:
    process.env.REACT_APP_SERVTRAN + `/trans/api/dealer/packnum`,
  URL_DEALER_PRICINGS:
    process.env.REACT_APP_SERVTRAN + `/trans/api/dealer/pricings`,
  URL_DEALER_MINIMUM_PROFIT:
    process.env.REACT_APP_SERVTRAN + `/trans/api/dealer/minimumprofit`,
  URL_USER_DETAIL: process.env.REACT_APP_SERVTRAN + `/trans/api/user/detail`,
  URL_DEALER_PROFILE:
    process.env.REACT_APP_SERVTRAN + `/trans/api/dealer/profile`,
  URL_DEALER_SESSION:
    process.env.REACT_APP_SERVTRAN + `/trans/api/dealer/getdealersession`,
  URL_DEALER_REGIS_CUSTOMER:
    process.env.REACT_APP_SERVTRAN + `/trans/api/dealer/registercustomer`,
  URL_DEALER_WINNER:
    process.env.REACT_APP_SERVTRAN + `/trans/api/dealer/winner`,
  URL_DEALER_PAYMENT_SEARCH:
    process.env.REACT_APP_SERVTRAN + `/trans/api/payment/paymentdealerlist`,
  URL_VAULT_CUSTOMER_LIST:
    process.env.REACT_APP_SERVTRAN + `/trans/api/vault/customer`,
  URL_VAULT_SEND_NUMBER:
    process.env.REACT_APP_SERVTRAN + `/trans/api/vault/transfer`,
  URL_NUMBER_INSTINFO:
    process.env.REACT_APP_SERVTRAN + `/trans/api/number/instinfo`,
  URL_VAULT_HISTORY:
    process.env.REACT_APP_SERVTRAN + `/trans/api/vault/history`,
  URL_REPORT_WINNER:
    process.env.REACT_APP_SERVTRAN + `/trans/api/adm/report/winners`,
  URL_REPORT_DEALER_PROFIT:
    process.env.REACT_APP_SERVTRAN + `/trans/api/adm/report/dealerprofit`,
};

const ServerInfo_Code = {
  URL_LOGIN: `1001`,
  URL_LOGOUT: `1007`,
  URL_CHECK_TOKEN: `1008`,
  URL_LOGIN_AUTH: `9999`,
  URL_REGISTER: `1002`,
  URL_FORGETPASS: `1003`,
  URL_PROFILE: `1004`,
  URL_PROFILE_UPDATE: `1005`,
  URL_CHANGEPASS: `1006`,
  URL_BANK: `2001`,
  URL_NUMBER: `3001`,
  URL_BASKET_ADD: `3002`,
  URL_BASKET_SEARCH: `3003`,
  URL_BASKET_CANCEL: `3004`,
  URL_NUMBER_INSTINFO: `3005`,
  URL_PAYMENT_ALL: `4001`,
  URL_PAYMENT_NOW: `4002`,
  URL_PAYMENT_NOW_FORCEPAY: `4003`,
  URL_PAYMENT_SEARCH: `4004`,
  URL_PAYMENT_GET: `4005`,
  URL_PAYMENT_CHECK: `4006`,
  URL_PAYMENT_UPLOAD_SLIP: `4007`,
  URL_STOCK_UPZIP: `5001`,
  URL_STOCK_SEARCH: `5002`,
  URL_STOCK_DETAIL_LIST: `5003`,
  URL_STOCK_CHECK: `5004`,
  URL_STOCK_ADDVERIFIED: `5005`,
  URL_STOCK_WAITRELEASE: `5006`,
  URL_STOCK_RELEASE: `5007`,
  URL_GET_FILE: `6001`,
  URL_SCAN_LOTTO: `6002`,
  URL_AGENT_SAVE: `7001`,
  URL_AGENT_SEARCH: `7002`,
  URL_AGENT_PACKNUM: `7003`,
  URL_AGENT_DETAIL: `7004`,
  URL_AGENT_UPDATE: `7005`,
  URL_AGNET_QR: `7006`,
  URL_AGENT_NUMBER_SEARCH: `7007`,
  URL_AGENT_NUMBER_UPSTATUS: `7008`,
  URL_AGENT_PRICINGS: `7019`,
  URL_ALL_AGENT_PRICINGS: `7020`,
  URL_ADMIN_PAYMENT_LIST: `7009`,
  URL_ADMIN_PAYMENT_DETAIL: `7010`,
  URL_ADMIN_PAYMENT_UPSTATUS: `7011`,
  URL_ADMIN_NUMBERGROUP_SEARCH: `7012`,
  URL_ADMIN_USER_LIST: "7013",
  URL_ADMIN_USER_CHANGEPASS: "7014",
  URL_ADMIN_DEALER_FROM_CUSTOMER: "7015",
  URL_ADMIN_AGENT_LIST: "7016",
  URL_ADMIN_INST_NEWINST: "7017",
  URL_ADMIN_INST_CLOSEINST: "7018",
  URL_DEALER_SAVE: "8001",
  URL_DEALER_SEARCH: "8002",
  URL_DEALER_DETAIL: "8003",
  URL_DEALER_UPDATE: "8004",
  URL_DEALER_PACKNUM: "8005",
  URL_DEALER_QR: "8006",
  URL_DEALER_MINIMUM_PROFIT: "8007",
  URL_DEALER_PROFILE: "8009",
  URL_DEALER_WINNER: "8010",
  URL_DEALER_PRICINGS: "8011",
  URL_USER_DETAIL: "9001",
  URL_VAULT_LIST: "10001",
  URL_VAULT_CUSTOMER_LIST: "10002",
  URL_VAULT_SEND_NUMBER: "10003",
  URL_VAULT_HISTORY: "10004",
  URL_REPORT_WINNER: "11001",
  URL_REPORT_DEALER_PROFIT: "11002",
};

const Constants = {
  anonymous_username: "sadm999", //'user7',
  anonymous_password: "password@1",
  grant_type: "password",
};

module.exports = {
  ServerInfo,
  Constants,
  ServerInfo_Code,
};
