import React, { Component, Fragment } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";

class Checkout extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <Leftnav />
        {/* <Rightchat /> */}

        <div className="main-content right-chat-active bg-white">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12 cart-wrapper mb-4">
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="card p-md-5 p-4 bg-primary-gradiant rounded-3 shadow-xss bg-pattern border-0 overflow-hidden">
                        <div className="bg-pattern-div"></div>
                        <h2 className="display2-size display2-md-size fw-700 text-white mb-0 mt-0">
                          ชำระเงิน
                        </h2>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-6 col-lg-6">
                      <div class="page-title">
                        <div class="table-content table-responsive mb-5 card border-0 bg-greyblue p-lg-5 p-4">
                          <table class="table order-table order-table-2 mb-0">
                            <thead>
                              <tr>
                                <th class="border-0">Product</th>
                                <th class="text-right border-0">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th class="text-grey-500 fw-500 font-xsss">
                                  Aliquam lobortis est
                                  <strong>
                                    <span>✕</span>1
                                  </strong>
                                </th>
                                <td class="text-right text-grey-500 fw-500 font-xsss">
                                  $80.00
                                </td>
                              </tr>
                              <tr>
                                <th class="text-grey-500 fw-500 font-xsss">
                                  Auctor gravida enim
                                  <strong>
                                    <span>✕</span>1
                                  </strong>
                                </th>
                                <td class="text-right text-grey-500 fw-500 font-xsss">
                                  $60.00
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr class="cart-subtotal">
                                <th>Subtotal</th>
                                <td class="text-right text-grey-700 font-xsss fw-700">
                                  $56.00
                                </td>
                              </tr>
                              <tr class="shipping">
                                <th>Shipping</th>
                                <td class="text-right">
                                  <span class="text-grey-700 font-xsss fw-700">
                                    Flat Rate; $20.00
                                  </span>
                                </td>
                              </tr>
                              <tr class="order-total">
                                <th>Order Total</th>
                                <td class="text-right text-grey-700 font-xsss fw-700">
                                  <span class="order-total-ammount">
                                    $56.00
                                  </span>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-5 offset-xl-1 col-lg-6">
                      <div class="order-details">
                        <div class="checkout-payment card border-0 mb-3 bg-greyblue p-lg-5 p-4">
                          <form action="#" class="payment-form">
                            <div class="payment-group mb-4">
                              <div class="payment-radio">
                                <input
                                  type="radio"
                                  value="bank"
                                  name="payment-method"
                                  id="bank"
                                  checked
                                />
                                <label class="payment-label fw-600 font-xsss text-grey-900 ms-2">
                                  Direct Bank Transfer
                                </label>
                              </div>
                              <div class="payment-info" data-method="bank">
                                <p class="font-xssss lh-24 text-grey-500 ps-4">
                                  Make your payment directly into our bank
                                  account. Please use your Order ID as the
                                  payment reference. Your order will not be
                                  shipped until the funds have cleared in our
                                  account.
                                </p>
                              </div>
                            </div>
                            <div class="payment-group mb-4">
                              <div class="payment-radio">
                                <input
                                  type="radio"
                                  value="cheque"
                                  name="payment-method"
                                  id="cheque"
                                />
                                <label class="payment-label fw-600 font-xsss text-grey-900 ms-2">
                                  Cheque payments
                                </label>
                              </div>
                              <div
                                class="payment-info cheque hide-in-default"
                                data-method="cheque"
                              >
                                <p class="font-xssss lh-24 text-grey-500 ps-4">
                                  Please send a check to Store Name, Store
                                  Street, Store Town, Store State / County,
                                  Store Postcode.
                                </p>
                              </div>
                            </div>
                            <div class="payment-group mb-0">
                              <div class="payment-radio">
                                <input
                                  type="radio"
                                  value="cash"
                                  name="payment-method"
                                  id="cash"
                                />
                                <label class="payment-label fw-600 font-xsss text-grey-900 ms-2">
                                  Cash on Delivary
                                </label>
                              </div>
                              <div
                                class="payment-info cash hide-in-default"
                                data-method="cash"
                              >
                                <p class="font-xssss lh-24 text-grey-500 ps-4">
                                  Pay with cash upon delivery.
                                </p>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="clearfix"></div>

                        <div class="card shadow-none border-0">
                          <a
                            href="/checkout"
                            class="w-100 p-3 mt-3 font-xsss text-center text-white bg-current rounded-3 text-uppercase fw-600 ls-3"
                          >
                            Place Order
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
}

export default Checkout;
