
const defaultState = {
  customer_data: {},
  customer_search_data: {}
}

const customer = (state = defaultState, action = "") => {
  switch(action.type) {
    case 'CUSTOMER_SET_CUSTOMER_DATA':
      return {
        ...state,
        customer_data: action.value
      }
    case 'CUSTOMER_SEARCH_CUSTOMER':
      return {
        ...state,
        customer_search_data: action.value
      }      
    default:
      return state
  }
}

export default customer