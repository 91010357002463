import axios from 'axios'
import { API } from '../actions/actionTypes';
import actionUtils from '../actions/actionUtils';
import { apiStart, apiEnd, apiError } from '../actions/api';
import { showHideLoading } from '../actions/display';

const apiMiddleware = ({dispatch}) => next => action => {
  next(action)

  if(action.type !== API) return

  const {
    url,
    method,
    data,
    onSuccess,
    onFailure,
    label,
    headersOverride,
    responseTypeOverride,
    onSuccessFailureParamOverride,
    onBackground,
  } = action.payload
  if(!onBackground){
    dispatch(showHideLoading(true))
  }
  // console.log("action.payload:" ,action.payload)
  const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
  const responseType = responseTypeOverride !== "" ? responseTypeOverride : "json";

  // axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || "";
  // axios.defaults.headers.common["Content-Type"] = "application/json";
  // axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  // console.log("=>>>>>>>>>label", label)

  if(label){
    dispatch(apiStart(label))
  }

  const requestData = {
    url,
    method,
    headers: headersOverride,
    [dataOrParams]: data,
    responseType,
  }

  axios.request(requestData)
    .then( ({ status, statusText, data }) => {
      dispatch(onSuccess(status, statusText, data, onSuccessFailureParamOverride));
    })
    .catch(error => {
      if(error.response){
        if(error.response.data){
          dispatch(onFailure(error.response.status, error.response.statusText, error.response.data, onSuccessFailureParamOverride));
        }else{
          dispatch(onFailure(error.response.status, error.response.statusText, "", onSuccessFailureParamOverride));
        }
      }
      else{
        console.log("apiError"+error)
        dispatch(actionUtils.onApiError(requestData, error, onSuccessFailureParamOverride));
        dispatch(apiError(requestData, error));
      }
    })
    .finally(() => {
      if(label){
        dispatch(showHideLoading(false))
        dispatch(apiEnd(label));
      }
    });
}

export default apiMiddleware;