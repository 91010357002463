import React, { Component , Fragment } from "react";
import { connect } from 'react-redux'
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import PageTitle from '../components/PageTitle';
import DatePicker from 'react-datepicker'
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import { getImageFileAndShow } from "../actions/file";
import { getNumberGroupSearch } from "../actions/number";

class NumberGroupSearchComponent extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      numb: "",
      set: "",
      numberStatus: "",
      agentId: "",
      agentCode: "",
      agentName: "",
      installDate: "",
      installDate_input: "",
    }
  }

  componentDidMount() {
    this.setQueryData()
  }

  setQueryData2 = () => {
    const queryParams = new URLSearchParams(window.location.search);
    // agentId=38&numberStatus=S
    if (queryParams.get("agentCode") != null) {
      const agentCodeIn = queryParams.get("agentCode")
      let numberStatusIn = ""
      if (queryParams.get("numberStatus") != null) {
        numberStatusIn = queryParams.get("numberStatus")
      }
      this.setState({
        agentCode: agentCodeIn,
        numberStatus: numberStatusIn,
      }, function () {
        this.initialPage()
      })
    }
    else{
      this.initialPage()
    }
  }

  initialPage = () => {
    const { dispatch } = this.props
    dispatch(getNumberGroupSearch(this.state))
  }

  setQueryData = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("numb") != null || 
    queryParams.get("set") != null ) {
      let numbIn = ""
      let installDateIn = ""
      let setIn = ""
      if (queryParams.get("numb") != null) {
        numbIn = queryParams.get("numb")
      }
      if (queryParams.get("set") != null) {
        setIn = queryParams.get("set")
      }
      this.setState({
        numb: numbIn,
        installDate_input: installDateIn === ""? "" :moment(installDateIn, 'yyyy-MM-DD').toDate(),
        set: setIn,
      }, function () {
        this.initialPage()
      })
    }
    else{
      this.initialPage()
    }
  }

  handleInputChange = event => {
    // console.log("handleInputChange")
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleInputDateChange = (date, inputValueName, inputName) => {
    // console.log("handleInputDateChange", date)
    if(date !== null){
      const dateFormatted = moment(date).format('yyyy-MM-DD');
      this.setState({
        [inputValueName]: dateFormatted,
        [inputName]: date
      });
    }else{
      this.setState({
        [inputValueName]: "",
        [inputName]: ""
      });
    }
  }

  handleSubmit = () => {
    const { dispatch } = this.props
    // console.log(this.state)
    dispatch(getNumberGroupSearch(this.state))
    //TODO Validate value before submit to backend
  }

  handleClickViewDetail = (data) => {
    const { dispatch } = this.props
    // console.log(data);
    //dispatch(getImageFileAndShow(data.fid, `${data.numberId} [${data.agentCode}: ${data.agentName}]`))
    let searchParam = "?&numb=" + data.numberId + "&installDate=" + data.installDate;
    window.location = `/agent/number-search${searchParam}`;
  }

  render() {
    const { agent_number_list } = this.props
    return (
      <Fragment> 
        <Header />
        <Leftnav />
        {/* <Rightchat /> */}

        <div className="main-content">
        <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">

          <div className="row lottery-search">
            <div className="col-xl-12">
                
              <PageTitle title="ค้นหาสลากชุด
              " />

              <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                <h4 className="mont-font fw-600 font-md mb-2 mb-0 mt-0">ค้นหา</h4>

                <div className="row">
                  <div className="col-sm-3 col-xs-12">
                    <div className="form-group lottery-digit">
                      <label className="mont-font fw-600 font-xsss mb-2">เลขสลาก</label>
                      <input type="text" name="numb" value={this.state.numb} onChange={this.handleInputChange} className="form-control" />
                    </div>
                  </div>

                  <div className="col-sm-3 col-xs-12">
                    <div className="form-group lottery-digit">
                      <label className="mont-font fw-600 font-xsss mb-2">จำนวนชุด</label>
                      <input type="text" name="set" value={this.state.set} onChange={this.handleInputChange} className="form-control" />
                    </div>
                  </div>

                  <div className="col-sm-3 col-xs-12">
                    <div className="form-group lottery-digit">
                      <label className="mont-font fw-600 font-xsss mb-2">วันที่สลาก</label>
                      <DatePicker selected={this.state.installDate_input} closeOnScroll={true}
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        className="form-control"
                        showPopperArrow={false}
                        placeholderText="dd/MM/yyyy"
                        onChange={(date) => this.handleInputDateChange(date, "installDate", "installDate_input")} />
                    </div>
                  </div>

                  <div className="col-sm-3 col-xs-12">
                    <label className="mont-font fw-600 font-xsss mb-2">&nbsp;</label>
                    <button onClick={this.handleSubmit} className="update-cart bg-blue-gradiant d-block text-white fw-600 text-uppercase 
                    font-xs rounded-3 border-size-md p-2 text-center ls-3 w-100 border-0">ค้นหา</button>
                  </div>


                </div>

              </div>


              <div className="row payment-list mb-show">
                <div className="col-xl-12 cart-wrapper mb-0">
                  {
                    agent_number_list != null &&
                    agent_number_list.map((data, index) => {
                      return (
                        <div key={index} className="row">
                          <div className="col-lg-12 mb-3 " >
                            <div className="card p-3 pb-0 rounded-3 shadow-xs border-0 bg-grey font-xss">                         
                              <div className="">
                                เลขสลาก: <strong>{data.numberId}</strong>
                              </div>
                              <div className="">
                                วันที่สลาก: <strong>{data.installDate}</strong>
                              </div>
                              <div className="p-3">
                                {
                                  data.fid !== null &&
                                  <div className={`nav-content-bttn rounded-3 text-white bg-cyan open-font me-3 d-inline-block w90 float-left lotto-view-btn lotto-view-btn-${index} hide`}
                                    onClick={() => this.handleClickViewDetail(data)} >
                                    <i className="feather-zoom-in btn-round-xs"></i><span>ดูสลาก</span>
                                  </div>
                                }
                              </div>
                            </div>
                            {/* <div className="card p-3 rounded-3 shadow-xs border-0 bg-grey font-xss">
                              <div className="">
                              </div>
                            </div> */}

                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>

              <div className="row payment-list mb-hide">
                <div className="col-lg-12 mb-3">
                  <div className="table-content table-responsive">
                    <table className="table text-center">
                      <thead className="bg-grey rounded-3">
                        <tr>
                          <th className="border-0 p-4">No</th>
                          <th className="border-0 p-4">เลขสลาก</th>
                          <th className="border-0 p-4">จำนวนชุด</th>
                          <th className="border-0 p-4">งวดประจำวันที่</th>
                          <th className="border-0 p-4">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          agent_number_list != null &&
                          agent_number_list.map((data, index) => {
                            const rowClass = index % 2 === 0 ? '' : 'bg-lightblue'
                            // let number_status = ""
                            // number_status = data.numberStatus === 'A' ? 'พร้อมขาย' : number_status
                            // number_status = data.numberStatus === 'W' ? 'อยู่ในตะกร้า' : number_status
                            // number_status = data.numberStatus === 'P' ? 'รอชำระ' : number_status
                            // number_status = data.numberStatus === 'S' ? 'ขายแล้ว' : number_status
                            // number_status = data.numberStatus === 'C' ? 'ยกเลิก' : number_status
                            return [
                              <tr key={index} className={`data-row ${rowClass}`}>
                                <td className="product-thumbnail ps-0">{index + 1}</td>                        
                                <td className="product-quantity">
                                  {data.numberId}
                                </td>
                                <td className="product-quantity">
                                  {data.packNum}
                                </td>
                                <td className="product-quantity">
                                  {data.installDate}
                                </td>
                                <td className="product-quantity">
                                  {
                                   <div className={`nav-content-bttn rounded-3 text-white bg-cyan open-font me-3 d-inline-block w90 float-right lotto-view-btn lotto-view-btn-${index} hide w125 mb-3`}
                                      onClick={() => this.handleClickViewDetail(data)} >
                                      <i className="feather-zoom-in btn-round-xs"></i><span>ดูสลาก</span>
                                    </div>
                                  }
                                </td>
                              </tr>
                            ]
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* CONTENT ABOVE */}

            </div>          
          </div>
            
        </div>
        </div>
        </div>

        {/* <Popupchat /> */}
        {/* <Appfooter />  */}
      </Fragment>
    );
  }
}


const mapStateToProps = function(state, props){
  return {
    agent_number_list: state.agent.agent_number_list || [],
  }
}

const NumberGroupSearch = connect(mapStateToProps)(NumberGroupSearchComponent)
export default NumberGroupSearch;
