import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import Lottery from "./Lottery";
import { showDisplayPopupError } from "../actions/display";

const PopupLotteryComponent = (props) => {
  const { is_show, data, type, onSubmit, onClose, user_detail } = props;

  const dispatch = useDispatch();

  const [total, setTotal] = useState(1);
  const [title, setTitle] = useState("ซื้อทันที");
  const [buttonText, setButtonText] = useState("สั่งซื้อ");
  const [isCustomerDealer, setIsCustomerDealer] = useState(false);

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     total: 1,
  //   };
  //   this.handleInputChange = this.handleInputChange.bind(this);
  //   this.handleSubmit = this.handleSubmit.bind(this);
  // }

  const close = () => {
    setTotal(1);
    onClose();
  };

  // const handleInputChange = (event) => {
  //   console.log("handleInputChange");
  //   const target = event.target;
  //   const value = target.type === "checkbox" ? target.checked : target.value;
  //   const name = target.name;
  //   this.setState({
  //     [name]: value,
  //   });
  // };

  const validateFormRequiredField = () => {
    let errorArray = [];
    if (total === "") {
      errorArray.push("จำนวน");
    }
    if (errorArray.length > 0) {
      return {
        status: false,
        message: `<b>กรุณากรอกข้อมูล</b><br/>- ${errorArray.join("<br/>- ")}`,
      };
    }
    return {
      status: true,
    };
  };

  const showErrorPopup = (title, message) => {
    dispatch(
      showDisplayPopupError({
        title: title,
        message: message,
      })
    );
  };

  const handleSubmit = () => {
    //TODO Validate value before submit to backend
    // console.log("state", state);
    const validated = validateFormRequiredField();
    if (!validated.status) {
      showErrorPopup("Error!!", validated.message);
    } else {
      onSubmit(data, type);
    }
  };

  useEffect(() => {
    setTitle(type === "add_to_cart" ? "หยิบลงตะกร้า" : "ซื้อทันที");
    setButtonText(type === "add_to_cart" ? "ใส่ลงตะกร้า" : "สั่งซื้อ");
  }, [type]);

  useEffect(() => {
    setIsCustomerDealer(user_detail?.is_dealer && user_detail?.is_customer);
  }, [user_detail]);

  return (
    <Modal centered backdrop="static" show={is_show}>
      <article className={`message lottery-search-result is-info`}>
        <div className="message-header bg-cyan">
          <p>{title}</p>
          {/* <button className="delete" aria-label="delete"></button> */}
        </div>
        <div className="message-body">
          <div
            style={{
              color: "#ff0000",
              textAlign: "center",
              fontSize: "24px",
            }}
          >
            เมื่อชำระเงินเสร็จ กรุณาตรวจสอบลอตเตอรี่ในตู้เซฟทุกครั้ง
          </div>
          <br />
          <Lottery data={data} />
          <div className="row pt-3">
            <div className="col-6">
              <div className="form-group mb-1">
                <label className="text-dark-color text-grey-600 font-xss mb-2 fw-600">
                  ราคาสลาก (บาท)
                </label>
                <div className="form-group icon-tab">
                  <input
                    type="text"
                    disabled
                    value={data?.basisAmount}
                    className="bg-none border-0 rounded-3 form-control bg-color-none border-bottom font-lg fw-700"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group mb-1">
                <label className="text-dark-color text-grey-600 font-xss mb-2 fw-600">
                  ค่าบริการฝากลอตเตอรี่ (บาท)
                </label>
                <div className="form-group icon-tab">
                  <input
                    type="text"
                    disabled
                    value={
                      isCustomerDealer ? data?.dealerFeeAmount : data?.feeAmount
                    }
                    className="bg-none border-0 rounded-3 form-control bg-color-none border-bottom font-lg fw-700"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group mb-1">
                <label className="text-dark-color text-grey-600 font-xss mb-2 fw-600">
                  จำนวนรวม (บาท)
                </label>
                <div className="form-group icon-tab">
                  <input
                    type="text"
                    disabled
                    value={
                      isCustomerDealer
                        ? data?.dealerTotalAmount
                        : data?.totalAmount
                    }
                    className="bg-none border-0 rounded-3 form-control bg-color-none border-bottom font-lg fw-700"
                  />
                </div>
              </div>
            </div>
            {isCustomerDealer && (
              <div className="col-6">
                <div className="form-group mb-1">
                  <label className="text-dark-color text-grey-600 font-xss mb-2 fw-600">
                    ราคาลูกค้าทั่วไป
                  </label>
                  <div className="form-group icon-tab">
                    <input
                      type="text"
                      disabled
                      value={data?.totalAmount}
                      min="1"
                      className="bg-none border-0 rounded-3 form-control bg-color-none border-bottom font-lg fw-400 text-red"
                      style={{ textDecoration: "line-through" }}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* <div className="col-5">
              <div className="form-group mb-1">
                  <label className="text-dark-color text-grey-600 font-xss mb-2 fw-600"></label>
                  <div className="form-group icon-tab">
                    <input type="hidden" name="total" value={this.state.total} onChange={this.handleInputChange} 
                          min="1"
                          className="bg-white border-0 rounded-3 form-control ps-4 bg-color-none border-bottom font-lg fw-700" 
                          placeholder="1" />                            
                  </div>
              </div>
            </div> */}
            <div className="col-sm-8 col-xs-7 pt-3">
              <button
                className="button is-success w-100 bg-cyan"
                onClick={() => handleSubmit()}
              >
                {buttonText}
              </button>
              {/* <button onClick={this.handleSubmit} className="rounded-3 bg-current p-2 w-100 fw-600 fw-700 border-0
                text-center font-xs mont-font text-uppercase ls-3 text-white d-block">{buttonText}</button> */}
            </div>
            <div className="col-sm-4 col-xs-5 pt-3">
              <button
                className="button is-danger w-100"
                onClick={() => close()}
              >
                ยกเลิก
              </button>
              {/* <button onClick={() => this.close()} className="rounded-3 bg-red-gradiant p-2 w-100 fw-600 fw-700 border-0
                text-center font-xs mont-font text-uppercase ls-3 text-white d-block">ยกเลิก</button> */}
            </div>
          </div>
        </div>
      </article>

      {/* <Alert className="lottery-search-result" show={is_show} variant={variant}>
          <h1>{title}</h1>
          <h3>กรุณาใส่จำนวนที่ต้องการ</h3>
          <Lottery data={data}/>
          <div className="row pt-3">
            <div className="col-5">
              <div className="form-group mb-1">
                  <label className="text-dark-color text-grey-600 font-xss mb-2 fw-600">ราคา (บาท)</label>
                  <div className="form-group icon-tab">
                      <input type="text" disabled value={data !== null ? data.packAmount: ""}
                      className="bg-none border-0 rounded-3 form-control bg-color-none border-bottom font-lg fw-700" />                         
                  </div>
              </div>
            </div>
            <div className="col-7">
              <div className="form-group mb-1">
                  <label className="text-dark-color text-grey-600 font-xss mb-2 fw-600">จำนวน</label>
                  <div className="form-group icon-tab">
                      <input type="number" name="total" value={this.state.total} onChange={this.handleInputChange} 
                        min="1"
                        className="bg-white border-0 rounded-3 form-control ps-4 bg-color-none border-bottom font-lg fw-700" 
                        placeholder="1" />                         
                  </div>
              </div>
            </div>
            <div className="col-sm-8 col-xs-7 pt-3">
              <button onClick={this.handleSubmit} className="rounded-3 bg-current p-2 w-100 fw-600 fw-700 border-0
                text-center font-xs mont-font text-uppercase ls-3 text-white d-block">{buttonText}</button>
            </div>
            <div className="col-sm-4 col-xs-5 pt-3">
              <button onClick={() => this.close()} className="rounded-3 bg-red-gradiant p-2 w-100 fw-600 fw-700 border-0
                text-center font-xs mont-font text-uppercase ls-3 text-white d-block">ยกเลิก</button>
            </div>
          </div>
          
          
        </Alert> */}
    </Modal>
  );
};

const mapStateToProps = function (state, props) {
  return {
    // is_show: state.display.popup.is_show || false,
    // title: state.display.popup.title || "",
    // message: state.display.popup.message || "",
    // submit_button_text: state.display.popup.submit_button_text || "",
    // cancel_button_text: state.display.popup.cancel_button_text || "",
    // submit_function: state.display.popup.submit_function || null,
    // is_show_submit_button: state.display.popup.is_show_submit_button || false,
    // is_show_cancel_button: state.display.popup.is_show_cancel_button || false,
    // status: state.display.popup.status || "success",
    user_detail: state.user.user_detail || null,
  };
};

const PopupLottery = connect(mapStateToProps)(PopupLotteryComponent);
export default PopupLottery;
